<template>
    <div>
        <svg style="width: 150%;margin-left: -10%;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 812.8 267.9">
            <defs>
                <clipPath id="a" transform="translate(38.43 31.03)">
                    <path class="a" d="M373.85,0H187.7V138.25c43.36,24.38,77.35,32.39,192.52-28V88.82l-6.37-.28Z"/>
                </clipPath>
                <clipPath id="b" transform="translate(38.43 31.03)">
                    <rect class="b" x="186.77" y="-1.05" width="194.88" height="158.97"/>
                </clipPath>
                <clipPath id="c" transform="translate(38.43 31.03)">
                    <path class="a" d="M125.79,112.51c32.08,7.27,69.19,42.18,109.69,48.18s103.5-24,185.81-66.18,124.58-55.13,174-60.38V66.76a798.47,798.47,0,0,1-108,69c-62.25,33-61.61,10.9-65.44-.75s-46.31,20.62-85.68,36-57.57,14.53-68.82,7.78-35.81,3.47-35.81,3.47-22.5,8.25-33.75,3-8.44-20.44-27.19-39.19S104.46,95.82,0,118.51c37.32-16,93.71-13.28,125.79-6Zm238.64,56.34s20.91-7.88,30.28-7.5,21,10.69,25.6,12.75,14.25,10.5,58.12-10.88S595.06,88.6,595.06,88.6V74.72c-76.18,61.4-151.58,98.6-168.38,92.63s-15.75-10.13-27-11.25-35.25,12.75-35.25,12.75Zm230.63-59.39a388.33,388.33,0,0,0-54.92,28.12c-30,18.38-99.75,61.89-122.62,57.75-8.45-1.53-10.32-15-27-21S332.16,187,323.39,189.52s-48.74,17.43-63.47,5.53c2.91,10,20.12,12.36,24.19,12.84,18.19,2.16,62.73-19.36,88.42-21.9s25.67,16.84,39.92,22.09,31.38-.91,49.88-9.37c17.62-8.07,54.19-31.13,80.06-45.38S595.06,123,595.06,123Z"/>
                </clipPath>
                <clipPath id="d" transform="translate(38.43 31.03)">
                    <polygon class="a" points="-21.93 233.57 -21.02 -31.08 608.91 -27.69 608.01 236.96 -21.93 233.57"/>
                </clipPath>
            </defs>
            <g>
                <g class="c">
                    <g class="d">
                        <image width="260" height="213" transform="translate(225.19 29.97) scale(0.75 0.75)" xlink:href="../assets/division.png"/>
                    </g>
                </g>
                <path class="e" d="M125.79,112.51c32.08,7.27,69.19,42.18,109.69,48.18s103.5-24,185.81-66.18,124.58-55.13,174-60.38V66.76a798.47,798.47,0,0,1-108,69c-62.25,33-61.61,10.9-65.44-.75s-46.31,20.62-85.68,36-57.57,14.53-68.82,7.78-35.81,3.47-35.81,3.47-22.5,8.25-33.75,3-8.44-20.44-27.19-39.19S104.46,95.82,0,118.51c37.32-16,93.71-13.28,125.79-6Zm238.64,56.34s20.91-7.88,30.28-7.5,21,10.69,25.6,12.75,14.25,10.5,58.12-10.88S595.06,88.6,595.06,88.6V74.72c-76.18,61.4-151.58,98.6-168.38,92.63s-15.75-10.13-27-11.25-35.25,12.75-35.25,12.75Zm230.63-59.39a388.33,388.33,0,0,0-54.92,28.12c-30,18.38-99.75,61.89-122.62,57.75-8.45-1.53-10.32-15-27-21S332.16,187,323.39,189.52s-48.74,17.43-63.47,5.53c2.91,10,20.12,12.36,24.19,12.84,18.19,2.16,62.73-19.36,88.42-21.9s25.67,16.84,39.92,22.09,31.38-.91,49.88-9.37c17.62-8.07,54.19-31.13,80.06-45.38S595.06,123,595.06,123Z" transform="translate(38.43 31.03)"/>
                <g class="f">
                    <g class="g">
                        <image width="2000" height="3000" transform="matrix(0, -0.13, 0.27, 0, 0, 264.6)" xlink:href="../assets/goldBg.png"/>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Poster1',
        methods:{
        },
        mounted() {

        },
        created() {
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .a, .b {
        fill: none;
    }

    .a {
        clip-rule: evenodd;
    }

    .c {
        clip-path: url(#a);
    }

    .d {
        clip-path: url(#b);
    }

    .e {
        fill: #ff0;
        fill-rule: evenodd;
    }

    .f {
        clip-path: url(#c);
    }

    .g {
        clip-path: url(#d);
    }
</style>
