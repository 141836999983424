<template>
    <div>
		<!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<?xml version="1.0" encoding="utf-8"?>
		<!-- Generator: Adobe Illustrator 25.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
		<svg version="1.1" xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
			 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-10 0 491.5 283.6"
			 style="width: 80%" xml:space="preserve">
				<path class="st0" d="M11.3,0h129.6c6.2,0,11.3,5.1,11.3,11.3V43c0,6.2-5.1,11.3-11.3,11.3H11.3C5.1,54.4,0,49.3,0,43V11.3
				C0,5.1,5.1,0,11.3,0z"/>
			<g id="_2075215966688">
				<defs>
<!--					rgba(144,1,05,0.2)-->
					 <filter id="shadow" x="0" y="0" width="200%" height="200%">
						 <feOffset result="offOut" in="SourceGraphic" dx="3" dy="3"></feOffset>
						 <feColorMatrix  result="matrixOut" in="SourceGraphic" :values='(144/255)+" 0 0 0  0 "+(1/255)+" 0 0  0 0 "+(1/255)+" 0 0 0 0 0.2  0 0 1 0"'></feColorMatrix>
						 <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="2"></feGaussianBlur>
						 <feBlend in="rgb(144,1,5)" in2="blurOut" mode="normal"></feBlend>
    				</filter>
				</defs>
		<path class="shadow" d="M263.2,242.8c-0.7,0-1.4-0.4-1.8-1.1l-6-11.4c-0.3-0.5-0.3-1.1-0.1-1.7c0.2-0.5,0.7-1,1.2-1.1
			c4.3-1.3,7.6-2.4,10-3.2c0.1-7.5,0.1-23.6,0.1-49.2c0-15.4,0-24.7-0.1-25.9c-0.2-3-0.8-5.7-2-8.1c-0.3-0.3-0.5-0.7-0.6-1.1
			c-0.1-0.6,0.1-1.2,0.5-1.7l1.7-1.9c0.4-0.4,0.9-0.6,1.4-0.7c0,0,0,0,0.1,0c0.4,0,0.7,0.1,1.1,0.3l11.3,3.5c5.9,0,11.3,0,16.2-0.1
			c-1.6-2.6-3.2-5-5-7.2c-0.6-0.7-0.6-1.8,0-2.5l2.9-3.5c0.4-0.5,1-0.7,1.5-0.7c0.2,0,0.4,0,0.6,0.1c3.1,1,6.2,2.7,9.2,4.9
			c2.9,2.1,5.3,4.6,7.2,7.2c0.2,0.2,0.3,0.5,0.3,0.8c5.2-0.5,10.1-1.2,14.8-2l2.7-1.5c0.3-0.2,0.6-0.2,0.9-0.2c0.4,0,0.7,0.1,1,0.3
			l10.1,6.2c0.8,0.5,1.2,1.6,0.8,2.5l-1.7,4.2v34.2c0.4,0,0.8,0.1,1.2,0.4c0.3,0.2,0.5,0.5,0.6,0.8l8.1,10.6
			c0.4,0.5,0.5,1.1,0.4,1.7c-0.1,0.6-0.5,1-1,1.3c-3.2,3-6.9,5.5-11,7.3c-3.2,1.5-6.6,2.7-10,3.5c4.1,4.4,8.9,8.5,14.1,12.1
			c6.2,4.3,12.2,7.6,18.4,10c0.8,0.3,1.3,1.2,1.3,2c-0.1,0.9-0.7,1.6-1.6,1.8c-3.1,0.6-5.9,1.6-8.4,3c-2.8,1.5-5.1,3.3-7,5.4
			c-0.4,0.4-0.9,0.7-1.5,0.7c-0.3,0-0.6-0.1-0.9-0.2c-10.2-5.4-18.9-13.3-25.8-23.5c-6.4-9.5-11.2-20.4-14.1-32.2
			c-10.2,0.7-17.4,1.2-22.3,1.6v30.5c8.5-3.1,16.6-6.6,24.1-10.3c0.3-0.1,0.6-0.2,0.9-0.2c0.6,0,1.2,0.3,1.6,0.8l1.2,1.6
			c0.6,0.8,0.5,2-0.3,2.7c-14.1,12-29.4,21.8-45.7,29.2C263.8,242.7,263.5,242.8,263.2,242.8z M313.5,186.1c2.9,4.9,6,9.5,9.4,13.7
			c1.8-1.1,3.2-2,4.3-3c1.6-1.3,4-3.4,7.1-6.1c2-2,3.1-3.8,3.3-5.5l-8.7,4.1c-0.3,0.1-0.6,0.2-0.8,0.2c-0.4,0-0.7-0.1-1.1-0.3
			c-0.6-0.4-0.9-1-0.9-1.7v-1.9C320.9,185.8,316.7,186,313.5,186.1z M282.1,176.1c9.9-0.1,17.5-0.3,23.9-0.7
			c6.8-0.4,13.4-1.1,19.8-2.1v-6.6c-13.7,0.6-28.4,1.9-43.7,3.7V176.1z M282.1,157.9c11.8-0.1,19.4-0.4,25.2-0.8
			c6.2-0.4,12.2-1.3,18.5-2.8v-5c-7.8,0.3-21.4,1.1-43.7,2.6V157.9z"/>
				<path class="shadow" d="M280.1,178.1c9.9-0.1,18.6-0.3,26-0.7s14.7-1.2,21.7-2.4v-10.4c-14.5,0.6-30.4,1.9-47.7,4V178.1L280.1,178.1z
			 M280.1,150v9.9c11.3-0.1,20.4-0.3,27.3-0.8c7-0.4,13.7-1.5,20.4-3.2v-8.7C317.8,147.6,301.9,148.5,280.1,150z M343.1,221.4
			c6.2,4.3,12.4,7.7,18.8,10.2c-3.1,0.6-6.1,1.6-9,3.2c-2.9,1.6-5.4,3.5-7.5,5.8c-10.1-5.3-18.4-12.9-25.1-22.8
			c-6.7-9.9-11.4-21-14.2-33.2c-11.9,0.8-20.5,1.4-25.9,1.9v35.2c9.6-3.4,18.6-7.2,27-11.4l1.2,1.6c-14.1,12-29.2,21.6-45.2,28.9
			l-6-11.4c5.1-1.6,8.9-2.8,11.4-3.7c0.1-6.8,0.1-23.7,0.1-50.6c0-16.1,0-24.8-0.1-26.1c-0.2-3.4-1-6.5-2.5-9.3l-0.2-0.1l1.7-1.9
			l0.1,0.1l12,3.7c8.9,0,15.5-0.1,19.9-0.2c-2.1-3.8-4.4-7.3-6.8-10.4l2.9-3.5c3,1,5.9,2.6,8.6,4.6s5,4.3,6.8,6.8l-2.5,2
			c6.6-0.5,13.3-1.3,19.9-2.5l3-1.6l10.1,6.2l-1.9,4.6v34.6l-11.6,5.4v-4c-8.1,0.3-14,0.6-17.9,0.8c3.7,6.7,7.8,12.7,12.3,18.1
			c2.4-1.4,4.4-2.7,6-4s4-3.4,7.2-6.2c2.6-2.6,3.9-5,4-7.4v-0.1l2-0.6v0.1l8.4,10.9l-0.2,0.1c-3,2.9-6.5,5.3-10.6,7.1
			c-4.1,1.9-8.5,3.3-13,4.1C331.2,212.1,336.9,217.1,343.1,221.4L343.1,221.4z"/>
				<path class="shadow" d="M357.2,260c-0.4,0-0.9-0.1-1.2-0.4l-1.9-1.5c-0.5-0.4-0.7-0.9-0.8-1.5c0-0.6,0.2-1.1,0.6-1.5
			c15.2-14.5,23-39.2,23-73.5c0-3.9-0.1-8.2-0.2-12.7l0-1.2c-0.1-0.7-0.1-1.6-0.1-2.7c0,0,0-0.1,0-0.1c0.1-2.1-0.2-3.6-1-4.1
			c-0.3-0.2-0.6-0.5-0.8-0.8l-0.1-0.2c-0.4-0.8-0.2-1.9,0.5-2.5l1.9-1.5c0.4-0.3,0.8-0.4,1.2-0.4c0.2,0,0.4,0,0.5,0.1
			c3.4,0.8,6.6,1.8,9.9,3l4.9-0.1l6-0.3c6.1-0.3,10.9-0.5,14.3-0.6c-1.5-2.5-3.1-4.7-4.8-7c-0.6-0.8-0.6-1.8,0-2.6l3-3.5
			c0.4-0.5,0.9-0.7,1.5-0.7c0.2,0,0.4,0,0.7,0.1c3.1,1.1,6.1,2.7,8.9,4.8c2.9,2.1,5.2,4.5,7.1,7.1c0.1,0.2,0.2,0.4,0.3,0.6
			c10.3-0.9,18.6-1.8,24.8-2.8l0.4-0.1c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0.1,0.9,0.2l0.2,0.1c0.3,0.1,0.5,0.3,0.7,0.5l7.3,9
			c0.5,0.6,0.6,1.4,0.3,2.1c-0.3,0.7-1,1.1-1.7,1.2c0,0-1.9,0.1-1.9,0.1c-12.2,0.1-26.7,0.7-43.6,2l9.4,2.7c0.8,0.2,1.4,1,1.5,1.8
			c0.1,1.9,0.1,4.5,0.1,7.9c0,3.1-0.1,5.8-0.2,8.3c11.1-0.7,20.2-1.8,28-3.2c0.1,0,0.2,0,0.4,0c0.7,0,1.3,0.3,1.7,0.9l6.3,9.2
			c0.4,0.6,0.5,1.4,0.1,2.1c-0.3,0.7-1,1.1-1.8,1.1c-10.4,0.1-20.9,0.5-31.2,1.1c3.1,9.4,7.8,18,13.9,25.7
			c6.7,8.6,14.5,15.7,23.7,21.6c0.7,0.5,1.1,1.3,0.9,2.2s-0.9,1.4-1.8,1.5c-4.2,0.4-8.7,2.4-13.3,6.2c-0.4,0.3-0.8,0.4-1.2,0.4
			c-0.4,0-0.9-0.1-1.2-0.4c-8.1-6.4-14.9-14.8-20.1-25c-3.4-6.6-5.9-13.5-7.5-20.5c-2,7.5-4.7,14-8.3,19.8
			c-4.4,6.8-10.1,12.6-16.8,17.2c-7,4.7-14.4,7.8-22.2,9.1c-0.1,0-0.2,0-0.3,0c-0.5,0-1-0.2-1.4-0.6c-0.5-0.5-1-1.1-1.5-1.8
			c-0.3-0.5-0.4-1.1-0.3-1.7c0.2-0.6,0.6-1,1.1-1.3c9.7-4.4,17.7-11.5,23.7-21.1c5.7-8.9,9.7-19,11.8-30l-13.8,1.2
			c-0.1,0-0.1,0-0.2,0c-0.6,0-1.2-0.3-1.6-0.8l-4.3-6c-1,12.1-2.7,22-5.4,30.1c-2.5,7.6-6.3,14.4-11.1,20.2
			c-4.9,5.8-10.8,10.3-17.5,13.2C357.7,259.9,357.5,260,357.2,260z M392.8,173.9c0,5.8-0.1,11.2-0.4,16.3l20.9-0.2
			c0.2-2.4,0.3-4.8,0.3-7.1c0-2.1,0-3.8-0.1-4.8c0-0.1,0-0.1,0-0.2c0-2-0.5-3.8-1.6-5.3c-0.6-0.9-0.5-2.1,0.4-2.7l0.9-0.7
			c-6.4,0.5-13.2,1.1-20.3,1.8v2.4C392.9,173.5,392.9,173.7,392.8,173.9z"/>
				<path class="shadow" d="M445,227.4c6.8,8.7,14.8,16,24.2,22l0,0c-4.6,0.4-9.4,2.6-14.4,6.6c-8-6.3-14.5-14.4-19.6-24.3
			c-5.1-9.9-8.1-20.2-9-30.9c-1.8,11.9-5.2,21.9-10.3,30.1c-4.2,6.5-9.6,12.1-16.2,16.6s-13.7,7.5-21.4,8.8
			c-0.4-0.4-0.8-0.9-1.2-1.5c10.1-4.6,18.3-11.9,24.6-21.8c6.3-9.9,10.5-21,12.6-33.3l-16.4,1.4l-6.4-8.9l23.7-0.2
			c0.2-3.1,0.4-6.1,0.4-9.1c0-2.2,0-3.9-0.1-5c0-2.5-0.7-4.7-2-6.5l1.9-1.5l12,3.4c0.1,1.8,0.1,4.4,0.1,7.8c0,3.6-0.1,7.1-0.4,10.4
			c11.9-0.7,22-1.8,30.5-3.4l6.3,9.2c-10.8,0.1-22.1,0.5-33.9,1.3C433.2,209.2,438.2,218.8,445,227.4L445,227.4z M390.8,173.3
			c0,22.5-2,40-6.1,52.5c-2.5,7.5-6.1,14-10.7,19.5s-10.2,9.8-16.8,12.7l-1.9-1.5c15.7-14.9,23.6-39.8,23.6-74.9
			c0-4.3-0.1-9-0.2-14.1c-0.1-0.6-0.1-1.5-0.1-2.5c0.2-3-0.5-5-2-5.9l-0.1-0.2l1.9-1.5h0.1c3.9,0.9,7.2,2,10,3.1l5.3-0.1
			c11.7-0.5,19.5-0.8,23.6-1.1c-2-3.7-4.2-7-6.6-10l3-3.5c2.9,1,5.7,2.5,8.4,4.5c2.7,2,4.9,4.2,6.7,6.7l-2.2,1.8
			c12.8-1,22.5-2.1,29.2-3.2l0.5-0.1l0.2,0.1l7.3,9l-1.8,0.1c-17.8,0.1-41.5,1.5-71.2,4.4L390.8,173.3L390.8,173.3z"/>
				<path class="shadow" d="M110.7,225.1c-0.4,0-0.9-0.1-1.2-0.4c-0.5-0.4-0.8-1-0.8-1.6l0.1-25c0,0-29,1.1-29,1.1
			c-0.6,0-1.2-0.3-1.6-0.8l-5.5-7.3c-0.5-0.6-0.5-1.4-0.2-2.1s1-1.1,1.8-1.1c6.2,0,10.6,0,13.8,0.1l13.7,0.1l7.1-0.1
			c0-0.2,0-0.4,0-0.5c-0.1-1.7-0.4-3.2-0.9-4.7c-8.6,0.4-15.5,0.6-20.2,0.9c0,0-3.3,0.1-3.3,0.1c-0.6,0-1.2-0.3-1.6-0.8l-5.5-7.1
			c-0.5-0.6-0.6-1.4-0.2-2.1c0.3-0.7,1-1.1,1.8-1.1c0,0,15.2,0.1,15.2,0.1c-1.1-1.7-2.3-3.4-3.6-5c-0.6-0.8-0.6-1.9,0.1-2.6l0.3-0.3
			l-1,0c0,0-14.9,0.8-14.9,0.8c-0.6,0-1.2-0.3-1.6-0.8l-5.5-7.1c-0.5-0.6-0.5-1.4-0.2-2.1s1-1.1,1.8-1.1l17.7-0.3
			c8.2-0.1,14.9-0.2,19.8-0.3l1.9,0c0-1.7,0-3.8,0-6.4c0,0-21.2,1.1-21.2,1.1c-0.6,0-1.2-0.3-1.6-0.8l-5.5-7.2
			c-0.5-0.6-0.5-1.4-0.2-2.1c0.3-0.7,1-1.1,1.8-1.1c0,0,16.4,0.1,16.4,0.1c1.9,0,4.6-0.1,7.9-0.1l2.4,0l0-1.1
			c-0.2-2.3-0.7-4.6-1.6-6.6c-0.3-0.3-0.5-0.7-0.6-1.1c-0.1-0.6,0.1-1.3,0.6-1.7l1.4-1.4c0.4-0.4,0.9-0.6,1.4-0.6h0.1
			c0.2,0,0.4,0,0.6,0.1l10.5,3.2c0.8,0.3,1.4,1,1.4,1.9v6.8c7.3-0.5,13.2-1.2,18.6-2.1c0.1,0,0.2,0,0.3,0c0.6,0,1.2,0.3,1.6,0.8
			l5.3,7.3c0.4,0.6,0.5,1.4,0.2,2.1c-0.3,0.7-1,1.1-1.7,1.1c-9.7,0.3-18.3,0.6-24.2,0.9v6.7c13.3-0.7,24.2-1.8,33.4-3.3
			c0.1,0,0.2,0,0.3,0c0.6,0,1.2,0.3,1.6,0.8l5.5,7.3c0.4,0.6,0.5,1.4,0.2,2.1c-0.3,0.7-1,1.1-1.7,1.1l-25,1.1l1.2,0.8
			c0.7,0.5,1,1.4,0.8,2.3L139,166c0,0.1,0,0.1-0.1,0.2c-0.7,1.6-1.7,3.2-2.9,4.6c2.8-0.3,5.6-0.7,8.3-1.2c0.1,0,0.2,0,0.3,0
			c0.6,0,1.2,0.3,1.6,0.8l5.3,7.4c0.4,0.6,0.5,1.4,0.2,2.1c-0.3,0.7-1,1.1-1.8,1.1c-5.7,0.1-13.6,0.4-22.7,0.8l-1.5,0.1
			c-0.9,0-1.8,0.1-3,0.1v5.6c10.8-0.3,20.7-1.2,29.4-2.5c0.1,0,0.2,0,0.3,0c0.7,0,1.3,0.3,1.7,0.9l4.9,7.4c0.4,0.6,0.4,1.4,0.1,2
			c-0.3,0.6-1,1.1-1.7,1.1c-11.6,0.3-23.4,0.7-35,1.1v22.8c0,0.9-0.6,1.7-1.5,1.9l-9.8,2.7C111.1,225.1,110.9,225.1,110.7,225.1z
			 M100.6,164.4c0.8,0.5,1.5,0.9,2.3,1.5c2.4,1.6,4.4,3.6,6.2,5.9c0.2,0.2,0.3,0.5,0.3,0.7c4-0.1,7.6-0.2,10.6-0.4
			c0.9-1.1,1.8-2.6,2.9-4.4c0.2-0.4,0.4-0.7,0.6-1.1c0.3-0.5,0.5-0.9,0.6-1.2c0.4-0.8,0.7-1.5,0.9-2.3L100.6,164.4z"/>
				<path class="shadow" d="M157.5,194.5c-12.4,0.3-24.7,0.7-37,1.2v24.7l-9.8,2.7l0.1-27.1l-31,1.2l-5.5-7.3c6.1,0,10.6,0,13.7,0.1
			l13.7,0.1l9.2-0.1c-0.1-0.7-0.1-1.6-0.1-2.6c-0.2-2.4-0.7-4.6-1.5-6.7c-9.6,0.4-16.8,0.7-21.7,1l-3.2,0.1l-5.5-7.1l18.7,0.1
			c-1.7-3.1-3.6-5.9-5.5-8.3l2.6-2.7c2.5,0.9,4.8,2.1,7.1,3.7c2.3,1.6,4.2,3.5,5.7,5.5l-2,1.6c6.9-0.1,12.1-0.3,15.5-0.5
			c1.1-1.3,2.3-3.1,3.6-5.4c0.5-0.9,0.9-1.7,1.2-2.2c1.1-2,1.5-3.8,1.3-5.4l-37.3,1.8L75,163.7l-5.5-7.1l17.7-0.3
			c10.5-0.1,18.4-0.3,23.6-0.4c0.1-2.1,0.1-5.6,0.1-10.5l-23.2,1.2l-5.5-7.2l16.4,0.1c2.7,0,6.8-0.1,12.4-0.2l-0.1-3.1
			c-0.2-2.9-0.9-5.6-2-7.9l-0.2-0.1l1.4-1.4h0.1l10.5,3.2v8.9c7.6-0.5,14.5-1.2,20.9-2.3l5.3,7.3c-11.7,0.4-20.4,0.7-26.1,1v10.7
			c13.9-0.7,25.8-1.8,35.7-3.4l5.5,7.3l-30.9,1.4l6.1,4.2l-0.1,0.3c-1.3,3-3.6,5.7-6.8,8c4.8-0.4,9.6-1,14.4-1.8l5.3,7.4
			c-5.5,0.1-13.1,0.4-22.7,0.8c-2.1,0.1-5.2,0.3-9.5,0.4l3.1,1v8.5c12-0.3,22.6-1.2,31.7-2.6L157.5,194.5L157.5,194.5z"/>
				<path class="shadow" d="M173.8,260c-0.4,0-0.8-0.1-1.2-0.4c-0.5-0.4-0.8-1-0.8-1.6l0.1-31.3c0-4.6,0-8.2,0-10.8
			c-5.6,5.2-11.2,9.5-17.3,13.1c-0.3,0.2-0.7,0.3-1,0.3h-0.1c-0.4,0-0.9-0.2-1.2-0.4l-1.4-1.1c-0.5-0.4-0.8-1.1-0.8-1.8
			c0.1-0.6,0.4-1.2,0.9-1.5c6.5-7.6,12.4-15,17.5-21.9c4.5-6,8.4-11.9,11.8-18c-2.5,0.2-4.9,0.5-7.1,0.9c-3.1,0.6-6.1,1.4-9,2.6
			c-0.2,0.1-0.5,0.1-0.7,0.1c-0.6,0-1.2-0.3-1.6-0.8l-5.7-7.5c-0.5-0.6-0.5-1.4-0.2-2.1c0.3-0.7,1-1.1,1.7-1.1
			c6.1-0.3,10.8-0.6,14.5-1c-1.5-4.1-3.1-7.3-5-9.6c-0.3-0.4-0.5-1-0.4-1.5c0.1-0.5,0.4-1,0.8-1.3l3.4-2.4c0.3-0.2,0.7-0.4,1.2-0.4
			c0.4,0,0.8,0.1,1.1,0.4c4.1,2.9,7.9,6.5,11.2,10.8c0.3,0.4,0.5,0.9,0.4,1.5c0.7-0.2,1.4-0.4,2.1-0.7l0.6-0.6
			c0.4-0.4,0.9-0.6,1.4-0.6c0.3,0,0.7,0.1,1,0.2l8,4.3l-4.7-6.4c-0.4-0.6-0.5-1.4-0.2-2.1s1-1.1,1.8-1.1c11.3-0.1,19.2-0.4,25.7-0.8
			c7.5-0.5,14.6-1.4,21-2.7c0.1,0,0.3,0,0.4,0c0.7,0,1.3,0.3,1.7,0.9l5.3,7.8c0.4,0.6,0.5,1.4,0.1,2.1c-0.3,0.7-1,1.1-1.8,1.1
			c0,0-1.2,0-1.8,0c-13.3,0-28.4,1.1-44.9,3.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.1-0.9-0.2c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.7-0.2,1
			c-3.6,7.2-7.8,14-12.5,20.2c3.7,2.9,6.9,6,9.6,9.6c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.5,0.1l5.3,1.3c-0.4-0.4-0.6-0.9-0.6-1.4
			l0.1-12.4c0-3.4,0-5.9-0.1-6.9c-0.2-2.5-0.8-4.9-1.8-6.9c-0.6-0.7-0.6-1.8,0-2.5l1.3-1.6c0.4-0.4,0.9-0.7,1.4-0.7c0,0,0.1,0,0.1,0
			c0.4,0,0.7,0.1,1.1,0.3l8.7,2.7c5.2-0.3,11-1.1,17.3-2.3l4.4-2c0.3-0.1,0.5-0.2,0.8-0.2c0.4,0,0.8,0.1,1.2,0.4l7.1,5.3
			c0.8,0.6,1,1.6,0.6,2.4l-1.5,3.4V203c0,0.9-0.6,1.7-1.4,1.9l-10,2.9c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0-0.9-0.1-1.2-0.4
			c-0.5-0.4-0.8-1-0.8-1.6v-1c-2.7,0.1-5,0.3-7.2,0.5c-1.9,0.1-3.7,0.4-5.8,0.9c-0.1,0.8-0.7,1.5-1.5,1.7l-9.7,2.7l1.9,0.5
			c11.6-0.1,22-1,31-2.5l4.1-1.9c0.3-0.1,0.6-0.2,0.8-0.2c0.4,0,0.9,0.1,1.2,0.4l6.9,5.4c0.7,0.6,1,1.6,0.6,2.4l-1.5,3.4v34.9
			c0,0.9-0.6,1.7-1.4,1.9l-9.7,3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0-0.8-0.1-1.2-0.4c-0.5-0.4-0.8-1-0.8-1.6v-3.5
			c-9.6,0.5-18.6,1.2-27.3,2v0.5c0,0.9-0.6,1.7-1.5,1.9l-9.8,2.8c-0.2,0.1-0.4,0.1-0.5,0.1c-0.4,0-0.9-0.1-1.2-0.4
			c-0.5-0.4-0.8-1-0.8-1.6l0.1-26.3c0-6.5,0-10.8-0.1-11.7c0,0,0-0.1,0-0.1c-0.1-1.9-0.3-3.7-0.8-5.3l-5.3,3.4
			c-0.3,0.2-0.7,0.3-1.1,0.3c-0.2,0-0.4,0-0.6-0.1c-0.6-0.2-1-0.6-1.2-1.2c0-0.1-0.1-0.2-0.1-0.3V255c0,0.9-0.6,1.7-1.4,1.9l-9.8,3
			C174.2,260,174,260,173.8,260z M208.6,243.9c2.3,0,4.3,0,5.8,0l0.1-6.6l-5.9,0.5V243.9z M228.1,243c1.9-0.2,4.5-0.4,7.7-0.9v-6.3
			l-7.7,0.4V243z M208.6,226.9l1.2,0c1,0,2.4-0.1,4.1-0.1l0.5,0l-0.1-3.3c0-0.5,0-1-0.1-1.6c-1.5,0.1-3.3,0.3-5.7,0.5V226.9z
			 M228.1,225.7c3.1-0.4,5.6-0.7,7.7-1v-4.3c-1.9,0.1-3.4,0.2-4.6,0.3l-1,0.1l-2.1,0.1V225.7z M215.8,195.6c4.1-0.3,8.4-0.9,13-1.8
			v-4.1c-4.6,0.3-9,0.8-13,1.5V195.6z"/>
				<path class="shadow" d="M237.8,243.9v-10.2l-11.7,0.6v10.9C228.9,245,232.8,244.6,237.8,243.9L237.8,243.9z M226.1,227.9
			c4.5-0.5,8.4-1,11.7-1.6v-8.1c-3.5,0.1-6.1,0.3-7.7,0.4l-4,0.2V227.9L226.1,227.9z M230.8,195.5v-7.9c-6.7,0.3-12.3,1-17,1.9v8.3
			C218.5,197.5,224.2,196.8,230.8,195.5z M206.6,245.9c4.4,0,7.7,0,9.8-0.1l0.1-10.7l-9.9,0.9L206.6,245.9L206.6,245.9z M206.6,229
			l3.3-0.1c1.4,0,3.6-0.1,6.6-0.2l-0.1-5.3c0-1.1-0.2-2.4-0.5-3.7c-1.9,0.1-5,0.4-9.3,0.8V229L206.6,229z M230.8,205.9v-3.1
			c-3.6,0.1-6.7,0.3-9.4,0.6c-2.7,0.2-5.2,0.7-7.6,1.3v1.4l-9.8,2.7l0.1-12.4c0-3.6,0-6-0.1-7.1c-0.2-2.8-0.9-5.5-2.1-7.9l-0.1-0.1
			l1.3-1.6l0.1,0.1l9.4,2.9c5.4-0.3,11.5-1.1,18.2-2.4l4.6-2.1l7.1,5.3l-1.7,3.8V203L230.8,205.9L230.8,205.9z M249.3,214l-1.7,3.8
			v35.3l-9.7,3v-5.6c-9.7,0.5-20.1,1.2-31.3,2.3v2.3l-9.8,2.8l0.1-26.3c0-6.9,0-10.9-0.1-11.9c-0.1-2.8-0.6-5.3-1.4-7.4l1.4-1.5
			l9.5,2.4c12.2-0.1,22.8-1,31.8-2.6l4.3-2L249.3,214L249.3,214z M200.5,175.9l-5.5-7.5c9.6-0.1,18.3-0.3,25.8-0.8
			c7.6-0.5,14.7-1.4,21.3-2.7l5.3,7.8C233.3,172.6,217.6,173.7,200.5,175.9L200.5,175.9z M187.6,216.2c-1.3-3.4-2.6-6.4-4-8.8V255
			l-9.8,3v-0.2l0.1-31.1c0-8.5,0-13.6-0.1-15.4c-6.5,6.5-13.2,11.8-20.2,16h-0.1l-1.4-1.1l0.2-0.1c6.7-7.9,12.6-15.3,17.8-22.3
			s9.8-14.1,13.8-21.4c-4,0.2-7.7,0.6-11,1.2c-3.3,0.6-6.4,1.5-9.4,2.7l-5.7-7.5c7-0.3,12.7-0.7,17.1-1.3c-1.8-5.5-3.8-9.6-6.1-12.5
			l3.4-2.4c4,2.8,7.6,6.3,10.8,10.4l-6.8,4.3c4.3-0.7,8.2-1.7,11.7-2.9l0.1,0.1l1.1-1.1l8.5,4.6v0.1c-3.8,7.7-8.3,14.9-13.5,21.5
			c4.7,3.4,8.5,7.2,11.6,11.4L187.6,216.2L187.6,216.2z"/>
				<path class="shadow" d="M27,234.5c-0.2,0-0.4,0-0.5-0.1c-0.6-0.2-1.1-0.6-1.3-1.2c-0.6-1.5-1.2-2.5-1.8-3.1c-0.3-0.1-0.7-0.3-0.9-0.5
			c-0.1-0.1-0.2-0.2-0.3-0.3c-1.2-0.7-2.7-1-4.4-1.1c-0.2,0.3-0.6,0.6-1,0.7c-0.2,0.1-0.4,0.1-0.6,0.1c-0.6,0-1.2-0.3-1.6-0.8
			c-3.4,0-7-0.3-10-3c-0.2-0.2-0.4-0.4-0.5-0.6c-1.8-3.7-1.7-9.6-1.6-14.3c0-2.4,0.1-5.1-0.2-6c-0.1-0.2-0.1-0.4-0.1-0.6
			c0-0.2,0-0.4,0-0.6c-0.2-0.3-0.3-0.6-0.3-1c0-0.1,0-0.2,0-0.2c0.1-0.7,0.1-1.3,0.2-1.8c0.4-4.1,0.5-5.1-1-9.5
			c-0.1-0.2-0.1-0.4-0.1-0.6c0-2,0-3.8,0.1-5.3c0.1-3.3,0.2-5.9-0.6-9.2c0-0.2-0.1-0.3-0.1-0.5c0-2.4,0-4.7,0.1-7c0-1.8,0-3.6,0-5.4
			c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0-0.5,0.1-0.7v-0.1c-0.1-4.8-0.3-10-1.5-15.7c0-0.1,0-0.3,0-0.4V141c0-0.4,0.1-0.7,0.3-1
			c2-3.4,4.8-5.3,6.7-6.4c0,0,0.1,0,0.1-0.1l2-1c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.5,0.1,0.8,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2-0.6,0.6-1.1,1.2-1.4l0.6-0.2c2.4-1,4.6-1.9,6.1-3.1c0.2-0.2,0.4-0.3,0.7-0.3c4.1-1.2,7-2.7,10.4-5.2c0.4-0.4,1-0.8,1.6-1.3
			c0.1-0.1,0.2-0.1,0.3-0.2c0.9-0.5,1.9-0.9,2.9-1.4c0.4-0.1,0.7-0.3,1.1-0.5c1.2-0.5,2.4-0.9,3.4-1.2c0.9-0.3,2-0.7,2.3-0.9
			c0.3-0.2,0.7-0.4,1.1-0.4h2c0.3,0,0.7,0.1,1,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.2-0.2,0.8-0.6,1.5-0.6c0.5,0,1.1,0.3,1.5,0.6
			c0.2,0.2,0.5,0.6,0.6,1.1c1.6,0.3,3.1,0.8,4.4,1.3c0.7,0.3,1.1,0.8,1.3,1.5c0.1,0.7-0.1,1.4-0.7,1.9c-1.7,1.5-3.6,2.7-5.7,3.6
			c-2.4,1-4.8,1.8-7.2,2.4c-0.4,0.1-0.8,0.2-1.1,0.3c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0.1-0.4,0.1-0.5,0.1c-0.3,0-0.5,0-0.7-0.1
			l-1.5-0.6l-2-0.8c0.1,0.9,0.2,1.7,0.2,2.5l0,0.2c0,0.1,0.1,0.3,0.1,0.4l0.1,3.2l0,0.3c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0
			c0.2,4,0.3,7.4,0.3,10.6c0,7-0.8,13.8-2.3,20.3c-0.5,2.4-1.3,4.7-2.2,6.8c1.6-2.8,3-6.2,4.2-10.3c1.2-4.5,1.9-9.8,2.2-15.8
			c0-0.9,0.7-1.7,1.6-1.9c3.1-0.6,5.8-1,7.7-1.2c0.1,0,0.1,0,0.2,0c0.8,0,1.6,0.5,1.9,1.3c0.5,1.4,1.1,3.2,1.1,5.3
			c0.1,0.9,0.1,3.3,0.1,9.4V186l1.9-0.6v-38.2c-0.4-0.2-0.7-0.6-0.8-1c0,0,0-0.1-0.1-0.2c-0.1-0.2-0.3-0.6-0.4-1
			c-0.1-0.6,0-1.2,0.4-1.7c0.4-0.5,1-0.8,1.6-0.8c0.1,0,0.2,0,0.4,0c0.2,0,0.5-0.1,0.7-0.1c0.2-0.1,0.3-0.1,0.5-0.1
			c2.9-0.2,5.8-0.3,8.6-0.4c0,0,0,0,0.1,0c1,0,1.9,0.8,2,1.8c0.1,0.5,0.1,1,0.1,1.5c0,0.4,0,0.9,0.1,1.3l0.5,3.8
			c0.1,0.7,0.3,1.1,0.3,1.2c0.5,0.4,0.9,1,0.9,1.7v10.5c0,0.5-0.2,0.9-0.4,1.2c-0.1,0.5-0.3,1.7-0.7,3.5c-0.2,8.3-0.2,16.7-0.3,24.8
			c-0.1,10.9-0.2,22.2-0.5,33.6c0,0.9-0.6,1.6-1.4,1.8c-0.4,1.3-1.2,2.5-2.3,3.5c-0.1,0.1-0.2,0.2-0.4,0.3c-1.2,0.7-2.3,1.1-3.4,1.3
			c-1.1,0.2-2.4,0.3-3.9,0.3h-3.7c-0.1,0-0.2,0-0.4,0c-1.4,0-2.7-0.7-3.6-1.9c0,0,0-0.1-0.1-0.1c-0.8-1.2-1.1-2.4-1.1-3.7
			c0-0.4,0.1-0.7,0.1-0.9l1.2-12.7l-3-0.9c-1-0.3-1.6-1.3-1.4-2.3c0.4-2.5,0.7-5,0.7-7.5c0-0.6-0.1-1.5-0.1-2.5
			c-0.4-0.1-0.8-0.2-1.3-0.3c0,0,0,0,0,0c0.1,1.4,0.1,2.6,0.1,3.3c0,7.3-1.6,14.1-4.7,20.1c-2,3.9-4.7,7.1-7.5,9.4
			C27.9,234.4,27.4,234.5,27,234.5z M30.2,195.4c0.1,0.8,0.1,2.7,0.1,8.1l-0.1,15.9l1.2-0.3v-23.3c0-0.7,0.4-1.3,0.9-1.7l-2.3-0.7
			L30.2,195.4z M51.7,192.8c0.2,0.1,0.5,0.2,0.7,0.4c0.4,0.4,0.6,0.9,0.6,1.4V218l1.1-0.3v-23.1c0-0.3,0.1-0.6,0.2-0.8l0.2-0.5
			l-1.4-1L51.7,192.8z M11.8,170.7l2-0.6V166c0-0.9,0.6-1.7,1.5-1.9c1.1-0.3,2.9-0.7,5.2-1.1c0.1,0,0.2,0,0.4,0
			c0.5,0,0.9,0.2,1.3,0.5c0,0,0,0,0.1,0.1l2.5-0.9v-25.5c0-0.2,0-0.5,0.1-0.7l0.5-1.2l-2.1-1.6l-3.8,1.3c-0.1,0-0.2,0.1-0.3,0.1
			c-1.2,0.2-2.1,0.4-2.9,0.6c-0.6,0.1-1.1,0.2-1.5,0.3c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l-2.5-0.8c0,0-0.1,0-0.1,0
			c0.3,1,0.4,2.1,0.5,3.1c0.1,0.8,0.1,3,0.1,9.6c0,0,0,15.1,0,15.1c0,0,0,0.1,0,0.1c0,0,0,0.4,0,0.4
			C11.8,163.3,11.8,170.7,11.8,170.7z"/>
				<path class="shadow" d="M27.9,221.2c0.1,0.2,0.2,0.4,0.3,0.5l0.1-18.2c0-4.7,0-7.3-0.1-7.9l-0.3-4.1v-0.1l-0.1-0.7h0.1l5.3,1.6
			c6.8,0,12.5-0.4,17.3-1.1l2.9-1.3l3.6,2.7l-0.9,2v24.7l-5.1,1.3v-25.9c-4.6,0.2-10.5,0.5-17.6,1.1v24.8l-4.9,1.4
			c0.1,0.2,0.3,0.4,0.3,0.7c0,0.1,0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1C27.5,222.6,27.7,222,27.9,221.2L27.9,221.2z M25.7,206.5
			c0-0.3,0.1-0.5,0.1-0.8C26.1,206,26,206.1,25.7,206.5z M39.8,183.1c0.1,0.4-0.2,0.5-0.4,0.7c0.2,0.1,0,0.2-0.1,0.4
			c-0.1-0.2,0-0.3,0-0.4C39.1,183.4,39.6,183.4,39.8,183.1L39.8,183.1z M45.9,146.6c0.1-0.2,0.3,0,0.3,0.1c0,0.4,0,0.8-0.4,1.2
			c-0.1-0.3-0.1-0.6-0.1-0.8c-0.3,0.1-0.5,0.4-1,0.2C45.1,147.1,45.6,147,45.9,146.6L45.9,146.6z M55.7,163.7
			c0.3-0.3,0.5-0.5,0.6-0.9c0.1-0.3,0.5-0.4,0.7-0.8c0-0.1,0.1-0.3,0.2-0.5c0,0.5,0.5,0.7,0.3,1.2c-0.1,0.4-0.2,0.8-0.8,0.7
			c-0.2,0-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.6,0.3C55.7,164,55.6,163.8,55.7,163.7L55.7,163.7z M57.3,149.7c0.1-0.1,0.2,0,0.4,0
			c-0.1,0.2-0.2,0.2-0.4,0.1C57.3,149.8,57.2,149.8,57.3,149.7z M57.2,163.6c0.1,0.3-0.2,0.5-0.2,0.9
			C56.9,164.1,56.9,163.8,57.2,163.6z M58,188.9c0,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.2,0.2,0.2c0.2-0.1,0.2,0,0.2,0.2
			c-0.1,0.5,0.3,1.2-0.4,1.6c-0.2,0.1-0.2,0.2-0.2,0.4s-0.2,0.4-0.4,0.5s-0.4,0.2-0.6,0.1c-0.2-0.1-0.1-0.3,0-0.5s0.1-0.4,0.3-0.6
			c0.8-0.5,0.8-1.2,0.8-1.8C57.7,189.2,57.7,189.1,58,188.9L58,188.9z M59.3,185.7c0,0.2,0.1,0.3-0.1,0.4h-0.1
			C59,185.9,59.2,185.8,59.3,185.7L59.3,185.7z M59.1,222.1c0.1,0.2,0.2,0.4,0.3,0.5C59.1,222.6,59,222.4,59.1,222.1z M60.4,181.2
			c0-0.2,0.2-0.3,0.4-0.4c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1
			c0.2-0.2,0.3-0.4,0.4-0.7c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5
			c-0.1,0.2-0.2,0.2-0.4,0.3c-0.6,0.2-0.6,0.3-0.5,0.8c0,0.3-0.2,0.7-0.4,1c-0.1,0.2-0.1,0.5-0.5,0.5
			C59.9,183.5,60.2,182.4,60.4,181.2L60.4,181.2z M60.9,156.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.1
			C60.9,156.1,60.9,156.2,60.9,156.3z M60.7,153.3c0.1-0.1,0.2,0,0.2,0.1c0.1,0.3,0.3,0.6,0.1,0.9c-0.3-0.2-0.2-0.6-0.4-0.8
			C60.5,153.4,60.6,153.3,60.7,153.3L60.7,153.3z M53.6,227.4c0.1,0.2,0.2,0.3,0.1,0.6C53.5,227.7,53.5,227.5,53.6,227.4z
			 M53.7,230.6c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.5-0.4C53.7,230.3,53.7,230.4,53.7,230.6L53.7,230.6z
			 M47.9,224c-0.2,0-0.2-0.2-0.2-0.4s0.2-0.2,0.3-0.2c0.2,0,0.3,0,0.4,0.2C48.3,223.8,48.1,224,47.9,224L47.9,224z M23.6,216
			c-0.5-0.5-0.2-0.8,0.3-1.2C23.8,215.3,23.4,215.6,23.6,216z M20.3,187c-0.1,0-0.2,0-0.1-0.1c0-0.1,0.1-0.1,0.2-0.2
			c0.1,0,0.1,0,0.1,0.1C20.5,187,20.4,187,20.3,187L20.3,187z M20,206.4c-0.4,0.3-0.4,0.7-0.6,1c-0.2-0.4,0-0.8,0.2-1.2
			c0.1-0.2,0.2-0.2,0.3-0.1C20.1,206.1,20.2,206.3,20,206.4L20,206.4z M18,206.4c-0.3,0.3-0.1,0.7-0.3,1.1c-0.2-0.6,0.1-1,0.2-1.5
			C18,206.1,18.3,206.1,18,206.4z M17.3,187.9c-0.4,0.1-0.5,0.3-0.5,0.6c0,0.2-0.1,0.4-0.2,0.5c0-0.6-0.1-1.2-0.1-1.8
			c0-0.5,0.6-0.9,1.1-0.9c0.2,0,0.4,0.1,0.4,0.3c-0.2,0.6,0,1.2-0.1,1.8C17.7,188.2,17.7,187.9,17.3,187.9L17.3,187.9z M16.5,210
			c0-0.2,0.1-0.3,0.3-0.2l0.1,0.1C16.9,210.1,16.6,209.9,16.5,210z M16.5,189.3c0.1,0.2,0,0.3-0.1,0.5
			C16.4,189.6,16.5,189.5,16.5,189.3z M15.8,226.3c0,0,0,0.1,0,0.2c-0.2-0.3-0.2-0.7-0.4-1.1v-0.1c0.3-0.2,0.1-0.4,0-0.6
			c0-0.1-0.1-0.2-0.1-0.3s0-0.2-0.2-0.3c-0.1,0,0,0,0-0.1c-0.1-0.5-0.5-1-0.2-1.6c0-0.1,0-0.1,0-0.2c-0.4-0.5-0.5-1-0.6-1.6
			c0-0.1,0-0.2,0-0.4c0.2,0.2,0.4,0.4,0.5,0.7c0.4,0.6,0.5,1.2,0.7,1.9c0.1,0.3,0.3,0.7,0.4,1c0.1,0.4,0.2,0.8-0.1,1.1
			c-0.1,0.2-0.1,0.3,0,0.4C16,225.7,16,226,15.8,226.3L15.8,226.3z M13.2,186.9c0.1,0.2,0.1,0.3,0.2,0.5
			C13,187.3,13,187.2,13.2,186.9z M12.9,193.3c-0.2-0.1-0.4,0.3-0.6,0.1c-0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.4-0.8,0.4-1.2
			c0.1,0,0.1,0,0.2,0c0.1,0.5,0.2,1,0.2,1.5C13.3,193.2,13.1,193.4,12.9,193.3L12.9,193.3z M8.4,174.2c-0.1,0.5,0,0.9-0.7,1.2
			c-0.2,0.1-0.2,0.2-0.3,0.4c-0.4-0.5-0.3-1-0.5-1.5c-0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.2
			C8.4,173.8,8.4,174,8.4,174.2L8.4,174.2z M29.5,134.9c0.1,0,0.2-0.1,0.3-0.2c0.1,0.3-0.2,0.5-0.3,0.4
			C29.1,135.1,29.1,135,29.5,134.9z M39.8,122.2c0.1-0.2,0.2-0.3,0.3-0.6c0.1,0.7,0.5,1.3,0.3,2h-0.1
			C39.2,123.2,39.2,123.2,39.8,122.2L39.8,122.2z M41.3,121.4c0.2-0.1,0.2,0.1,0.4,0.1s0.2,0.2,0.2,0.3c0,0.3-0.3,0.4-0.3,0.8
			c-0.2-0.3-0.2-0.6-0.2-0.9C41.3,121.6,41.2,121.5,41.3,121.4L41.3,121.4z M34,223.8c3-5.8,4.5-12.2,4.5-19.2c0-0.7,0-1.8-0.1-3.2
			c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.3-0.6-0.6-0.9l0.6-0.7c2,0.3,3.8,0.6,5.4,1.1l0.1,0.1c0.1,1.7,0.2,3.1,0.2,4
			c0,2.5-0.2,5.1-0.7,7.9l4.6,1.4l-1.4,14.4c0,0.1-0.1,0.3-0.1,0.6c0,1,0.3,1.8,0.8,2.6c0.5,0.7,1.3,1.1,2.2,1l3.8,0
			c1.3,0,2.5-0.1,3.5-0.3s1.9-0.6,2.8-1.1c1.1-1,1.8-2.3,2-3.9h1c0.6-19.9,0.4-39.1,0.8-58.6c0.8-4.3,0.8-4.3,1.1-4.5v-10.5
			c-0.6-0.3-1-1.3-1.2-2.6l-0.5-3.8c-0.1-0.9-0.1-1.8-0.2-2.8c-2.8,0.1-5.6,0.2-8.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3s-0.2,0.3-0.2,0.4
			h-0.1c0-0.2,0.1-0.4,0.1-0.6c-0.4,0-0.8,0.1-1.2,0.1c0.1,0.4,0.3,0.7,0.4,1l0.9,0.3v41l-5.9,1.9v-7.9v-13.2
			c-0.1,0.1-0.1,0.1-0.3,0.2c0-0.2,0.1-0.3,0.3-0.4v-8.2c0-5.5,0-8.6-0.1-9.3c0-1.6-0.4-3.1-1-4.7c-1.8,0.2-4.4,0.6-7.6,1.2
			c-0.3,6.4-1.1,11.8-2.3,16.3c-1.3,4.5-2.9,8.4-4.8,11.5c-1.9,3.2-4.5,5.5-7.7,7.1l-1.2-0.9c3.8-4,6.5-9.1,7.9-15.2
			c1.4-6.1,2.2-12.7,2.2-19.9c0-3.2-0.1-6.6-0.3-10.5c-0.1,0-0.2-0.1-0.1-0.2l0.1-0.1c0-0.1,0-0.1,0-0.2l-0.1-3.2l-0.1-0.4
			c0-1-0.1-1.9-0.2-2.6c-0.1-0.7-0.4-1.3-0.9-1.8l-0.1-0.1l0.9-1.3l0.1,0.1l4.7,1.9l0,0l0,0l1.5,0.6c0.7-0.2,1.4-0.3,2.1-0.5v-0.1
			v0.1c2.5-0.6,4.8-1.4,6.9-2.3c2-0.9,3.7-2,5.2-3.3c-1.3-0.5-2.7-0.9-4.1-1.2c-0.6-0.1-1.3-0.2-1.9-0.3c0.1-0.1,0.2-0.2,0.2-0.3
			c0.1-0.2,0-0.5,0.2-0.8c0-0.1,0-0.1-0.1-0.2s-0.2,0-0.3,0.1c-0.1,0.2-0.3,0.4-0.6,0.4c-0.2,0-0.3,0.1-0.4,0.3
			c-0.1,0.2,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.2,0-0.2,0c-0.7-0.5-1.4-0.8-2.1-1.2h-0.5h-1.5
			c-0.1,0.2-3.2,1.2-6.1,2.3c-1.4,0.6-2.8,1.1-3.8,1.7c-0.6,0.5-1.2,1-1.7,1.4c-3.2,2.4-6.3,4.1-11,5.5c-1.9,1.5-4.5,2.5-7.2,3.6
			l2.5,0.8c1-0.2,2.4-0.5,4.4-0.9l4.8-1.7l4.2,3.2l-1,2.6V164l-5.9,2v-1.1c-2.2,0.4-3.9,0.8-5.1,1.1v5.6l-6,1.8v-10.3
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.4v-2.2c-0.2-0.2-0.2-0.4-0.1-0.6
			c0.1,0.1,0.1,0.3,0.1,0.4v-11.8v-0.7l-0.1-0.1c0,0,0.1,0,0.1-0.1c0-5.7,0-8.8-0.1-9.4c-0.1-1.4-0.4-2.8-0.8-4l-2,1
			c-2.4,1.4-4.5,3.1-6,5.7v4.1c1.1,5.5,1.4,10.6,1.5,16.1c0,0.1,0,0.4,0,0.5s-0.1,0.2-0.1,0.3c0.1,0,0.1,0,0.1,0
			c0,4.1-0.1,8.3-0.1,13c1.4,5.3,0.5,8.9,0.6,15c1.8,5.4,1.4,6.4,0.9,12.1c0-0.1,0.1-0.2,0.1-0.4s0.2-0.3,0.4-0.4
			c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1c0.2-0.2,0.3-0.4,0.4-0.7
			c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.2-0.4,0.3
			C4,202.9,4,203,4.1,203.5v0.1c1.1,3.1-0.9,14.6,1.7,20.1c2.8,2.5,6.4,2.5,9.9,2.5c0.1,0.3,0.2,0.5,0.4,0.8c0-0.1,0-0.2,0-0.3
			c0.1-0.2,0.1-0.3,0.1-0.5c2.7,0,5.3,0.2,7.4,1.7c0,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.1,0.1-0.2c1.2,0.9,2.3,2.4,3.1,4.5
			C29.7,230.4,32.1,227.4,34,223.8L34,223.8z"/>
				<path class="shadow" d="M51.5,147.3c-0.2,0-0.4,0-0.7-0.1l-1.7-0.6c-0.9-0.3-1.4-1.2-1.3-2.1c0.1-0.9,0.8-1.7,1.7-1.8
			c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.5,0,0.8-0.1c0.1,0,0.2,0,0.3,0c0.5,0,0.9,0.2,1.3,0.5c0.4,0.4,0.7,0.9,0.7,1.5
			c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.5-0.4,1-0.8,1.4C52.3,147.2,51.9,147.3,51.5,147.3z"/>
				<path class="shadow" d="M51.7,144.5c-0.6,0.1-1.2,0.1-1.9,0.2l1.7,0.6c0-0.1,0-0.1,0-0.2C51.6,144.9,51.7,144.7,51.7,144.5z"/>
				<path class="shadow" d="M46.8,251.3c-0.7,0-1.4-0.4-1.8-1.1c-0.2-0.3-1-0.9-3.3-2.3c-0.5-0.4-1-0.7-1.4-1c-0.6-0.4-1.2-0.8-1.7-1.1
			h-3.3c-4.2-0.2-6.4-2.2-7.4-6.8c0-0.1,0-0.2,0-0.2l-1.1-2.7c-0.4-0.9,0-2,0.8-2.5c2.1-1.3,4-2.8,5.6-4.7c3.4-4,5.9-8.8,7.3-14.3
			c0.2-0.9,1-1.5,1.9-1.5c0,0,0,0,0.1,0c0.9,0,1.6,0.5,1.9,1.4c0.2,0.6,0.2,1.3,0.2,2c0,0.1,0,0.1,0,0.2c0,0.4-0.1,0.9-0.1,1.4
			c0,0.5,0,1-0.1,1.5c0,0.1,0,0.2,0,0.2c-0.2,1.8-0.4,4.1-0.6,6.8l-0.3,3.2c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2
			c0,1,0.3,1.7,0.8,2.4c0.2,0.3,0.6,0.5,1,0.7c0.1,0,0.2,0.1,0.3,0.2c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0
			c0.8,0.1,2,0.2,3.6,0.3c2.2,0.2,3.8,0.3,5,0.3c2.2,0,4-0.3,5.3-0.8c0.2-0.1,0.5-0.2,0.8-0.2c0.5,0,0.9,0.2,1.3,0.5
			c0.6,0.5,0.8,1.2,0.7,1.9l-0.8,3.9c-0.5,2.2-1,4.6-1.6,6.2c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.7-0.6,1.1
			c-0.1,0.1-0.1,0.1-0.2,0.2c-1.5,1.1-2.5,1.9-2.7,2.1c0,0,0,0,0,0c0,0,0,0,0,0.1c-0.3,0.7-1,1.2-1.8,1.2H46.8z"/>
				<path class="shadow" d="M37.3,232.5c-0.4,0.1-0.8,0.2-1.3,0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0-0.7,0.3-0.7c0.4,0,0.8,0.2,1.1,0.4
			C37.8,232.1,37.6,232.5,37.3,232.5L37.3,232.5z M35.9,231.7C35.8,231.7,35.8,231.7,35.9,231.7c0-0.3,0-0.6,0.1-0.8c0,0,0,0,0.1,0
			C35.9,231.1,35.9,231.4,35.9,231.7z M33.7,236.4c0.2-0.5,0.3-0.9,0.7-1.5C34.6,235.8,34.5,235.9,33.7,236.4z M49.7,236.3
			c-1.7-0.1-2.9-0.2-3.7-0.3c-0.5,0-1-0.1-1.5-0.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.3-0.3-0.3-0.5c-0.7-0.3-1.3-0.7-1.8-1.3
			c-0.9-1.1-1.3-2.3-1.3-3.7c0-0.3,0-0.6,0.1-0.8l0.3-3.2c0.2-2.8,0.4-5.1,0.6-6.9c-0.1,0.3-0.5,0.4-0.4,0.8
			c-0.1-0.5-0.1-0.5,0.4-0.9c0.1-1.2,0.1-2.2,0.2-3.1c0-0.6,0-1.1-0.1-1.4c-1.5,5.8-4.1,10.9-7.7,15.1c-1.8,2.1-3.9,3.8-6.1,5.1
			l1.2,3c0,0.1,0,0.1,0,0.2c1,4.3,2.8,5.2,5.5,5.3c1.2,0,2.5,0,4,0c0.8,0.7,2.2,1.5,3.5,2.4c1.9,1.2,3.7,2.4,4,3.1h1.4H55
			c0.3-0.7,0.3-0.7,3.3-3c0.1-0.1,0.1-0.3,0.2-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1h0.1c0.5-1.5,1-3.7,1.5-6l0.8-3.9
			c-1.7,0.7-3.7,1-6.1,1C53.6,236.6,51.9,236.5,49.7,236.3L49.7,236.3z"/>
				<path class="shadow" d="M38.8,143.6c-0.5,0-1-0.2-1.4-0.5c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0-1.3,0-1.9c0-0.8-0.1-1.6-0.1-2.4
			c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1c-0.1-0.8-0.1-1.6-0.1-2.4c0-0.7,0-1.5-0.1-2.2c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3
			c0-0.2,0-0.4,0-0.6c0-0.3-0.1-0.6-0.1-0.9c0-1.1,0.9-2,2-2c2.8,0,6-0.4,9.8-1.2c2.6-0.6,4.9-1.3,6.9-2.2l-0.4-0.6
			c-0.5-0.8-0.5-1.8,0.1-2.5c0.4-0.4,0.9-0.7,1.5-0.7c0.3,0,0.6,0.1,0.9,0.2c1.3,0.7,2.6,1.4,3.9,2.1c0.3,0.2,0.5,0.4,0.6,0.6
			c1.9,2.7,2.2,8.5,2.4,17.1l0,0.3c0,0.9-0.5,1.6-1.3,1.9c-0.2,0.1-0.5,0.1-0.7,0.1c-0.6,0-1.2-0.3-1.6-0.8l-1.2-1.5
			c-5.9,1-12.5,1.8-20.5,2.5C38.9,143.6,38.9,143.6,38.8,143.6z"/>
				<path class="shadow" d="M49,138.5c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2s0.2-0.3,0.3-0.3C49,138.1,49,138.3,49,138.5L49,138.5z
			 M48.2,137.8c-0.1-0.2,0.1-0.3,0.2-0.5c0.2,0.3,0,0.4-0.1,0.5C48.3,137.8,48.2,137.8,48.2,137.8z M45.3,135.8
			c-0.1,0.2-0.2,0.4-0.5,0.5c-0.1-0.4,0.2-0.7,0.4-1c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3,0-0.4,0c-0.2,0.1-0.5,0.5-0.7,0.3
			s-0.1-0.5,0.1-0.8v-0.1c0-0.2,0.1-0.4,0.2-0.4c0.2,0,0.3,0.2,0.3,0.4s0.2,0.3,0.4,0.4C45.6,135.1,45.6,135.3,45.3,135.8
			L45.3,135.8z M41.9,132.6c-0.2,0.1-0.5,0.1-0.7,0c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3,0C42.1,132.4,42,132.5,41.9,132.6
			L41.9,132.6z M41.2,138.5c-0.1,0.2-0.2,0.4-0.4,0.6c0,0,0,0-0.1,0c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0.1-0.2,0.3-0.2
			C41.2,138.3,41.2,138.4,41.2,138.5L41.2,138.5z M57.9,125.7c-2.9,1.4-6,2.4-9.3,3.2c-3.4,0.7-6.8,1.2-10.2,1.2
			c0,0.5,0.1,1,0.1,1.5l0.1-0.1c0,0.3,0.1,0.5-0.1,0.7c0.1,1.6,0.1,3.1,0.2,4.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.4,0,0.7-0.3,1
			c0,1.5,0.1,3,0.1,4.3c7.4-0.6,14.5-1.4,21.2-2.6l0.3-0.1v0.1l1.9,2.4c-0.2-6.5-0.4-13.8-2.1-16.3c-1.2-0.7-2.5-1.4-3.8-2.1
			l1.8,2.5L57.9,125.7L57.9,125.7z"/>
				<path class="shadow" d="M15.5,163.4c-0.5,0-0.9-0.2-1.3-0.5c-0.5-0.4-0.7-0.9-0.7-1.5v-22.6c0-0.9,0.6-1.7,1.5-1.9
			c2.2-0.6,3.9-1,5.4-1.2c0.1,0,0.2,0,0.2,0c0.5,0,1,0.2,1.3,0.5c0.4,0.4,0.7,0.9,0.7,1.5v22.6c0,0.9-0.7,1.7-1.6,2
			c-1.8,0.4-3.6,0.8-5.1,1.1C15.8,163.4,15.6,163.4,15.5,163.4z"/>
				<path class="shadow" d="M20.6,137.7c-1.6,0.2-3.3,0.6-5.1,1.1v22.6c1.5-0.3,3.3-0.7,5.1-1.1V137.7z"/>
				<path class="shadow" d="M15.5,163.4c-0.5,0-0.9-0.2-1.3-0.5c-0.5-0.4-0.7-0.9-0.7-1.5v-22.6c0-0.9,0.6-1.7,1.5-1.9
			c2.2-0.6,3.9-1,5.4-1.2c0.1,0,0.2,0,0.2,0c0.5,0,1,0.2,1.3,0.5c0.4,0.4,0.7,0.9,0.7,1.5v22.6c0,0.9-0.7,1.7-1.6,2
			c-1.8,0.4-3.6,0.8-5.1,1.1C15.8,163.4,15.6,163.4,15.5,163.4z"/>
				<path class="st2" d="M20.6,137.7c-1.6,0.2-3.3,0.6-5.1,1.1v22.6c1.5-0.3,3.3-0.7,5.1-1.1V137.7z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_1_" d="M20.6,137.7c-1.6,0.2-3.3,0.6-5.1,1.1v22.6c1.5-0.3,3.3-0.7,5.1-1.1V137.7z"/>
				</defs>
				<clipPath id="SVGID_2_">
					<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st3">
					<g>
						<g>
							<defs>
								<path id="SVGID_3_" d="M20.6,137.7c-1.6,0.2-3.3,0.6-5.1,1.1v22.6c1.5-0.3,3.3-0.7,5.1-1.1V137.7z"/>
							</defs>
							<clipPath id="SVGID_4_">
								<use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st4">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M20.6,137.7c-1.6,0.2-3.3,0.6-5.1,1.1v22.6c1.5-0.3,3.3-0.7,5.1-1.1V137.7z"/>
				<path class="st2" d="M37.3,232.5c-0.4,0.1-0.8,0.2-1.3,0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0-0.7,0.3-0.7c0.4,0,0.8,0.2,1.1,0.4
			C37.8,232.1,37.6,232.5,37.3,232.5L37.3,232.5z M35.9,231.7C35.8,231.7,35.8,231.7,35.9,231.7c0-0.3,0-0.6,0.1-0.8c0,0,0,0,0.1,0
			C35.9,231.1,35.9,231.4,35.9,231.7z M33.7,236.4c0.2-0.5,0.3-0.9,0.7-1.5C34.6,235.8,34.5,235.9,33.7,236.4z M49.7,236.3
			c-1.7-0.1-2.9-0.2-3.7-0.3c-0.5,0-1-0.1-1.5-0.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.3-0.3-0.3-0.5c-0.7-0.3-1.3-0.7-1.8-1.3
			c-0.9-1.1-1.3-2.3-1.3-3.7c0-0.3,0-0.6,0.1-0.8l0.3-3.2c0.2-2.8,0.4-5.1,0.6-6.9c-0.1,0.3-0.5,0.4-0.4,0.8
			c-0.1-0.5-0.1-0.5,0.4-0.9c0.1-1.2,0.1-2.2,0.2-3.1c0-0.6,0-1.1-0.1-1.4c-1.5,5.8-4.1,10.9-7.7,15.1c-1.8,2.1-3.9,3.8-6.1,5.1
			l1.2,3c0,0.1,0,0.1,0,0.2c1,4.3,2.8,5.2,5.5,5.3c1.2,0,2.5,0,4,0c0.8,0.7,2.2,1.5,3.5,2.4c1.9,1.2,3.7,2.4,4,3.1h1.4H55
			c0.3-0.7,0.3-0.7,3.3-3c0.1-0.1,0.1-0.3,0.2-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1h0.1c0.5-1.5,1-3.7,1.5-6l0.8-3.9
			c-1.7,0.7-3.7,1-6.1,1C53.6,236.6,51.9,236.5,49.7,236.3L49.7,236.3z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_5_" d="M37.3,232.5c-0.4,0.1-0.8,0.2-1.3,0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0-0.7,0.3-0.7
						c0.4,0,0.8,0.2,1.1,0.4C37.8,232.1,37.6,232.5,37.3,232.5L37.3,232.5z M35.9,231.7C35.8,231.7,35.8,231.7,35.9,231.7
						c0-0.3,0-0.6,0.1-0.8c0,0,0,0,0.1,0C35.9,231.1,35.9,231.4,35.9,231.7z M33.7,236.4c0.2-0.5,0.3-0.9,0.7-1.5
						C34.6,235.8,34.5,235.9,33.7,236.4z M49.7,236.3c-1.7-0.1-2.9-0.2-3.7-0.3c-0.5,0-1-0.1-1.5-0.3c0.1,0.1,0.1,0.3,0.2,0.4
						c-0.2-0.1-0.3-0.3-0.3-0.5c-0.7-0.3-1.3-0.7-1.8-1.3c-0.9-1.1-1.3-2.3-1.3-3.7c0-0.3,0-0.6,0.1-0.8l0.3-3.2
						c0.2-2.8,0.4-5.1,0.6-6.9c-0.1,0.3-0.5,0.4-0.4,0.8c-0.1-0.5-0.1-0.5,0.4-0.9c0.1-1.2,0.1-2.2,0.2-3.1c0-0.6,0-1.1-0.1-1.4
						c-1.5,5.8-4.1,10.9-7.7,15.1c-1.8,2.1-3.9,3.8-6.1,5.1l1.2,3c0,0.1,0,0.1,0,0.2c1,4.3,2.8,5.2,5.5,5.3c1.2,0,2.5,0,4,0
						c0.8,0.7,2.2,1.5,3.5,2.4c1.9,1.2,3.7,2.4,4,3.1h1.4H55c0.3-0.7,0.3-0.7,3.3-3c0.1-0.1,0.1-0.3,0.2-0.5
						c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1h0.1c0.5-1.5,1-3.7,1.5-6l0.8-3.9c-1.7,0.7-3.7,1-6.1,1
						C53.6,236.6,51.9,236.5,49.7,236.3L49.7,236.3z"/>
				</defs>
				<clipPath id="SVGID_6_">
					<use xlink:href="#SVGID_5_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st5">
					<g>
						<g>
							<defs>
								<path id="SVGID_7_" d="M37.3,232.5c-0.4,0.1-0.8,0.2-1.3,0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0-0.7,0.3-0.7
									c0.4,0,0.8,0.2,1.1,0.4C37.8,232.1,37.6,232.5,37.3,232.5L37.3,232.5z M35.9,231.7C35.8,231.7,35.8,231.7,35.9,231.7
									c0-0.3,0-0.6,0.1-0.8c0,0,0,0,0.1,0C35.9,231.1,35.9,231.4,35.9,231.7z M33.7,236.4c0.2-0.5,0.3-0.9,0.7-1.5
									C34.6,235.8,34.5,235.9,33.7,236.4z M49.7,236.3c-1.7-0.1-2.9-0.2-3.7-0.3c-0.5,0-1-0.1-1.5-0.3c0.1,0.1,0.1,0.3,0.2,0.4
									c-0.2-0.1-0.3-0.3-0.3-0.5c-0.7-0.3-1.3-0.7-1.8-1.3c-0.9-1.1-1.3-2.3-1.3-3.7c0-0.3,0-0.6,0.1-0.8l0.3-3.2
									c0.2-2.8,0.4-5.1,0.6-6.9c-0.1,0.3-0.5,0.4-0.4,0.8c-0.1-0.5-0.1-0.5,0.4-0.9c0.1-1.2,0.1-2.2,0.2-3.1c0-0.6,0-1.1-0.1-1.4
									c-1.5,5.8-4.1,10.9-7.7,15.1c-1.8,2.1-3.9,3.8-6.1,5.1l1.2,3c0,0.1,0,0.1,0,0.2c1,4.3,2.8,5.2,5.5,5.3c1.2,0,2.5,0,4,0
									c0.8,0.7,2.2,1.5,3.5,2.4c1.9,1.2,3.7,2.4,4,3.1h1.4H55c0.3-0.7,0.3-0.7,3.3-3c0.1-0.1,0.1-0.3,0.2-0.5
									c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1h0.1c0.5-1.5,1-3.7,1.5-6l0.8-3.9c-1.7,0.7-3.7,1-6.1,1
									C53.6,236.6,51.9,236.5,49.7,236.3L49.7,236.3z"/>
							</defs>
							<clipPath id="SVGID_8_">
								<use xlink:href="#SVGID_7_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st6">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M37.3,232.5c-0.4,0.1-0.8,0.2-1.3,0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0-0.7,0.3-0.7c0.4,0,0.8,0.2,1.1,0.4
			C37.8,232.1,37.6,232.5,37.3,232.5L37.3,232.5z M35.9,231.7C35.8,231.7,35.8,231.7,35.9,231.7c0-0.3,0-0.6,0.1-0.8c0,0,0,0,0.1,0
			C35.9,231.1,35.9,231.4,35.9,231.7z M33.7,236.4c0.2-0.5,0.3-0.9,0.7-1.5C34.6,235.8,34.5,235.9,33.7,236.4z M49.7,236.3
			c-1.7-0.1-2.9-0.2-3.7-0.3c-0.5,0-1-0.1-1.5-0.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.2-0.1-0.3-0.3-0.3-0.5c-0.7-0.3-1.3-0.7-1.8-1.3
			c-0.9-1.1-1.3-2.3-1.3-3.7c0-0.3,0-0.6,0.1-0.8l0.3-3.2c0.2-2.8,0.4-5.1,0.6-6.9c-0.1,0.3-0.5,0.4-0.4,0.8
			c-0.1-0.5-0.1-0.5,0.4-0.9c0.1-1.2,0.1-2.2,0.2-3.1c0-0.6,0-1.1-0.1-1.4c-1.5,5.8-4.1,10.9-7.7,15.1c-1.8,2.1-3.9,3.8-6.1,5.1
			l1.2,3c0,0.1,0,0.1,0,0.2c1,4.3,2.8,5.2,5.5,5.3c1.2,0,2.5,0,4,0c0.8,0.7,2.2,1.5,3.5,2.4c1.9,1.2,3.7,2.4,4,3.1h1.4H55
			c0.3-0.7,0.3-0.7,3.3-3c0.1-0.1,0.1-0.3,0.2-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1h0.1c0.5-1.5,1-3.7,1.5-6l0.8-3.9
			c-1.7,0.7-3.7,1-6.1,1C53.6,236.6,51.9,236.5,49.7,236.3L49.7,236.3z"/>
				<path class="st2" d="M49,138.5c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2s0.2-0.3,0.3-0.3C49,138.1,49,138.3,49,138.5L49,138.5z
			 M48.2,137.8c-0.1-0.2,0.1-0.3,0.2-0.5c0.2,0.3,0,0.4-0.1,0.5C48.3,137.8,48.2,137.8,48.2,137.8z M45.3,135.8
			c-0.1,0.2-0.2,0.4-0.5,0.5c-0.1-0.4,0.2-0.7,0.4-1c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3,0-0.4,0c-0.2,0.1-0.5,0.5-0.7,0.3
			s-0.1-0.5,0.1-0.8v-0.1c0-0.2,0.1-0.4,0.2-0.4c0.2,0,0.3,0.2,0.3,0.4s0.2,0.3,0.4,0.4C45.6,135.1,45.6,135.3,45.3,135.8
			L45.3,135.8z M41.9,132.6c-0.2,0.1-0.5,0.1-0.7,0c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3,0C42.1,132.4,42,132.5,41.9,132.6
			L41.9,132.6z M41.2,138.5c-0.1,0.2-0.2,0.4-0.4,0.6c0,0,0,0-0.1,0c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0.1-0.2,0.3-0.2
			C41.2,138.3,41.2,138.4,41.2,138.5L41.2,138.5z M57.9,125.7c-2.9,1.4-6,2.4-9.3,3.2c-3.4,0.7-6.8,1.2-10.2,1.2
			c0,0.5,0.1,1,0.1,1.5l0.1-0.1c0,0.3,0.1,0.5-0.1,0.7c0.1,1.6,0.1,3.1,0.2,4.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.4,0,0.7-0.3,1
			c0,1.5,0.1,3,0.1,4.3c7.4-0.6,14.5-1.4,21.2-2.6l0.3-0.1v0.1l1.9,2.4c-0.2-6.5-0.4-13.8-2.1-16.3c-1.2-0.7-2.5-1.4-3.8-2.1
			l1.8,2.5L57.9,125.7L57.9,125.7z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_9_" d="M49,138.5c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2s0.2-0.3,0.3-0.3C49,138.1,49,138.3,49,138.5
						L49,138.5z M48.2,137.8c-0.1-0.2,0.1-0.3,0.2-0.5c0.2,0.3,0,0.4-0.1,0.5C48.3,137.8,48.2,137.8,48.2,137.8z M45.3,135.8
						c-0.1,0.2-0.2,0.4-0.5,0.5c-0.1-0.4,0.2-0.7,0.4-1c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3,0-0.4,0c-0.2,0.1-0.5,0.5-0.7,0.3
						s-0.1-0.5,0.1-0.8v-0.1c0-0.2,0.1-0.4,0.2-0.4c0.2,0,0.3,0.2,0.3,0.4s0.2,0.3,0.4,0.4C45.6,135.1,45.6,135.3,45.3,135.8
						L45.3,135.8z M41.9,132.6c-0.2,0.1-0.5,0.1-0.7,0c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3,0C42.1,132.4,42,132.5,41.9,132.6
						L41.9,132.6z M41.2,138.5c-0.1,0.2-0.2,0.4-0.4,0.6c0,0,0,0-0.1,0c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0.1-0.2,0.3-0.2
						C41.2,138.3,41.2,138.4,41.2,138.5L41.2,138.5z M57.9,125.7c-2.9,1.4-6,2.4-9.3,3.2c-3.4,0.7-6.8,1.2-10.2,1.2
						c0,0.5,0.1,1,0.1,1.5l0.1-0.1c0,0.3,0.1,0.5-0.1,0.7c0.1,1.6,0.1,3.1,0.2,4.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.4,0,0.7-0.3,1
						c0,1.5,0.1,3,0.1,4.3c7.4-0.6,14.5-1.4,21.2-2.6l0.3-0.1v0.1l1.9,2.4c-0.2-6.5-0.4-13.8-2.1-16.3c-1.2-0.7-2.5-1.4-3.8-2.1
						l1.8,2.5L57.9,125.7L57.9,125.7z"/>
				</defs>
				<clipPath id="SVGID_10_">
					<use xlink:href="#SVGID_9_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st7">
					<g>
						<g>
							<defs>
								<path id="SVGID_11_" d="M49,138.5c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2s0.2-0.3,0.3-0.3C49,138.1,49,138.3,49,138.5
									L49,138.5z M48.2,137.8c-0.1-0.2,0.1-0.3,0.2-0.5c0.2,0.3,0,0.4-0.1,0.5C48.3,137.8,48.2,137.8,48.2,137.8z M45.3,135.8
									c-0.1,0.2-0.2,0.4-0.5,0.5c-0.1-0.4,0.2-0.7,0.4-1c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3,0-0.4,0c-0.2,0.1-0.5,0.5-0.7,0.3
									s-0.1-0.5,0.1-0.8v-0.1c0-0.2,0.1-0.4,0.2-0.4c0.2,0,0.3,0.2,0.3,0.4s0.2,0.3,0.4,0.4C45.6,135.1,45.6,135.3,45.3,135.8
									L45.3,135.8z M41.9,132.6c-0.2,0.1-0.5,0.1-0.7,0c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3,0
									C42.1,132.4,42,132.5,41.9,132.6L41.9,132.6z M41.2,138.5c-0.1,0.2-0.2,0.4-0.4,0.6c0,0,0,0-0.1,0c0-0.2,0.1-0.5,0.1-0.7
									c0-0.1,0.1-0.2,0.3-0.2C41.2,138.3,41.2,138.4,41.2,138.5L41.2,138.5z M57.9,125.7c-2.9,1.4-6,2.4-9.3,3.2
									c-3.4,0.7-6.8,1.2-10.2,1.2c0,0.5,0.1,1,0.1,1.5l0.1-0.1c0,0.3,0.1,0.5-0.1,0.7c0.1,1.6,0.1,3.1,0.2,4.6
									c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.4,0,0.7-0.3,1c0,1.5,0.1,3,0.1,4.3c7.4-0.6,14.5-1.4,21.2-2.6l0.3-0.1v0.1l1.9,2.4
									c-0.2-6.5-0.4-13.8-2.1-16.3c-1.2-0.7-2.5-1.4-3.8-2.1l1.8,2.5L57.9,125.7L57.9,125.7z"/>
							</defs>
							<clipPath id="SVGID_12_">
								<use xlink:href="#SVGID_11_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st8">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M49,138.5c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2s0.2-0.3,0.3-0.3C49,138.1,49,138.3,49,138.5L49,138.5z
			 M48.2,137.8c-0.1-0.2,0.1-0.3,0.2-0.5c0.2,0.3,0,0.4-0.1,0.5C48.3,137.8,48.2,137.8,48.2,137.8z M45.3,135.8
			c-0.1,0.2-0.2,0.4-0.5,0.5c-0.1-0.4,0.2-0.7,0.4-1c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.3,0-0.4,0c-0.2,0.1-0.5,0.5-0.7,0.3
			s-0.1-0.5,0.1-0.8v-0.1c0-0.2,0.1-0.4,0.2-0.4c0.2,0,0.3,0.2,0.3,0.4s0.2,0.3,0.4,0.4C45.6,135.1,45.6,135.3,45.3,135.8
			L45.3,135.8z M41.9,132.6c-0.2,0.1-0.5,0.1-0.7,0c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3,0C42.1,132.4,42,132.5,41.9,132.6
			L41.9,132.6z M41.2,138.5c-0.1,0.2-0.2,0.4-0.4,0.6c0,0,0,0-0.1,0c0-0.2,0.1-0.5,0.1-0.7c0-0.1,0.1-0.2,0.3-0.2
			C41.2,138.3,41.2,138.4,41.2,138.5L41.2,138.5z M57.9,125.7c-2.9,1.4-6,2.4-9.3,3.2c-3.4,0.7-6.8,1.2-10.2,1.2
			c0,0.5,0.1,1,0.1,1.5l0.1-0.1c0,0.3,0.1,0.5-0.1,0.7c0.1,1.6,0.1,3.1,0.2,4.6c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.4,0,0.7-0.3,1
			c0,1.5,0.1,3,0.1,4.3c7.4-0.6,14.5-1.4,21.2-2.6l0.3-0.1v0.1l1.9,2.4c-0.2-6.5-0.4-13.8-2.1-16.3c-1.2-0.7-2.5-1.4-3.8-2.1
			l1.8,2.5L57.9,125.7L57.9,125.7z"/>
				<path class="st2" d="M27.9,221.2c0.1,0.2,0.2,0.4,0.3,0.5l0.1-18.2c0-4.7,0-7.3-0.1-7.9l-0.3-4.1v-0.1l-0.1-0.7h0.1l5.3,1.6
			c6.8,0,12.5-0.4,17.3-1.1l2.9-1.3l3.6,2.7l-0.9,2v24.7l-5.1,1.3v-25.9c-4.6,0.2-10.5,0.5-17.6,1.1v24.8l-4.9,1.4
			c0.1,0.2,0.3,0.4,0.3,0.7c0,0.1,0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1C27.5,222.6,27.7,222,27.9,221.2L27.9,221.2z M25.7,206.5
			c0-0.3,0.1-0.5,0.1-0.8C26.1,206,26,206.1,25.7,206.5z M39.8,183.1c0.1,0.4-0.2,0.5-0.4,0.7c0.2,0.1,0,0.2-0.1,0.4
			c-0.1-0.2,0-0.3,0-0.4C39.1,183.4,39.6,183.4,39.8,183.1L39.8,183.1z M45.9,146.6c0.1-0.2,0.3,0,0.3,0.1c0,0.4,0,0.8-0.4,1.2
			c-0.1-0.3-0.1-0.6-0.1-0.8c-0.3,0.1-0.5,0.4-1,0.2C45.1,147.1,45.6,147,45.9,146.6L45.9,146.6z M55.7,163.7
			c0.3-0.3,0.5-0.5,0.6-0.9c0.1-0.3,0.5-0.4,0.7-0.8c0-0.1,0.1-0.3,0.2-0.5c0,0.5,0.5,0.7,0.3,1.2c-0.1,0.4-0.2,0.8-0.8,0.7
			c-0.2,0-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.6,0.3C55.7,164,55.6,163.8,55.7,163.7L55.7,163.7z M57.3,149.7c0.1-0.1,0.2,0,0.4,0
			c-0.1,0.2-0.2,0.2-0.4,0.1C57.3,149.8,57.2,149.8,57.3,149.7z M57.2,163.6c0.1,0.3-0.2,0.5-0.2,0.9
			C56.9,164.1,56.9,163.8,57.2,163.6z M58,188.9c0,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.2,0.2,0.2c0.2-0.1,0.2,0,0.2,0.2
			c-0.1,0.5,0.3,1.2-0.4,1.6c-0.2,0.1-0.2,0.2-0.2,0.4s-0.2,0.4-0.4,0.5s-0.4,0.2-0.6,0.1c-0.2-0.1-0.1-0.3,0-0.5s0.1-0.4,0.3-0.6
			c0.8-0.5,0.8-1.2,0.8-1.8C57.7,189.2,57.7,189.1,58,188.9L58,188.9z M59.3,185.7c0,0.2,0.1,0.3-0.1,0.4h-0.1
			C59,185.9,59.2,185.8,59.3,185.7L59.3,185.7z M59.1,222.1c0.1,0.2,0.2,0.4,0.3,0.5C59.1,222.6,59,222.4,59.1,222.1z M60.4,181.2
			c0-0.2,0.2-0.3,0.4-0.4c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1
			c0.2-0.2,0.3-0.4,0.4-0.7c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5
			c-0.1,0.2-0.2,0.2-0.4,0.3c-0.6,0.2-0.6,0.3-0.5,0.8c0,0.3-0.2,0.7-0.4,1c-0.1,0.2-0.1,0.5-0.5,0.5
			C59.9,183.5,60.2,182.4,60.4,181.2L60.4,181.2z M60.9,156.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.1
			C60.9,156.1,60.9,156.2,60.9,156.3z M60.7,153.3c0.1-0.1,0.2,0,0.2,0.1c0.1,0.3,0.3,0.6,0.1,0.9c-0.3-0.2-0.2-0.6-0.4-0.8
			C60.5,153.4,60.6,153.3,60.7,153.3L60.7,153.3z M53.6,227.4c0.1,0.2,0.2,0.3,0.1,0.6C53.5,227.7,53.5,227.5,53.6,227.4z
			 M53.7,230.6c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.5-0.4C53.7,230.3,53.7,230.4,53.7,230.6L53.7,230.6z
			 M47.9,224c-0.2,0-0.2-0.2-0.2-0.4s0.2-0.2,0.3-0.2c0.2,0,0.3,0,0.4,0.2C48.3,223.8,48.1,224,47.9,224L47.9,224z M23.6,216
			c-0.5-0.5-0.2-0.8,0.3-1.2C23.8,215.3,23.4,215.6,23.6,216z M20.3,187c-0.1,0-0.2,0-0.1-0.1c0-0.1,0.1-0.1,0.2-0.2
			c0.1,0,0.1,0,0.1,0.1C20.5,187,20.4,187,20.3,187L20.3,187z M20,206.4c-0.4,0.3-0.4,0.7-0.6,1c-0.2-0.4,0-0.8,0.2-1.2
			c0.1-0.2,0.2-0.2,0.3-0.1C20.1,206.1,20.2,206.3,20,206.4L20,206.4z M18,206.4c-0.3,0.3-0.1,0.7-0.3,1.1c-0.2-0.6,0.1-1,0.2-1.5
			C18,206.1,18.3,206.1,18,206.4z M17.3,187.9c-0.4,0.1-0.5,0.3-0.5,0.6c0,0.2-0.1,0.4-0.2,0.5c0-0.6-0.1-1.2-0.1-1.8
			c0-0.5,0.6-0.9,1.1-0.9c0.2,0,0.4,0.1,0.4,0.3c-0.2,0.6,0,1.2-0.1,1.8C17.7,188.2,17.7,187.9,17.3,187.9L17.3,187.9z M16.5,210
			c0-0.2,0.1-0.3,0.3-0.2l0.1,0.1C16.9,210.1,16.6,209.9,16.5,210z M16.5,189.3c0.1,0.2,0,0.3-0.1,0.5
			C16.4,189.6,16.5,189.5,16.5,189.3z M15.8,226.3c0,0,0,0.1,0,0.2c-0.2-0.3-0.2-0.7-0.4-1.1v-0.1c0.3-0.2,0.1-0.4,0-0.6
			c0-0.1-0.1-0.2-0.1-0.3s0-0.2-0.2-0.3c-0.1,0,0,0,0-0.1c-0.1-0.5-0.5-1-0.2-1.6c0-0.1,0-0.1,0-0.2c-0.4-0.5-0.5-1-0.6-1.6
			c0-0.1,0-0.2,0-0.4c0.2,0.2,0.4,0.4,0.5,0.7c0.4,0.6,0.5,1.2,0.7,1.9c0.1,0.3,0.3,0.7,0.4,1c0.1,0.4,0.2,0.8-0.1,1.1
			c-0.1,0.2-0.1,0.3,0,0.4C16,225.7,16,226,15.8,226.3L15.8,226.3z M13.2,186.9c0.1,0.2,0.1,0.3,0.2,0.5
			C13,187.3,13,187.2,13.2,186.9z M12.9,193.3c-0.2-0.1-0.4,0.3-0.6,0.1c-0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.4-0.8,0.4-1.2
			c0.1,0,0.1,0,0.2,0c0.1,0.5,0.2,1,0.2,1.5C13.3,193.2,13.1,193.4,12.9,193.3L12.9,193.3z M8.4,174.2c-0.1,0.5,0,0.9-0.7,1.2
			c-0.2,0.1-0.2,0.2-0.3,0.4c-0.4-0.5-0.3-1-0.5-1.5c-0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.2
			C8.4,173.8,8.4,174,8.4,174.2L8.4,174.2z M29.5,134.9c0.1,0,0.2-0.1,0.3-0.2c0.1,0.3-0.2,0.5-0.3,0.4
			C29.1,135.1,29.1,135,29.5,134.9z M39.8,122.2c0.1-0.2,0.2-0.3,0.3-0.6c0.1,0.7,0.5,1.3,0.3,2h-0.1
			C39.2,123.2,39.2,123.2,39.8,122.2L39.8,122.2z M41.3,121.4c0.2-0.1,0.2,0.1,0.4,0.1s0.2,0.2,0.2,0.3c0,0.3-0.3,0.4-0.3,0.8
			c-0.2-0.3-0.2-0.6-0.2-0.9C41.3,121.6,41.2,121.5,41.3,121.4L41.3,121.4z M34,223.8c3-5.8,4.5-12.2,4.5-19.2c0-0.7,0-1.8-0.1-3.2
			c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.3-0.6-0.6-0.9l0.6-0.7c2,0.3,3.8,0.6,5.4,1.1l0.1,0.1c0.1,1.7,0.2,3.1,0.2,4
			c0,2.5-0.2,5.1-0.7,7.9l4.6,1.4l-1.4,14.4c0,0.1-0.1,0.3-0.1,0.6c0,1,0.3,1.8,0.8,2.6c0.5,0.7,1.3,1.1,2.2,1l3.8,0
			c1.3,0,2.5-0.1,3.5-0.3s1.9-0.6,2.8-1.1c1.1-1,1.8-2.3,2-3.9h1c0.6-19.9,0.4-39.1,0.8-58.6c0.8-4.3,0.8-4.3,1.1-4.5v-10.5
			c-0.6-0.3-1-1.3-1.2-2.6l-0.5-3.8c-0.1-0.9-0.1-1.8-0.2-2.8c-2.8,0.1-5.6,0.2-8.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3s-0.2,0.3-0.2,0.4
			h-0.1c0-0.2,0.1-0.4,0.1-0.6c-0.4,0-0.8,0.1-1.2,0.1c0.1,0.4,0.3,0.7,0.4,1l0.9,0.3v41l-5.9,1.9v-7.9v-13.2
			c-0.1,0.1-0.1,0.1-0.3,0.2c0-0.2,0.1-0.3,0.3-0.4v-8.2c0-5.5,0-8.6-0.1-9.3c0-1.6-0.4-3.1-1-4.7c-1.8,0.2-4.4,0.6-7.6,1.2
			c-0.3,6.4-1.1,11.8-2.3,16.3c-1.3,4.5-2.9,8.4-4.8,11.5c-1.9,3.2-4.5,5.5-7.7,7.1l-1.2-0.9c3.8-4,6.5-9.1,7.9-15.2
			c1.4-6.1,2.2-12.7,2.2-19.9c0-3.2-0.1-6.6-0.3-10.5c-0.1,0-0.2-0.1-0.1-0.2l0.1-0.1c0-0.1,0-0.1,0-0.2l-0.1-3.2l-0.1-0.4
			c0-1-0.1-1.9-0.2-2.6c-0.1-0.7-0.4-1.3-0.9-1.8l-0.1-0.1l0.9-1.3l0.1,0.1l4.7,1.9l0,0l0,0l1.5,0.6c0.7-0.2,1.4-0.3,2.1-0.5v-0.1
			v0.1c2.5-0.6,4.8-1.4,6.9-2.3c2-0.9,3.7-2,5.2-3.3c-1.3-0.5-2.7-0.9-4.1-1.2c-0.6-0.1-1.3-0.2-1.9-0.3c0.1-0.1,0.2-0.2,0.2-0.3
			c0.1-0.2,0-0.5,0.2-0.8c0-0.1,0-0.1-0.1-0.2s-0.2,0-0.3,0.1c-0.1,0.2-0.3,0.4-0.6,0.4c-0.2,0-0.3,0.1-0.4,0.3
			c-0.1,0.2,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.2,0-0.2,0c-0.7-0.5-1.4-0.8-2.1-1.2h-0.5h-1.5
			c-0.1,0.2-3.2,1.2-6.1,2.3c-1.4,0.6-2.8,1.1-3.8,1.7c-0.6,0.5-1.2,1-1.7,1.4c-3.2,2.4-6.3,4.1-11,5.5c-1.9,1.5-4.5,2.5-7.2,3.6
			l2.5,0.8c1-0.2,2.4-0.5,4.4-0.9l4.8-1.7l4.2,3.2l-1,2.6V164l-5.9,2v-1.1c-2.2,0.4-3.9,0.8-5.1,1.1v5.6l-6,1.8v-10.3
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.4v-2.2c-0.2-0.2-0.2-0.4-0.1-0.6
			c0.1,0.1,0.1,0.3,0.1,0.4v-11.8v-0.7l-0.1-0.1c0,0,0.1,0,0.1-0.1c0-5.7,0-8.8-0.1-9.4c-0.1-1.4-0.4-2.8-0.8-4l-2,1
			c-2.4,1.4-4.5,3.1-6,5.7v4.1c1.1,5.5,1.4,10.6,1.5,16.1c0,0.1,0,0.4,0,0.5s-0.1,0.2-0.1,0.3c0.1,0,0.1,0,0.1,0
			c0,4.1-0.1,8.3-0.1,13c1.4,5.3,0.5,8.9,0.6,15c1.8,5.4,1.4,6.4,0.9,12.1c0-0.1,0.1-0.2,0.1-0.4s0.2-0.3,0.4-0.4
			c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1c0.2-0.2,0.3-0.4,0.4-0.7
			c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.2-0.4,0.3
			C4,202.9,4,203,4.1,203.5v0.1c1.1,3.1-0.9,14.6,1.7,20.1c2.8,2.5,6.4,2.5,9.9,2.5c0.1,0.3,0.2,0.5,0.4,0.8c0-0.1,0-0.2,0-0.3
			c0.1-0.2,0.1-0.3,0.1-0.5c2.7,0,5.3,0.2,7.4,1.7c0,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.1,0.1-0.2c1.2,0.9,2.3,2.4,3.1,4.5
			C29.7,230.4,32.1,227.4,34,223.8L34,223.8z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_13_" d="M27.9,221.2c0.1,0.2,0.2,0.4,0.3,0.5l0.1-18.2c0-4.7,0-7.3-0.1-7.9l-0.3-4.1v-0.1l-0.1-0.7h0.1l5.3,1.6
						c6.8,0,12.5-0.4,17.3-1.1l2.9-1.3l3.6,2.7l-0.9,2v24.7l-5.1,1.3v-25.9c-4.6,0.2-10.5,0.5-17.6,1.1v24.8l-4.9,1.4
						c0.1,0.2,0.3,0.4,0.3,0.7c0,0.1,0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1C27.5,222.6,27.7,222,27.9,221.2L27.9,221.2z
						 M25.7,206.5c0-0.3,0.1-0.5,0.1-0.8C26.1,206,26,206.1,25.7,206.5z M39.8,183.1c0.1,0.4-0.2,0.5-0.4,0.7c0.2,0.1,0,0.2-0.1,0.4
						c-0.1-0.2,0-0.3,0-0.4C39.1,183.4,39.6,183.4,39.8,183.1L39.8,183.1z M45.9,146.6c0.1-0.2,0.3,0,0.3,0.1c0,0.4,0,0.8-0.4,1.2
						c-0.1-0.3-0.1-0.6-0.1-0.8c-0.3,0.1-0.5,0.4-1,0.2C45.1,147.1,45.6,147,45.9,146.6L45.9,146.6z M55.7,163.7
						c0.3-0.3,0.5-0.5,0.6-0.9c0.1-0.3,0.5-0.4,0.7-0.8c0-0.1,0.1-0.3,0.2-0.5c0,0.5,0.5,0.7,0.3,1.2c-0.1,0.4-0.2,0.8-0.8,0.7
						c-0.2,0-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.6,0.3C55.7,164,55.6,163.8,55.7,163.7L55.7,163.7z M57.3,149.7
						c0.1-0.1,0.2,0,0.4,0c-0.1,0.2-0.2,0.2-0.4,0.1C57.3,149.8,57.2,149.8,57.3,149.7z M57.2,163.6c0.1,0.3-0.2,0.5-0.2,0.9
						C56.9,164.1,56.9,163.8,57.2,163.6z M58,188.9c0,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.2,0.2,0.2c0.2-0.1,0.2,0,0.2,0.2
						c-0.1,0.5,0.3,1.2-0.4,1.6c-0.2,0.1-0.2,0.2-0.2,0.4s-0.2,0.4-0.4,0.5s-0.4,0.2-0.6,0.1c-0.2-0.1-0.1-0.3,0-0.5
						s0.1-0.4,0.3-0.6c0.8-0.5,0.8-1.2,0.8-1.8C57.7,189.2,57.7,189.1,58,188.9L58,188.9z M59.3,185.7c0,0.2,0.1,0.3-0.1,0.4h-0.1
						C59,185.9,59.2,185.8,59.3,185.7L59.3,185.7z M59.1,222.1c0.1,0.2,0.2,0.4,0.3,0.5C59.1,222.6,59,222.4,59.1,222.1z
						 M60.4,181.2c0-0.2,0.2-0.3,0.4-0.4c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1
						c0.1,0.3-0.2,0.7,0.1,1c0.2-0.2,0.3-0.4,0.4-0.7c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8
						v0.1c-0.6,0.4-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.2-0.4,0.3c-0.6,0.2-0.6,0.3-0.5,0.8c0,0.3-0.2,0.7-0.4,1c-0.1,0.2-0.1,0.5-0.5,0.5
						C59.9,183.5,60.2,182.4,60.4,181.2L60.4,181.2z M60.9,156.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2
						c0-0.1,0-0.1,0.1-0.1C60.9,156.1,60.9,156.2,60.9,156.3z M60.7,153.3c0.1-0.1,0.2,0,0.2,0.1c0.1,0.3,0.3,0.6,0.1,0.9
						c-0.3-0.2-0.2-0.6-0.4-0.8C60.5,153.4,60.6,153.3,60.7,153.3L60.7,153.3z M53.6,227.4c0.1,0.2,0.2,0.3,0.1,0.6
						C53.5,227.7,53.5,227.5,53.6,227.4z M53.7,230.6c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.5-0.4
						C53.7,230.3,53.7,230.4,53.7,230.6L53.7,230.6z M47.9,224c-0.2,0-0.2-0.2-0.2-0.4s0.2-0.2,0.3-0.2c0.2,0,0.3,0,0.4,0.2
						C48.3,223.8,48.1,224,47.9,224L47.9,224z M23.6,216c-0.5-0.5-0.2-0.8,0.3-1.2C23.8,215.3,23.4,215.6,23.6,216z M20.3,187
						c-0.1,0-0.2,0-0.1-0.1c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.1,0.1C20.5,187,20.4,187,20.3,187L20.3,187z M20,206.4
						c-0.4,0.3-0.4,0.7-0.6,1c-0.2-0.4,0-0.8,0.2-1.2c0.1-0.2,0.2-0.2,0.3-0.1C20.1,206.1,20.2,206.3,20,206.4L20,206.4z M18,206.4
						c-0.3,0.3-0.1,0.7-0.3,1.1c-0.2-0.6,0.1-1,0.2-1.5C18,206.1,18.3,206.1,18,206.4z M17.3,187.9c-0.4,0.1-0.5,0.3-0.5,0.6
						c0,0.2-0.1,0.4-0.2,0.5c0-0.6-0.1-1.2-0.1-1.8c0-0.5,0.6-0.9,1.1-0.9c0.2,0,0.4,0.1,0.4,0.3c-0.2,0.6,0,1.2-0.1,1.8
						C17.7,188.2,17.7,187.9,17.3,187.9L17.3,187.9z M16.5,210c0-0.2,0.1-0.3,0.3-0.2l0.1,0.1C16.9,210.1,16.6,209.9,16.5,210z
						 M16.5,189.3c0.1,0.2,0,0.3-0.1,0.5C16.4,189.6,16.5,189.5,16.5,189.3z M15.8,226.3c0,0,0,0.1,0,0.2c-0.2-0.3-0.2-0.7-0.4-1.1
						v-0.1c0.3-0.2,0.1-0.4,0-0.6c0-0.1-0.1-0.2-0.1-0.3s0-0.2-0.2-0.3c-0.1,0,0,0,0-0.1c-0.1-0.5-0.5-1-0.2-1.6c0-0.1,0-0.1,0-0.2
						c-0.4-0.5-0.5-1-0.6-1.6c0-0.1,0-0.2,0-0.4c0.2,0.2,0.4,0.4,0.5,0.7c0.4,0.6,0.5,1.2,0.7,1.9c0.1,0.3,0.3,0.7,0.4,1
						c0.1,0.4,0.2,0.8-0.1,1.1c-0.1,0.2-0.1,0.3,0,0.4C16,225.7,16,226,15.8,226.3L15.8,226.3z M13.2,186.9c0.1,0.2,0.1,0.3,0.2,0.5
						C13,187.3,13,187.2,13.2,186.9z M12.9,193.3c-0.2-0.1-0.4,0.3-0.6,0.1c-0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.4-0.8,0.4-1.2
						c0.1,0,0.1,0,0.2,0c0.1,0.5,0.2,1,0.2,1.5C13.3,193.2,13.1,193.4,12.9,193.3L12.9,193.3z M8.4,174.2c-0.1,0.5,0,0.9-0.7,1.2
						c-0.2,0.1-0.2,0.2-0.3,0.4c-0.4-0.5-0.3-1-0.5-1.5c-0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.2
						C8.4,173.8,8.4,174,8.4,174.2L8.4,174.2z M29.5,134.9c0.1,0,0.2-0.1,0.3-0.2c0.1,0.3-0.2,0.5-0.3,0.4
						C29.1,135.1,29.1,135,29.5,134.9z M39.8,122.2c0.1-0.2,0.2-0.3,0.3-0.6c0.1,0.7,0.5,1.3,0.3,2h-0.1
						C39.2,123.2,39.2,123.2,39.8,122.2L39.8,122.2z M41.3,121.4c0.2-0.1,0.2,0.1,0.4,0.1s0.2,0.2,0.2,0.3c0,0.3-0.3,0.4-0.3,0.8
						c-0.2-0.3-0.2-0.6-0.2-0.9C41.3,121.6,41.2,121.5,41.3,121.4L41.3,121.4z M34,223.8c3-5.8,4.5-12.2,4.5-19.2
						c0-0.7,0-1.8-0.1-3.2c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.3-0.6-0.6-0.9l0.6-0.7c2,0.3,3.8,0.6,5.4,1.1l0.1,0.1
						c0.1,1.7,0.2,3.1,0.2,4c0,2.5-0.2,5.1-0.7,7.9l4.6,1.4l-1.4,14.4c0,0.1-0.1,0.3-0.1,0.6c0,1,0.3,1.8,0.8,2.6
						c0.5,0.7,1.3,1.1,2.2,1l3.8,0c1.3,0,2.5-0.1,3.5-0.3s1.9-0.6,2.8-1.1c1.1-1,1.8-2.3,2-3.9h1c0.6-19.9,0.4-39.1,0.8-58.6
						c0.8-4.3,0.8-4.3,1.1-4.5v-10.5c-0.6-0.3-1-1.3-1.2-2.6l-0.5-3.8c-0.1-0.9-0.1-1.8-0.2-2.8c-2.8,0.1-5.6,0.2-8.5,0.4
						c-0.1,0.1-0.2,0.2-0.3,0.3s-0.2,0.3-0.2,0.4h-0.1c0-0.2,0.1-0.4,0.1-0.6c-0.4,0-0.8,0.1-1.2,0.1c0.1,0.4,0.3,0.7,0.4,1l0.9,0.3
						v41l-5.9,1.9v-7.9v-13.2c-0.1,0.1-0.1,0.1-0.3,0.2c0-0.2,0.1-0.3,0.3-0.4v-8.2c0-5.5,0-8.6-0.1-9.3c0-1.6-0.4-3.1-1-4.7
						c-1.8,0.2-4.4,0.6-7.6,1.2c-0.3,6.4-1.1,11.8-2.3,16.3c-1.3,4.5-2.9,8.4-4.8,11.5c-1.9,3.2-4.5,5.5-7.7,7.1l-1.2-0.9
						c3.8-4,6.5-9.1,7.9-15.2c1.4-6.1,2.2-12.7,2.2-19.9c0-3.2-0.1-6.6-0.3-10.5c-0.1,0-0.2-0.1-0.1-0.2l0.1-0.1c0-0.1,0-0.1,0-0.2
						l-0.1-3.2l-0.1-0.4c0-1-0.1-1.9-0.2-2.6c-0.1-0.7-0.4-1.3-0.9-1.8l-0.1-0.1l0.9-1.3l0.1,0.1l4.7,1.9l0,0l0,0l1.5,0.6
						c0.7-0.2,1.4-0.3,2.1-0.5v-0.1v0.1c2.5-0.6,4.8-1.4,6.9-2.3c2-0.9,3.7-2,5.2-3.3c-1.3-0.5-2.7-0.9-4.1-1.2
						c-0.6-0.1-1.3-0.2-1.9-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0-0.5,0.2-0.8c0-0.1,0-0.1-0.1-0.2s-0.2,0-0.3,0.1
						c-0.1,0.2-0.3,0.4-0.6,0.4c-0.2,0-0.3,0.1-0.4,0.3c-0.1,0.2,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.2,0-0.2,0
						c-0.7-0.5-1.4-0.8-2.1-1.2h-0.5h-1.5c-0.1,0.2-3.2,1.2-6.1,2.3c-1.4,0.6-2.8,1.1-3.8,1.7c-0.6,0.5-1.2,1-1.7,1.4
						c-3.2,2.4-6.3,4.1-11,5.5c-1.9,1.5-4.5,2.5-7.2,3.6l2.5,0.8c1-0.2,2.4-0.5,4.4-0.9l4.8-1.7l4.2,3.2l-1,2.6V164l-5.9,2v-1.1
						c-2.2,0.4-3.9,0.8-5.1,1.1v5.6l-6,1.8v-10.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4
						c0.1-0.2,0.2-0.3,0.4-0.4v-2.2c-0.2-0.2-0.2-0.4-0.1-0.6c0.1,0.1,0.1,0.3,0.1,0.4v-11.8v-0.7l-0.1-0.1c0,0,0.1,0,0.1-0.1
						c0-5.7,0-8.8-0.1-9.4c-0.1-1.4-0.4-2.8-0.8-4l-2,1c-2.4,1.4-4.5,3.1-6,5.7v4.1c1.1,5.5,1.4,10.6,1.5,16.1c0,0.1,0,0.4,0,0.5
						s-0.1,0.2-0.1,0.3c0.1,0,0.1,0,0.1,0c0,4.1-0.1,8.3-0.1,13c1.4,5.3,0.5,8.9,0.6,15c1.8,5.4,1.4,6.4,0.9,12.1
						c0-0.1,0.1-0.2,0.1-0.4s0.2-0.3,0.4-0.4c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1
						c0.1,0.3-0.2,0.7,0.1,1c0.2-0.2,0.3-0.4,0.4-0.7c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8
						v0.1c-0.6,0.4-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.2-0.4,0.3C4,202.9,4,203,4.1,203.5v0.1c1.1,3.1-0.9,14.6,1.7,20.1
						c2.8,2.5,6.4,2.5,9.9,2.5c0.1,0.3,0.2,0.5,0.4,0.8c0-0.1,0-0.2,0-0.3c0.1-0.2,0.1-0.3,0.1-0.5c2.7,0,5.3,0.2,7.4,1.7
						c0,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.1,0.1-0.2c1.2,0.9,2.3,2.4,3.1,4.5C29.7,230.4,32.1,227.4,34,223.8L34,223.8z"/>
				</defs>
				<clipPath id="SVGID_14_">
					<use xlink:href="#SVGID_13_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st9">
					<g>
						<g>
							<defs>
								<path id="SVGID_15_" d="M27.9,221.2c0.1,0.2,0.2,0.4,0.3,0.5l0.1-18.2c0-4.7,0-7.3-0.1-7.9l-0.3-4.1v-0.1l-0.1-0.7h0.1
									l5.3,1.6c6.8,0,12.5-0.4,17.3-1.1l2.9-1.3l3.6,2.7l-0.9,2v24.7l-5.1,1.3v-25.9c-4.6,0.2-10.5,0.5-17.6,1.1v24.8l-4.9,1.4
									c0.1,0.2,0.3,0.4,0.3,0.7c0,0.1,0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1C27.5,222.6,27.7,222,27.9,221.2L27.9,221.2z
									 M25.7,206.5c0-0.3,0.1-0.5,0.1-0.8C26.1,206,26,206.1,25.7,206.5z M39.8,183.1c0.1,0.4-0.2,0.5-0.4,0.7
									c0.2,0.1,0,0.2-0.1,0.4c-0.1-0.2,0-0.3,0-0.4C39.1,183.4,39.6,183.4,39.8,183.1L39.8,183.1z M45.9,146.6
									c0.1-0.2,0.3,0,0.3,0.1c0,0.4,0,0.8-0.4,1.2c-0.1-0.3-0.1-0.6-0.1-0.8c-0.3,0.1-0.5,0.4-1,0.2
									C45.1,147.1,45.6,147,45.9,146.6L45.9,146.6z M55.7,163.7c0.3-0.3,0.5-0.5,0.6-0.9c0.1-0.3,0.5-0.4,0.7-0.8
									c0-0.1,0.1-0.3,0.2-0.5c0,0.5,0.5,0.7,0.3,1.2c-0.1,0.4-0.2,0.8-0.8,0.7c-0.2,0-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.6,0.3
									C55.7,164,55.6,163.8,55.7,163.7L55.7,163.7z M57.3,149.7c0.1-0.1,0.2,0,0.4,0c-0.1,0.2-0.2,0.2-0.4,0.1
									C57.3,149.8,57.2,149.8,57.3,149.7z M57.2,163.6c0.1,0.3-0.2,0.5-0.2,0.9C56.9,164.1,56.9,163.8,57.2,163.6z M58,188.9
									c0,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.2,0.2,0.2c0.2-0.1,0.2,0,0.2,0.2c-0.1,0.5,0.3,1.2-0.4,1.6c-0.2,0.1-0.2,0.2-0.2,0.4
									s-0.2,0.4-0.4,0.5s-0.4,0.2-0.6,0.1c-0.2-0.1-0.1-0.3,0-0.5s0.1-0.4,0.3-0.6c0.8-0.5,0.8-1.2,0.8-1.8
									C57.7,189.2,57.7,189.1,58,188.9L58,188.9z M59.3,185.7c0,0.2,0.1,0.3-0.1,0.4h-0.1C59,185.9,59.2,185.8,59.3,185.7
									L59.3,185.7z M59.1,222.1c0.1,0.2,0.2,0.4,0.3,0.5C59.1,222.6,59,222.4,59.1,222.1z M60.4,181.2c0-0.2,0.2-0.3,0.4-0.4
									c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1c0.2-0.2,0.3-0.4,0.4-0.7
									c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5
									c-0.1,0.2-0.2,0.2-0.4,0.3c-0.6,0.2-0.6,0.3-0.5,0.8c0,0.3-0.2,0.7-0.4,1c-0.1,0.2-0.1,0.5-0.5,0.5
									C59.9,183.5,60.2,182.4,60.4,181.2L60.4,181.2z M60.9,156.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2
									c0-0.1,0-0.1,0.1-0.1C60.9,156.1,60.9,156.2,60.9,156.3z M60.7,153.3c0.1-0.1,0.2,0,0.2,0.1c0.1,0.3,0.3,0.6,0.1,0.9
									c-0.3-0.2-0.2-0.6-0.4-0.8C60.5,153.4,60.6,153.3,60.7,153.3L60.7,153.3z M53.6,227.4c0.1,0.2,0.2,0.3,0.1,0.6
									C53.5,227.7,53.5,227.5,53.6,227.4z M53.7,230.6c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.5-0.4
									C53.7,230.3,53.7,230.4,53.7,230.6L53.7,230.6z M47.9,224c-0.2,0-0.2-0.2-0.2-0.4s0.2-0.2,0.3-0.2c0.2,0,0.3,0,0.4,0.2
									C48.3,223.8,48.1,224,47.9,224L47.9,224z M23.6,216c-0.5-0.5-0.2-0.8,0.3-1.2C23.8,215.3,23.4,215.6,23.6,216z M20.3,187
									c-0.1,0-0.2,0-0.1-0.1c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.1,0.1C20.5,187,20.4,187,20.3,187L20.3,187z M20,206.4
									c-0.4,0.3-0.4,0.7-0.6,1c-0.2-0.4,0-0.8,0.2-1.2c0.1-0.2,0.2-0.2,0.3-0.1C20.1,206.1,20.2,206.3,20,206.4L20,206.4z
									 M18,206.4c-0.3,0.3-0.1,0.7-0.3,1.1c-0.2-0.6,0.1-1,0.2-1.5C18,206.1,18.3,206.1,18,206.4z M17.3,187.9
									c-0.4,0.1-0.5,0.3-0.5,0.6c0,0.2-0.1,0.4-0.2,0.5c0-0.6-0.1-1.2-0.1-1.8c0-0.5,0.6-0.9,1.1-0.9c0.2,0,0.4,0.1,0.4,0.3
									c-0.2,0.6,0,1.2-0.1,1.8C17.7,188.2,17.7,187.9,17.3,187.9L17.3,187.9z M16.5,210c0-0.2,0.1-0.3,0.3-0.2l0.1,0.1
									C16.9,210.1,16.6,209.9,16.5,210z M16.5,189.3c0.1,0.2,0,0.3-0.1,0.5C16.4,189.6,16.5,189.5,16.5,189.3z M15.8,226.3
									c0,0,0,0.1,0,0.2c-0.2-0.3-0.2-0.7-0.4-1.1v-0.1c0.3-0.2,0.1-0.4,0-0.6c0-0.1-0.1-0.2-0.1-0.3s0-0.2-0.2-0.3
									c-0.1,0,0,0,0-0.1c-0.1-0.5-0.5-1-0.2-1.6c0-0.1,0-0.1,0-0.2c-0.4-0.5-0.5-1-0.6-1.6c0-0.1,0-0.2,0-0.4
									c0.2,0.2,0.4,0.4,0.5,0.7c0.4,0.6,0.5,1.2,0.7,1.9c0.1,0.3,0.3,0.7,0.4,1c0.1,0.4,0.2,0.8-0.1,1.1c-0.1,0.2-0.1,0.3,0,0.4
									C16,225.7,16,226,15.8,226.3L15.8,226.3z M13.2,186.9c0.1,0.2,0.1,0.3,0.2,0.5C13,187.3,13,187.2,13.2,186.9z M12.9,193.3
									c-0.2-0.1-0.4,0.3-0.6,0.1c-0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.4-0.8,0.4-1.2c0.1,0,0.1,0,0.2,0c0.1,0.5,0.2,1,0.2,1.5
									C13.3,193.2,13.1,193.4,12.9,193.3L12.9,193.3z M8.4,174.2c-0.1,0.5,0,0.9-0.7,1.2c-0.2,0.1-0.2,0.2-0.3,0.4
									c-0.4-0.5-0.3-1-0.5-1.5c-0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.2
									C8.4,173.8,8.4,174,8.4,174.2L8.4,174.2z M29.5,134.9c0.1,0,0.2-0.1,0.3-0.2c0.1,0.3-0.2,0.5-0.3,0.4
									C29.1,135.1,29.1,135,29.5,134.9z M39.8,122.2c0.1-0.2,0.2-0.3,0.3-0.6c0.1,0.7,0.5,1.3,0.3,2h-0.1
									C39.2,123.2,39.2,123.2,39.8,122.2L39.8,122.2z M41.3,121.4c0.2-0.1,0.2,0.1,0.4,0.1s0.2,0.2,0.2,0.3c0,0.3-0.3,0.4-0.3,0.8
									c-0.2-0.3-0.2-0.6-0.2-0.9C41.3,121.6,41.2,121.5,41.3,121.4L41.3,121.4z M34,223.8c3-5.8,4.5-12.2,4.5-19.2
									c0-0.7,0-1.8-0.1-3.2c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.3-0.6-0.6-0.9l0.6-0.7c2,0.3,3.8,0.6,5.4,1.1l0.1,0.1
									c0.1,1.7,0.2,3.1,0.2,4c0,2.5-0.2,5.1-0.7,7.9l4.6,1.4l-1.4,14.4c0,0.1-0.1,0.3-0.1,0.6c0,1,0.3,1.8,0.8,2.6
									c0.5,0.7,1.3,1.1,2.2,1l3.8,0c1.3,0,2.5-0.1,3.5-0.3s1.9-0.6,2.8-1.1c1.1-1,1.8-2.3,2-3.9h1c0.6-19.9,0.4-39.1,0.8-58.6
									c0.8-4.3,0.8-4.3,1.1-4.5v-10.5c-0.6-0.3-1-1.3-1.2-2.6l-0.5-3.8c-0.1-0.9-0.1-1.8-0.2-2.8c-2.8,0.1-5.6,0.2-8.5,0.4
									c-0.1,0.1-0.2,0.2-0.3,0.3s-0.2,0.3-0.2,0.4h-0.1c0-0.2,0.1-0.4,0.1-0.6c-0.4,0-0.8,0.1-1.2,0.1c0.1,0.4,0.3,0.7,0.4,1
									l0.9,0.3v41l-5.9,1.9v-7.9v-13.2c-0.1,0.1-0.1,0.1-0.3,0.2c0-0.2,0.1-0.3,0.3-0.4v-8.2c0-5.5,0-8.6-0.1-9.3
									c0-1.6-0.4-3.1-1-4.7c-1.8,0.2-4.4,0.6-7.6,1.2c-0.3,6.4-1.1,11.8-2.3,16.3c-1.3,4.5-2.9,8.4-4.8,11.5
									c-1.9,3.2-4.5,5.5-7.7,7.1l-1.2-0.9c3.8-4,6.5-9.1,7.9-15.2c1.4-6.1,2.2-12.7,2.2-19.9c0-3.2-0.1-6.6-0.3-10.5
									c-0.1,0-0.2-0.1-0.1-0.2l0.1-0.1c0-0.1,0-0.1,0-0.2l-0.1-3.2l-0.1-0.4c0-1-0.1-1.9-0.2-2.6c-0.1-0.7-0.4-1.3-0.9-1.8
									l-0.1-0.1l0.9-1.3l0.1,0.1l4.7,1.9l0,0l0,0l1.5,0.6c0.7-0.2,1.4-0.3,2.1-0.5v-0.1v0.1c2.5-0.6,4.8-1.4,6.9-2.3
									c2-0.9,3.7-2,5.2-3.3c-1.3-0.5-2.7-0.9-4.1-1.2c-0.6-0.1-1.3-0.2-1.9-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0-0.5,0.2-0.8
									c0-0.1,0-0.1-0.1-0.2s-0.2,0-0.3,0.1c-0.1,0.2-0.3,0.4-0.6,0.4c-0.2,0-0.3,0.1-0.4,0.3c-0.1,0.2,0.2,0.1,0.2,0.2
									c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.2,0-0.2,0c-0.7-0.5-1.4-0.8-2.1-1.2h-0.5h-1.5c-0.1,0.2-3.2,1.2-6.1,2.3
									c-1.4,0.6-2.8,1.1-3.8,1.7c-0.6,0.5-1.2,1-1.7,1.4c-3.2,2.4-6.3,4.1-11,5.5c-1.9,1.5-4.5,2.5-7.2,3.6l2.5,0.8
									c1-0.2,2.4-0.5,4.4-0.9l4.8-1.7l4.2,3.2l-1,2.6V164l-5.9,2v-1.1c-2.2,0.4-3.9,0.8-5.1,1.1v5.6l-6,1.8v-10.3
									c0,0.1,0,0.1,0,0.2c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.4v-2.2c-0.2-0.2-0.2-0.4-0.1-0.6
									c0.1,0.1,0.1,0.3,0.1,0.4v-11.8v-0.7l-0.1-0.1c0,0,0.1,0,0.1-0.1c0-5.7,0-8.8-0.1-9.4c-0.1-1.4-0.4-2.8-0.8-4l-2,1
									c-2.4,1.4-4.5,3.1-6,5.7v4.1c1.1,5.5,1.4,10.6,1.5,16.1c0,0.1,0,0.4,0,0.5s-0.1,0.2-0.1,0.3c0.1,0,0.1,0,0.1,0
									c0,4.1-0.1,8.3-0.1,13c1.4,5.3,0.5,8.9,0.6,15c1.8,5.4,1.4,6.4,0.9,12.1c0-0.1,0.1-0.2,0.1-0.4s0.2-0.3,0.4-0.4
									c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1c0.2-0.2,0.3-0.4,0.4-0.7
									c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5
									c-0.1,0.2-0.2,0.2-0.4,0.3C4,202.9,4,203,4.1,203.5v0.1c1.1,3.1-0.9,14.6,1.7,20.1c2.8,2.5,6.4,2.5,9.9,2.5
									c0.1,0.3,0.2,0.5,0.4,0.8c0-0.1,0-0.2,0-0.3c0.1-0.2,0.1-0.3,0.1-0.5c2.7,0,5.3,0.2,7.4,1.7c0,0.1,0.1,0.2,0.2,0.3
									c0-0.1,0-0.1,0.1-0.2c1.2,0.9,2.3,2.4,3.1,4.5C29.7,230.4,32.1,227.4,34,223.8L34,223.8z"/>
							</defs>
							<clipPath id="SVGID_16_">
								<use xlink:href="#SVGID_15_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st10">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M27.9,221.2c0.1,0.2,0.2,0.4,0.3,0.5l0.1-18.2c0-4.7,0-7.3-0.1-7.9l-0.3-4.1v-0.1l-0.1-0.7h0.1l5.3,1.6
			c6.8,0,12.5-0.4,17.3-1.1l2.9-1.3l3.6,2.7l-0.9,2v24.7l-5.1,1.3v-25.9c-4.6,0.2-10.5,0.5-17.6,1.1v24.8l-4.9,1.4
			c0.1,0.2,0.3,0.4,0.3,0.7c0,0.1,0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.3-0.1C27.5,222.6,27.7,222,27.9,221.2L27.9,221.2z M25.7,206.5
			c0-0.3,0.1-0.5,0.1-0.8C26.1,206,26,206.1,25.7,206.5z M39.8,183.1c0.1,0.4-0.2,0.5-0.4,0.7c0.2,0.1,0,0.2-0.1,0.4
			c-0.1-0.2,0-0.3,0-0.4C39.1,183.4,39.6,183.4,39.8,183.1L39.8,183.1z M45.9,146.6c0.1-0.2,0.3,0,0.3,0.1c0,0.4,0,0.8-0.4,1.2
			c-0.1-0.3-0.1-0.6-0.1-0.8c-0.3,0.1-0.5,0.4-1,0.2C45.1,147.1,45.6,147,45.9,146.6L45.9,146.6z M55.7,163.7
			c0.3-0.3,0.5-0.5,0.6-0.9c0.1-0.3,0.5-0.4,0.7-0.8c0-0.1,0.1-0.3,0.2-0.5c0,0.5,0.5,0.7,0.3,1.2c-0.1,0.4-0.2,0.8-0.8,0.7
			c-0.2,0-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.4-0.6,0.3C55.7,164,55.6,163.8,55.7,163.7L55.7,163.7z M57.3,149.7c0.1-0.1,0.2,0,0.4,0
			c-0.1,0.2-0.2,0.2-0.4,0.1C57.3,149.8,57.2,149.8,57.3,149.7z M57.2,163.6c0.1,0.3-0.2,0.5-0.2,0.9
			C56.9,164.1,56.9,163.8,57.2,163.6z M58,188.9c0,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.2,0.2,0.2c0.2-0.1,0.2,0,0.2,0.2
			c-0.1,0.5,0.3,1.2-0.4,1.6c-0.2,0.1-0.2,0.2-0.2,0.4s-0.2,0.4-0.4,0.5s-0.4,0.2-0.6,0.1c-0.2-0.1-0.1-0.3,0-0.5s0.1-0.4,0.3-0.6
			c0.8-0.5,0.8-1.2,0.8-1.8C57.7,189.2,57.7,189.1,58,188.9L58,188.9z M59.3,185.7c0,0.2,0.1,0.3-0.1,0.4h-0.1
			C59,185.9,59.2,185.8,59.3,185.7L59.3,185.7z M59.1,222.1c0.1,0.2,0.2,0.4,0.3,0.5C59.1,222.6,59,222.4,59.1,222.1z M60.4,181.2
			c0-0.2,0.2-0.3,0.4-0.4c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1
			c0.2-0.2,0.3-0.4,0.4-0.7c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5
			c-0.1,0.2-0.2,0.2-0.4,0.3c-0.6,0.2-0.6,0.3-0.5,0.8c0,0.3-0.2,0.7-0.4,1c-0.1,0.2-0.1,0.5-0.5,0.5
			C59.9,183.5,60.2,182.4,60.4,181.2L60.4,181.2z M60.9,156.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.1
			C60.9,156.1,60.9,156.2,60.9,156.3z M60.7,153.3c0.1-0.1,0.2,0,0.2,0.1c0.1,0.3,0.3,0.6,0.1,0.9c-0.3-0.2-0.2-0.6-0.4-0.8
			C60.5,153.4,60.6,153.3,60.7,153.3L60.7,153.3z M53.6,227.4c0.1,0.2,0.2,0.3,0.1,0.6C53.5,227.7,53.5,227.5,53.6,227.4z
			 M53.7,230.6c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.5-0.4C53.7,230.3,53.7,230.4,53.7,230.6L53.7,230.6z
			 M47.9,224c-0.2,0-0.2-0.2-0.2-0.4s0.2-0.2,0.3-0.2c0.2,0,0.3,0,0.4,0.2C48.3,223.8,48.1,224,47.9,224L47.9,224z M23.6,216
			c-0.5-0.5-0.2-0.8,0.3-1.2C23.8,215.3,23.4,215.6,23.6,216z M20.3,187c-0.1,0-0.2,0-0.1-0.1c0-0.1,0.1-0.1,0.2-0.2
			c0.1,0,0.1,0,0.1,0.1C20.5,187,20.4,187,20.3,187L20.3,187z M20,206.4c-0.4,0.3-0.4,0.7-0.6,1c-0.2-0.4,0-0.8,0.2-1.2
			c0.1-0.2,0.2-0.2,0.3-0.1C20.1,206.1,20.2,206.3,20,206.4L20,206.4z M18,206.4c-0.3,0.3-0.1,0.7-0.3,1.1c-0.2-0.6,0.1-1,0.2-1.5
			C18,206.1,18.3,206.1,18,206.4z M17.3,187.9c-0.4,0.1-0.5,0.3-0.5,0.6c0,0.2-0.1,0.4-0.2,0.5c0-0.6-0.1-1.2-0.1-1.8
			c0-0.5,0.6-0.9,1.1-0.9c0.2,0,0.4,0.1,0.4,0.3c-0.2,0.6,0,1.2-0.1,1.8C17.7,188.2,17.7,187.9,17.3,187.9L17.3,187.9z M16.5,210
			c0-0.2,0.1-0.3,0.3-0.2l0.1,0.1C16.9,210.1,16.6,209.9,16.5,210z M16.5,189.3c0.1,0.2,0,0.3-0.1,0.5
			C16.4,189.6,16.5,189.5,16.5,189.3z M15.8,226.3c0,0,0,0.1,0,0.2c-0.2-0.3-0.2-0.7-0.4-1.1v-0.1c0.3-0.2,0.1-0.4,0-0.6
			c0-0.1-0.1-0.2-0.1-0.3s0-0.2-0.2-0.3c-0.1,0,0,0,0-0.1c-0.1-0.5-0.5-1-0.2-1.6c0-0.1,0-0.1,0-0.2c-0.4-0.5-0.5-1-0.6-1.6
			c0-0.1,0-0.2,0-0.4c0.2,0.2,0.4,0.4,0.5,0.7c0.4,0.6,0.5,1.2,0.7,1.9c0.1,0.3,0.3,0.7,0.4,1c0.1,0.4,0.2,0.8-0.1,1.1
			c-0.1,0.2-0.1,0.3,0,0.4C16,225.7,16,226,15.8,226.3L15.8,226.3z M13.2,186.9c0.1,0.2,0.1,0.3,0.2,0.5
			C13,187.3,13,187.2,13.2,186.9z M12.9,193.3c-0.2-0.1-0.4,0.3-0.6,0.1c-0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.4-0.8,0.4-1.2
			c0.1,0,0.1,0,0.2,0c0.1,0.5,0.2,1,0.2,1.5C13.3,193.2,13.1,193.4,12.9,193.3L12.9,193.3z M8.4,174.2c-0.1,0.5,0,0.9-0.7,1.2
			c-0.2,0.1-0.2,0.2-0.3,0.4c-0.4-0.5-0.3-1-0.5-1.5c-0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.2
			C8.4,173.8,8.4,174,8.4,174.2L8.4,174.2z M29.5,134.9c0.1,0,0.2-0.1,0.3-0.2c0.1,0.3-0.2,0.5-0.3,0.4
			C29.1,135.1,29.1,135,29.5,134.9z M39.8,122.2c0.1-0.2,0.2-0.3,0.3-0.6c0.1,0.7,0.5,1.3,0.3,2h-0.1
			C39.2,123.2,39.2,123.2,39.8,122.2L39.8,122.2z M41.3,121.4c0.2-0.1,0.2,0.1,0.4,0.1s0.2,0.2,0.2,0.3c0,0.3-0.3,0.4-0.3,0.8
			c-0.2-0.3-0.2-0.6-0.2-0.9C41.3,121.6,41.2,121.5,41.3,121.4L41.3,121.4z M34,223.8c3-5.8,4.5-12.2,4.5-19.2c0-0.7,0-1.8-0.1-3.2
			c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.3-0.6-0.6-0.9l0.6-0.7c2,0.3,3.8,0.6,5.4,1.1l0.1,0.1c0.1,1.7,0.2,3.1,0.2,4
			c0,2.5-0.2,5.1-0.7,7.9l4.6,1.4l-1.4,14.4c0,0.1-0.1,0.3-0.1,0.6c0,1,0.3,1.8,0.8,2.6c0.5,0.7,1.3,1.1,2.2,1l3.8,0
			c1.3,0,2.5-0.1,3.5-0.3s1.9-0.6,2.8-1.1c1.1-1,1.8-2.3,2-3.9h1c0.6-19.9,0.4-39.1,0.8-58.6c0.8-4.3,0.8-4.3,1.1-4.5v-10.5
			c-0.6-0.3-1-1.3-1.2-2.6l-0.5-3.8c-0.1-0.9-0.1-1.8-0.2-2.8c-2.8,0.1-5.6,0.2-8.5,0.4c-0.1,0.1-0.2,0.2-0.3,0.3s-0.2,0.3-0.2,0.4
			h-0.1c0-0.2,0.1-0.4,0.1-0.6c-0.4,0-0.8,0.1-1.2,0.1c0.1,0.4,0.3,0.7,0.4,1l0.9,0.3v41l-5.9,1.9v-7.9v-13.2
			c-0.1,0.1-0.1,0.1-0.3,0.2c0-0.2,0.1-0.3,0.3-0.4v-8.2c0-5.5,0-8.6-0.1-9.3c0-1.6-0.4-3.1-1-4.7c-1.8,0.2-4.4,0.6-7.6,1.2
			c-0.3,6.4-1.1,11.8-2.3,16.3c-1.3,4.5-2.9,8.4-4.8,11.5c-1.9,3.2-4.5,5.5-7.7,7.1l-1.2-0.9c3.8-4,6.5-9.1,7.9-15.2
			c1.4-6.1,2.2-12.7,2.2-19.9c0-3.2-0.1-6.6-0.3-10.5c-0.1,0-0.2-0.1-0.1-0.2l0.1-0.1c0-0.1,0-0.1,0-0.2l-0.1-3.2l-0.1-0.4
			c0-1-0.1-1.9-0.2-2.6c-0.1-0.7-0.4-1.3-0.9-1.8l-0.1-0.1l0.9-1.3l0.1,0.1l4.7,1.9l0,0l0,0l1.5,0.6c0.7-0.2,1.4-0.3,2.1-0.5v-0.1
			v0.1c2.5-0.6,4.8-1.4,6.9-2.3c2-0.9,3.7-2,5.2-3.3c-1.3-0.5-2.7-0.9-4.1-1.2c-0.6-0.1-1.3-0.2-1.9-0.3c0.1-0.1,0.2-0.2,0.2-0.3
			c0.1-0.2,0-0.5,0.2-0.8c0-0.1,0-0.1-0.1-0.2s-0.2,0-0.3,0.1c-0.1,0.2-0.3,0.4-0.6,0.4c-0.2,0-0.3,0.1-0.4,0.3
			c-0.1,0.2,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.2,0-0.2,0c-0.7-0.5-1.4-0.8-2.1-1.2h-0.5h-1.5
			c-0.1,0.2-3.2,1.2-6.1,2.3c-1.4,0.6-2.8,1.1-3.8,1.7c-0.6,0.5-1.2,1-1.7,1.4c-3.2,2.4-6.3,4.1-11,5.5c-1.9,1.5-4.5,2.5-7.2,3.6
			l2.5,0.8c1-0.2,2.4-0.5,4.4-0.9l4.8-1.7l4.2,3.2l-1,2.6V164l-5.9,2v-1.1c-2.2,0.4-3.9,0.8-5.1,1.1v5.6l-6,1.8v-10.3
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.4-0.2,0.3c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.4v-2.2c-0.2-0.2-0.2-0.4-0.1-0.6
			c0.1,0.1,0.1,0.3,0.1,0.4v-11.8v-0.7l-0.1-0.1c0,0,0.1,0,0.1-0.1c0-5.7,0-8.8-0.1-9.4c-0.1-1.4-0.4-2.8-0.8-4l-2,1
			c-2.4,1.4-4.5,3.1-6,5.7v4.1c1.1,5.5,1.4,10.6,1.5,16.1c0,0.1,0,0.4,0,0.5s-0.1,0.2-0.1,0.3c0.1,0,0.1,0,0.1,0
			c0,4.1-0.1,8.3-0.1,13c1.4,5.3,0.5,8.9,0.6,15c1.8,5.4,1.4,6.4,0.9,12.1c0-0.1,0.1-0.2,0.1-0.4s0.2-0.3,0.4-0.4
			c0.4-0.2,0.5-0.5,0.6-0.9c0.2-0.8,0.1-1.6,0.2-2.4c0.1-0.4,0.3-0.7,0.5-1c0.1,0.3-0.2,0.7,0.1,1c0.2-0.2,0.3-0.4,0.4-0.7
			c-0.1,0.5,0,0.9-0.3,1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,1.2-0.1,1.8v0.1c-0.6,0.4-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.2-0.4,0.3
			C4,202.9,4,203,4.1,203.5v0.1c1.1,3.1-0.9,14.6,1.7,20.1c2.8,2.5,6.4,2.5,9.9,2.5c0.1,0.3,0.2,0.5,0.4,0.8c0-0.1,0-0.2,0-0.3
			c0.1-0.2,0.1-0.3,0.1-0.5c2.7,0,5.3,0.2,7.4,1.7c0,0.1,0.1,0.2,0.2,0.3c0-0.1,0-0.1,0.1-0.2c1.2,0.9,2.3,2.4,3.1,4.5
			C29.7,230.4,32.1,227.4,34,223.8L34,223.8z"/>
				<path class="st2" d="M51.7,144.5c-0.6,0.1-1.2,0.1-1.9,0.2l1.7,0.6c0-0.1,0-0.1,0-0.2C51.6,144.9,51.7,144.7,51.7,144.5z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_17_" d="M51.7,144.5c-0.6,0.1-1.2,0.1-1.9,0.2l1.7,0.6c0-0.1,0-0.1,0-0.2C51.6,144.9,51.7,144.7,51.7,144.5z"/>
				</defs>
				<clipPath id="SVGID_18_">
					<use xlink:href="#SVGID_17_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st11">
					<g>
						<g>
							<defs>
								<path id="SVGID_19_" d="M51.7,144.5c-0.6,0.1-1.2,0.1-1.9,0.2l1.7,0.6c0-0.1,0-0.1,0-0.2C51.6,144.9,51.7,144.7,51.7,144.5z
									"/>
							</defs>
							<clipPath id="SVGID_20_">
								<use xlink:href="#SVGID_19_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st12">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M51.7,144.5c-0.6,0.1-1.2,0.1-1.9,0.2l1.7,0.6c0-0.1,0-0.1,0-0.2C51.6,144.9,51.7,144.7,51.7,144.5z"/>
				<path class="st2" d="M157.5,194.5c-12.4,0.3-24.7,0.7-37,1.2v24.7l-9.8,2.7l0.1-27.1l-31,1.2l-5.5-7.3c6.1,0,10.6,0,13.7,0.1
			l13.7,0.1l9.2-0.1c-0.1-0.7-0.1-1.6-0.1-2.6c-0.2-2.4-0.7-4.6-1.5-6.7c-9.6,0.4-16.8,0.7-21.7,1l-3.2,0.1l-5.5-7.1l18.7,0.1
			c-1.7-3.1-3.6-5.9-5.5-8.3l2.6-2.7c2.5,0.9,4.8,2.1,7.1,3.7c2.3,1.6,4.2,3.5,5.7,5.5l-2,1.6c6.9-0.1,12.1-0.3,15.5-0.5
			c1.1-1.3,2.3-3.1,3.6-5.4c0.5-0.9,0.9-1.7,1.2-2.2c1.1-2,1.5-3.8,1.3-5.4l-37.3,1.8L75,163.7l-5.5-7.1l17.7-0.3
			c10.5-0.1,18.4-0.3,23.6-0.4c0.1-2.1,0.1-5.6,0.1-10.5l-23.2,1.2l-5.5-7.2l16.4,0.1c2.7,0,6.8-0.1,12.4-0.2l-0.1-3.1
			c-0.2-2.9-0.9-5.6-2-7.9l-0.2-0.1l1.4-1.4h0.1l10.5,3.2v8.9c7.6-0.5,14.5-1.2,20.9-2.3l5.3,7.3c-11.7,0.4-20.4,0.7-26.1,1v10.7
			c13.9-0.7,25.8-1.8,35.7-3.4l5.5,7.3l-30.9,1.4l6.1,4.2l-0.1,0.3c-1.3,3-3.6,5.7-6.8,8c4.8-0.4,9.6-1,14.4-1.8l5.3,7.4
			c-5.5,0.1-13.1,0.4-22.7,0.8c-2.1,0.1-5.2,0.3-9.5,0.4l3.1,1v8.5c12-0.3,22.6-1.2,31.7-2.6L157.5,194.5L157.5,194.5z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_21_" d="M157.5,194.5c-12.4,0.3-24.7,0.7-37,1.2v24.7l-9.8,2.7l0.1-27.1l-31,1.2l-5.5-7.3
						c6.1,0,10.6,0,13.7,0.1l13.7,0.1l9.2-0.1c-0.1-0.7-0.1-1.6-0.1-2.6c-0.2-2.4-0.7-4.6-1.5-6.7c-9.6,0.4-16.8,0.7-21.7,1
						l-3.2,0.1l-5.5-7.1l18.7,0.1c-1.7-3.1-3.6-5.9-5.5-8.3l2.6-2.7c2.5,0.9,4.8,2.1,7.1,3.7c2.3,1.6,4.2,3.5,5.7,5.5l-2,1.6
						c6.9-0.1,12.1-0.3,15.5-0.5c1.1-1.3,2.3-3.1,3.6-5.4c0.5-0.9,0.9-1.7,1.2-2.2c1.1-2,1.5-3.8,1.3-5.4l-37.3,1.8L75,163.7
						l-5.5-7.1l17.7-0.3c10.5-0.1,18.4-0.3,23.6-0.4c0.1-2.1,0.1-5.6,0.1-10.5l-23.2,1.2l-5.5-7.2l16.4,0.1c2.7,0,6.8-0.1,12.4-0.2
						l-0.1-3.1c-0.2-2.9-0.9-5.6-2-7.9l-0.2-0.1l1.4-1.4h0.1l10.5,3.2v8.9c7.6-0.5,14.5-1.2,20.9-2.3l5.3,7.3
						c-11.7,0.4-20.4,0.7-26.1,1v10.7c13.9-0.7,25.8-1.8,35.7-3.4l5.5,7.3l-30.9,1.4l6.1,4.2l-0.1,0.3c-1.3,3-3.6,5.7-6.8,8
						c4.8-0.4,9.6-1,14.4-1.8l5.3,7.4c-5.5,0.1-13.1,0.4-22.7,0.8c-2.1,0.1-5.2,0.3-9.5,0.4l3.1,1v8.5c12-0.3,22.6-1.2,31.7-2.6
						L157.5,194.5L157.5,194.5z"/>
				</defs>
				<clipPath id="SVGID_22_">
					<use xlink:href="#SVGID_21_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st13">
					<g>
						<g>
							<defs>
								<path id="SVGID_23_" d="M157.5,194.5c-12.4,0.3-24.7,0.7-37,1.2v24.7l-9.8,2.7l0.1-27.1l-31,1.2l-5.5-7.3
									c6.1,0,10.6,0,13.7,0.1l13.7,0.1l9.2-0.1c-0.1-0.7-0.1-1.6-0.1-2.6c-0.2-2.4-0.7-4.6-1.5-6.7c-9.6,0.4-16.8,0.7-21.7,1
									l-3.2,0.1l-5.5-7.1l18.7,0.1c-1.7-3.1-3.6-5.9-5.5-8.3l2.6-2.7c2.5,0.9,4.8,2.1,7.1,3.7c2.3,1.6,4.2,3.5,5.7,5.5l-2,1.6
									c6.9-0.1,12.1-0.3,15.5-0.5c1.1-1.3,2.3-3.1,3.6-5.4c0.5-0.9,0.9-1.7,1.2-2.2c1.1-2,1.5-3.8,1.3-5.4l-37.3,1.8L75,163.7
									l-5.5-7.1l17.7-0.3c10.5-0.1,18.4-0.3,23.6-0.4c0.1-2.1,0.1-5.6,0.1-10.5l-23.2,1.2l-5.5-7.2l16.4,0.1
									c2.7,0,6.8-0.1,12.4-0.2l-0.1-3.1c-0.2-2.9-0.9-5.6-2-7.9l-0.2-0.1l1.4-1.4h0.1l10.5,3.2v8.9c7.6-0.5,14.5-1.2,20.9-2.3
									l5.3,7.3c-11.7,0.4-20.4,0.7-26.1,1v10.7c13.9-0.7,25.8-1.8,35.7-3.4l5.5,7.3l-30.9,1.4l6.1,4.2l-0.1,0.3
									c-1.3,3-3.6,5.7-6.8,8c4.8-0.4,9.6-1,14.4-1.8l5.3,7.4c-5.5,0.1-13.1,0.4-22.7,0.8c-2.1,0.1-5.2,0.3-9.5,0.4l3.1,1v8.5
									c12-0.3,22.6-1.2,31.7-2.6L157.5,194.5L157.5,194.5z"/>
							</defs>
							<clipPath id="SVGID_24_">
								<use xlink:href="#SVGID_23_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st14">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M157.5,194.5c-12.4,0.3-24.7,0.7-37,1.2v24.7l-9.8,2.7l0.1-27.1l-31,1.2l-5.5-7.3c6.1,0,10.6,0,13.7,0.1
			l13.7,0.1l9.2-0.1c-0.1-0.7-0.1-1.6-0.1-2.6c-0.2-2.4-0.7-4.6-1.5-6.7c-9.6,0.4-16.8,0.7-21.7,1l-3.2,0.1l-5.5-7.1l18.7,0.1
			c-1.7-3.1-3.6-5.9-5.5-8.3l2.6-2.7c2.5,0.9,4.8,2.1,7.1,3.7c2.3,1.6,4.2,3.5,5.7,5.5l-2,1.6c6.9-0.1,12.1-0.3,15.5-0.5
			c1.1-1.3,2.3-3.1,3.6-5.4c0.5-0.9,0.9-1.7,1.2-2.2c1.1-2,1.5-3.8,1.3-5.4l-37.3,1.8L75,163.7l-5.5-7.1l17.7-0.3
			c10.5-0.1,18.4-0.3,23.6-0.4c0.1-2.1,0.1-5.6,0.1-10.5l-23.2,1.2l-5.5-7.2l16.4,0.1c2.7,0,6.8-0.1,12.4-0.2l-0.1-3.1
			c-0.2-2.9-0.9-5.6-2-7.9l-0.2-0.1l1.4-1.4h0.1l10.5,3.2v8.9c7.6-0.5,14.5-1.2,20.9-2.3l5.3,7.3c-11.7,0.4-20.4,0.7-26.1,1v10.7
			c13.9-0.7,25.8-1.8,35.7-3.4l5.5,7.3l-30.9,1.4l6.1,4.2l-0.1,0.3c-1.3,3-3.6,5.7-6.8,8c4.8-0.4,9.6-1,14.4-1.8l5.3,7.4
			c-5.5,0.1-13.1,0.4-22.7,0.8c-2.1,0.1-5.2,0.3-9.5,0.4l3.1,1v8.5c12-0.3,22.6-1.2,31.7-2.6L157.5,194.5L157.5,194.5z"/>
				<path class="st2" d="M237.8,243.9v-10.2l-11.7,0.6v10.9C228.9,245,232.8,244.6,237.8,243.9L237.8,243.9z M226.1,227.9
			c4.5-0.5,8.4-1,11.7-1.6v-8.1c-3.5,0.1-6.1,0.3-7.7,0.4l-4,0.2V227.9L226.1,227.9z M230.8,195.5v-7.9c-6.7,0.3-12.3,1-17,1.9v8.3
			C218.5,197.5,224.2,196.8,230.8,195.5z M206.6,245.9c4.4,0,7.7,0,9.8-0.1l0.1-10.7l-9.9,0.9L206.6,245.9L206.6,245.9z M206.6,229
			l3.3-0.1c1.4,0,3.6-0.1,6.6-0.2l-0.1-5.3c0-1.1-0.2-2.4-0.5-3.7c-1.9,0.1-5,0.4-9.3,0.8V229L206.6,229z M230.8,205.9v-3.1
			c-3.6,0.1-6.7,0.3-9.4,0.6c-2.7,0.2-5.2,0.7-7.6,1.3v1.4l-9.8,2.7l0.1-12.4c0-3.6,0-6-0.1-7.1c-0.2-2.8-0.9-5.5-2.1-7.9l-0.1-0.1
			l1.3-1.6l0.1,0.1l9.4,2.9c5.4-0.3,11.5-1.1,18.2-2.4l4.6-2.1l7.1,5.3l-1.7,3.8V203L230.8,205.9L230.8,205.9z M249.3,214l-1.7,3.8
			v35.3l-9.7,3v-5.6c-9.7,0.5-20.1,1.2-31.3,2.3v2.3l-9.8,2.8l0.1-26.3c0-6.9,0-10.9-0.1-11.9c-0.1-2.8-0.6-5.3-1.4-7.4l1.4-1.5
			l9.5,2.4c12.2-0.1,22.8-1,31.8-2.6l4.3-2L249.3,214L249.3,214z M200.5,175.9l-5.5-7.5c9.6-0.1,18.3-0.3,25.8-0.8
			c7.6-0.5,14.7-1.4,21.3-2.7l5.3,7.8C233.3,172.6,217.6,173.7,200.5,175.9L200.5,175.9z M187.6,216.2c-1.3-3.4-2.6-6.4-4-8.8V255
			l-9.8,3v-0.2l0.1-31.1c0-8.5,0-13.6-0.1-15.4c-6.5,6.5-13.2,11.8-20.2,16h-0.1l-1.4-1.1l0.2-0.1c6.7-7.9,12.6-15.3,17.8-22.3
			s9.8-14.1,13.8-21.4c-4,0.2-7.7,0.6-11,1.2c-3.3,0.6-6.4,1.5-9.4,2.7l-5.7-7.5c7-0.3,12.7-0.7,17.1-1.3c-1.8-5.5-3.8-9.6-6.1-12.5
			l3.4-2.4c4,2.8,7.6,6.3,10.8,10.4l-6.8,4.3c4.3-0.7,8.2-1.7,11.7-2.9l0.1,0.1l1.1-1.1l8.5,4.6v0.1c-3.8,7.7-8.3,14.9-13.5,21.5
			c4.7,3.4,8.5,7.2,11.6,11.4L187.6,216.2L187.6,216.2z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_25_" d="M237.8,243.9v-10.2l-11.7,0.6v10.9C228.9,245,232.8,244.6,237.8,243.9L237.8,243.9z M226.1,227.9
						c4.5-0.5,8.4-1,11.7-1.6v-8.1c-3.5,0.1-6.1,0.3-7.7,0.4l-4,0.2V227.9L226.1,227.9z M230.8,195.5v-7.9c-6.7,0.3-12.3,1-17,1.9
						v8.3C218.5,197.5,224.2,196.8,230.8,195.5z M206.6,245.9c4.4,0,7.7,0,9.8-0.1l0.1-10.7l-9.9,0.9L206.6,245.9L206.6,245.9z
						 M206.6,229l3.3-0.1c1.4,0,3.6-0.1,6.6-0.2l-0.1-5.3c0-1.1-0.2-2.4-0.5-3.7c-1.9,0.1-5,0.4-9.3,0.8V229L206.6,229z
						 M230.8,205.9v-3.1c-3.6,0.1-6.7,0.3-9.4,0.6c-2.7,0.2-5.2,0.7-7.6,1.3v1.4l-9.8,2.7l0.1-12.4c0-3.6,0-6-0.1-7.1
						c-0.2-2.8-0.9-5.5-2.1-7.9l-0.1-0.1l1.3-1.6l0.1,0.1l9.4,2.9c5.4-0.3,11.5-1.1,18.2-2.4l4.6-2.1l7.1,5.3l-1.7,3.8V203
						L230.8,205.9L230.8,205.9z M249.3,214l-1.7,3.8v35.3l-9.7,3v-5.6c-9.7,0.5-20.1,1.2-31.3,2.3v2.3l-9.8,2.8l0.1-26.3
						c0-6.9,0-10.9-0.1-11.9c-0.1-2.8-0.6-5.3-1.4-7.4l1.4-1.5l9.5,2.4c12.2-0.1,22.8-1,31.8-2.6l4.3-2L249.3,214L249.3,214z
						 M200.5,175.9l-5.5-7.5c9.6-0.1,18.3-0.3,25.8-0.8c7.6-0.5,14.7-1.4,21.3-2.7l5.3,7.8C233.3,172.6,217.6,173.7,200.5,175.9
						L200.5,175.9z M187.6,216.2c-1.3-3.4-2.6-6.4-4-8.8V255l-9.8,3v-0.2l0.1-31.1c0-8.5,0-13.6-0.1-15.4
						c-6.5,6.5-13.2,11.8-20.2,16h-0.1l-1.4-1.1l0.2-0.1c6.7-7.9,12.6-15.3,17.8-22.3s9.8-14.1,13.8-21.4c-4,0.2-7.7,0.6-11,1.2
						c-3.3,0.6-6.4,1.5-9.4,2.7l-5.7-7.5c7-0.3,12.7-0.7,17.1-1.3c-1.8-5.5-3.8-9.6-6.1-12.5l3.4-2.4c4,2.8,7.6,6.3,10.8,10.4
						l-6.8,4.3c4.3-0.7,8.2-1.7,11.7-2.9l0.1,0.1l1.1-1.1l8.5,4.6v0.1c-3.8,7.7-8.3,14.9-13.5,21.5c4.7,3.4,8.5,7.2,11.6,11.4
						L187.6,216.2L187.6,216.2z"/>
				</defs>
				<clipPath id="SVGID_26_">
					<use xlink:href="#SVGID_25_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st15">
					<g>
						<g>
							<defs>
								<path id="SVGID_27_" d="M237.8,243.9v-10.2l-11.7,0.6v10.9C228.9,245,232.8,244.6,237.8,243.9L237.8,243.9z M226.1,227.9
									c4.5-0.5,8.4-1,11.7-1.6v-8.1c-3.5,0.1-6.1,0.3-7.7,0.4l-4,0.2V227.9L226.1,227.9z M230.8,195.5v-7.9
									c-6.7,0.3-12.3,1-17,1.9v8.3C218.5,197.5,224.2,196.8,230.8,195.5z M206.6,245.9c4.4,0,7.7,0,9.8-0.1l0.1-10.7l-9.9,0.9
									L206.6,245.9L206.6,245.9z M206.6,229l3.3-0.1c1.4,0,3.6-0.1,6.6-0.2l-0.1-5.3c0-1.1-0.2-2.4-0.5-3.7
									c-1.9,0.1-5,0.4-9.3,0.8V229L206.6,229z M230.8,205.9v-3.1c-3.6,0.1-6.7,0.3-9.4,0.6c-2.7,0.2-5.2,0.7-7.6,1.3v1.4l-9.8,2.7
									l0.1-12.4c0-3.6,0-6-0.1-7.1c-0.2-2.8-0.9-5.5-2.1-7.9l-0.1-0.1l1.3-1.6l0.1,0.1l9.4,2.9c5.4-0.3,11.5-1.1,18.2-2.4l4.6-2.1
									l7.1,5.3l-1.7,3.8V203L230.8,205.9L230.8,205.9z M249.3,214l-1.7,3.8v35.3l-9.7,3v-5.6c-9.7,0.5-20.1,1.2-31.3,2.3v2.3
									l-9.8,2.8l0.1-26.3c0-6.9,0-10.9-0.1-11.9c-0.1-2.8-0.6-5.3-1.4-7.4l1.4-1.5l9.5,2.4c12.2-0.1,22.8-1,31.8-2.6l4.3-2
									L249.3,214L249.3,214z M200.5,175.9l-5.5-7.5c9.6-0.1,18.3-0.3,25.8-0.8c7.6-0.5,14.7-1.4,21.3-2.7l5.3,7.8
									C233.3,172.6,217.6,173.7,200.5,175.9L200.5,175.9z M187.6,216.2c-1.3-3.4-2.6-6.4-4-8.8V255l-9.8,3v-0.2l0.1-31.1
									c0-8.5,0-13.6-0.1-15.4c-6.5,6.5-13.2,11.8-20.2,16h-0.1l-1.4-1.1l0.2-0.1c6.7-7.9,12.6-15.3,17.8-22.3s9.8-14.1,13.8-21.4
									c-4,0.2-7.7,0.6-11,1.2c-3.3,0.6-6.4,1.5-9.4,2.7l-5.7-7.5c7-0.3,12.7-0.7,17.1-1.3c-1.8-5.5-3.8-9.6-6.1-12.5l3.4-2.4
									c4,2.8,7.6,6.3,10.8,10.4l-6.8,4.3c4.3-0.7,8.2-1.7,11.7-2.9l0.1,0.1l1.1-1.1l8.5,4.6v0.1c-3.8,7.7-8.3,14.9-13.5,21.5
									c4.7,3.4,8.5,7.2,11.6,11.4L187.6,216.2L187.6,216.2z"/>
							</defs>
							<clipPath id="SVGID_28_">
								<use xlink:href="#SVGID_27_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st16">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M237.8,243.9v-10.2l-11.7,0.6v10.9C228.9,245,232.8,244.6,237.8,243.9L237.8,243.9z M226.1,227.9
			c4.5-0.5,8.4-1,11.7-1.6v-8.1c-3.5,0.1-6.1,0.3-7.7,0.4l-4,0.2V227.9L226.1,227.9z M230.8,195.5v-7.9c-6.7,0.3-12.3,1-17,1.9v8.3
			C218.5,197.5,224.2,196.8,230.8,195.5z M206.6,245.9c4.4,0,7.7,0,9.8-0.1l0.1-10.7l-9.9,0.9L206.6,245.9L206.6,245.9z M206.6,229
			l3.3-0.1c1.4,0,3.6-0.1,6.6-0.2l-0.1-5.3c0-1.1-0.2-2.4-0.5-3.7c-1.9,0.1-5,0.4-9.3,0.8V229L206.6,229z M230.8,205.9v-3.1
			c-3.6,0.1-6.7,0.3-9.4,0.6c-2.7,0.2-5.2,0.7-7.6,1.3v1.4l-9.8,2.7l0.1-12.4c0-3.6,0-6-0.1-7.1c-0.2-2.8-0.9-5.5-2.1-7.9l-0.1-0.1
			l1.3-1.6l0.1,0.1l9.4,2.9c5.4-0.3,11.5-1.1,18.2-2.4l4.6-2.1l7.1,5.3l-1.7,3.8V203L230.8,205.9L230.8,205.9z M249.3,214l-1.7,3.8
			v35.3l-9.7,3v-5.6c-9.7,0.5-20.1,1.2-31.3,2.3v2.3l-9.8,2.8l0.1-26.3c0-6.9,0-10.9-0.1-11.9c-0.1-2.8-0.6-5.3-1.4-7.4l1.4-1.5
			l9.5,2.4c12.2-0.1,22.8-1,31.8-2.6l4.3-2L249.3,214L249.3,214z M200.5,175.9l-5.5-7.5c9.6-0.1,18.3-0.3,25.8-0.8
			c7.6-0.5,14.7-1.4,21.3-2.7l5.3,7.8C233.3,172.6,217.6,173.7,200.5,175.9L200.5,175.9z M187.6,216.2c-1.3-3.4-2.6-6.4-4-8.8V255
			l-9.8,3v-0.2l0.1-31.1c0-8.5,0-13.6-0.1-15.4c-6.5,6.5-13.2,11.8-20.2,16h-0.1l-1.4-1.1l0.2-0.1c6.7-7.9,12.6-15.3,17.8-22.3
			s9.8-14.1,13.8-21.4c-4,0.2-7.7,0.6-11,1.2c-3.3,0.6-6.4,1.5-9.4,2.7l-5.7-7.5c7-0.3,12.7-0.7,17.1-1.3c-1.8-5.5-3.8-9.6-6.1-12.5
			l3.4-2.4c4,2.8,7.6,6.3,10.8,10.4l-6.8,4.3c4.3-0.7,8.2-1.7,11.7-2.9l0.1,0.1l1.1-1.1l8.5,4.6v0.1c-3.8,7.7-8.3,14.9-13.5,21.5
			c4.7,3.4,8.5,7.2,11.6,11.4L187.6,216.2L187.6,216.2z"/>
				<path class="st2" d="M280.1,178.1c9.9-0.1,18.6-0.3,26-0.7s14.7-1.2,21.7-2.4v-10.4c-14.5,0.6-30.4,1.9-47.7,4V178.1L280.1,178.1z
			 M280.1,150v9.9c11.3-0.1,20.4-0.3,27.3-0.8c7-0.4,13.7-1.5,20.4-3.2v-8.7C317.8,147.6,301.9,148.5,280.1,150z M343.1,221.4
			c6.2,4.3,12.4,7.7,18.8,10.2c-3.1,0.6-6.1,1.6-9,3.2c-2.9,1.6-5.4,3.5-7.5,5.8c-10.1-5.3-18.4-12.9-25.1-22.8
			c-6.7-9.9-11.4-21-14.2-33.2c-11.9,0.8-20.5,1.4-25.9,1.9v35.2c9.6-3.4,18.6-7.2,27-11.4l1.2,1.6c-14.1,12-29.2,21.6-45.2,28.9
			l-6-11.4c5.1-1.6,8.9-2.8,11.4-3.7c0.1-6.8,0.1-23.7,0.1-50.6c0-16.1,0-24.8-0.1-26.1c-0.2-3.4-1-6.5-2.5-9.3l-0.2-0.1l1.7-1.9
			l0.1,0.1l12,3.7c8.9,0,15.5-0.1,19.9-0.2c-2.1-3.8-4.4-7.3-6.8-10.4l2.9-3.5c3,1,5.9,2.6,8.6,4.6s5,4.3,6.8,6.8l-2.5,2
			c6.6-0.5,13.3-1.3,19.9-2.5l3-1.6l10.1,6.2l-1.9,4.6v34.6l-11.6,5.4v-4c-8.1,0.3-14,0.6-17.9,0.8c3.7,6.7,7.8,12.7,12.3,18.1
			c2.4-1.4,4.4-2.7,6-4s4-3.4,7.2-6.2c2.6-2.6,3.9-5,4-7.4v-0.1l2-0.6v0.1l8.4,10.9l-0.2,0.1c-3,2.9-6.5,5.3-10.6,7.1
			c-4.1,1.9-8.5,3.3-13,4.1C331.2,212.1,336.9,217.1,343.1,221.4L343.1,221.4z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_29_" d="M280.1,178.1c9.9-0.1,18.6-0.3,26-0.7s14.7-1.2,21.7-2.4v-10.4c-14.5,0.6-30.4,1.9-47.7,4V178.1
						L280.1,178.1z M280.1,150v9.9c11.3-0.1,20.4-0.3,27.3-0.8c7-0.4,13.7-1.5,20.4-3.2v-8.7C317.8,147.6,301.9,148.5,280.1,150z
						 M343.1,221.4c6.2,4.3,12.4,7.7,18.8,10.2c-3.1,0.6-6.1,1.6-9,3.2c-2.9,1.6-5.4,3.5-7.5,5.8c-10.1-5.3-18.4-12.9-25.1-22.8
						c-6.7-9.9-11.4-21-14.2-33.2c-11.9,0.8-20.5,1.4-25.9,1.9v35.2c9.6-3.4,18.6-7.2,27-11.4l1.2,1.6c-14.1,12-29.2,21.6-45.2,28.9
						l-6-11.4c5.1-1.6,8.9-2.8,11.4-3.7c0.1-6.8,0.1-23.7,0.1-50.6c0-16.1,0-24.8-0.1-26.1c-0.2-3.4-1-6.5-2.5-9.3l-0.2-0.1l1.7-1.9
						l0.1,0.1l12,3.7c8.9,0,15.5-0.1,19.9-0.2c-2.1-3.8-4.4-7.3-6.8-10.4l2.9-3.5c3,1,5.9,2.6,8.6,4.6s5,4.3,6.8,6.8l-2.5,2
						c6.6-0.5,13.3-1.3,19.9-2.5l3-1.6l10.1,6.2l-1.9,4.6v34.6l-11.6,5.4v-4c-8.1,0.3-14,0.6-17.9,0.8c3.7,6.7,7.8,12.7,12.3,18.1
						c2.4-1.4,4.4-2.7,6-4s4-3.4,7.2-6.2c2.6-2.6,3.9-5,4-7.4v-0.1l2-0.6v0.1l8.4,10.9l-0.2,0.1c-3,2.9-6.5,5.3-10.6,7.1
						c-4.1,1.9-8.5,3.3-13,4.1C331.2,212.1,336.9,217.1,343.1,221.4L343.1,221.4z"/>
				</defs>
				<clipPath id="SVGID_30_">
					<use xlink:href="#SVGID_29_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st17">
					<g>
						<g>
							<defs>
								<path id="SVGID_31_" d="M280.1,178.1c9.9-0.1,18.6-0.3,26-0.7s14.7-1.2,21.7-2.4v-10.4c-14.5,0.6-30.4,1.9-47.7,4V178.1
									L280.1,178.1z M280.1,150v9.9c11.3-0.1,20.4-0.3,27.3-0.8c7-0.4,13.7-1.5,20.4-3.2v-8.7C317.8,147.6,301.9,148.5,280.1,150z
									 M343.1,221.4c6.2,4.3,12.4,7.7,18.8,10.2c-3.1,0.6-6.1,1.6-9,3.2c-2.9,1.6-5.4,3.5-7.5,5.8c-10.1-5.3-18.4-12.9-25.1-22.8
									c-6.7-9.9-11.4-21-14.2-33.2c-11.9,0.8-20.5,1.4-25.9,1.9v35.2c9.6-3.4,18.6-7.2,27-11.4l1.2,1.6
									c-14.1,12-29.2,21.6-45.2,28.9l-6-11.4c5.1-1.6,8.9-2.8,11.4-3.7c0.1-6.8,0.1-23.7,0.1-50.6c0-16.1,0-24.8-0.1-26.1
									c-0.2-3.4-1-6.5-2.5-9.3l-0.2-0.1l1.7-1.9l0.1,0.1l12,3.7c8.9,0,15.5-0.1,19.9-0.2c-2.1-3.8-4.4-7.3-6.8-10.4l2.9-3.5
									c3,1,5.9,2.6,8.6,4.6s5,4.3,6.8,6.8l-2.5,2c6.6-0.5,13.3-1.3,19.9-2.5l3-1.6l10.1,6.2l-1.9,4.6v34.6l-11.6,5.4v-4
									c-8.1,0.3-14,0.6-17.9,0.8c3.7,6.7,7.8,12.7,12.3,18.1c2.4-1.4,4.4-2.7,6-4s4-3.4,7.2-6.2c2.6-2.6,3.9-5,4-7.4v-0.1l2-0.6
									v0.1l8.4,10.9l-0.2,0.1c-3,2.9-6.5,5.3-10.6,7.1c-4.1,1.9-8.5,3.3-13,4.1C331.2,212.1,336.9,217.1,343.1,221.4L343.1,221.4z
									"/>
							</defs>
							<clipPath id="SVGID_32_">
								<use xlink:href="#SVGID_31_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st18">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M280.1,178.1c9.9-0.1,18.6-0.3,26-0.7s14.7-1.2,21.7-2.4v-10.4c-14.5,0.6-30.4,1.9-47.7,4V178.1L280.1,178.1z
			 M280.1,150v9.9c11.3-0.1,20.4-0.3,27.3-0.8c7-0.4,13.7-1.5,20.4-3.2v-8.7C317.8,147.6,301.9,148.5,280.1,150z M343.1,221.4
			c6.2,4.3,12.4,7.7,18.8,10.2c-3.1,0.6-6.1,1.6-9,3.2c-2.9,1.6-5.4,3.5-7.5,5.8c-10.1-5.3-18.4-12.9-25.1-22.8
			c-6.7-9.9-11.4-21-14.2-33.2c-11.9,0.8-20.5,1.4-25.9,1.9v35.2c9.6-3.4,18.6-7.2,27-11.4l1.2,1.6c-14.1,12-29.2,21.6-45.2,28.9
			l-6-11.4c5.1-1.6,8.9-2.8,11.4-3.7c0.1-6.8,0.1-23.7,0.1-50.6c0-16.1,0-24.8-0.1-26.1c-0.2-3.4-1-6.5-2.5-9.3l-0.2-0.1l1.7-1.9
			l0.1,0.1l12,3.7c8.9,0,15.5-0.1,19.9-0.2c-2.1-3.8-4.4-7.3-6.8-10.4l2.9-3.5c3,1,5.9,2.6,8.6,4.6s5,4.3,6.8,6.8l-2.5,2
			c6.6-0.5,13.3-1.3,19.9-2.5l3-1.6l10.1,6.2l-1.9,4.6v34.6l-11.6,5.4v-4c-8.1,0.3-14,0.6-17.9,0.8c3.7,6.7,7.8,12.7,12.3,18.1
			c2.4-1.4,4.4-2.7,6-4s4-3.4,7.2-6.2c2.6-2.6,3.9-5,4-7.4v-0.1l2-0.6v0.1l8.4,10.9l-0.2,0.1c-3,2.9-6.5,5.3-10.6,7.1
			c-4.1,1.9-8.5,3.3-13,4.1C331.2,212.1,336.9,217.1,343.1,221.4L343.1,221.4z"/>
				<path class="st2" d="M445,227.4c6.8,8.7,14.8,16,24.2,22l0,0c-4.6,0.4-9.4,2.6-14.4,6.6c-8-6.3-14.5-14.4-19.6-24.3
			c-5.1-9.9-8.1-20.2-9-30.9c-1.8,11.9-5.2,21.9-10.3,30.1c-4.2,6.5-9.6,12.1-16.2,16.6s-13.7,7.5-21.4,8.8
			c-0.4-0.4-0.8-0.9-1.2-1.5c10.1-4.6,18.3-11.9,24.6-21.8c6.3-9.9,10.5-21,12.6-33.3l-16.4,1.4l-6.4-8.9l23.7-0.2
			c0.2-3.1,0.4-6.1,0.4-9.1c0-2.2,0-3.9-0.1-5c0-2.5-0.7-4.7-2-6.5l1.9-1.5l12,3.4c0.1,1.8,0.1,4.4,0.1,7.8c0,3.6-0.1,7.1-0.4,10.4
			c11.9-0.7,22-1.8,30.5-3.4l6.3,9.2c-10.8,0.1-22.1,0.5-33.9,1.3C433.2,209.2,438.2,218.8,445,227.4L445,227.4z M390.8,173.3
			c0,22.5-2,40-6.1,52.5c-2.5,7.5-6.1,14-10.7,19.5s-10.2,9.8-16.8,12.7l-1.9-1.5c15.7-14.9,23.6-39.8,23.6-74.9
			c0-4.3-0.1-9-0.2-14.1c-0.1-0.6-0.1-1.5-0.1-2.5c0.2-3-0.5-5-2-5.9l-0.1-0.2l1.9-1.5h0.1c3.9,0.9,7.2,2,10,3.1l5.3-0.1
			c11.7-0.5,19.5-0.8,23.6-1.1c-2-3.7-4.2-7-6.6-10l3-3.5c2.9,1,5.7,2.5,8.4,4.5c2.7,2,4.9,4.2,6.7,6.7l-2.2,1.8
			c12.8-1,22.5-2.1,29.2-3.2l0.5-0.1l0.2,0.1l7.3,9l-1.8,0.1c-17.8,0.1-41.5,1.5-71.2,4.4L390.8,173.3L390.8,173.3z"/>
				<g>
			<g>
				<defs>
					<path id="SVGID_33_" d="M445,227.4c6.8,8.7,14.8,16,24.2,22l0,0c-4.6,0.4-9.4,2.6-14.4,6.6c-8-6.3-14.5-14.4-19.6-24.3
						c-5.1-9.9-8.1-20.2-9-30.9c-1.8,11.9-5.2,21.9-10.3,30.1c-4.2,6.5-9.6,12.1-16.2,16.6s-13.7,7.5-21.4,8.8
						c-0.4-0.4-0.8-0.9-1.2-1.5c10.1-4.6,18.3-11.9,24.6-21.8c6.3-9.9,10.5-21,12.6-33.3l-16.4,1.4l-6.4-8.9l23.7-0.2
						c0.2-3.1,0.4-6.1,0.4-9.1c0-2.2,0-3.9-0.1-5c0-2.5-0.7-4.7-2-6.5l1.9-1.5l12,3.4c0.1,1.8,0.1,4.4,0.1,7.8
						c0,3.6-0.1,7.1-0.4,10.4c11.9-0.7,22-1.8,30.5-3.4l6.3,9.2c-10.8,0.1-22.1,0.5-33.9,1.3C433.2,209.2,438.2,218.8,445,227.4
						L445,227.4z M390.8,173.3c0,22.5-2,40-6.1,52.5c-2.5,7.5-6.1,14-10.7,19.5s-10.2,9.8-16.8,12.7l-1.9-1.5
						c15.7-14.9,23.6-39.8,23.6-74.9c0-4.3-0.1-9-0.2-14.1c-0.1-0.6-0.1-1.5-0.1-2.5c0.2-3-0.5-5-2-5.9l-0.1-0.2l1.9-1.5h0.1
						c3.9,0.9,7.2,2,10,3.1l5.3-0.1c11.7-0.5,19.5-0.8,23.6-1.1c-2-3.7-4.2-7-6.6-10l3-3.5c2.9,1,5.7,2.5,8.4,4.5
						c2.7,2,4.9,4.2,6.7,6.7l-2.2,1.8c12.8-1,22.5-2.1,29.2-3.2l0.5-0.1l0.2,0.1l7.3,9l-1.8,0.1c-17.8,0.1-41.5,1.5-71.2,4.4
						L390.8,173.3L390.8,173.3z"/>
				</defs>
				<clipPath id="SVGID_34_">
					<use xlink:href="#SVGID_33_"  style="overflow:visible;"/>
				</clipPath>
				<g class="st19">
					<g>
						<g>
							<defs>
								<path id="SVGID_35_" d="M445,227.4c6.8,8.7,14.8,16,24.2,22l0,0c-4.6,0.4-9.4,2.6-14.4,6.6c-8-6.3-14.5-14.4-19.6-24.3
									c-5.1-9.9-8.1-20.2-9-30.9c-1.8,11.9-5.2,21.9-10.3,30.1c-4.2,6.5-9.6,12.1-16.2,16.6s-13.7,7.5-21.4,8.8
									c-0.4-0.4-0.8-0.9-1.2-1.5c10.1-4.6,18.3-11.9,24.6-21.8c6.3-9.9,10.5-21,12.6-33.3l-16.4,1.4l-6.4-8.9l23.7-0.2
									c0.2-3.1,0.4-6.1,0.4-9.1c0-2.2,0-3.9-0.1-5c0-2.5-0.7-4.7-2-6.5l1.9-1.5l12,3.4c0.1,1.8,0.1,4.4,0.1,7.8
									c0,3.6-0.1,7.1-0.4,10.4c11.9-0.7,22-1.8,30.5-3.4l6.3,9.2c-10.8,0.1-22.1,0.5-33.9,1.3C433.2,209.2,438.2,218.8,445,227.4
									L445,227.4z M390.8,173.3c0,22.5-2,40-6.1,52.5c-2.5,7.5-6.1,14-10.7,19.5s-10.2,9.8-16.8,12.7l-1.9-1.5
									c15.7-14.9,23.6-39.8,23.6-74.9c0-4.3-0.1-9-0.2-14.1c-0.1-0.6-0.1-1.5-0.1-2.5c0.2-3-0.5-5-2-5.9l-0.1-0.2l1.9-1.5h0.1
									c3.9,0.9,7.2,2,10,3.1l5.3-0.1c11.7-0.5,19.5-0.8,23.6-1.1c-2-3.7-4.2-7-6.6-10l3-3.5c2.9,1,5.7,2.5,8.4,4.5
									c2.7,2,4.9,4.2,6.7,6.7l-2.2,1.8c12.8-1,22.5-2.1,29.2-3.2l0.5-0.1l0.2,0.1l7.3,9l-1.8,0.1c-17.8,0.1-41.5,1.5-71.2,4.4
									L390.8,173.3L390.8,173.3z"/>
							</defs>
							<clipPath id="SVGID_36_">
								<use xlink:href="#SVGID_35_"  style="overflow:visible;"/>
							</clipPath>
							<g class="st20">

									<image style="overflow:visible;enable-background:new    ;" width="2000" height="3000" xlink:href="../assets/goldBg.png"  transform="matrix(2.046621e-03 -0.1323 0.24 1.128453e-03 -73.8057 319.2401)">
								</image>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
				<path class="st1" d="M445,227.4c6.8,8.7,14.8,16,24.2,22l0,0c-4.6,0.4-9.4,2.6-14.4,6.6c-8-6.3-14.5-14.4-19.6-24.3
			c-5.1-9.9-8.1-20.2-9-30.9c-1.8,11.9-5.2,21.9-10.3,30.1c-4.2,6.5-9.6,12.1-16.2,16.6s-13.7,7.5-21.4,8.8
			c-0.4-0.4-0.8-0.9-1.2-1.5c10.1-4.6,18.3-11.9,24.6-21.8c6.3-9.9,10.5-21,12.6-33.3l-16.4,1.4l-6.4-8.9l23.7-0.2
			c0.2-3.1,0.4-6.1,0.4-9.1c0-2.2,0-3.9-0.1-5c0-2.5-0.7-4.7-2-6.5l1.9-1.5l12,3.4c0.1,1.8,0.1,4.4,0.1,7.8c0,3.6-0.1,7.1-0.4,10.4
			c11.9-0.7,22-1.8,30.5-3.4l6.3,9.2c-10.8,0.1-22.1,0.5-33.9,1.3C433.2,209.2,438.2,218.8,445,227.4L445,227.4z M390.8,173.3
			c0,22.5-2,40-6.1,52.5c-2.5,7.5-6.1,14-10.7,19.5s-10.2,9.8-16.8,12.7l-1.9-1.5c15.7-14.9,23.6-39.8,23.6-74.9
			c0-4.3-0.1-9-0.2-14.1c-0.1-0.6-0.1-1.5-0.1-2.5c0.2-3-0.5-5-2-5.9l-0.1-0.2l1.9-1.5h0.1c3.9,0.9,7.2,2,10,3.1l5.3-0.1
			c11.7-0.5,19.5-0.8,23.6-1.1c-2-3.7-4.2-7-6.6-10l3-3.5c2.9,1,5.7,2.5,8.4,4.5c2.7,2,4.9,4.2,6.7,6.7l-2.2,1.8
			c12.8-1,22.5-2.1,29.2-3.2l0.5-0.1l0.2,0.1l7.3,9l-1.8,0.1c-17.8,0.1-41.5,1.5-71.2,4.4L390.8,173.3L390.8,173.3z"/>
	</g>
			<g id="_2075215968032">
		<path class="st21" d="M238.7,237.1C238.7,237.1,238.6,237.1,238.7,237.1C238.6,237,238.6,237,238.7,237.1l-0.5,0.5c0,0,0,0-0.1,0
			c0,0,0,0,0,0.1c0.1-0.1,0.2-0.2,0.4-0.3s0.3-0.2,0.4-0.3c0,0,0,0-0.2-0.1L238.7,237.1L238.7,237.1z M229.5,243.6
			c0.1-0.1,0.2-0.2,0.3-0.2c-0.5,0.1-0.7,0.2-0.6,0.4C229.2,243.7,229.3,243.7,229.5,243.6L229.5,243.6z M225,258.4
			c-0.8,0.1-1.3,0.4-1.4,0.8C224,258.9,224.5,258.6,225,258.4z M240.1,234.5c0.6-0.7,1-1.2,1.2-1.6c0.4-0.7,0.3-1.3-0.2-1.7
			c-0.3,0.4-0.5,0.6-0.6,0.6c0.2-0.4,0.2-0.7,0.2-0.8c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.3,0-0.4,0c0,0-0.1,0.1-0.1,0.2
			c0,0.1,0,0.1-0.1,0.1c-0.1-0.1-0.2-0.1-0.2-0.2v-0.1c-0.1,0.3-0.3,0.4-0.6,0.5c-0.1,0-0.2,0-0.5,0.1c-0.2,0-0.4,0-0.5,0.1
			c-0.1,0-0.5,0.3-1.2,0.7c-0.4,0.2-1.5,1-3.3,2.4c-0.1,0.3-0.4,0.6-0.8,0.9c-0.3,0.7-1.4,1.7-3.1,2.8c-0.2,0.2-0.4,0.4-0.6,0.6
			c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.1,0.1l0,0
			c-0.3,0.2-0.6,0.6-0.9,1.1c-0.4,0.7-0.6,1-0.7,1.2c-0.3,0.3-0.8,0.9-1.7,1.6c-0.8,0.7-1.3,1.2-1.6,1.7l0,0h-0.1
			c-0.2,0.2-0.4,0.5-0.6,0.9c-0.3,0.3-0.5,0.6-0.6,0.8c0.2-0.2,0.7-0.6,1.4-1s1.1-0.8,1.4-1.1c0.2-0.1,0.4-0.2,0.7-0.4
			s0.6-0.3,0.7-0.4c0.6-0.8,2-1.9,4.3-3.1c0.3-0.1,1-0.6,2.3-1.5c0.9-0.6,1.7-1.1,2.3-1.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.3,0.3-0.4,0.3-0.4l0,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2
			c0.3-0.2,0.5-0.4,0.5-0.7c0.4-0.3,0.8-0.7,1.3-1.3c0.5-0.5,0.9-1,1.3-1.3c-0.1-0.2-0.1-0.3-0.1-0.4c0.1,0,0.2,0,0.3-0.1
			s0.2-0.1,0.3-0.1c0-0.2,0-0.5,0.2-0.7c0.1-0.3,0.3-0.4,0.5-0.4c-0.4,1.1-0.6,1.7-0.6,1.7c-0.1,0-0.1,0-0.2,0h-0.1l0,0
			c-0.3,0.7-0.8,1.4-1.6,2.1c0,0,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.2c-0.1,0-0.2,0-0.2,0.1s-0.1,0.2-0.1,0.2
			C238.6,236,239.2,235.4,240.1,234.5L240.1,234.5z M220.4,245.5c-0.2-0.4-0.2-0.6-0.1-0.8C220.5,244.7,220.5,245,220.4,245.5z
			 M220.1,245.5C220.2,245.5,220.2,245.5,220.1,245.5c0.1,0.1,0.1,0.2,0,0.2s-0.1,0.1-0.1,0.1C219.9,245.8,219.9,245.7,220.1,245.5
			L220.1,245.5L220.1,245.5z M211.4,257.5c0,0-0.1,0.4-0.4,1.1c0,0.1,0,0.2,0,0.2s0-0.1,0-0.2c0,0,0-0.1,0.1-0.3
			c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.6,0.2-0.7c-0.1,0.2-0.1,0.2-0.2,0L211.4,257.5L211.4,257.5z M219.1,246.3
			c0.2-0.4,0.5-0.6,0.7-0.4c-0.1,0.2-0.4,0.5-0.9,0.9c-1.4,1.3-2.3,2.3-2.7,3.1c0,0.1,0,0.1-0.1,0.1c-0.1-0.1-0.1-0.1-0.2-0.1
			c-1.8,2.3-3,4-3.7,5.1c0.1,0.2,0.1,0.4,0,0.6c-0.2,0.3-0.3,0.5-0.3,0.7c1-1.8,2.4-3.8,4.4-5.8c0.6-0.7,1.5-1.5,2.6-2.6
			s2-2,2.5-2.6c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.4,0.3-0.6c0,0.1,0.2,0.3,0.5,0.5c1-0.8,1.5-1.4,1.5-1.8c0.3,0,0.5,0,0.4,0.1
			c0.2-0.2,0.3-0.3,0.4-0.5c0.2-0.3,0.3-0.4,0.4-0.5c0,0,0.1-0.1,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.2,0.7-0.5
			s0.7-0.4,0.8-0.5c0.1,0,0.4-0.3,0.9-0.8c0.6-0.5,0.9-0.9,1-1.1c0,0,0,0,0.1,0s0.1,0,0.1-0.1l0.2-0.1c0.1-0.1,0.5-0.5,1.3-1.1
			c0.5-0.5,0.9-0.9,1.1-1.3c0.2,0,0.3,0,0.3,0c0.5-0.6,0.8-1,0.8-1.2c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c1.7-1.5,3-2.7,3.8-3.5
			c1.1-1.1,1.8-1.7,2.1-2c1-0.7,2-1,3.1-0.9c1,0.1,1.7,0.5,2.3,1.1c0.7,0.7,0.8,1.4,0.4,2.3c-0.4,0.8-1.2,1.9-2.5,3.4
			c-1.8,2-3.9,3.9-6.4,5.7c-2.7,2-4.9,3.4-6.4,4.2c-0.1,0-0.8,0.5-2.3,1.3c-1.1,0.6-1.9,1-2.3,1.3c-6.2,2.9-9.7,5.9-10.5,8.9
			c-0.2,0.8,0,1.7,0.8,2.8c0.7,1,1.4,1.6,2.2,1.8c1-0.1,3.1-1.1,6.2-2.9c1.4-0.8,2.4-1,3.1-0.4c0.3,0.3,0.4,0.6,0.5,1
			c0,0.5-0.1,0.9-0.4,1.2c-0.4,0.5-1.2,1-2.3,1.5c-0.7,0.3-1.2,0.5-1.5,0.6c-0.7,0.2-1.2,0.4-1.4,0.5c-3.6,1.5-5.5,2.2-5.6,2.2
			c-0.3,0-1-0.1-2-0.3c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.7-0.2-1.3-0.7-1.8-1.4c-0.2-0.3-0.3-0.7-0.5-1.3
			c-0.3-1.2-0.4-2.4-0.2-3.7c0-0.3,0.3-1.1,0.9-2.3c1.1-2.3,2.4-4.1,4-5.4c0-0.4,0.4-0.8,1.2-1.3c0.2-0.5,0.7-1.1,1.6-1.9v0.1h0.1
			c0.1,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1C218.6,247,218.8,246.8,219.1,246.3L219.1,246.3z"/>
				<path class="st21" d="M244.3,237.7c-0.1-0.3-0.2-0.5-0.4-0.5c0.1,0.1,0.2,0.3,0.2,0.6c0,0.4,0,0.6,0.1,0.7
			C244.4,238.3,244.4,238,244.3,237.7L244.3,237.7z M242.7,235.5c0.4,0.4,0.8,0.6,1.3,0.5c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0,0.2,0.1
			c0,0,0.1,0,0.2,0.1c0.1,0.2,0.1,0.3-0.1,0.3c0.2,0.2,0.3,0.4,0.3,0.5c0,0,0,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3
			c-0.1,0.5-0.1,0.7-0.1,0.8c-0.1,0-0.3,0.1-0.4,0.2c0.1,0.1,0.1,0.2,0.1,0.4v0.2c-0.2,0.1-0.5,0-0.8-0.1c-0.4,1.1-1.3,1.8-2.6,2.1
			c-0.3-0.2-0.5-0.2-0.8-0.2c0,0.1-0.2,0-0.5-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0-0.1-0.2c0-0.1,0-0.3,0-0.6
			c0-0.1,0.1-0.2,0.1-0.3s0.1-0.2,0.1-0.3l0,0c0-0.1,0-0.1,0-0.1v-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
			c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.1,0.5-0.3,0.8-0.7c0.4-0.4,0.7-0.6,1-0.7c0.1,0,0.2,0,0.3,0.1s0.2,0.2,0.3,0.1c0-0.1,0-0.1,0-0.3
			v-0.1C242.6,235.6,242.6,235.6,242.7,235.5C242.6,235.6,242.6,235.5,242.7,235.5L242.7,235.5L242.7,235.5z M234.6,261
			c0.2-0.1,0.2-0.3,0.1-0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.2-0.4,0.3C234.2,261.2,234.4,261.2,234.6,261L234.6,261z
			 M228.6,252.5c0.1,0.1,0.1,0.2,0,0.3c-0.1-0.1-0.2-0.2-0.2-0.3H228.6z M228.5,252.1c0.1,0.2,0.2,0.3,0.3,0.3
			c-0.2,0-0.4-0.2-0.6-0.7C228.4,251.9,228.5,252,228.5,252.1z M225.2,260.7L225.2,260.7c0-0.1,0-0.1,0-0.2v-0.1l0.2-0.8
			c0,0.1-0.1,0.1-0.2,0c0.1,0.1,0.1,0.2,0,0.3c0,0.2-0.1,0.3,0,0.5C225.1,260.6,225.1,260.7,225.2,260.7L225.2,260.7z M230.1,259.9
			c0.2,0.2,0.8,0.1,1.8-0.3c1.6-0.7,3.1-1.5,4.4-2.4c0.1-0.1,0.6-0.5,1.4-1.3c0.6-0.5,1.1-0.9,1.5-1.1c0.7-0.3,1.1-0.3,1.3,0
			c0.1,0.3,0.1,0.7-0.2,1.2c-0.4,0.7-1.7,2-4.1,3.8c-1.8,1.4-2.9,2.3-3.4,2.6c-0.3,0.1-0.7,0.4-1.2,0.8c-0.7,0.5-1.1,0.8-1.2,0.9
			c-1,0.6-1.9,0.9-2.8,0.7c-0.5-0.1-0.9-0.3-1.5-0.6c-0.6-0.5-1.1-0.7-1.3-0.9c-0.2-0.2-0.4-0.6-0.4-1.2v-1.4c0-0.1-0.1-0.2-0.1-0.3
			c-0.1-0.2-0.1-0.3-0.1-0.3c0-0.4,0.3-1.3,0.9-2.5c0.4-0.8,0.9-1.5,1.3-2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2l0,0l0,0l0,0l0,0
			l0,0l0,0l0.5-0.4c-0.1-0.2,0-0.5,0.1-0.7l0.3-0.6c0.2,0,0.3,0.1,0.3,0.1h-0.1c0,0,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1,0
			c0.1,0.1,0.2,0.1,0.2,0c0,0,0.1-0.2,0.1-0.4s0-0.3,0.1-0.4c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.1c-0.1,0.1-0.2,0.3-0.4,0.6
			l-0.4,0.5c-0.3,0.5-0.5,0.9-0.5,1.5v0.1c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.2-0.1c-0.4,1.1-0.9,2.1-1.4,3.1c0,0,0.1,0.1,0.2,0.1
			c0.1,0,0.1,0.1,0.2,0.2c-0.2,0-0.3,0.2-0.2,0.6c0.5-0.9,1.2-2.1,2.1-3.4c1.1-1.6,1.9-2.7,2.2-3.2c0,0-0.2-0.2-0.5-0.4
			c-0.2-0.2-0.3-0.3-0.2-0.5c0.1,0,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.3-0.4
			c0,0-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.2c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0-0.5,0-0.8,0.1-1
			c0.3-0.3,0.6-0.5,1.1-0.7c0,0,0,0,0.1-0.2c0.1-0.1,0.2-0.4,0.4-0.8c0.2-0.4,0.5-0.8,0.7-1.1c0.5-0.9,1.1-1.2,1.9-1.1
			c0.5,0.1,1,0.3,1.6,0.7s1.1,0.9,1.2,1.2c0.3,0.7,0.2,1.5-0.4,2.2c-0.5,0.7-1.2,1-2,1.1c-0.4,0.2-0.8,0.6-1.2,1.2
			c-0.4,0.6-0.8,1-1.2,1.2c-0.4,0.3-0.9,1.3-1.6,2.8c0,0.1-0.3,0.5-0.8,1.2s-0.8,1.2-0.9,1.5C230,259.7,230,259.8,230.1,259.9
			L230.1,259.9z"/>
				<path class="st21" d="M255.8,258.4c0,0.1,0,0.2,0,0.4c0,0.2,0,0.4,0,0.4c0-0.1-0.1-0.4-0.2-0.9L255.8,258.4L255.8,258.4z
			 M255.7,259.2c0,0.1,0,0.1,0,0.2c-0.1,0-0.1-0.1-0.1-0.3C255.6,259.1,255.7,259.1,255.7,259.2z M252.3,248.2c0.2,0,0.4,0,0.5,0
			c-0.2-0.1-0.3-0.2-0.5-0.2s-0.4,0-0.5,0.2C251.9,248.1,252.1,248.1,252.3,248.2L252.3,248.2z M249,259.8c-0.1,0.3-0.1,0.5-0.1,0.6
			c0.1-0.2,0.2-0.2,0.3-0.1c-0.1-0.1-0.1-0.3,0-0.5c0.1-0.3,0.1-0.6,0.1-0.7l-0.2,0.4C249,259.7,249,259.8,249,259.8L249,259.8z
			 M246.5,258c0.1-0.1,0.1-0.1,0.2-0.1c0,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1c-0.1,0.2-0.1,0.4-0.2,0.4
			C246.3,258.1,246.4,258,246.5,258z M241.8,261c0.1,0,0.2-0.1,0.2-0.1l-0.4-0.1c0,0,0,0.1,0,0.2s0,0.1,0.1,0.1
			C241.7,261.1,241.7,261,241.8,261z M248.1,255.4c-0.2,0.2-0.3,0.4-0.2,0.8l-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0,0.2-0.1,0.5-0.3,0.7
			s-0.3,0.4-0.4,0.6c0.1,0.1,0.1,0.2,0.1,0.2s0,0,0,0.1c0,0,0,0,0,0.1l-0.1,0.1c0.3-0.2,1.3-0.8,2.8-2c0-0.1-0.2-0.3-0.4-0.5
			s-0.3-0.3-0.3-0.5c0,0,0.2,0.2,0.4,0.4c0.1,0.2,0.3,0.3,0.4,0.3c0-0.1,0.1-0.2,0.2-0.2c0-0.1,0-0.2-0.1-0.3s-0.1-0.1-0.1-0.2
			l0.3,0.2c0,0,0-0.1-0.1-0.3c-0.1-0.1-0.1-0.2-0.1-0.3s0.1-0.1,0.3-0.1s0.3-0.1,0.4-0.1c0.2-0.1,0.3-0.3,0.3-0.6c0-0.1,0-0.4,0-0.8
			c-0.7,0-1.2,0-1.8,0.1c-0.2,0.4-0.5,0.6-0.9,0.8s-0.8,0.1-1.2-0.2c-0.2,0-0.4,0.1-0.6,0.3c-0.2,0.3-0.4,0.4-0.4,0.4
			c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0c-0.3,0.1-0.5,0.3-0.7,0.6c-0.2,0.5-0.4,0.7-0.5,0.8c-0.2,0.2-0.5,0.4-1,0.6
			c-0.4,0.2-0.7,0.4-0.9,0.6c-0.1,0-0.1,0-0.2,0c-0.1,0.3-0.3,0.6-0.8,1c-0.4,0.4-0.7,0.7-0.8,1c0,0,0,0.2-0.1,0.6
			c0,0.2-0.1,0.4-0.2,0.5c0.5,0,1.2-0.3,2-0.8s1.4-0.7,1.9-0.8c0.1-0.7,0.5-1,1.1-0.9c0,0,0.1-0.2,0.1-0.4c0.2-0.1,0.5-0.3,0.8-0.6
			c0.3-0.3,0.5-0.5,0.8-0.6c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.2c0.1,0.1,0.2,0.1,0.4,0.1c0-0.2,0.1-0.4,0.2-0.5
			c0.1-0.1,0.2,0,0.4,0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.1c0-0.1,0-0.2-0.1-0.3
			s-0.1-0.2,0-0.3c0,0.1,0,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.1,0.4C248.5,255.2,248.2,255.3,248.1,255.4L248.1,255.4z M252.2,246.8
			c1.1,0.2,2,0.5,2.7,1c0.8,0.6,1.2,1.3,1.2,2.1s-0.5,1.8-1.5,3.1c-1.1,1.6-2,2.6-2.5,3.1c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2
			c-0.3,0.7-0.8,1.3-1.3,2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.3-0.4,0.5-0.4,0.7c-0.1,0.8,0.2,0.9,0.8,0.5
			c0.4-0.2,0.8-0.6,1.2-1.1c1.2-1.4,2.2-2.2,3.1-2.4c-0.3,0.4-0.8,0.9-1.4,1.4c-0.7,0.6-1.3,1-1.5,1.3c-1.2,1.2-2.1,1.9-2.7,2
			c0-0.2-0.1-0.5-0.3-0.7c-0.1,0.4-0.1,0.7,0,0.9c0.1,0.2,0.3,0.2,0.6,0.2c0.4-0.1,0.9-0.3,1.4-0.7c0.3-0.3,0.8-0.7,1.3-1.2
			c0.7-0.7,1.1-1.2,1.2-1.3c0.6-0.6,1.3-1,2-1.3c0.5-0.1,0.8-0.2,1.1-0.1c0.4,0.1,0.5,0.4,0.4,0.8c0-0.1-0.6,0.5-1.7,2
			c-0.4,0.6-1.1,1.2-2.1,1.8c-1.6,1-2.5,1.6-2.7,1.6c-0.1,0-0.4,0.2-1,0.5c-0.5,0.3-0.9,0.4-1.1,0.5c-0.9,0.3-1.5,0.1-2-0.6
			c-0.3-0.4-0.4-0.9-0.3-1.5c0,0,0.1-0.3,0.2-0.8c0.1-0.3,0.1-0.6,0-0.8c-0.2-0.2-0.5-0.3-0.7-0.2c-0.2,0-0.4,0.2-0.6,0.5
			c-0.7,0.8-1.1,1.3-1.3,1.3c-0.1,0-0.2,0.1-0.4,0.1s-0.3,0-0.3,0.1c-0.4,0.1-1.3,0.7-2.6,1.6c-1,0.7-2,1-2.8,0.9
			c-1-0.1-1.5-0.8-1.5-2.2c0-0.7,0.3-1.8,0.7-3.2h0.1c0.1,0,0.1,0,0.1-0.1c0.4-1,1-2,1.8-3.1c0.4-0.5,1.1-1.3,2.3-2.5
			c0.2-0.6,0.6-1.2,1.2-1.8c0.4-0.4,0.7-0.7,0.8-0.8l0.8-0.8c0.1-0.1,0.3-0.3,0.5-0.6s0.4-0.4,0.5-0.5c0.2-0.2,0.6-0.3,1-0.5
			c0.6-0.2,0.9-0.4,1.1-0.4c0.1,0,0.4-0.2,0.8-0.4c0.5-0.3,0.8-0.5,1-0.6c0.5-0.3,1.2-0.5,2.1-0.7L252.2,246.8L252.2,246.8z"/>
				<path class="st21" d="M269.7,255.8c0.3-0.2,0.4-0.4,0.4-0.7c-0.1,0.1-0.3,0.3-0.7,0.6c-0.3,0.2-0.5,0.4-0.5,0.5L269.7,255.8
			L269.7,255.8z M261.8,256.5c-0.2,0.3-0.3,0.5-0.4,0.6c0.8-0.9,1.2-1.5,1.3-1.6c0,0.1-0.1,0.1-0.1,0.1l-0.2-0.1v0.4c0,0,0,0-0.1,0
			c0,0,0,0-0.1,0C262.1,256,262,256.2,261.8,256.5L261.8,256.5z M255.4,253.2c0,0.1,0,0.3,0,0.5s0,0.4,0,0.5c0,0-0.1-0.3-0.1-0.9
			L255.4,253.2L255.4,253.2z M255.1,254.2c-0.1,0.1-0.1,0.2-0.2,0.2l0,0c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2c0,0,0.1,0,0.1-0.1
			c0,0,0.1,0,0.1-0.1C255.1,254.1,255.1,254.2,255.1,254.2L255.1,254.2z M253.4,255.7c0.1-0.3,0.2-0.6,0.4-0.9
			c0.3-0.4,0.5-0.5,0.6-0.3c0,0.1-0.1,0.2-0.3,0.5c-0.3,0.3-0.5,0.5-0.5,0.7c-0.2,0.5-0.1,1,0.1,1.5c0.2,0.4,0.5,0.7,0.9,0.9
			c0.7,0.4,1.5,0.5,2.4,0.1c0.8-0.3,1.4-0.9,1.6-1.7h0.1c0.1,0,0.1,0.1,0.2,0.1c1.5-1.7,2.4-2.6,2.7-2.8c0.3-0.2,0.7-0.3,1-0.3
			c0.1,0,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.5-0.2,0.8-0.4,1.1c0.3,0,0.4-0.4,0.5-0.9c0-0.6-0.1-0.9-0.5-1
			c-1.1-0.1-2.2,0.5-3.4,1.6c-1.6,1.5-2.5,2.4-2.7,2.5c-0.4,0.2-0.8,0.2-1.4,0.2c-0.6-0.1-1-0.3-1.1-0.7c-0.1-0.5,0.1-0.9,0.7-1.3
			c0.8-0.6,1.3-0.9,1.3-0.9c-0.2-0.3-0.3-0.5-0.3-0.7c-0.1-0.3,0-0.5,0.3-0.6c0,0.2,0.1,0.3,0.2,0.4l0.3,0.3l1.1-0.7
			c0.4-0.3,0.6-0.7,0.7-1.1c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.2-0.1,0.3-0.2,0.4-0.4l0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
			c0.3-0.1,0.4-0.2,0.4-0.3c0.2-0.2,0.6-0.4,1.1-0.6c0.5-0.2,0.9-0.4,1.2-0.6c0.2-0.1,0.4-0.3,0.8-0.7l0.8-0.8
			c0.7-0.5,1.4-0.8,2.1-0.6c0.5,0.1,0.8,0.4,0.9,0.8c0.1,0.3,0.2,0.8,0.1,1.4c-0.1,1.8-0.1,2.9,0,3.1c0.4-0.1,0.8-0.3,1.2-0.6
			c0,0,0.2-0.2,0.5-0.4c0.2-0.2,0.3-0.3,0.5-0.4c0.2-0.2,0.6-0.4,1-0.7c0.5-0.3,0.9-0.6,1.1-0.7c1.2-0.9,2.3-1.5,3.2-1.8
			c1.5-0.5,2.6-0.4,3.4,0.4c0.5,0.5,0.8,1.2,0.9,2.2c0,0.3,0.1,0.7,0.2,1.4s0.2,1.2,0.2,1.5c0.1,0.3,0.2,0.6,0.3,0.9
			c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3s0,0.1,0.1,0.2c0.1,0.3,0.1,0.6,0.1,1c0.1,0.5,0.1,0.7,0.1,0.9c0.2,1,0.5,1.8,1,2.4
			c0.6,0.8,1.4,1.1,2.3,1c0.6-0.1,2-0.7,4.2-1.8c0.3-0.2,0.6-0.4,1-0.6s0.7-0.4,1-0.5c0.8-0.4,1.6-0.5,2.3-0.4
			c0.2,0.8-0.1,1.4-0.9,1.8c-1.3,0.8-1.9,1.2-1.9,1.1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0c-0.2,0.1-1.7,1.2-4.6,3.3
			c-1.2,0.9-2.4,1.5-3.6,1.9c-1.8,0.6-3,0.4-3.8-0.6c-0.5-0.6-1-1.8-1.4-3.4c-0.8-3.3-1.3-5.8-1.3-7.7c0-0.1,0-0.1,0-0.1s0,0,0.1,0
			c0,0,0.1,0,0.1-0.1v-0.1c-0.2,0-0.4,0-0.6,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1l-0.1,0.1c0,0-0.1,0.1-0.2,0.2
			c-0.1,0.1-2.5,1.8-7.2,5.1c-1.7,1.2-3,2.3-3.9,3.1c-1,1-1.7,1.7-2.1,1.9c-0.9,0.6-1.9,0.8-3,0.7c-0.8-0.1-1.3-0.3-1.5-0.7
			c-0.3-0.5-0.3-0.9,0-1.4c0.4-0.6,0.6-1.1,0.6-1.4c0.1-0.1,0.5-0.6,1.2-1.6c0.2-0.3,0.8-0.9,1.8-1.8c0.7-0.7,1.2-1.4,1.4-2.1
			c0.3-0.3,0.8-0.9,1.4-1.8c-0.4,0.1-0.9,0.4-1.4,0.9c-0.7,0.6-1.2,1-1.4,1.1v0.3c0,0.2-0.1,0.3-0.1,0.3c-0.2,0-0.4,0.2-0.6,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0.6-0.8,1-1.5,1.1c-0.8,0.2-1.4,0.1-2.1-0.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0-0.3-0.1-0.3h-0.1
			c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.4-0.2c0.1-0.1,0.1-0.3,0-0.5c-0.2-0.4-0.3-0.6-0.3-0.7v-0.7H253.4z"/>
				<path class="st21" d="M309.4,250.7c-0.1,0.3-0.2,0.5-0.1,0.6c0-0.1,0-0.1,0-0.2L309.4,250.7z M305.5,250.5
			C305.5,250.4,305.5,250.4,305.5,250.5C305.5,250.4,305.5,250.4,305.5,250.5C305.5,250.4,305.5,250.4,305.5,250.5l0.1-0.3l0,0
			C305.7,250.2,305.6,250.3,305.5,250.5C305.6,250.5,305.5,250.5,305.5,250.5L305.5,250.5z M303.8,254.2c-0.3,0-0.4,0-0.4,0.1
			C303.6,254.3,303.7,254.2,303.8,254.2z M296,263.2c0-0.1,0-0.3,0-0.6c0-0.3,0.1-0.5,0.2-0.6C296.2,262,296.2,262.4,296,263.2
			L296,263.2z M295.6,263.6c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.1-0.1-0.3,0-0.4s0.2-0.2,0.3-0.1C295.7,263.4,295.7,263.5,295.6,263.6
			L295.6,263.6z M304.4,249.2c-0.1,0.3-0.2,0.4-0.4,0.3c0,0,0,0-0.1,0h-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.4-0.1
			c-0.1,0-0.8,0.1-1.9,0.4c-0.4,0.1-1,0.3-1.8,0.5c-0.7,0.9-1.5,1.3-2.4,1.1c-0.2,0-0.4,0.2-0.7,0.6c-0.2,0.3-0.5,0.4-0.8,0.5
			c-0.5,0.4-1,1.6-1.5,3.5c0.4,0.4,0.9,0.4,1.7,0.3c0.1,0,0.4-0.1,1-0.3c0.3-0.1,0.7-0.2,0.9-0.2c0.1-0.2,0.5-0.4,1.2-0.6
			c0.2-0.1,0.4-0.1,0.6-0.3c0.3-0.1,0.5-0.2,0.6-0.3c0.1,0,0.2,0,0.5-0.1c0.3,0,0.4-0.1,0.5-0.1s0.2-0.1,0.2-0.2
			c0.1-0.1,0.1-0.2,0.2-0.2c0.5-0.3,1.2-0.5,2.1-0.6c0.6-0.1,1.2-0.3,2-0.5c0.9-0.3,1.5-0.5,1.8-0.6c0,0.1,0.3-0.3,1.1-1.2
			c0,0,0,0-0.1,0c-0.2-0.1-0.5-0.3-1.1-0.6c-0.2,0.1-0.3,0.2-0.3,0.2c0-0.1,0-0.1-0.1-0.2l-0.1-0.1c0,0.1-0.1,0.1-0.3,0.1
			c-0.2,0-0.3,0-0.4-0.1c0-0.1,0-0.1,0-0.1c0,0.1-0.1,0.1-0.1,0.1v-0.2c-0.1,0-0.1,0-0.2,0.1s-0.1,0.1-0.2,0.1c0-0.1,0-0.2,0-0.3
			v-0.1c-0.1-0.1-0.2-0.1-0.2,0c0,0,0,0,0,0.1v0c0.8,0.3,1.3,0.3,1.6,0v0.2c0.2-0.1,0.5-0.1,1,0.1c0.4,0.1,0.6,0.1,0.8,0
			c0,0.1,0,0.2,0.2,0.2c-0.3-0.5-0.8-0.8-1.6-1.1c-0.4-0.1-1.1-0.2-2.2-0.3v0.2c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.3-0.2,0.3
			c0-0.2,0-0.4,0-0.8c-0.3-0.1-0.6,0-0.7,0.3c0-0.1,0-0.2-0.1-0.2C304.5,249.2,304.5,249.2,304.4,249.2L304.4,249.2z M287.8,267
			c-1.2,0.1-2.2,0.4-2.8,0.7c0,0,0,0,0.2-0.1s0.5-0.1,1-0.3C286.6,267.3,287.1,267.2,287.8,267L287.8,267z M288.6,271.6
			c-0.1-0.1-0.2-0.1-0.3-0.1c-2.5,2.9-5.2,5.4-8.3,7.5l0,0v0.1c0,0.1,0,0.2-0.1,0.2c-0.2,0.1-0.6,0.2-1.1,0.4
			c-0.5,0.2-0.8,0.3-1.1,0.4c1.4-0.2,3-1.1,4.8-2.6l4-3.4c1.1-0.9,2.3-2.2,3.6-3.8c1.8-2,2.9-3.3,3.5-3.9l0,0l0,0
			c-0.4,0.2-0.9,0.3-1.6,0.5C290.4,268.9,289.2,270.5,288.6,271.6L288.6,271.6z M276.6,280c-0.4,0.1-0.6,0.1-0.7,0.2
			c0-0.1,0-0.1-0.1-0.1c-1.6-0.4-2.4-0.8-2.5-1c0.7,0.8,1.8,1.2,3.3,1.1C276.4,280.2,276.4,280.2,276.6,280L276.6,280z M281.1,270.9
			c-0.6,0.2-1.1,0.4-1.4,0.5c-0.1,0-0.2,0.2-0.4,0.3c-0.2,0.2-0.4,0.3-0.4,0.4c-0.3,0.2-0.6,0.3-0.8,0.4c-0.3,0.2-0.6,0.3-0.8,0.4
			c-0.1,0.1-0.3,0.3-0.6,0.5c-0.4,0.3-0.6,0.5-0.6,0.6c0,0-0.1,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-1.4,1.3-2.1,2.4-2,3.5
			c0.1,0.9,1,1.4,2.6,1.6c1.4,0.1,2.7-0.1,3.9-0.9c0.5-0.3,1.4-1,2.9-2.3l4.6-3.9c0.1-0.2,0.8-1,2.1-2.4c0.2-0.4,0.5-0.9,1-1.4
			c0.6-0.8,1-1.2,1-1.3c-1.8,0.5-4.8,1.6-8.8,3.1C282.5,270.4,282,270.6,281.1,270.9L281.1,270.9z M301.6,262.8
			c1.7-0.4,2.6-0.5,2.8-0.1c0.1,0.2,0,0.5-0.4,0.9c-0.2,0.2-0.5,0.4-0.9,0.6c-0.2,0.1-0.6,0.3-1.1,0.4c-0.2,0.1-0.4,0.1-0.6,0.2
			s-0.3,0.1-0.4,0.1c-1.3,0.6-2.5,1.3-3.7,2c-0.7,0.1-1.6,0.8-2.9,2.1c-0.6,0.7-1.3,1.5-2.1,2.5c-2.2,2.9-5.4,5.9-9.4,9.1
			c-2.3,1.8-4.4,2.7-6.4,2.9c-0.2,0-0.4,0-0.6,0c-1.9,0-3.6-0.6-4.9-1.9c-0.9-0.9-1.4-1.9-1.5-3c-0.1-1.2,0.2-2.4,0.9-3.6
			c0.6-1.1,1.3-2,2-2.6c0,0,0.2-0.2,0.5-0.4c0.3-0.2,0.5-0.3,0.5-0.4c2.4-1.9,5.4-3.3,8.9-4.4c0.9-0.3,2.4-0.7,4.5-1.1
			c2-0.5,3.5-0.8,4.6-1.2c1.5-0.4,2.4-0.7,2.8-0.9c0.1,0,0.2-0.1,0.3-0.2l0.3-0.2c0.2,0.3,0.6,0.3,1.2,0.1c0.2-0.1,0.5-0.3,0.8-0.8
			s0.6-0.8,0.7-0.8c0-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.1-0.3-0.1-0.4c0.3-0.6,0.6-0.9,0.7-0.9c-0.1,0.1-0.1,0.2,0,0.3
			c0.2,0.1,0.3,0.1,0.3,0.2c0.2-0.1,0.6-0.5,1.3-1.3l1.1-1.4c0-0.3,0-0.5,0.1-0.8c0,0,0.1-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2
			c0.1-0.1,0.3-0.4,0.4-0.9s0.2-0.8,0.3-1c-0.4,0.2-1.1,0.4-2.3,0.9l-2.3,0.8c-2,0.8-3.7,1.1-5.1,1.1c-1.2,0-2.1-0.2-2.7-0.6
			s-0.8-1.1-0.7-1.9c0.1-1.1,0.8-2.3,2.2-3.8c1-1.1,2.1-1.9,3.4-2.6c2.3-1.2,5.5-2,9.5-2.5c1-0.1,2-0.1,3.1,0.1
			c0.4,0,1.1,0.3,2.1,0.6c0.8,0.3,1.5,0.5,2,0.5s1.2-0.2,2.1-0.5c1.2-0.4,1.8-0.7,2-0.7c0.4,0,0.9,0.2,1.3,0.6
			c0.5,0.4,0.7,0.8,0.7,1.1c0,0.4-0.3,0.8-0.9,1c-0.3,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.5,0.1-0.7,0.2c-0.5,0.2-1.9,1-4.1,2.3
			c-0.4,0.3-1.5,1.1-3.2,2.6c-5.8,5.1-8.9,7.8-9.2,8C299.4,263.3,300.4,263.1,301.6,262.8L301.6,262.8z"/>
				<path class="st21" d="M337,251c0.1,0,0.1,0,0.1,0c0-0.2-0.1-0.4-0.1-0.4s0,0.1-0.1,0.1l-0.1,0.1C336.9,250.9,337,250.9,337,251
			L337,251z M337.8,248.8C337.9,248.7,337.9,248.7,337.8,248.8L337.8,248.8L337.8,248.8c-0.7-0.4-1.1-0.4-1.4-0.3c0,0,0,0,0.1,0
			c0,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.1C337.6,248.8,337.7,248.8,337.8,248.8L337.8,248.8z M334.1,258.6
			c0.1,0,0.1,0,0.1,0c-0.1,0.1-0.1,0.2,0,0.2c0,0,0-0.1,0-0.2s0-0.2,0-0.2h0.1c0,0,0,0,0.1,0c0-0.1,0.2-0.3,0.4-0.6
			c0.2-0.2,0.3-0.4,0.3-0.6c-0.1,0.1-0.3,0.4-0.6,0.7c-0.3,0.3-0.5,0.6-0.5,0.7C334,258.6,334,258.6,334.1,258.6L334.1,258.6z
			 M331.7,250.9L331.7,250.9c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.2,0.1-0.3,0.1C331.3,251.2,331.5,251.2,331.7,250.9z M328.5,254.2
			C328.4,254.2,328.4,254.2,328.5,254.2c-0.2-0.1-0.2-0.2-0.2-0.3c0-0.2,0-0.3-0.1-0.4C328.3,253.6,328.4,253.9,328.5,254.2
			L328.5,254.2z M328.3,253.5C328.2,253.4,328.2,253.4,328.3,253.5C328.2,253.5,328.3,253.5,328.3,253.5c0-0.1,0-0.1,0-0.2
			C328.3,253.3,328.3,253.4,328.3,253.5c0.1,0,0.1,0,0.1,0C328.4,253.6,328.3,253.6,328.3,253.5L328.3,253.5z M327.1,269
			c-0.1,0.1-0.1,0.3,0.1,0.6c0.1,0.2,0.1,0.4,0,0.5c-0.1-0.1-0.1-0.4-0.2-1.1H327.1z M326.9,270.4c-0.1,0-0.1,0-0.1,0v-0.1
			c0-0.1,0-0.1,0-0.1C326.9,270.2,326.9,270.3,326.9,270.4L326.9,270.4z M327.4,267.8c1.2-1.3,1.9-2.4,2-3.2l0.4,0.2
			c0.7-1.1,1.8-2.6,3.3-4.5c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0.3-0.1,0.5-0.4,0.6-0.8c-4.8,5.4-7.1,8-7,7.9
			C326.7,267,327,267.3,327.4,267.8L327.4,267.8z M330.8,251.7c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.1,0.1,0.2,0,0.3-0.2
			c-1.4,0.4-2.6,0.9-3.5,1.5c0,0,0,0,0.1,0.1c0,0.1,0.1,0.2,0.2,0.4s0.2,0.4,0.1,0.6l-0.3-0.5c-0.1-0.2-0.2-0.3-0.3-0.5
			c-0.1,0-0.1,0-0.2,0.1s-0.2,0.1-0.2,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.2,0,0.2s-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1
			c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.3c0,0-0.2,0-0.8,0c-1.3,0.9-1.9,1.8-1.9,2.8c1.1,1.1,1.6,1.6,1.6,1.4c0.3,0.1,0.6,0,1-0.2
			c0.3-0.2,0.6-0.1,0.7,0c0.3,0,0.7-0.1,0.9-0.4c0.3-0.4,0.6-0.6,0.7-0.6c0.4-0.2,0.9-0.3,1.5-0.3c0.6-0.6,1.1-0.8,1.7-0.7
			c0.5-0.4,1.1-0.7,1.7-1c0-0.3,0.3-0.6,0.7-0.9c0.4-0.3,0.6-0.6,0.6-1c0.3-0.3,0.5-0.5,0.6-0.6c-0.1-0.4-0.1-0.8,0-1.1
			c-1.4,0.1-2.1,0.1-2.3,0c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.3,0-0.5,0.3c0,0-0.1,0-0.4,0c-0.2,0-0.4-0.1-0.5-0.1
			c0,0-0.1,0.2-0.2,0.5c-0.2,0.1-0.6,0.2-0.9,0.5c-0.4,0.2-0.7,0.4-1,0.4c0.1,0.1,0.1,0.3,0,0.4c0,0-0.1-0.1-0.2-0.1s-0.2,0-0.2-0.1
			c0,0.3-0.1,0.5-0.4,0.5c0-0.1-0.1-0.2-0.1-0.2s0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.2,0,0.3-0.1,0.4
			c-0.2-0.2-0.3-0.4-0.2-0.5c0.3,0,0.6,0,0.8-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1
			C329.8,252.4,330.2,252,330.8,251.7L330.8,251.7z M330.3,265.1c-0.2,0-0.4,0.1-0.6,0.3c-0.2,0.2-0.4,0.3-0.5,0.3
			c0,0.3-0.2,0.7-0.4,1c-0.3,0.4-0.5,0.7-0.6,0.8c0,0,0,0-0.1-0.1s-0.1-0.1-0.2,0s0,0.1,0,0.2c0.1,0.1,0.1,0.2,0.1,0.2
			c-0.1,0-0.1,0-0.1,0c0-0.1-0.1-0.1-0.1-0.1c-0.2,0.1-0.4,0.3-0.4,0.8c-0.1,0-0.1-0.1-0.2-0.1s-0.2-0.1-0.2-0.1
			c0.1,0,0.1,0,0.2-0.1c0-0.1,0-0.2,0-0.3c-0.1-0.3-0.4-0.6-0.8-0.7c-0.2,0.2-0.4,0.4-0.6,0.8c0,0.1,0,0.1,0.1,0.1s0.2,0,0.2,0.1
			l-0.1,0.1c-0.1,0.1-0.2,0.3-0.4,0.6c0.3,0.4,0.5,0.7,0.5,0.7c0.2,0.3,0.1,0.5-0.2,0.7c0-0.2-0.1-0.4-0.3-0.5l-0.2-0.1
			c-0.1-0.1-0.2-0.1-0.2-0.1c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.2-0.4,0.4-0.6,0.6c0,0.4,0.1,0.6,0,0.6s-0.1,0-0.2-0.1
			s-0.1-0.1-0.2-0.1c-0.2,0-0.3,0.2-0.3,0.4v0.3v0.3l-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.3-0.3,0.6-0.7,0.8
			c0,0-0.2,0.1-0.5,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.5,0.5-1.1,1.2c-0.9,1-1.7,1.7-2.5,2.1c-0.2,0.1-0.5,0.2-1,0.4
			c-0.5,0.2-0.9,0.3-1.1,0.4c-0.1-0.1-0.3-0.1-0.7-0.2c-0.4,0-0.6,0-0.7,0c0-0.9,0.1-1.4,0.2-1.4l0,0c0.2-0.2,0.4-0.3,0.6-0.3
			c0.5-0.5,1.1-1.2,1.9-2.4c0.8-1.2,1.4-2,1.8-2.4c0.8-0.8,1.6-1.4,2.3-1.9c0,0,0.2-0.1,0.5-0.2c0.2-0.1,0.4-0.2,0.5-0.2
			c0.1-0.1,0.2-0.3,0.4-0.6c0.1-0.2,0.2-0.4,0.4-0.6c0,0,0.2-0.2,0.5-0.6c0.3-0.3,0.4-0.5,0.4-0.5c0.1-0.1,0.2-0.3,0.5-0.7
			c0.2-0.3,0.3-0.5,0.5-0.7c0.1-0.1,0.3-0.2,0.5-0.3c0.1,0,0.2-0.1,0.5-0.3c0.8-0.6,1.6-1.6,2.3-3.1c0.3-0.6,0.8-1.3,1.4-2
			c0.9-1.1,1.5-1.7,1.6-1.9c-0.3,0.1-0.8,0.4-1.4,0.9s-1.2,0.8-1.7,0.8V259c0,0-0.3,0.1-0.7,0.2c-0.3,0-0.4,0.3-0.5,0.6
			c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3,0.1-0.8,0.3-1.6,0.8c-0.7,0.4-1.3,0.6-1.9,0.6c-1.1-0.2-1.8-0.8-2.1-1.9
			c0-0.2,0.4-0.9,1-2.2c0.7-1.4,1-2.1,1.1-2.2v-0.3c0,0,0.1-0.1,0.3-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1l2.3-2.1
			c0.4-0.4,0.9-0.8,1.4-1.2c0.4-0.2,0.9-0.5,1.4-0.7c0.9-0.4,1.4-0.6,1.5-0.7c0,0,0.1,0,0.1,0.1c2.1-1.4,4.4-2,6.9-1.7
			c-0.3-1.2,0.1-2,1.3-2.4c1.1-0.4,2.1-0.2,3,0.5s1.2,1.7,1.1,2.9c-0.2,1.4-1.2,3.1-2.9,5v0.5c-0.6,0.9-0.9,1.4-0.9,1.6
			c-0.1,0-0.2,0.2-0.3,0.3c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.3-0.6,0.4-0.6,0.5c-0.5,0.4-0.8,0.9-0.9,1.5
			c-0.5,0.6-1.3,1.5-2.5,3c-1,1.2-1.9,2.2-2.7,2.9C330.3,264.5,330.3,264.7,330.3,265.1L330.3,265.1z"/>
				<path class="st21" d="M357.7,236.2c-0.1-0.3-0.2-0.5-0.3-0.5c0.1,0.1,0.2,0.3,0.2,0.6s0,0.5,0.1,0.7
			C357.8,236.7,357.8,236.5,357.7,236.2L357.7,236.2z M356.3,234.2c0.3,0.4,0.7,0.5,1.2,0.5c0,0.1,0.1,0.2,0.2,0.2h0.1
			c0,0,0.1,0,0.2,0.1c0.1,0.2,0.1,0.3,0,0.3c0.2,0.2,0.3,0.4,0.2,0.5c0,0,0,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3
			c-0.1,0.4-0.1,0.7,0,0.8c-0.1,0-0.2,0-0.4,0.1c0.1,0.1,0.1,0.2,0,0.4v0.2c-0.2,0.1-0.5,0-0.7-0.1c-0.4,1-1.1,1.6-2.3,1.9
			c-0.2-0.2-0.5-0.2-0.7-0.2c0,0.1-0.2,0-0.5-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0-0.2c0-0.1,0-0.3,0-0.6c0-0.1,0.1-0.2,0.1-0.2
			c0-0.1,0-0.2,0.1-0.3l0,0c0-0.1,0-0.1,0-0.1v-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.3,0.4-0.7
			c0.2-0.1,0.4-0.3,0.7-0.6c0.3-0.3,0.6-0.6,0.9-0.6c0.1,0,0.1,0,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0-0.1,0-0.1,0-0.2v-0.1
			C356.2,234.3,356.2,234.2,356.3,234.2C356.2,234.2,356.2,234.2,356.3,234.2L356.3,234.2L356.3,234.2z M348.9,257.3
			c0.2-0.1,0.2-0.2,0.1-0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.3,0.2-0.3,0.2C348.6,257.5,348.8,257.4,348.9,257.3L348.9,257.3z
			 M343.5,249.5c0.1,0.1,0.1,0.2,0,0.3c-0.1-0.1-0.1-0.2-0.1-0.3H343.5z M343.4,249.2c0.1,0.2,0.2,0.3,0.2,0.3
			c-0.2,0-0.4-0.2-0.5-0.6C343.3,249,343.3,249.1,343.4,249.2z M340.4,257L340.4,257c0-0.1,0-0.1,0-0.2v-0.1l0.1-0.7
			c0,0.1-0.1,0.1-0.2,0c0.1,0.1,0.1,0.2,0,0.3c0,0.2-0.1,0.3,0,0.5C340.3,256.9,340.3,257,340.4,257L340.4,257z M344.8,256.2
			c0.2,0.2,0.7,0.1,1.7-0.3c1.5-0.6,2.8-1.3,4-2.2c0.1-0.1,0.6-0.5,1.3-1.1c0.5-0.5,1-0.8,1.3-1c0.6-0.3,1-0.3,1.2,0
			c0.1,0.2,0.1,0.6-0.2,1.1c-0.3,0.6-1.6,1.8-3.7,3.5c-1.6,1.3-2.6,2.1-3.1,2.3c-0.2,0.1-0.6,0.4-1.1,0.7c-0.7,0.5-1,0.7-1.1,0.8
			c-0.9,0.6-1.8,0.8-2.5,0.7c-0.4-0.1-0.9-0.3-1.3-0.6c-0.6-0.4-1-0.7-1.2-0.8c-0.2-0.2-0.3-0.6-0.3-1.1V257c0-0.1-0.1-0.2-0.1-0.3
			c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.4,0.2-1.1,0.8-2.3c0.4-0.8,0.8-1.4,1.2-1.8c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2l0,0l0,0l0,0l0,0
			l0,0l0,0l0.5-0.3c-0.1-0.2,0-0.4,0.1-0.6l0.3-0.6c0.2,0,0.3,0.1,0.2,0.1h-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0.1,0s0.1,0.1,0.2,0
			c0,0,0.1-0.2,0-0.3c0-0.2,0-0.3,0-0.3c0.1,0,0.1,0,0.2,0.1s0.2,0.1,0.2,0.1c-0.1,0.1-0.2,0.3-0.4,0.5l-0.4,0.5
			c-0.3,0.4-0.4,0.9-0.4,1.3v0.1c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.2-0.1c-0.4,1-0.8,1.9-1.3,2.8c0,0,0.1,0.1,0.2,0.1
			s0.1,0.1,0.1,0.1c-0.2,0-0.2,0.2-0.2,0.6c0.5-0.9,1.1-1.9,1.9-3c1-1.5,1.7-2.5,2-2.9c0,0-0.2-0.2-0.5-0.4
			c-0.2-0.1-0.3-0.3-0.2-0.5c0.1,0,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.4
			c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.2c0.1,0,0.1,0,0.2,0s0.1,0.1,0.2,0c0-0.4,0-0.7,0.1-0.9c0.3-0.3,0.6-0.5,1-0.7
			c0,0,0,0,0.1-0.2c0.1-0.1,0.2-0.3,0.4-0.7c0.2-0.4,0.4-0.7,0.6-1c0.5-0.8,1-1.1,1.7-1c0.4,0.1,0.9,0.3,1.5,0.6
			c0.6,0.4,1,0.8,1.1,1.1c0.3,0.7,0.2,1.3-0.3,2c-0.5,0.6-1.1,0.9-1.8,1c-0.3,0.2-0.7,0.5-1.1,1.1s-0.8,0.9-1.1,1.1
			c-0.4,0.3-0.8,1.2-1.4,2.6c0,0.1-0.2,0.4-0.7,1.1c-0.4,0.6-0.7,1.1-0.8,1.4C344.7,256.1,344.7,256.2,344.8,256.2L344.8,256.2z"/>
				<path class="st21" d="M365.1,252.6c0.3-0.2,0.4-0.4,0.4-0.6c-0.1,0.1-0.3,0.3-0.6,0.5c-0.3,0.2-0.4,0.4-0.5,0.5L365.1,252.6
			L365.1,252.6z M358,253.2c-0.2,0.3-0.3,0.5-0.4,0.6c0.7-0.8,1.1-1.3,1.1-1.5c0,0.1-0.1,0.1-0.1,0.1l-0.2-0.1v0.4l0,0
			c0,0,0,0-0.1,0C358.2,252.8,358.1,253,358,253.2L358,253.2z M352.2,250.2c0,0.1,0,0.2,0,0.4s0,0.3,0,0.4c0,0,0-0.3-0.1-0.9
			L352.2,250.2L352.2,250.2z M351.8,251.1c-0.1,0.1-0.1,0.1-0.1,0.2l0,0c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2h0.1c0,0,0.1,0,0.1-0.1
			C351.9,251,351.9,251.1,351.8,251.1L351.8,251.1z M350.3,252.4c0.1-0.3,0.2-0.5,0.4-0.8c0.2-0.4,0.4-0.4,0.6-0.2
			c0,0.1,0,0.2-0.3,0.5s-0.4,0.5-0.5,0.6c-0.1,0.5-0.1,0.9,0.1,1.3c0.2,0.4,0.4,0.6,0.8,0.8c0.7,0.4,1.4,0.4,2.2,0.1
			c0.8-0.3,1.3-0.8,1.5-1.5h0.1c0.1,0,0.1,0.1,0.2,0c1.3-1.5,2.1-2.4,2.5-2.6c0.3-0.2,0.6-0.3,0.9-0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.2c-0.1,0.4-0.2,0.7-0.3,1c0.3,0,0.4-0.3,0.4-0.8s-0.1-0.8-0.4-0.9c-1-0.1-2,0.4-3.1,1.5
			c-1.4,1.4-2.3,2.1-2.4,2.3c-0.3,0.2-0.8,0.2-1.3,0.1c-0.6-0.1-0.9-0.3-1-0.7s0.1-0.8,0.6-1.2c0.8-0.5,1.1-0.8,1.2-0.8
			c-0.2-0.3-0.3-0.5-0.3-0.7c-0.1-0.3,0-0.5,0.3-0.6c0,0.2,0.1,0.3,0.2,0.4l0.3,0.3l1-0.6c0.4-0.3,0.6-0.6,0.7-1c0.1,0,0.1,0,0.2,0
			c0.1,0.1,0.1,0.1,0.2,0.1c0.2-0.1,0.3-0.2,0.4-0.4l0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0.2-0.1,0.4-0.2,0.4-0.3
			c0.2-0.2,0.5-0.4,1-0.5c0.5-0.2,0.8-0.4,1.1-0.5c0.2-0.1,0.4-0.3,0.8-0.6l0.7-0.7c0.6-0.5,1.2-0.7,1.9-0.6
			c0.4,0.1,0.7,0.3,0.8,0.7c0.1,0.3,0.2,0.7,0.1,1.3c-0.1,1.7-0.1,2.6,0,2.8c0.4-0.1,0.8-0.3,1.1-0.5c0,0,0.2-0.2,0.5-0.4
			c0.2-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.5-0.4,0.9-0.6c0.5-0.3,0.8-0.5,1-0.7c1.1-0.8,2-1.4,2.9-1.7c1.3-0.5,2.4-0.3,3.1,0.4
			c0.4,0.4,0.7,1.1,0.8,2c0,0.2,0.1,0.7,0.2,1.3s0.1,1.1,0.2,1.3c0.1,0.3,0.2,0.6,0.2,0.8c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.3
			s0,0.1,0,0.2c0.1,0.3,0.1,0.6,0.1,0.9c0.1,0.4,0.1,0.7,0.1,0.8c0.2,0.9,0.5,1.6,0.9,2.1c0.6,0.7,1.3,1,2.1,0.9
			c0.6-0.1,1.8-0.6,3.8-1.7c0.3-0.2,0.6-0.3,0.9-0.5s0.6-0.3,0.9-0.5c0.7-0.3,1.4-0.5,2.1-0.4c0.2,0.7-0.1,1.2-0.8,1.7
			c-1.2,0.8-1.8,1.1-1.7,1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0c-0.2,0.1-1.5,1.1-4.2,3c-1.1,0.8-2.2,1.4-3.3,1.7
			c-1.6,0.5-2.7,0.3-3.5-0.5c-0.5-0.6-0.9-1.6-1.2-3.1c-0.7-3-1.1-5.3-1.2-7c0-0.1,0-0.1,0-0.1s0,0,0.1,0h0.1v-0.1
			c-0.2,0-0.3,0-0.5,0.1l0,0c0,0,0,0-0.1,0.1l-0.1,0.1c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-2.3,1.6-6.5,4.6c-1.6,1.1-2.7,2.1-3.5,2.8
			c-0.9,0.9-1.6,1.5-1.9,1.7c-0.8,0.5-1.7,0.8-2.8,0.7c-0.7-0.1-1.2-0.3-1.4-0.6c-0.2-0.4-0.2-0.8,0-1.3c0.3-0.6,0.5-1,0.6-1.2
			c0.1-0.1,0.5-0.6,1.1-1.4c0.2-0.2,0.7-0.8,1.6-1.7c0.7-0.7,1.1-1.3,1.3-1.9c0.3-0.3,0.7-0.8,1.3-1.6c-0.4,0.1-0.8,0.4-1.3,0.8
			c-0.7,0.6-1.1,0.9-1.3,1v0.3c0,0.2,0,0.3-0.1,0.3s-0.3,0.2-0.6,0.4c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0.6-0.7,0.9-1.4,1
			c-0.7,0.2-1.3,0.1-1.9-0.2c-0.1,0-0.2-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.3l0,0H351c0,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.3-0.2
			c0.1-0.1,0.1-0.2,0-0.5c-0.2-0.3-0.2-0.6-0.2-0.6L350.3,252.4L350.3,252.4z"/>
				<path class="st21" d="M401,248c-0.1,0.3-0.2,0.5-0.1,0.6c0-0.1,0-0.1,0-0.2L401,248z M397.6,247.7L397.6,247.7
			C397.5,247.6,397.5,247.6,397.6,247.7L397.6,247.7l0.1-0.3l0,0C397.7,247.5,397.7,247.6,397.6,247.7
			C397.6,247.8,397.6,247.7,397.6,247.7L397.6,247.7z M396,251.1c-0.2,0-0.4,0-0.4,0.1C395.8,251.2,395.9,251.2,396,251.1z
			 M389,259.2c0-0.1,0-0.3,0-0.5c0-0.3,0.1-0.5,0.2-0.5C389.2,258.1,389.1,258.5,389,259.2L389,259.2z M388.5,259.6
			c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.1-0.2,0-0.4c0.1-0.1,0.2-0.2,0.3-0.1C388.7,259.5,388.6,259.6,388.5,259.6z M396.6,246.6
			c-0.1,0.2-0.2,0.3-0.4,0.3l0,0h-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.7,0.1-1.8,0.4c-0.3,0.1-0.9,0.2-1.7,0.5
			c-0.6,0.8-1.3,1.1-2.1,1c-0.2,0-0.4,0.2-0.6,0.5s-0.4,0.4-0.7,0.4c-0.4,0.4-0.9,1.4-1.4,3.2c0.3,0.3,0.9,0.4,1.6,0.2
			c0.1,0,0.4-0.1,0.9-0.2c0.3-0.1,0.6-0.2,0.8-0.2c0.1-0.2,0.5-0.4,1.1-0.6c0.1-0.1,0.3-0.1,0.5-0.2c0.3-0.1,0.4-0.2,0.6-0.3
			c0.1,0,0.2,0,0.5-0.1c0.2,0,0.4,0,0.5-0.1c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.5-0.3,1.1-0.5,1.9-0.6
			c0.5-0.1,1.1-0.2,1.8-0.5c0.8-0.3,1.3-0.5,1.7-0.5c0,0.1,0.3-0.3,1-1.1c0,0,0,0-0.1,0c-0.2-0.1-0.5-0.2-1-0.5
			c-0.2,0.1-0.3,0.2-0.2,0.2c0-0.1,0-0.1-0.1-0.2c0,0-0.1,0-0.1-0.1c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3,0c0-0.1,0-0.1,0-0.1
			c0,0.1-0.1,0.1-0.1,0.1v-0.2c-0.1,0-0.1,0-0.2,0.1s-0.1,0.1-0.2,0.1c0-0.1,0-0.2,0-0.2v-0.1c-0.1-0.1-0.1-0.1-0.1,0c0,0,0,0,0,0.1
			v-0.1c0.7,0.3,1.2,0.3,1.5,0v0.2c0.2-0.1,0.5-0.1,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0c0,0.1,0,0.2,0.2,0.2c-0.2-0.4-0.7-0.8-1.5-1
			c-0.3-0.1-1-0.2-2-0.3v0.2c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.3-0.2,0.3c0-0.2,0-0.4,0-0.7c-0.3-0.1-0.5,0-0.6,0.3
			C396.7,246.8,396.7,246.7,396.6,246.6C396.7,246.6,396.6,246.6,396.6,246.6L396.6,246.6z M381.5,262.8c-1.1,0.1-2,0.3-2.5,0.6
			c0,0,0,0,0.2-0.1c0.2,0,0.5-0.1,0.9-0.2C380.4,263,380.9,262.9,381.5,262.8L381.5,262.8z M382.2,266.9c-0.1-0.1-0.1-0.1-0.3-0.1
			c-2.2,2.7-4.7,4.9-7.5,6.8l0,0v0.1c0,0.1,0,0.1-0.1,0.2c-0.2,0.1-0.5,0.2-1,0.3c-0.4,0.2-0.7,0.3-1,0.4c1.3-0.2,2.8-1,4.4-2.3
			l3.6-3.1c1-0.8,2.1-2,3.3-3.4c1.6-1.9,2.7-3,3.2-3.5l0,0l0,0c-0.3,0.1-0.8,0.3-1.5,0.5C383.9,264.5,382.8,265.9,382.2,266.9
			L382.2,266.9z M371.3,274.5c-0.3,0.1-0.5,0.1-0.6,0.1c0-0.1,0-0.1,0-0.1c-1.4-0.4-2.2-0.7-2.2-0.9c0.6,0.8,1.7,1.1,3,1
			C371.2,274.7,371.1,274.7,371.3,274.5z M375.5,266.2c-0.5,0.2-1,0.3-1.3,0.5c-0.1,0-0.2,0.1-0.4,0.3c-0.2,0.2-0.3,0.3-0.4,0.3
			c-0.3,0.2-0.5,0.3-0.7,0.4c-0.3,0.2-0.5,0.3-0.7,0.4c-0.1,0.1-0.3,0.2-0.6,0.5c-0.3,0.3-0.5,0.5-0.6,0.5c0,0-0.1,0.1-0.3,0.2
			c-0.1,0.1-0.2,0.1-0.3,0.2c-1.3,1.2-1.9,2.2-1.8,3.2c0.1,0.8,0.9,1.3,2.4,1.4c1.2,0.1,2.4-0.1,3.5-0.8c0.4-0.3,1.3-0.9,2.6-2
			l4.2-3.6c0.1-0.2,0.7-0.9,1.9-2.2c0.2-0.4,0.5-0.8,0.9-1.3c0.6-0.7,0.9-1.1,0.9-1.2c-1.7,0.5-4.3,1.4-8,2.8
			C376.7,265.8,376.3,266,375.5,266.2L375.5,266.2z M394,258.9c1.5-0.4,2.4-0.4,2.5-0.1c0.1,0.2,0,0.5-0.4,0.8
			c-0.2,0.2-0.5,0.4-0.8,0.6c-0.2,0.1-0.5,0.2-1,0.4c-0.2,0.1-0.4,0.1-0.6,0.2s-0.3,0.1-0.3,0.1c-1.2,0.5-2.3,1.1-3.4,1.8
			c-0.6,0.1-1.5,0.7-2.6,1.9c-0.6,0.6-1.2,1.4-1.9,2.3c-2,2.6-4.9,5.4-8.5,8.3c-2.1,1.6-4,2.5-5.8,2.6c-2,0.1-3.7-0.4-5-1.7
			c-0.8-0.8-1.3-1.7-1.4-2.7c-0.1-1.1,0.2-2.2,0.8-3.3c0.6-1,1.2-1.8,1.8-2.3c0,0,0.2-0.1,0.5-0.3c0.3-0.2,0.4-0.3,0.5-0.3
			c2.2-1.7,4.9-3,8.1-4c0.8-0.2,2.2-0.6,4.1-1c1.8-0.4,3.2-0.8,4.1-1.1c1.4-0.4,2.2-0.7,2.5-0.8c0.1,0,0.2-0.1,0.3-0.2l0.3-0.2
			c0.2,0.3,0.6,0.3,1.1,0.1c0.2-0.1,0.5-0.3,0.8-0.7c0.3-0.4,0.5-0.7,0.6-0.7c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.3-0.1-0.3
			c0.3-0.6,0.5-0.8,0.6-0.8c-0.1,0.1-0.1,0.2,0,0.3c0.2,0.1,0.3,0.1,0.3,0.2c0.2-0.1,0.6-0.5,1.2-1.2l1-1.3c0-0.2,0-0.5,0.1-0.7
			c0,0,0.1-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.4,0.4-0.8c0.1-0.5,0.2-0.8,0.3-0.9c-0.3,0.1-1,0.4-2.1,0.8l-2.1,0.8
			c-1.8,0.7-3.3,1-4.6,1c-1.1,0-1.9-0.2-2.4-0.6c-0.5-0.4-0.7-1-0.6-1.7c0.1-1,0.8-2.1,2-3.4c0.9-1,1.9-1.7,3.1-2.3
			c2.1-1,4.9-1.8,8.6-2.2c0.9-0.1,1.8-0.1,2.8,0c0.4,0,1,0.2,1.9,0.6c0.7,0.3,1.3,0.4,1.8,0.5c0.4,0,1.1-0.1,1.9-0.5
			c1.1-0.4,1.7-0.6,1.8-0.6c0.4,0,0.8,0.2,1.2,0.6c0.4,0.4,0.6,0.7,0.6,1c0,0.4-0.3,0.7-0.8,0.9c-0.3,0.1-0.5,0.2-0.7,0.2
			c-0.2,0.1-0.4,0.1-0.6,0.2c-0.5,0.2-1.7,0.9-3.7,2.1c-0.4,0.2-1.4,1-2.9,2.4c-5.3,4.7-8,7.1-8.3,7.3
			C392.1,259.3,392.9,259.2,394,258.9L394,258.9z"/>
	</g>
			<g id="_2075215973216">
		<path class="st22" d="M39.2,32.5c-0.1,0.2-0.4,0.3-1,0.3C38,32.9,37.8,33,37.7,33c-1.8,0-3.4,0-4.6,0c-2.2,0.1-5.5,0.3-9.8,0.5
			c-0.6,0-1.5,0-2.9,0.2c-3,0.3-5.6,0.5-7.6,0.6c-1.7,0.1-3.4,0.3-5.1,0.6c-0.1-0.5-0.4-1.1-0.8-1.9c1.2,0.1,2.7,0.1,4.4,0
			c0.6,0,1.1,0,1.4,0c0.7-0.1,1.9-0.2,3.6-0.2c1.5-0.1,2.6-0.2,3.5-0.2c-0.1-0.3-0.2-0.7-0.4-1.1c0-0.1,0-0.3,0-0.5
			c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.1-0.1-0.3-0.1-0.5c-0.3-0.6-0.5-1-0.7-1.1c0-0.2-0.1-0.3-0.3-0.3c-0.2,0.3-0.5,0.7-1.1,1.2
			c-0.7,0.3-1.2,0.5-1.5,0.5c-0.3-0.1-0.4-0.3-0.4-0.6v-3.6c0-0.1,0-1.1-0.1-3c0-0.2,0-1.1-0.1-2.6c0.9,0.3,1.7,0.6,2.2,0.8
			c0.1,0,0.2,0.1,0.4,0.2c0.2,0,0.6,0.2,1.2,0.5c4.6-0.2,7.7-0.4,9.4-0.5l1.2-1.7c0.6,0.3,1.3,0.8,2.2,1.5c0.8,0.5,1.2,0.9,1.1,1.1
			c0,0.4-0.3,0.8-1,1.2c-0.1,0.4-0.1,0.9-0.1,1.4c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.3c0.4,0.4,0.5,0.7,0.5,0.9
			c0,0.2-0.2,0.3-0.5,0.3h-1c-0.5,0-1.2,0-2.4,0h-1.2c0.4,0.3,0.9,0.7,1.5,1.1c0.8,0.5,1.2,0.9,1.1,1.2c0,0.3-0.5,0.6-1.5,0.8
			c-0.1,0.2-0.4,0.5-0.8,0.9c-0.6,0.5-1,0.9-1.2,1.2l6.8-0.5l1.9-3.1c0.3,0.2,0.9,0.7,1.9,1.4c0.6,0.5,1,0.9,1.2,1.1
			C38.9,31.6,39.2,32.1,39.2,32.5L39.2,32.5z M33.4,42.3c0,0.3-0.6,0.4-1.7,0.3c-0.3,0-0.8,0-1.5,0c-0.7,0.1-1.3,0.2-1.7,0.2
			c-0.6,0-1.5,0.1-2.8,0.2c-3,0.2-5.5,0.4-7.5,0.5c0,0.8-0.3,1.5-1,2.2c-0.3,0.3-0.6,0.5-1,0.6c-0.6,0.3-0.9,0.4-1,0.3
			c-0.2-0.1-0.3-0.5-0.3-1.2c0-0.3,0-0.9-0.1-1.9c0-1.9,0-3.5-0.1-5c0-0.4,0-1.1-0.1-2.2c-0.1-0.5-0.1-0.9-0.1-1.2
			c1.1,0.3,2,0.6,2.6,0.9c0.4,0.1,0.8,0.3,1.2,0.5c0.1,0.1,0.1,0.2,0.1,0.2l10-0.6l1.4-2c0.1,0.1,0.2,0.2,0.3,0.3
			c0.4,0.3,0.9,0.7,1.7,1.2c1,0.8,1.5,1.4,1.5,1.7c0,0.4-0.4,0.8-1.2,1.2c0,0.1-0.1,0.5-0.1,1c-0.1,0.4-0.1,0.8-0.1,1.1
			c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.2C33.1,41.5,33.4,42,33.4,42.3L33.4,42.3z M35.8,14.7c0,0.4-0.5,0.6-1.4,0.6
			c-0.4-0.1-1.1-0.1-2.1,0c-0.7,0-1.3,0-1.8,0c-0.8,0-1.9,0.1-3.3,0.2c-1.1,0-1.9,0-2.5,0v2.9c0.5,0,1.1-0.1,1.8-0.2
			c0.8,0,1.4-0.1,1.8-0.2c0.1-0.1,0.3-0.5,0.7-1.1c0.3-0.4,0.5-0.8,0.7-1.1c0.8,0.7,1.6,1.3,2.3,1.9c0.6,0.6,1,1,1,1.1
			c0,0.3-0.1,0.5-0.4,0.5s-0.6,0-1,0c-0.6,0-1.6,0-3.2,0c-2.2,0.1-5.7,0.3-10.5,0.5c-0.8,0.1-2.1,0.3-3.7,0.5c0-0.1,0-0.2-0.1-0.3
			c-0.1-0.1-0.3-0.5-0.6-1.2c2.4,0,3.8,0,4.1,0s0.8,0,1.5,0c0.9-0.1,1.7-0.2,2.2-0.2v-3c-1.8,0.1-3.7,0.3-5.9,0.5
			c-0.4,0-1.2,0-2.5,0.2c-0.7,0.1-1.3,0.2-1.7,0.2l-0.8-1.7c1.6,0.1,3.2,0.1,5,0c0.6,0,1.7-0.1,3-0.2c1.3,0,2.3,0,2.9,0V13
			c0-0.2,0-0.8-0.1-1.7c-0.1-0.7-0.1-1.3-0.1-1.7l2.2,0.6c0.5,0.1,1,0.3,1.7,0.5c0.6,0.3,0.8,0.6,0.7,0.8c0,0.4-0.3,0.8-1,1.2v1.7
			c1.9-0.2,3.8-0.3,5.7-0.3l1.7-2.9c0.3,0.2,0.7,0.6,1.4,1.2c0.6,0.4,1,0.7,1.2,0.9C35.5,13.9,35.8,14.4,35.8,14.7L35.8,14.7z
			 M28.6,37.2c-0.6,0-2,0.1-4,0.3c-2.6,0.1-4.7,0.2-6.5,0.3c0,0.3,0,0.8,0,1.5c0.1,1.1,0.1,2.1,0.1,2.8l10.2-0.6c0-0.3,0-0.8,0-1.5
			C28.5,38.8,28.6,37.9,28.6,37.2L28.6,37.2z M28.2,22.9c-0.8,0-2.7,0.1-5.7,0.3c-1.8,0.1-3.3,0.2-4.4,0.3c0,0.2,0,0.5,0,0.9
			c0.1,0.7,0.1,1.3,0.1,1.9l9.8-0.6C28,25.7,28.2,22.9,28.2,22.9z M26.4,27.1l-8.2,0.3l0.1,0.6c0.3,0,0.6,0,0.8,0
			c1.1,0.3,1.9,0.7,2.5,1.2c0.6,0.4,1,1,1,1.9c0,0.5-0.1,1-0.4,1.4l1.9-0.2C25.1,30.7,25.8,29,26.4,27.1L26.4,27.1z M53.7,29.6
			c1-0.5,1.5-1.5,1.5-3v-5c0-1,0-2.5,0-4.3c0-1.5,0-3.1-0.1-4.7c0.5,0.2,1.1,0.5,1.8,0.8c0.1,0,0.3,0.1,0.7,0.3
			c0.2,0.1,0.3,0.2,0.4,0.2c0.1-0.1,0.2-0.3,0.4-0.5c0.3-0.4,0.5-0.7,0.7-0.9c0.1-0.1,0.2-0.4,0.4-0.8c0.4-0.8,0.7-1.5,1.1-2.2
			c0.2,0.2,0.6,0.7,1.1,1.4c0.3,0.4,0.5,0.7,0.7,0.9c0.1,0.2,0.2,0.5,0.4,0.8c0.2,0.2,0.3,0.4,0.3,0.5c0.2,0.1,0.5,0.2,0.8,0.3
			c0.4,0.2,0.6,0.4,0.8,0.5c0.3,0.1,0.8,0.4,1.7,0.9c0.2,0,0.5,0,1,0c0.8-0.1,1.4-0.2,1.8-0.2c0.1-0.1,0.3-0.4,0.6-0.8
			c0.3-0.5,0.6-0.9,0.8-1.2c0.4,0.4,1.1,1.1,2.2,2c0.3,0.3,0.5,0.6,0.7,0.8c0.3,0.2,0.4,0.5,0.4,0.8s-0.5,0.8-1.5,1.4
			c0,1.1,0,3.1,0,5.7c-0.2,3.2-0.5,5.4-1.1,6.7c-0.4,1-1.1,1.7-2.1,2c-0.3,0-0.5,0-0.7,0c-0.3,0-0.5-0.4-0.6-1.1c0-0.5,0-1-0.1-1.4
			c-0.1-0.6-0.4-1-0.8-1.2c-0.6-0.7-0.9-1.1-0.8-1.2c0-0.1,0.4-0.2,1.2-0.2c0.5,0,1-0.1,1.2-0.2c0.2-0.2,0.3-0.7,0.3-1.4
			c0.2-0.8,0.3-2.1,0.3-3.7c0-0.2,0-0.6,0-1.2c0.1-1.8,0.1-3.3,0-4.7c-0.5,0-1.7,0.1-3.7,0.3c0,0.8,0,3.1,0,6.8c0,4.7,0,7.9,0.1,9.8
			c0,1.9,0,2.8-0.1,3c0,0.6-0.4,1.2-1.1,1.9c-0.8,0.6-1.5,0.9-1.9,0.8c-0.2-0.1-0.3-0.4-0.3-0.8c0.1-0.9,0.1-2,0.1-3.3
			c0.1-1.9,0.2-5.1,0.3-9.6c0.1-2.3,0.1-3.9,0.1-5c0-1.9-0.1-3.6-0.3-5.3c0,0,0,0-0.1,0c-0.3-0.1-0.5-0.2-0.7-0.2
			c-1.1,0.7-2.3,1.4-3.5,1.9v11.6c0.2-0.1,0.6-0.3,1.1-0.5c0.8-0.5,1.5-0.9,2.1-1.1c0,0,0,0,0.1,0c0.4-0.2,0.6-0.2,0.7,0
			c0.2,0,0,0.2-0.4,0.6c-0.5,0.4-1.1,1-1.9,1.9c-1.6,1.7-2.6,2.8-3,3.4c-0.2,0.2-0.5,0.5-0.8,0.9c-0.3,0-0.7-0.4-1.2-1.2
			c0-0.2-0.1-0.6-0.4-1.1C54,30,53.8,29.7,53.7,29.6L53.7,29.6z M75.7,40c0.1,0.3,0,0.6-0.4,0.8c-0.1,0.1-0.3,0.3-0.7,0.6
			c0.2-0.2,0.1-0.2-0.3,0.2c-0.5,0.5-1.1,1.3-1.9,2.3c-0.2,0.5-0.5,1-1,1.4c-0.2,0.2-0.6,0.2-1.2,0c0,0-0.1,0-0.4,0
			c-0.6-0.1-1.2-0.2-1.8-0.3c-3-0.5-6-1.5-9-3.1c-2.8-1.3-5.5-3.4-8.3-6.2c-0.5,0.4-1.6,1.3-3.2,2.8c-0.5,0.4-0.8,0.7-1.1,0.9
			c-0.4,0.6-0.9,1.2-1.5,1.9c-0.2,0-0.5-0.3-1-0.8c0,0-0.1-0.2-0.3-0.5c-0.6-0.8-1.1-1.6-1.7-2.3c0.4-0.2,0.9-0.4,1.7-0.6
			c0.7-0.3,1.3-0.5,1.7-0.6c0.7-0.3,1.9-0.9,3.5-1.9v-6.7c-0.4,0.2-1.2,0.5-2.6,0.8c-0.2,0-0.5,0.1-0.8,0.2
			c-0.5,0.2-0.8,0.3-1.1,0.3c0,0-0.3-0.6-1-1.7c0.1,0,0.4-0.1,1-0.2c0.7-0.1,1.3-0.2,1.8-0.2c0.2,0,0.6-0.1,1.2-0.2
			c0.5-0.1,1-0.2,1.2-0.2c0.3-0.5,0.7-1.3,1.2-2.3c0.3,0.2,0.7,0.5,1.4,0.9c0.5,0.4,0.9,0.7,1.2,0.9c0.6,0.6,1,1.1,1,1.4
			s-0.3,0.7-1,1.1V34c0,0.7,0,1.2,0,1.4c1.2,1,2.3,1.8,3.5,2.2c1.6,0.8,3.4,1.4,5.5,1.7c2.7,0.5,5.6,0.8,8.7,0.8c0.1,0,0.4,0,0.8,0
			c1.3-0.1,2.5-0.2,3.6-0.3C75.3,39.8,75.7,39.9,75.7,40L75.7,40z M52.2,18.1c0,0.7-0.2,1.4-0.5,2c-0.5,0.4-1,0.7-1.5,0.8
			c-1.1,0-1.8-1-1.9-3.1c-0.1-0.9-0.3-1.8-0.7-2.5c0-0.1-0.1-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3-0.3-0.4-0.6-0.3-0.8
			c0.2-0.3,0.9-0.2,2.1,0.3c0.8,0.3,1.4,0.6,1.8,0.9C51.6,15.5,52.1,16.5,52.2,18.1L52.2,18.1z M109.2,40.4c0,0.5-0.3,0.9-0.8,1.2
			s-1.1,0.6-1.8,0.8c-1.8,0.4-3.9,0.6-6.1,0.6c-1.4,0-2.5-0.2-3.5-0.5c-0.7-0.2-1.2-0.7-1.5-1.6c-0.1-0.3-0.1-1-0.1-2.2
			c0-0.4,0-1.9,0-4.3c0.1-1.8,0.1-3.1,0.1-3.9V26c0-0.5,0-1.3,0-2.3C94.9,23.9,94,24,92.6,24c-1.5,0.1-2.5,0.2-3,0.2
			c0.2,0.1,0.5,0.3,0.8,0.5c0.6,0.3,1,0.6,1.2,0.8c0.7,0.3,1.2,0.6,1.2,0.8c0.3,0.3,0.4,0.6,0.4,0.9c0,0.2-0.1,0.4-0.4,0.6
			c-0.3,0.1-0.6,0.3-0.8,0.6c-0.6,3.7-1.2,6.5-2.1,8.2c-2.2,4.1-5.9,6.8-10.9,7.9c-0.6,0.2-1,0.2-1-0.2c-0.1-0.2,0.2-0.5,0.8-0.8
			c3-1.8,5.3-3.5,6.6-5.3c1.6-2,2.6-4.9,3-8.7c0.1-0.6,0.2-1.8,0.3-3.6c0.1-0.8,0.1-1.5,0.1-1.9c-0.3,0-0.6,0.1-1,0.2
			c-0.9,0.1-1.8,0.2-2.5,0.2c-1.2,0.1-2.5,0.3-3.9,0.6l-1-1.9c0.6,0,2-0.1,4-0.2c0.8-0.1,1.5-0.2,1.9-0.2c2.5,0,7.3-0.3,14.5-0.8
			c0.1-0.2,0.3-0.6,0.7-1.1c0.5-0.9,0.9-1.7,1.2-2.3c0.2,0.2,0.5,0.4,0.8,0.6c0.6,0.5,1.2,1.1,2.1,1.9c0.8,0.7,1.2,1.2,1.1,1.5
			c0,0.3-0.5,0.5-1.4,0.6c-0.1,0-1.4,0.1-4,0.2c-1.8,0-3.5,0.1-5.4,0.3c0.6,0.2,1.3,0.5,2.1,0.9c0.9,0.5,1.4,0.9,1.4,1.1
			c0,0.3-0.4,0.8-1.1,1.6v11.5c0.1,0,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0.2,0.2,0.6,0.3,1.2,0.3s1.4-0.1,2.4-0.3
			c-0.1,0,0,0,0.3,0c0.8-0.2,1.5-0.5,1.9-0.8c0.6-0.4,1-1.2,1.4-2.5c0.1-0.5,0.3-1.2,0.6-2.2c0.1-0.3,0.1-0.6,0.1-0.8
			c0-0.1,0-0.3,0.1-0.6c0-0.2,0.1-0.8,0.4-1.9c0.3-0.6,0.5-0.9,0.6-0.9c0.2,0,0.3,0.3,0.3,0.9c0,0.3,0,0.8,0,1.4c0,0.7,0,1.2,0,1.4
			c0,0.7,0,1.8,0,3.3c0,1.2,0.1,2,0.3,2.3c0,0.1,0.1,0.3,0.3,0.5C108.9,39.9,109.1,40.2,109.2,40.4L109.2,40.4z M103.1,14.4
			c-0.1,0.3-0.5,0.5-1.4,0.6c-0.4,0-1.2,0-2.5,0.2c-0.7,0.1-1.3,0.2-1.7,0.2l-8.2,0.5c-1.7,0.1-2.9,0.3-3.9,0.6
			c0-0.2-0.1-0.5-0.3-0.8c-0.2-0.4-0.3-0.7-0.4-0.9c1.1,0,2.5-0.1,4.1-0.2c2.4-0.1,5.1-0.3,8.2-0.5l1.8-3.4c0.8,0.6,1.8,1.3,2.8,2
			C102.6,13.5,103.1,14.1,103.1,14.4z M138.5,15c0.1,0.2-0.2,0.7-1,1.4c0.1-0.1,0-0.1-0.3,0.2v4.3c0,0.6,0,1.8,0,3.6
			c0.1,2.8,0.1,5.1,0.1,7c0,0.6,0,1.1-0.1,1.4c-0.3,0.7-0.7,1.3-1.4,1.7c-0.8,0.6-1.5,0.8-1.9,0.6c-0.4,0-0.6-0.3-0.6-0.8
			c0.1-0.9,0.1-1.9,0.1-2.8l-8.7,0.6c0,1.2-0.3,2.2-1,2.9c-0.3,0.3-0.6,0.6-1.1,0.8c-0.4,0.1-0.7,0.1-1,0c-0.2,0-0.3-0.1-0.3-0.3
			c0-0.3,0-0.7,0.1-1.1c0-0.5,0-0.9,0-1.1c0-0.6,0-1.2,0-1.7c0.1-0.8,0.1-2.5,0.1-5c0-2.9,0-5.7-0.1-8.5c0-0.7,0-2.1-0.1-4
			c-0.1-0.7-0.1-1.3-0.1-1.7c0.5,0.1,1.2,0.4,2.1,0.9c0.3,0.1,0.8,0.4,1.5,0.8c0.2,0.1,0.4,0.2,0.5,0.3l7.9-0.5c0,0,0.5-0.8,1.4-2.3
			c0.2,0.1,0.5,0.3,1,0.6c0.7,0.5,1.3,0.9,1.8,1.2C138.2,14,138.5,14.5,138.5,15L138.5,15z M133.6,20.3c0-0.3,0-2,0-5
			c-0.6,0-2.1,0.1-4.3,0.2c-1.9,0.2-3.5,0.3-4.6,0.3v6.5c1.5,0,2.5-0.1,3-0.2c1,0,1.6,0,1.8,0c0.6-0.2,1.1-0.4,1.5-0.5
			c0.2,0.1,0.3,0.3,0.4,0.6c0.3,0.3,0.4,0.6,0.3,0.8c0,0,0,0-0.1,0c-0.5,0.1-0.8,0.2-1.1,0.2l-5.7,0.3c0,2.6,0,5.1,0,7.5l8.6-0.6
			c0-0.8,0-2.2,0-4C133.6,23.7,133.6,21.7,133.6,20.3L133.6,20.3z M138.5,39.8c-5.4,0.3-11.8,0.8-19.2,1.4c-0.4,0-1,0-1.9,0.2
			s-1.7,0.3-2.2,0.3c-0.3-0.6-0.6-1.3-0.8-2c2.6,0,4.2,0,5,0l18.8-1.1c0.2-0.2,0.5-0.7,0.8-1.4c0.6-1,1.2-1.9,1.7-2.6
			c0.4,0.4,1.1,1.1,2.1,2c0.5,0.4,1,0.8,1.2,1.1c0.7,0.6,1.1,1.1,1.1,1.4c0,0.5-0.6,0.8-1.7,0.8C142.1,39.8,140.5,39.8,138.5,39.8z"
		/>
				<path class="st21" d="M39.8,96.8c0,0.1-0.3,0.3-0.8,0.6c-0.6,0.2-1.5,0.6-2.5,1.1c-0.2,0.2-0.3,0.3-0.4,0.3
			c-0.5,0.3-1.2,0.8-1.8,1.4c-0.4,0.4-0.8,0.6-1.2,0.6s-1.2-0.6-2.5-1.9c-1.1-1.1-1.9-2-2.4-2.5c-0.3-0.4-0.6-1-1-1.7
			c-0.5-0.7-1-1.7-1.7-2.8c-0.6-1.2-1.2-3-1.8-5.3c-0.6,3-1.7,5.4-3.2,7.3c-1.4,1.7-2.8,2.9-4.1,3.7c-2.3,1.2-4.7,2.1-7.3,2.6
			c-0.6,0.1-0.9,0-1-0.2c0-0.3,0.3-0.6,0.8-0.8c2.4-0.9,4.3-2,5.7-3.1c1.4-0.9,2.6-2.3,3.7-4c1.1-1.6,1.9-3.6,2.4-6.2
			c-1.9,0.1-2.2,0.1-0.8,0c-1.4,0.1-3.3,0.3-5.8,0.6c-0.5,0-1.4,0.1-2.8,0.3c-0.8,0.1-1.5,0.2-1.9,0.3c-0.1-0.3-0.4-1-1-2
			c1.3,0,3-0.1,5.1-0.2h1c-0.1-1.8-0.2-4.4-0.4-7.8c0-1.2-0.1-2.4-0.3-3.6c0.6,0.1,1.5,0.5,2.8,1.1c0.5,0.3,0.9,0.5,1.1,0.5
			c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.3,0.2,0.3,0.3l3-0.2c0-0.5,0-1.3-0.1-2.3c0-1.6-0.1-3.7-0.4-6.4c0.3,0.1,1.1,0.4,2.5,0.9
			c-1.1-0.4-0.9-0.3,0.7,0.3c1.1,0.3,1.7,0.7,1.7,1.1c0.2,0.3,0,0.7-0.4,1.1c0.1,0-0.1,0.2-0.7,0.5c0,0.3,0,1.9,0,4.7
			c0.5,0,1.2-0.1,2.1-0.2c0.6,0,1,0,1.2,0l1.5-2.3c0.1,0.1,0.2,0.3,0.4,0.5c0.3,0.1,0.9,0.6,1.9,1.6c0.8,0.6,1.2,1.1,1.1,1.4
			c0,0.4-0.2,0.8-0.6,1.1c-0.1,0-0.2,0-0.3,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c0,0.6,0,1.9-0.1,3.9c-0.1,0.8-0.1,1.5-0.1,2h1.1l2.1-3.3
			c0.2,0.2,0.5,0.5,1.1,0.9c0.8,0.7,1.5,1.3,2.1,1.9c0.5,0.4,0.9,0.9,1,1.4c-0.2,0.3-0.7,0.5-1.5,0.5c-2,0-3.6,0-4.8,0
			c-1.4,0-3.6,0.1-6.8,0.3c2.2-0.2,1.9-0.2-0.8,0c1.4,3.2,3.3,5.6,5.7,7.3c1.9,1.5,4.8,2.5,8.7,3.1C39.4,96.3,39.8,96.5,39.8,96.8
			L39.8,96.8z M27.9,77c-0.8,0-2,0.1-3.6,0.2c0,0.8,0,2.5-0.1,5.1c-0.1,1-0.1,1.8-0.1,2.3l3.5-0.2C27.6,82.6,27.8,80.1,27.9,77
			L27.9,77z M21.2,77.3l-3.9,0.3c0,0.1,0,0.3,0,0.6c0.1,2.2,0.1,4.5,0.1,6.8l3.3-0.3C21.1,83,21.2,80.5,21.2,77.3z M65.9,75.7
			c0,0.3-0.3,0.7-1,1.1c0.1,4.3-0.1,8-0.7,10.9c0.5,0.1,1.1,0.3,1.5,0.6c0.9,0.3,1.4,0.6,1.4,0.9s-0.3,0.7-0.8,1.1v5.6
			c0,0.4,0.1,0.7,0.3,0.8c0.2,0.1,0.6,0.2,1.1,0.2c1,0,1.9,0,2.6-0.2c0.6-0.2,1-0.7,1.4-1.5c0.3-0.9,0.7-3.3,1.2-7
			C73,88,73,87.7,73,87.3c0.1-0.3,0.1-0.6,0.1-0.8c0.2-0.6,0.4-0.9,0.6-0.9c0.1,0,0.1,0.2,0.1,0.5c0.1,0.3,0.1,0.5,0.1,0.6
			c-0.1,0.3-0.1,0.8-0.1,1.5s0,2,0,3.9c0.1,1.7,0.3,2.8,0.7,3.4c0,0,0.2,0.2,0.7,0.6c0,0.1,0,0.2,0.1,0.2c0.2,0.3,0.3,0.6,0.3,0.8
			c0,0.6-0.2,1.1-0.7,1.5c-0.7,0.8-2.1,1.3-4,1.6c-1.8,0.2-3.3,0.2-4.4,0c-0.3,0-0.6-0.1-1-0.2c-0.4,0-0.6,0-0.7,0
			c-0.6-0.2-1.1-0.8-1.2-1.7c-0.1-0.3-0.1-1.1-0.1-2.3v-3.4c0-0.3,0-0.9,0-1.7c-1.5,5-5.4,8.5-11.7,10.6c-0.6,0.2-1,0.2-1.1,0
			c0-0.2,0.3-0.5,0.8-0.8c4.4-2.6,7.3-5.4,8.7-8.5c1.4-2.9,2-7.7,1.8-14.4c0-1,0-2.4-0.1-4c1,0.2,1.9,0.6,2.8,1.1
			C65.5,75,65.9,75.4,65.9,75.7L65.9,75.7z M67.1,87.4v-1.1c0.1-2.9,0.1-5.8,0.1-8.7c-0.1-3.5-0.1-5.6-0.1-6.4
			c-1.1,0.1-3.5,0.3-7.2,0.5v14.9l-0.1,1.2c-0.1,0.6-0.4,1.1-1,1.4c-0.7,0.6-1.3,0.8-1.8,0.6c-0.3-0.1-0.4-0.3-0.3-0.6
			c0.1-0.3,0.1-1,0.1-2.2c0.1-0.9,0.1-1.7,0.1-2.2c0-0.8,0-1.9,0.1-3.1c0-1,0-1.8,0-2.3c0-2.9-0.1-5.7-0.3-8.5c0-0.3,0-0.8-0.1-1.4
			c0-0.4,0-0.7,0-0.9c0.6,0.2,1.2,0.5,1.9,0.8c0.3,0.1,0.7,0.4,1.4,0.8c0.2,0.1,0.3,0.2,0.4,0.3c0.5-0.1,1.6-0.2,3.2-0.2
			c1.4-0.1,2.4-0.2,3.2-0.2l1.4-2c0.5,0.3,1.2,0.9,2.1,1.9c0.8,0.7,1.2,1.2,1.1,1.5c0,0.3-0.2,0.6-0.7,0.9c0,0.1-0.2,0.3-0.6,0.5
			v3.3c0,1,0,3,0.1,5.7c0.1,1,0.1,1.9,0.1,2.5c0,1.1,0,1.9-0.1,2.2c-0.1,0.6-0.5,1.2-1.2,1.7c-0.8,0.5-1.4,0.6-1.8,0.3
			C67.2,88.5,67.1,88.1,67.1,87.4L67.1,87.4z M55.5,76.7c0,0.4-0.3,0.8-0.8,1.1c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.2-0.4,0.2
			c-0.6,1.3-1.3,2.5-2.1,3.4V84c1.8,0.2,3,0.9,3.6,2c0.6,1.8,0.2,2.8-1.2,3.3c-0.6-0.1-1-0.8-1.2-2c-0.2-0.8-0.6-1.5-1.1-2l0.1,4
			c0,0.6,0,1.5,0,2.8c0,0.4,0,1,0.1,1.9c0,0.5,0,0.9,0,1.1c0,0.4,0,0.9-0.1,1.6c0,0.2,0,0.4,0,0.5c-0.2,0.6-0.6,1.3-1.4,2.2
			c-0.6,0.8-1.2,1.1-1.5,0.9c-0.3,0-0.4-0.2-0.4-0.6c0-0.3,0-0.6,0-0.9c0.2-1.7,0.3-3.1,0.3-4.3c0.1-1.3,0.1-3,0.1-5
			c0.1-1.7,0.1-3.1,0.1-4.2c-1.7,2.2-3.7,4-6.1,5.6c-0.6,0.4-1,0.5-1.1,0.2c0-0.2,0.3-0.6,0.8-1.2c4-3.8,6.8-8.2,8.4-13.2
			c-0.1,0-0.2,0.1-0.4,0.2c-0.6,0.1-1.8,0.3-3.6,0.5c-0.2,0-0.6,0.1-1.1,0.3c-0.6,0.1-1.1,0.2-1.5,0.3c-0.1-0.1-0.2-0.3-0.3-0.6
			c-0.3-0.6-0.5-1-0.6-1.2c0.2,0,0.6,0,1.1,0c0.7-0.1,1.3-0.2,1.8-0.2c0.8-0.1,2.4-0.3,4.8-0.5c0.1-0.2,0.3-0.5,0.7-0.9
			c0.3-0.4,0.5-0.7,0.7-0.9c0.3,0.2,0.6,0.6,1,1.1c0.3,0.3,0.5,0.5,0.5,0.6C55.3,75.8,55.6,76.3,55.5,76.7L55.5,76.7z M52.5,69.8
			c0.1,0.3,0.1,0.7,0.1,1.1c0,0.6-0.2,1.2-0.6,1.7c-0.4,0.6-0.8,0.9-1.4,0.9c-0.8,0-1.4-0.6-1.7-1.9c-0.1-1.4-0.4-2.3-0.8-3
			c0-0.1,0-0.3-0.1-0.5c0.1,0.2,0,0.1-0.3-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c0-0.3,0.4-0.4,1.1-0.3c0.8,0.2,1.6,0.5,2.2,0.8
			C51.7,68.6,52.3,69.2,52.5,69.8z M95.1,81.3c0.1,0.8-0.1,1.5-0.7,2h0.3l0.7-0.2l1.1-1.5c0.4,0.1,1,0.6,1.8,1.4
			c0.6,0.3,0.8,0.6,0.7,0.9c0,0.2-0.3,0.3-1,0.3c-1.1,0-1.9,0-2.5,0c-0.3,0-0.7,0-1.2,0c-0.7,0.1-1.3,0.2-1.7,0.2c0,0,0,0,0.1,0
			c0.6,0.2,1,0.5,1,0.8c0,0.2-0.3,0.5-0.8,0.8c0,0.2,0,0.5-0.1,0.8c0.7,0,1.4-0.1,2.1-0.2c0.1-0.1,0.3-0.4,0.6-0.8
			c0.2-0.3,0.4-0.6,0.6-0.8c0.2,0.1,0.7,0.6,1.7,1.4c0.5,0.3,0.7,0.5,0.7,0.6s0,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.1,0.3
			c0,0.3-0.4,0.7-1.1,1.1c-0.1,0.6-0.2,1.6-0.4,2.9c-0.3,1.5-0.8,2.5-1.5,3.1c-0.5,0.5-1.2,0.8-1.8,0.8c-0.3,0-0.5-0.2-0.6-0.6
			c-0.1-0.2-0.2-0.5-0.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.3-0.4-0.8-0.9c-0.3-0.3-0.4-0.6-0.4-0.8c0.1,0,0.4,0,1,0
			c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.2c0.6,0,0.9-0.3,1-0.9c0.3-1.1,0.5-2.4,0.6-3.7c-0.3,0-1,0-2.2,0.2
			c-0.4,1.8-1.2,3.3-2.3,4.5c-0.1,0.2-0.4,0.5-1,0.9c-0.4,0.3-0.6,0.6-0.8,0.8c0.2,0.1,0.4,0.2,0.7,0.3c0.6,0.3,1.1,0.5,1.4,0.6
			c2.5,1,4.9,1.7,7.3,2c1.6,0.1,3.8,0.1,6.8,0c0.4,0,2-0.1,4.8-0.3c0.8-0.1,1.2-0.1,1.2,0.2c0.1,0.2,0,0.4-0.3,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.4,0.3-0.7,0.6c-0.5,0.3-1.1,1.2-1.9,2.6c-0.3,0.2-0.4,0.4-0.4,0.6c-0.2,0.3-0.4,0.5-0.6,0.5
			c-0.3,0.2-0.7,0.2-1.4,0c-0.1,0-0.3,0-0.7,0c-0.6-0.1-1.2-0.2-1.5-0.2c-2.9-0.4-5.8-1.4-8.7-2.8c-1.9-0.7-3.5-1.6-4.7-2.5
			c-1.4-1.1-2.5-2.1-3.5-3c-0.4,0.3-1,0.8-1.9,1.6c-0.9,0.9-1.7,1.7-2.4,2.2c-0.2,0.3-0.6,0.8-1.2,1.4c0.6-0.5,0.5-0.5-0.3,0.2
			c-0.4,0-0.8-0.4-1.4-1.1c-0.5-0.7-1-1.5-1.5-2.5c0.3-0.1,0.8-0.3,1.5-0.6c2.5-0.9,4.1-1.6,4.8-2V85c-0.2,0-0.6,0.1-1.4,0.3
			C81.1,85.5,80,85.8,79,86c0-0.1-0.1-0.4-0.4-0.9c-0.2-0.3-0.3-0.6-0.4-0.8c0.2,0,1.1-0.1,2.8-0.2c0.2,0,0.5-0.1,0.8-0.2
			c0.6-0.1,1.2-0.2,1.7-0.3c0.2-0.2,0.5-0.7,1-1.4c0.2-0.4,0.4-0.7,0.6-0.9c0.4,0.4,1.1,1,2.1,1.7c0.6,0.4,1,0.9,1,1.4
			c0,0.3-0.4,0.7-1.1,1.1c0,2.8,0,5.3,0,7.6c0.1,0,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.3,0.3c0.3-0.3,0.7-1,1.2-2.2
			c0.6-1,1-2.3,1.2-3.9c0-0.4,0-1.1,0.1-2.2c0.1-0.3,0.1-0.6,0.1-0.8c-0.2,0-0.5,0-0.8,0c-0.6,0.1-1,0.2-1.2,0.2L88,83.5
			c1.5,0,2.8-0.1,3.9-0.2H93c0-0.1-0.1-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0-0.3-0.1-0.5s-0.1-0.4-0.1-0.5
			c-0.2-0.8-0.5-1.3-0.8-1.5s-0.4-0.4-0.3-0.5c0.1-0.2,0.3-0.3,0.7-0.3C93.7,79.7,94.8,80.3,95.1,81.3L95.1,81.3z M109.6,76.8
			c0,0.3-0.4,0.5-1.1,0.5c-0.6,0-1.7,0-3,0h-0.8c0.2,0.1,0.5,0.3,0.8,0.5c0.3,0.1,0.5,0.2,0.6,0.3c0.6,0.3,1,0.6,1,0.9
			c0,0.2-0.3,0.6-1,1.2c-0.1,1-0.4,2.6-0.8,4.7c-0.2,0.9-0.4,1.8-0.7,2.6c0.9,0.8,1.7,1.6,2.2,2.2c1.1,1.2,1.7,2.4,1.7,3.6
			c0,0.6-0.1,1.1-0.4,1.6c-0.5,0.4-1,0.6-1.5,0.6c-0.4,0-0.8-0.2-1.2-0.6c-0.1-0.2-0.4-0.8-0.8-1.7c-0.5-1.1-0.8-2-1.1-2.6
			c-0.9,1.4-2.1,2.7-3.5,3.9c-0.5,0.5-1.1,1.1-1.9,1.7c-0.2,0.1-0.3,0.2-0.4,0.2c-0.4,0.1-0.6,0.1-0.8,0c0-0.1,0.1-0.3,0.4-0.6
			c0.5-0.6,1.2-1.5,2.1-2.5c1.1-1.5,1.9-3.2,2.5-5c-0.8-1.1-1.7-2.1-2.6-2.8c-0.4-0.5-0.5-0.8-0.5-0.9c0.1-0.2,0.4-0.2,1,0.2
			c0.9,0.3,1.8,0.8,2.8,1.4c0.6-2.5,0.8-5.3,0.8-8.5l-1.4,0.2c-0.4,1-0.8,2-1.2,3c-0.1,0.2-0.2,0.5-0.4,0.8
			c-0.2,0.3-0.3,0.6-0.4,0.8c-0.4,0.4-0.6,0.6-0.8,0.5c-0.2-0.1-0.2-0.4,0-0.8c0.1-0.4,0.2-0.9,0.4-1.4c0.1-0.3,0.1-0.5,0.1-0.6
			c0.8-4.2,1.2-8.6,1.2-13.2c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.9,0.4,2.1,0.9c0.9,0.5,1.4,0.9,1.4,1.2c0,0.4-0.4,0.8-1.1,1.1
			c-0.1,0.9-0.4,2.3-1,4.2c-0.3,0.8-0.5,1.5-0.6,2l3.3-0.3c0.1-0.1,0.2-0.4,0.4-0.9c0.4-0.7,0.7-1.3,1-1.9c0.2,0.2,0.5,0.5,0.8,0.9
			c0.6,0.5,1,0.9,1.2,1.2C109.5,75.8,109.8,76.3,109.6,76.8L109.6,76.8z M99.1,72.2c0.1,0.1-0.3,0.4-1.1,0.9
			c0.2,1.8,0.3,3.7,0.3,5.9c0,0.6-0.5,1.2-1.5,1.7c-0.1,0-0.1,0-0.1,0c-0.5,0.2-0.8,0.3-1.1,0.2c0,0,0-0.2-0.1-0.5V79l-4.4,0.3
			c0,0.3-0.3,0.7-1,1.1c-0.1,0-0.1,0-0.1,0c-0.5,0.2-0.8,0.3-1.1,0.2c-0.2,0-0.3-0.2-0.3-0.5c0-0.1,0-0.4,0.1-0.8c0-0.4,0-1,0-1.9
			c0.1-1.7,0.1-3,0-4c0-0.7,0-1.9,0-3.4c0.1,0.1,0.5,0.3,1.1,0.6c0.6,0.4,1.2,0.7,1.7,0.9h0.4c0.1-0.6,0.2-1.8,0.4-3.6
			c0.1-0.8,0.2-1.5,0.3-1.9c0.2,0.1,0.6,0.3,1.2,0.6c0.4,0.2,0.7,0.4,1,0.5c0.5,0.1,0.7,0.3,0.7,0.5c0.4,0.3,0.6,0.5,0.6,0.6
			c0,0.3-0.4,0.6-1.2,0.9c-0.1,0.5-0.4,1.2-1,2.2c0.3,0,0.6-0.1,1.1-0.2c0.3,0,0.5,0,0.6,0c0.3-0.3,0.7-0.8,1.2-1.5
			c0.3,0.3,0.8,0.7,1.7,1.2C98.8,71.4,99.1,71.8,99.1,72.2L99.1,72.2z M86.6,75c0.2,0.6,0,1.2-0.4,1.9c-0.4,0.4-0.9,0.6-1.5,0.6
			s-1.1-0.3-1.4-0.8c-0.2-0.4-0.3-1.1-0.3-2c-0.2-0.8-0.4-1.6-0.7-2.3c-0.2-0.2-0.4-0.5-0.7-0.9c0,0,0-0.1-0.1-0.2
			c-0.1-0.2-0.1-0.4,0-0.5c0.1-0.2,0.3-0.2,0.6,0c0.2,0,0.5,0.1,0.8,0.2c0.3,0,0.5,0,0.5,0c0.8,0.3,1.4,0.6,1.7,0.9
			C86.1,72.7,86.6,73.7,86.6,75L86.6,75z M95.5,72.3c-0.5,0.1-1.2,0.2-2.2,0.2c-1,0.1-1.8,0.2-2.3,0.2v2.2c0.7-0.1,1.3-0.2,1.7-0.2
			c0.7,0,1.4-0.1,1.9-0.3c0.4,0.6,0.6,0.9,0.6,0.9c0,0.2-0.2,0.3-0.6,0.3c-0.4,0.1-1,0.2-1.8,0.2C92.1,75.9,91.5,76,91,76v2.3
			l4.4-0.3C95.3,75.1,95.4,73.2,95.5,72.3L95.5,72.3z"/>
				<path class="st21" d="M129.6,74.2c-0.2,0.2-0.4,0.5-0.7,0.9s-0.5,0.7-0.7,0.9c-1,1.1-1.9,2-2.8,2.6c-0.4,0.3-0.6,0.4-0.8,0.2
			c-0.2-0.1-0.1-0.4,0.1-0.8c2.5-4.4,3.9-8.2,4.3-11.6c0.5,0.2,1.4,0.7,2.9,1.5c1,0.6,1.5,1.1,1.4,1.4c0,0.3-0.5,0.6-1.5,0.8
			c-0.1,0.2-0.2,0.5-0.4,0.9c-0.4,0.7-0.7,1.3-1,1.7l9.1-0.6l1.4-2c0.2,0.2,0.5,0.5,0.8,0.9c0.6,0.6,1.2,1.1,1.5,1.5
			c0.6,0.7,1,1.2,1,1.6s-0.2,0.7-0.6,0.8c-0.1,0-0.3,0.1-0.6,0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0-0.4,0-0.7,0.2
			c-0.9,0.1-1.9,0.6-2.9,1.4c-0.4,0.3-0.7,0.5-1,0.5c-0.2-0.1-0.1-0.5,0.1-1.1c0.2-0.3,0.5-0.9,0.8-1.9c0.2-0.4,0.3-0.7,0.4-0.9
			l-5.7,0.3c0.8,0.3,1.5,0.5,1.9,0.6c0.7,0.3,1.1,0.6,1.1,0.9c0,0.4-0.3,0.8-1,1.2c-0.1,0.5,0,2.3,0.1,5.3c0,0.6,0,1.1,0,1.4
			c0.1,1.5,0,2.5-0.1,2.8c-0.2,0.7-0.6,1.3-1.1,1.9c-0.6,0.6-1.2,0.9-1.7,0.9c-0.1,0-0.4-0.4-1-1.1c-0.2-0.5-0.6-1-1.1-1.4
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.2-0.4-0.4-0.7-0.6c-0.4-0.2-0.5-0.4-0.5-0.6c0.2-0.1,0.3-0.2,0.4-0.2c0.1-0.2,0.3-0.2,0.6,0
			c0.4,0,0.6,0,0.7,0c0.7,0,1.2-0.1,1.2-0.3c0.3-0.1,0.4-0.6,0.3-1.5c0-3.6,0-6.6,0-9l-2.9,0.2v0.1H129.6z M126.3,70.3
			c0,0.3-0.5,0.6-1.5,0.8c-0.4,0.6-1,1.5-1.9,2.5v6.1c0,0.4,0,1.4,0,2.9c-0.1,1-0.1,2,0,2.8c0,0.8-0.2,1.5-0.7,2
			c-0.1,0.1-0.3,0.3-0.6,0.5c-0.3,0.3-0.5,0.5-0.7,0.6c-0.7,0.4-1.2,0.6-1.5,0.5c-0.2-0.1-0.3-0.4-0.3-0.8c0,0,0-0.1,0-0.2
			c0.1-0.3,0.1-0.6,0.1-0.9c0.2-0.7,0.3-1.7,0.3-2.9c0.2-2.2,0.3-4.9,0.4-8.1c-1.8,1.7-3.8,3-5.8,4c-0.5,0.2-0.8,0.2-1-0.2
			c0.1-0.2,0.3-0.5,0.6-0.8c2.7-2.3,5.2-5.3,7.5-9c0.6-0.9,1.1-2,1.7-3.3c0.7,0.6,1.5,1.2,2.3,1.9C126,69.5,126.4,70,126.3,70.3
			L126.3,70.3z M137.6,97.6c-0.1,0.8-0.5,1.4-1.2,1.9s-1.6,0.8-2.6,0.9c-1.4,0.4-2.9,0.6-4.4,0.6c-3,0-4.8-0.5-5.4-1.4
			c-0.1-0.1-0.1-0.4-0.1-0.9c-0.1-0.4-0.1-0.7-0.1-0.9v-5.3c0-0.9,0-2,0-3.3l2.2,0.9c1.1,0.4,1.7,0.9,1.7,1.4c0,0.3-0.3,0.6-0.8,0.9
			v4c0,0.3,0,0.6,0.1,0.9c0.2,0.2,1.1,0.3,2.6,0.3c1.7-0.2,2.9-0.5,3.6-0.9c0.6-0.3,1.2-1.1,1.8-2.3c0-0.1,0-0.2,0.1-0.3
			c0.1-0.2,0.3-0.9,0.6-2.2c0.3-0.6,0.5-0.9,0.6-0.9s0.1,0.2,0.1,0.5c0.1,0.2,0.1,0.4,0.1,0.5c0,0.6,0,1.4,0,2.3
			c0.1,0.7,0.3,1.4,0.6,2c0.1,0,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3C137.6,97.4,137.6,97.5,137.6,97.6
			L137.6,97.6z M143.6,94.8c0,0.7-0.2,1.3-0.7,1.7c-0.4,0.3-0.8,0.5-1.2,0.5c-0.6,0-1.2-0.3-1.5-0.9c0-0.1-0.1-0.3-0.3-0.6
			c-0.2-0.5-0.3-1-0.4-1.4c-0.5-1.2-1-2.1-1.5-2.6c-0.1-0.1-0.2-0.3-0.4-0.5c-0.2-0.1-0.3-0.2-0.4-0.3c0,0,0-0.1-0.1-0.2
			c-0.2-0.1-0.2-0.2-0.1-0.3c0.1-0.2,0.4-0.3,1-0.2c1.4,0.1,2.4,0.3,3,0.6c1.1,0.4,1.9,1.1,2.5,2C143.7,93.3,143.8,94,143.6,94.8
			L143.6,94.8z M121.2,92.6c0.2,2.3-0.1,4-1,5.3c-0.6,1-1.2,1.5-1.9,1.5c-0.7-0.1-1.2-0.6-1.5-1.4c0-0.2,0-0.4-0.1-0.6
			s-0.1-0.4-0.1-0.6c0.2-0.8,0.7-1.7,1.7-2.5c0.1-0.1,0.2-0.3,0.4-0.6c0.4-0.4,0.6-0.8,0.8-1.2c0.2-0.3,0.4-0.9,0.6-1.7
			c0-0.1,0-0.3,0.1-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.1-0.2C120.8,89.6,121.1,90.6,121.2,92.6L121.2,92.6z M143.4,82.6
			c0.2,0.7,0.1,1.4-0.1,2c-0.3,0.7-0.7,1.1-1.4,1.2c-1.3,0.3-2-0.4-2.2-2c-0.1-0.9-0.2-1.8-0.4-2.6c-0.4-0.8-0.9-1.6-1.5-2.3
			c-0.3-0.5-0.4-0.8-0.4-0.8c0.2-0.2,0.6-0.2,1.1,0c0.3,0.2,0.5,0.3,0.7,0.3c0.6,0.3,1.4,0.8,2.3,1.4c0.2,0.1,0.3,0.2,0.4,0.3
			C142.7,80.9,143.2,81.7,143.4,82.6L143.4,82.6z M130.2,78.4c0.2,1.6-0.1,3.2-0.8,4.8c-0.6,1.5-1.4,2.4-2.2,2.5
			c-0.8,0.1-1.4-0.2-1.8-0.9c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.5,0.1-1,0.3-1.4c0.2-0.2,0.5-0.4,0.8-0.6c0.6-0.4,1-0.6,1-0.6
			c0.6-0.4,1.1-0.9,1.2-1.4c0-0.1,0.1-0.3,0.3-0.5c0.2-0.4,0.4-0.8,0.6-1.2c0.2-0.3,0.3-0.5,0.4-0.5c0.2,0,0.3,0.2,0.3,0.5
			C130.1,78.2,130.2,78.3,130.2,78.4L130.2,78.4z M132.9,92c0,0.6-0.1,1.1-0.4,1.4c-0.4,0.4-0.8,0.6-1.2,0.6c-0.8,0-1.4-0.6-1.8-1.7
			c-0.1-0.5-0.2-1-0.4-1.4c-0.1-0.7-0.3-1.2-0.7-1.6c-0.1-0.3-0.3-0.6-0.7-0.8c-0.3-0.3-0.4-0.5-0.4-0.6c0-0.2,0.5-0.2,1.5,0
			c1.4,0.2,2.5,0.7,3.3,1.5C132.7,90.2,132.9,91,132.9,92z M174.1,95.7c-0.8,3.1-2,5.1-3.5,6.1c-0.1,0-0.2,0-0.3,0.2
			c-0.2,0.1-0.4,0.2-0.7,0.2s-0.5-0.2-0.8-0.6c-0.1-0.1-0.2-0.3-0.4-0.5c-0.1-0.3-0.2-0.5-0.3-0.6c-0.4-0.5-1.1-1.2-2.2-2
			c-0.3-0.2-0.6-0.5-1-0.8c-0.3-0.2-0.4-0.4-0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.3,0,0.6,0c0.2,0.1,0.3,0.2,0.4,0.2
			c0.1,0,0.3,0,0.7,0c0.5,0.1,1,0.2,1.4,0.2c0.6,0.1,1.2,0.1,1.5-0.2c0.3-0.3,0.6-0.9,1.1-1.9c0.1-0.3,0.2-0.9,0.4-1.9
			c0.1-0.5,0.2-0.9,0.3-1.1c0.6-4.5,1-7.8,1-9.6c-0.6,0-4.2,0.2-10.8,0.6c-1.5,0.1-2.4,0.2-2.8,0.2c-0.4,1-0.8,1.7-1.4,2
			c-1.6-0.9-2.5-1.8-2.9-2.5c0.3-0.8,0.7-1.4,1.2-1.7c0.7-4.8,1.2-7.9,1.4-9.5c0.3-2.1,0.4-3.9,0.4-5.6c0.2,0.1,0.5,0.3,1,0.5
			c0.7,0.4,1.3,0.7,1.8,0.9c1,0.4,1.5,0.9,1.5,1.4c0,0.3-0.4,0.7-1.2,1.2l-0.7,5c1,0,3-0.1,6.1-0.3c3.1-0.2,4.8-0.3,5.1-0.3l2.1-3.3
			c0.7,0.5,1.6,1.3,2.6,2.3c0.3,0.2,0.5,0.4,0.6,0.5c0.6,0.4,0.8,0.8,0.7,1.1c0,0.2-0.1,0.3-0.4,0.3c-0.3,0-0.6,0.1-0.8,0.2
			c-1.1,0-2.6,0-4.4,0.2c-2.6,0.1-6.4,0.3-11.6,0.5l-0.8,5.9c7.3-0.5,11.7-0.8,13.3-0.8c0.1-0.1,0.3-0.5,0.7-1.1
			c0.3-0.4,0.5-0.8,0.7-1.1c0.3,0.1,0.8,0.5,1.7,1.2c0.4,0.3,0.6,0.6,0.8,0.8c0.7,0.6,1.1,1.1,1,1.4c0,0.3-0.5,0.7-1.4,1.2
			C175.6,88.2,175,92.2,174.1,95.7L174.1,95.7z M171.5,89.7c0,0.2-0.5,0.3-1.5,0.3c-0.3,0-0.7,0-1.4,0c-1.2,0.1-2.2,0.2-2.9,0.2
			c-3.8,0.2-7.5,0.5-11.1,0.8c-1.6,0.1-3,0.3-4.4,0.6l-0.8-1.7c0.6,0,1.8-0.1,3.6-0.2c0.6-0.1,1.2-0.2,1.5-0.2
			c1.7-0.1,5.5-0.3,11.6-0.6c0.2-0.5,0.6-1.2,1.2-2.2c0.3-0.5,0.5-0.9,0.7-1.1c0.3,0.2,0.7,0.6,1.2,1.1c0.5,0.4,0.8,0.7,1.1,0.9
			c0.5,0.6,0.9,1,1,1.1C171.6,89.1,171.6,89.4,171.5,89.7L171.5,89.7z M213.1,98.2c-0.2,0.3-0.7,0.5-1.5,0.5c-2.1,0-3.4,0-3.9,0
			c-0.6,0-1.6,0.1-3,0.2c-5.1,0.2-9.3,0.4-12.7,0.6c-0.1,0-0.2,0-0.4,0c-0.8,0.1-1.9,0.3-3.2,0.6l-0.8-1.9c2,0,3.5,0,4.4,0l6.4-0.3
			c0-2.7,0-5.2,0-7.6c-1.1,0.1-2,0.2-2.6,0.2c-0.2,0-0.6,0-1.2,0.2c-1.1,0.1-1.9,0.2-2.4,0.3l-0.7-1.6c1.2,0,2.6,0,4.3,0l2.6-0.3
			v-3.7c0-0.2,0-0.5,0-0.9c-0.1-0.9-0.1-1.8-0.1-2.5c-0.4,0.1-0.8,0.2-1.2,0.2c-1.3,0-2.4,0.2-3.3,0.5l-0.6-1.2
			c-2.5,2.3-5.2,4.2-8.3,5.9c0,0,0,0-0.1,0c-0.5,0.2-0.8,0.2-0.8,0c0.1-0.3,0.3-0.6,0.7-0.9c6.3-4.9,10.6-10.7,13-17.5
			c0.2-0.3,0.5-1.2,1-2.8c0.2,0.1,0.7,0.4,1.5,0.9c0.6,0.3,1,0.5,1.2,0.6c0.8,0.6,1.2,1.1,1.2,1.4c-0.1,0.3-0.2,0.5-0.4,0.5
			c-0.4,0.1-0.7,0.3-1,0.5c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.4-0.1,0.5c3.9,4.7,8.8,7.7,14.8,9.2c1.1,0.3,1.6,0.6,1.5,0.9
			c0,0.2-0.5,0.5-1.7,0.8c-0.1,0-0.2,0.1-0.4,0.2c-0.5,0.1-1.1,0.5-1.9,1.1c-0.2,0.1-0.4,0.4-0.7,0.9c-0.3,0.2-0.5,0.3-0.8,0.3
			c-0.4,0.1-0.9-0.1-1.7-0.6c-3.2-2.2-6.4-6.2-9.5-11.9c-2,3.8-4.3,7-6.9,9.5c1.5,0.1,2.6,0.1,3.5,0c0.4,0,1,0,1.9,0
			c1.2-0.1,2.2-0.2,2.9-0.2l1.5-2.6c0.5,0.4,1.4,1.2,2.5,2.3c0.1,0.1,0.2,0.3,0.4,0.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2,0,0.3,0,0.3
			c-0.1,0.2-0.7,0.3-1.8,0.3c-0.3,0-1.2,0-2.6,0c-0.6,0.1-1.2,0.2-1.8,0.2c0.7,0.1,1.2,0.3,1.5,0.5c0.6,0.1,1,0.4,1,0.9
			c0.1,0.1,0,0.3-0.3,0.5c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.1-0.3,0.2-0.4,0.3V87c0,1.1,0,2,0,2.5l1.9-0.2l1.8-2.8
			c0.6,0.6,1.4,1.5,2.6,2.5c0.5,0.5,0.6,0.9,0.4,1.1c-0.1,0.3-0.6,0.5-1.5,0.5s-2,0-3.3,0c-0.9,0-1.6,0.1-1.9,0.2v7.6l5.7-0.3
			c0.5-0.6,1.2-1.7,2.1-3.3c0.2,0.2,0.5,0.5,1,0.9c0.8,0.7,1.5,1.3,2.1,1.9C213,97.5,213.2,97.9,213.1,98.2L213.1,98.2z M248.5,71.1
			c0,0.3-0.5,0.8-1.4,1.5c0,2.6,0,5.2,0.1,7.9c0.2,3.8,0.3,6.8,0.3,8.8c0,0.1,0,0.3,0,0.5c0.1,0.9,0.1,1.8,0.1,2.6
			c0.1,1.1,0.1,2.2,0.1,3.1c0,0.4,0,0.9-0.1,1.4c0,0.2,0,0.4,0,0.5c-0.5,1.2-1.2,2.3-2.3,3.1c-0.3,0.1-0.6,0.2-0.8,0.2
			c-0.4,0-0.6-0.2-0.7-0.6v-0.9c0-0.3,0-1,0.1-2c-7.4,0.3-13.1,0.6-17.3,0.9c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.1,0.2-0.1,0.3
			c-0.3,0.3-0.7,0.7-1.2,1.2c-0.7,0.4-1.2,0.6-1.5,0.6c-0.3-0.2-0.4-0.5-0.4-0.8c0,0,0-0.1,0-0.3c0.1-0.3,0.1-0.6,0.1-0.8
			c0.3-2.2,0.5-5.4,0.8-9.8c0-0.5,0-1.2,0.1-2.2c0.1-2.6,0.1-5.2,0.1-7.9c0-0.9,0-2.5-0.1-4.8c0-1-0.2-2.8-0.6-5.4
			c0.4,1.9,0.4,1.7,0-0.5c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0,0.9,0.3,2.1,0.9c0.5,0.1,1.1,0.4,1.5,0.9c3.5-0.2,8.6-0.5,15.3-0.9
			c0.1-0.1,0.6-0.8,1.4-2.2c0.2,0.1,0.5,0.4,1,0.8c0.8,0.5,1.5,1,2.1,1.4c0.3,0.3,0.5,0.5,0.6,0.6c0,0.1,0,0.2,0.1,0.2
			C248.5,70.6,248.5,70.8,248.5,71.1L248.5,71.1z M244.1,90.8c0-3.7,0-6.3,0-7.6c0-1.8,0-3.2-0.1-4.2c0-0.6,0-1.6,0-2.9
			c-0.1-2.2-0.1-3.9-0.1-5.1c-1.9,0.1-4.8,0.3-8.6,0.5c-0.6,0-1.6,0.1-3.2,0.3c-1.9,0.1-3.6,0.2-4.8,0.3v10.6c0,3.8,0,6.5,0,7.9
			c0,1.8,0,3.2-0.1,4.2c0,0.5,0,1.2-0.1,2.2c0.5,0,1.3,0,2.5-0.2c2.4-0.2,4.3-0.4,5.8-0.5c0.5,0,1.4-0.1,2.9-0.2
			c2.4-0.1,4.3-0.2,5.8-0.3C244,94.2,244.1,92.5,244.1,90.8L244.1,90.8z M242.7,91.9c-0.2,0-0.5,0-1,0c-0.9,0.1-1.7,0.2-2.3,0.2
			s-2.1,0.1-4.4,0.3c-1.6,0.1-2.7,0.2-3.5,0.2s-1.8,0.1-2.8,0.3c-0.1-0.4-0.4-0.9-0.8-1.5c1.5,0,2.6,0,3.5,0l2.1-0.2l-0.1-7.8
			c-0.4,0-0.9,0.1-1.7,0.2c-0.8,0-1.4,0.1-1.8,0.2c-0.3-0.5-0.6-1-0.8-1.4c1.7,0,2.5,0,2.6,0l1.7-0.2v-5.4c-1.6,0-3,0.1-4.4,0.3
			l-0.7-1.5c1,0,2.2,0,3.6,0c0.8,0,1.9-0.1,3.3-0.2c1.6,0,2.7-0.1,3.3-0.2c0.1-0.1,0.2-0.3,0.3-0.5s0.5-0.8,1.1-1.9
			c0.2,0.2,0.7,0.7,1.7,1.4c0.3,0.2,0.5,0.4,0.6,0.5c0.6,0.4,0.8,0.7,0.8,0.9c0,0.3-0.2,0.5-0.7,0.5c-0.6,0.1-1.8,0.2-3.3,0.2
			c-0.2,0.1-0.8,0.2-1.9,0.2c-0.8,0.1-1.5,0.2-1.9,0.2c0.6,0.1,1,0.3,1.2,0.5c0.6,0.2,0.8,0.5,0.8,0.8s-0.3,0.7-0.8,1.1v3.1h1.2
			c0.3-0.6,0.7-1.4,1.4-2.3c0.1,0.1,0.3,0.3,0.6,0.5c0.2,0.1,0.7,0.6,1.7,1.4c0.5,0.4,0.7,0.7,0.7,0.9c0,0.2-0.1,0.4-0.4,0.5
			c-0.3,0-0.6,0.1-1,0.2c-0.8,0-2.2,0-4,0v7.9l3-0.2c0.1-0.1,0.3-0.4,0.6-0.8c0.3-0.5,0.5-0.9,0.7-1.1c0,0,0,0-0.1,0
			c-0.2,0.1-0.3,0.2-0.4,0.2c-0.5-0.1-0.8-0.3-1.1-0.6c-0.1-0.2-0.2-0.7-0.4-1.6c-0.1-0.3-0.3-0.7-0.6-1.1c-0.2-0.2-0.4-0.5-0.7-0.8
			c-0.4-0.3-0.5-0.5-0.5-0.5c0-0.2,0.2-0.3,0.7-0.3c1.1,0,2.1,0.3,3,0.8c0.8,0.5,1.2,1.2,1.2,2.2c0,0.5-0.1,0.9-0.4,1.2
			c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0.1,0.1,0.2c0.4,0.1,1,0.6,1.8,1.6c0.5,0.3,0.7,0.7,0.7,1.1C243.7,91.8,243.4,91.9,242.7,91.9
			L242.7,91.9z M254.9,97.3l-1.4,0.2c-0.3,0-0.4-0.2-0.4-0.6c-0.1-0.3-0.1-0.6,0-0.8c0.1-0.1,0.4-0.2,1-0.2c0.7,0,1.2-0.1,1.5-0.3
			c0.6-0.2,0.9-0.5,1-0.9c0.1-0.5,0.1-1.2,0.1-2.2V77.3c0-1.1,0-1.7,0-1.5c0-0.3,0-0.5-0.1-0.5c-0.1-0.1-0.3-0.1-0.7,0.2
			c-0.3,0.1-0.5,0.2-0.7,0.3c-0.1,0.1-0.3,0.3-0.7,0.5c-0.3,0.1-0.6,0.3-0.8,0.5c-0.5,0.2-0.9,0.2-1-0.2c-0.2-0.3-0.1-0.7,0.1-1.2
			c0,0,0.9-0.5,2.8-1.4c0.6-0.3,1.4-0.7,2.4-1.1c0.9-0.5,1.5-0.8,1.7-0.9c0.3-0.1,0.5-0.2,0.6-0.2c0.3,0.1,0.4,0.3,0.4,0.6v4.8v15.4
			c0,1.7,0.2,2.6,0.6,3c0.1,0.2,0.5,0.3,1.2,0.3l0.8,0.2c0.2-0.1,0.4-0.1,0.7,0.2c0.2,0.2,0.2,0.5,0,0.8c0,0.4-0.1,0.6-0.3,0.6
			c-0.1,0-0.6,0-1.7,0c-0.8-0.1-1.9-0.2-3.3-0.2C258,97.3,256.7,97.3,254.9,97.3L254.9,97.3z M276.2,87.4l-1.9,2.2
			c-0.4,0.3-0.9,1-1.7,2c-0.4,0.5-0.6,0.9-0.8,1.1c-0.3,0.4-0.4,0.7-0.4,0.9c0.1,0,0.3,0,0.5,0c0.3,0.1,1.1,0.1,2.5,0h2.5
			c0.8,0.1,1.4,0.1,1.7-0.2c0.6,0,0.9-0.2,1-0.5c0.3-0.2,0.4-0.6,0.4-1.1c0.1-0.6,0.3-0.9,0.7-0.8c0.2,0,0.4,0.3,0.6,0.9
			c0,0.4,0,1.9-0.1,4.3c0,0.6-0.2,1-0.7,1.1c-0.6,0-1.3-0.1-2.3-0.2c-1,0-1.9,0-2.8,0c-0.3,0-1.2,0-2.8,0c-1.1,0-2.1,0-3,0
			c-0.2,0-0.5,0-0.8,0c-0.6,0.1-1,0.2-1.4,0.2c-0.4-0.1-0.6-0.4-0.6-0.8c0-0.2,0.1-0.5,0.4-0.9c1.1-1.1,2.7-3.2,4.7-6.1
			c0.7-0.8,1.7-2.2,2.9-4c1.4-2.4,2.1-4.6,2.1-6.5c0-1.6-0.3-2.7-1-3.6c-0.8-0.9-1.8-1.4-3-1.4c-0.8,0-1.6,0.3-2.2,0.9
			c-0.8,0.6-1.5,1.4-1.9,2.3c0,0,0,0.1,0,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c-0.3,0.3-0.5,0.5-0.6,0.5c-0.3,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.5c0-0.2,0.2-0.7,0.6-1.4c0.9-1.7,2.1-2.8,3.5-3.6c1.1-0.5,2.3-0.8,3.5-0.8c1.8,0,3.2,0.6,4.4,1.7
			c1.4,1.2,2,2.8,1.9,4.8c0,1.9-0.5,3.6-1.5,5.1C278.9,84.2,277.8,85.5,276.2,87.4L276.2,87.4z"/>
				<path class="st21" d="M314.1,95.1c-0.1,1.1-1,2-2.6,2.5c-0.8,0.3-1.9,0.5-3.3,0.6c-1.6,0.1-3,0.2-4.3,0.2c-3.1,0-5.2-0.2-6.1-0.6
			c-1.9-0.7-2.9-2-3-3.9c0.1-1.2,0.4-2.5,1-3.7c2.4-4.8,5.8-10,10.1-15.8c-0.8,0-2.2,0.1-4.1,0.3c-1.1,0.1-1.9,0.2-2.5,0.2
			c-0.1,0-0.3,0.1-0.6,0.2c-0.5,0-1.3,0.2-2.5,0.5c-0.4-0.5-0.6-1.1-0.8-1.9c0.5,0.1,1.6,0.1,3.2,0c2,0,4.4-0.2,7.2-0.5
			c0.1-0.2,0.5-0.6,1.1-1.2c0.3-0.3,0.5-0.6,0.7-0.8c0.9,0.8,1.7,1.7,2.3,2.6c0.6,0.5,0.8,1,0.8,1.6c0,0.3-0.7,0.7-2.1,1.1
			c-2.8,3.4-5.4,7.2-8,11.3c-1,1.8-1.6,3.1-1.8,3.9c-0.3,0.6-0.4,1.2-0.4,1.7c0,0.3,0,0.5,0,0.6c0.3,0.5,0.9,0.8,1.9,0.8
			c1,0.2,2.2,0.3,3.5,0.3c1,0,2.2-0.2,3.6-0.5c0.4-0.1,0.6-0.2,0.8-0.2c1.1-0.3,2-1,2.6-2.2c0.4-0.6,0.8-1.6,1.4-2.9
			c0.1,0,0.4-0.9,1-2.8c0,0,0-0.1,0.1-0.2c0.3-0.7,0.5-1,0.7-0.9c0.1,0,0.1,0.4,0.1,1.2c-0.1,0.5-0.2,1.3-0.4,2.5
			c-0.1,1.2-0.1,2.3-0.1,3.3c0,0.6,0.1,1.2,0.3,1.7C314,94.7,314.1,95,314.1,95.1L314.1,95.1z M295.6,70.6c0,0.3-0.4,0.7-1.2,1.1
			c-0.5,0.8-1.1,2-1.9,3.4c-0.2,0.2-0.3,0.4-0.4,0.5l0.1,16.5c0,2.5,0,3.9-0.1,4.3c0,1.1-0.2,2-0.7,2.6c-0.9,1.1-1.8,1.7-2.5,1.5
			c-0.2,0-0.3-0.2-0.3-0.5s0-0.6,0-0.9c0.5-6.2,0.7-12.7,0.7-19.6c-0.8,1-1.8,2-2.9,2.9c-0.8,0.7-1.9,1.5-3.3,2.3
			c-0.2,0.1-0.3,0.2-0.4,0.2s-0.2,0.1-0.4,0.2c-0.3,0.1-0.4,0.1-0.4,0c-0.1-0.2,0.1-0.6,0.5-1.1c0.3-0.3,0.7-0.8,1.4-1.6
			c0.6-0.6,1-1.1,1.2-1.4c2.5-3.1,4.2-6.1,5.2-9c0.1-0.3,0.2-0.7,0.4-1.2c0.6-1.4,1-2.5,1.1-3.3c0.7,0.3,1.6,0.8,2.5,1.6
			C295.1,69.8,295.6,70.3,295.6,70.6L295.6,70.6z M340.9,75.4c0,0.3-0.3,0.7-1,1.1c0,4.9-0.3,8.4-0.8,10.7c0.4,0.1,0.9,0.3,1.5,0.5
			c0.1,0.1,0.2,0.2,0.3,0.2c0.9,0.3,1.4,0.7,1.4,1.1c0,0.3-0.3,0.6-0.8,0.9V96c0,0.3,0.1,0.6,0.3,0.8c0.1,0.1,0.4,0.2,1,0.2
			c1.1,0,1.9,0,2.3-0.2c0.6-0.2,1.1-0.7,1.4-1.5c0,0,0-0.1,0-0.2c0.6-1.3,1-3.4,1.4-6.1c0-0.2,0-0.6,0.1-1.1
			c0.1-0.2,0.1-0.4,0.1-0.6c0.2-0.6,0.3-0.9,0.4-0.9c0.2,0,0.3,0.2,0.3,0.6c0.1,0.2,0.1,0.4,0.1,0.5c-0.1,0.3-0.1,0.8-0.1,1.5
			c0,0.5,0,1.6,0,3.1c0.1,1.7,0.3,2.8,0.7,3.4c0,0,0.2,0.2,0.7,0.6c0.3,0.3,0.4,0.6,0.4,0.8c0.1,0.6-0.1,1.2-0.7,1.7
			c-0.6,0.9-2,1.5-4.1,1.6c-1.8,0.2-3.1,0.2-4.1,0c-0.6,0-1.2-0.1-1.8-0.2c-0.6-0.2-1.1-0.8-1.2-1.7c0-0.1,0-0.3,0-0.5
			c-0.1-0.7-0.1-1.3-0.1-1.9V92c0-0.5,0-1.1,0-1.9c-1.6,5.2-5.8,8.9-12.6,11.2c-0.7,0.2-1.2,0.2-1.2,0c-0.1-0.2,0.2-0.5,0.8-0.9
			c4.8-2.7,7.9-5.6,9.4-8.7c1.5-2.9,2.1-7.7,1.9-14.4c0-0.9,0-2.3-0.1-4c1.5,0.5,2.5,0.9,3,1.1C340.5,74.7,340.9,75.1,340.9,75.4
			L340.9,75.4z M342.2,87.1v-1.2c0.2-2.9,0.2-5.8,0-8.7c0-3.6,0-5.7,0-6.4c-1.1,0.1-3.6,0.3-7.5,0.5v14.9l-0.1,1.2
			c-0.1,0.6-0.4,1.1-1,1.4c-0.7,0.6-1.3,0.8-1.8,0.6c-0.2-0.1-0.3-0.3-0.3-0.6c0.2-1.3,0.3-2.8,0.3-4.4c0.1-2.2,0.1-4,0.1-5.4
			c0-2.9-0.1-5.7-0.3-8.5c0-0.3,0-0.8-0.1-1.4c0-0.5,0-0.9,0-1.1c0.3,0.1,0.7,0.3,1.4,0.6c0.3,0.1,0.5,0.2,0.7,0.3
			c0.2,0.1,0.5,0.3,0.8,0.5c0.4,0.3,0.7,0.5,1,0.6c1.9-0.2,4.1-0.3,6.5-0.3l1.4-2c0.5,0.3,1.2,0.9,2.1,1.9c0.8,0.7,1.2,1.2,1.2,1.6
			c0,0.3-0.2,0.6-0.7,0.9c-0.1,0.1-0.3,0.3-0.6,0.5v3.3c0,2,0,4.7,0.1,8.2c0,1.1,0,1.9,0,2.2c-0.2,0.6-0.6,1.2-1.4,1.7
			s-1.4,0.6-1.8,0.3C342.2,88,342.2,87.7,342.2,87.1L342.2,87.1z M329.9,88c0.8,1.8,0.8,3.3-0.1,4.5c-0.3,0.3-0.6,0.5-1.1,0.5
			c-0.7,0-1.2-0.5-1.4-1.4c-0.1-0.7-0.3-1.6-0.7-2.6c0-0.1,0-0.2-0.1-0.3c0-0.3-0.1-0.7-0.4-1.2c-1.8,3.4-4.3,6.3-7.7,8.5
			c-0.5,0.3-0.7,0.4-0.8,0.2c0-0.2,0.2-0.5,0.5-0.9c3.3-3.7,5.6-7.3,6.8-10.7c-1.3-2.5-2.9-4.9-4.8-7.1c-0.2-0.3-0.2-0.6-0.1-0.8
			c0.1-0.1,0.3-0.1,0.6,0.2c1.8,1.2,3.6,2.9,5.2,5c0.6-2.5,1-5.4,1.2-8.7c-0.4,0-1.3,0.1-2.8,0.2c-0.6,0.1-1.2,0.2-1.5,0.2
			c-1,0.1-2.1,0.3-3.2,0.5l-0.7-1.7c1.1,0,2.5,0,4.2,0c0.4-0.1,1-0.2,1.9-0.2c0.8-0.1,1.5-0.2,1.9-0.2c0-0.1,0.1-0.3,0.3-0.6
			c0.4-0.5,0.6-1,0.8-1.4c1.5,1,2.4,1.8,2.9,2.3c0.4,0.3,0.5,0.6,0.5,0.9c0,0.4-0.4,0.9-1.2,1.4c-0.7,4.1-1.6,7.4-2.5,9.8
			c0.6,0.6,1.2,1.7,1.9,3.1C329.6,87.5,329.8,87.8,329.9,88L329.9,88z M355.2,101c-0.8,0.4-1.3,0.5-1.4,0.3c0-0.2,0.3-0.6,1-1.2
			c3.6-3.1,5.6-6.7,6.1-10.7c0.4-2.3,0.4-4.9,0.1-7.9c-1.8,1.2-3.8,2.3-6.2,3.1c-0.6,0.3-1.1,0.4-1.2,0.2c0-0.3,0.3-0.6,0.8-0.9
			c4-2.8,7.1-5.7,9.3-8.8c1.1-1.6,2.1-3.4,2.9-5.4c0.5-1.1,0.8-2.2,1-3.1c1,0.4,2,1,2.9,1.9c0.8,0.5,1.2,0.9,1.1,1.2
			c-0.1,0.4-0.5,0.8-1.2,1.1c3.1,4.7,7.6,7.5,13.5,8.7l1,0.2c0.4,0.1,0.5,0.3,0.5,0.5c0,0.1-0.2,0.3-0.5,0.5s-0.6,0.3-1,0.3
			c-0.8,0.2-1.8,0.9-2.9,2.2c-0.1,0.1-0.4,0.3-0.8,0.5c-0.3,0-0.6-0.1-1.1-0.3c-0.1-0.1-0.3-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.5
			c-3.1-2-5.9-5.5-8.3-10.7c-2.1,4.1-4.7,7.3-7.7,9.5c0.1,0,0.3,0.1,0.7,0.3c0.5,0.2,0.9,0.4,1.2,0.5c0.7,0.3,1.2,0.5,1.2,0.5
			c0.4,0.2,0.6,0.4,0.6,0.6c0,0.4-0.4,0.9-1.1,1.4c0,0.4,0,1.1-0.1,2c0,0.9,0,1.6-0.1,2c0.9,0.3,1.7,0.6,2.4,0.9
			c1.1,0.5,1.8,1.1,2.2,1.7c1.2,2.1,0.9,3.5-1,4.4c-0.6,0.1-1.2-0.5-1.7-1.7c-0.6-1.9-1.2-3.3-2.1-4.3
			C363.4,94.6,360.4,98.5,355.2,101L355.2,101z M384.9,97.9c0,0.3-0.2,0.5-0.7,0.6c-1,0.3-1.9,0.7-2.6,1.2c0,0-0.2,0.3-0.7,0.8
			c-0.3,0.3-0.5,0.5-0.7,0.5c-0.6,0-1.4-0.7-2.2-2.2c-1.6-2.3-2.6-5.9-3.2-10.9l-0.1,1.5c-0.6,6-3.9,9.9-10,11.6
			c-0.6,0.1-0.9,0.1-1,0c0-0.2,0.2-0.5,0.7-0.8c3.8-2.3,6.1-5.1,6.9-8.5c0.5-2,0.7-4.2,0.7-6.7c0-0.3,0-0.9-0.1-1.7
			c-0.1-1.6-0.2-2.6-0.3-3.3c0.3,0.1,0.6,0.2,1.1,0.3c0.9,0.3,1.7,0.5,2.2,0.6c0.9,0.4,1.4,0.8,1.4,1.1c0,0.3-0.4,0.7-1.2,1.2v1.9
			c1.2,5.8,4.3,9.8,9.3,12.1C384.7,97.6,384.9,97.7,384.9,97.9L384.9,97.9z M420.1,82.7c0,0.5-0.4,0.8-1.2,0.8
			c-2.6-0.1-4.1-0.2-4.7-0.2c-2.3,0-5.7,0.2-10.2,0.5c-4,0.2-7.4,0.5-10.2,0.8c-1.1,0.1-2.3,0.3-3.5,0.5c-0.1-0.3-0.3-0.7-0.6-1.2
			c-0.2-0.5-0.4-0.9-0.5-1.1c1.8,0.1,5.6,0,11.3-0.3c1.5-0.1,2.6-0.2,3.5-0.2c1.9-0.2,4.9-0.4,8.8-0.5l2.1-4.3
			c0.1,0.1,0.2,0.3,0.4,0.5c0.3,0.1,1.5,1.1,3.6,2.9C419.7,81.6,420.1,82.2,420.1,82.7L420.1,82.7z M457.2,95.9
			c0,0.2-0.2,0.5-0.7,0.8c-1.1,0.8-2.2,2-3.3,3.4c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.3,0.2-0.7,0.2
			c-0.5,0-1.3-0.1-2.5-0.3c0.8,0.2,0.7,0.2-0.4,0c-7.8-2.1-14.2-5.4-19.1-10.1c-0.7,1.7-1.6,3.1-2.6,4.3c-0.2,0.1-0.4,0.3-0.5,0.6
			c-0.3,0.2-0.7,0.6-1.4,1.1c-0.5,0.4-0.8,0.6-1,0.6c-0.1,0-0.1,0-0.1,0c-0.1-0.1,0-0.5,0.4-1.1c0.6-0.7,1-1.6,1.4-2.5
			c0.9-1.9,1.6-3.7,1.9-5.6c0.4-1.6,0.7-3.4,1-5.4c0.3,0.2,0.6,0.5,1.1,0.8c0.6,0.3,0.9,0.6,1.1,0.8c0.9,0.6,1.4,1.1,1.4,1.4
			s-0.4,0.6-1.1,0.9c-0.1,0.3-0.3,0.8-0.6,1.6c-0.2,0.6-0.3,1-0.4,1.2c0.9,0.7,1.8,1.4,2.6,2v-2.9c0-1.1,0-3.8-0.1-7.9
			c-1.8,0.1-3.4,0.3-4.7,0.5c-0.3,0-0.8,0.1-1.7,0.2c-0.6,0.1-1,0.2-1.2,0.2l-0.5-1.7c0.5,0,1,0,1.7,0c0.9,0.1,1.5,0.1,1.7,0
			c0.5-0.1,1.2-0.2,2.3-0.2c1.1-0.1,1.9-0.2,2.5-0.2c0-1.8,0-3.3,0-4.7c-0.5,0.1-1.2,0.2-1.8,0.2c-0.2,0-0.5,0.1-1,0.2
			c-0.7,0.1-1.4,0.2-1.9,0.3c-0.1-0.2-0.2-0.5-0.3-0.9c-0.2-0.3-0.3-0.6-0.4-0.8c1.5,0,2.6,0,3.5,0h1.9v-2.6c0-0.2,0-1.8-0.1-4.8
			c0.2,0.1,0.6,0.3,1.1,0.5c0.6,0.2,1,0.4,1.4,0.5c1.1,0.3,1.7,0.7,1.7,1.1c0,0.2-0.3,0.6-1,1.2v4c0.3,0,0.6-0.1,0.8-0.2
			c0.1-0.2,0.3-0.5,0.5-0.9c0.4-0.6,0.6-1.1,0.8-1.4c0.2,0.2,0.6,0.6,1.4,1.2c0.3,0.1,0.5,0.2,0.6,0.3c0.6,0.6,0.8,1.1,0.8,1.4
			c-0.1,0.3-0.6,0.5-1.4,0.5c-0.5,0-1.2,0.1-2.1,0.2h-1.5v4.7l2.1-0.2c0.1-0.1,0.3-0.4,0.5-0.9c0.4-0.5,0.6-1,0.8-1.4
			c0.2,0.1,0.5,0.4,0.8,0.8c0.6,0.5,1.2,1,1.7,1.4c0-0.6,0-1.2,0-1.9c0.2,0.1,0.5,0.3,1,0.6c0.6,0.2,1,0.4,1.4,0.6
			c0.5,0.1,1,0.4,1.5,0.9l1.9-0.2c0.2-1.7,0.3-4,0.4-7.1c-0.3,0.1-0.9,0.2-1.8,0.2c-0.7,0.1-1.3,0.2-1.8,0.2
			c-0.2,0.1-0.6,0.2-1.4,0.2c-0.6,0.1-1,0.2-1.4,0.2l-0.8-1.6c1.1,0,2.1-0.1,3-0.2c1.5,0,2.8-0.1,4-0.3l1.4-2
			c0.2,0.1,0.5,0.4,0.8,0.8c0.6,0.4,1,0.8,1.2,1.1c0.8,0.7,1.2,1.3,1.2,1.9c0,0.3-0.4,0.7-1.2,1.2l-0.4,4.8l0.1,0.3
			c0.6,0.4,0.8,0.7,0.8,0.9c0,0.3-0.4,0.5-1.2,0.6c-0.5,0-1.2,0.1-2.4,0.2c-1.2,0-2.3,0-3.3,0.2v5.6c0,0,0,0.2-0.1,0.6V88
			c0,0.7,0.1,1.1,0.3,1.2c0.3,0.3,0.9,0.5,1.8,0.5s1.8,0,2.8-0.2c0.7,0,1.3-0.2,1.7-0.5c0.5-0.3,0.8-0.9,1.1-1.7
			c0.1-0.3,0.2-0.8,0.4-1.4c0.1-0.4,0.2-0.7,0.3-0.9c0-0.3,0.1-0.9,0.4-1.9c0.1-0.2,0.1-0.4,0.1-0.5c0.2-0.6,0.3-0.9,0.4-0.9
			c0.2,0,0.3,0.3,0.3,0.9c0,0.3,0,0.7,0,1.2V85c0,1.7,0.1,2.6,0.3,2.8c0.3,0.4,0.6,0.9,1.1,1.4c0.1,0.8-0.4,1.5-1.4,2
			c-0.6,0.4-1.4,0.7-2.4,0.8c-2.1,0.4-3.6,0.6-4.6,0.5c-0.7,0-1.5-0.1-2.4-0.2c-0.5,0-0.7,0-0.8,0c-1-0.3-1.7-0.7-1.9-1.2
			c-0.3-0.3-0.4-0.8-0.4-1.4v-8.4c0-0.3,0-0.6,0-0.9c-0.1,0-0.4,0-0.8,0l-3.5,0.2l-3.2,0.2c0.5,0.1,1,0.3,1.2,0.5
			c0.6,0.3,1,0.5,1.1,0.6c0,0.3-0.1,0.6-0.3,0.8c-0.3,0.1-0.5,0.3-0.6,0.6v2.5c0.3,0,0.5,0,0.7,0c0.1-0.1,0.2-0.3,0.3-0.5
			c0.4-0.7,0.7-1.3,1-1.7c0.1,0.1,0.3,0.3,0.7,0.6c0.5,0.4,0.8,0.8,1.1,1.1c0.6,0.3,0.8,0.7,0.8,1.1c0,0.3-0.4,0.5-1.2,0.5l-3.3,0.2
			l-0.1,6.1c1.9,0.7,4.1,1.3,6.5,1.9c3,0.5,5.9,0.8,8.4,0.8c0.7,0,1.7,0,2.9,0c0.6,0,1.3,0,2.1-0.2c0.4,0,0.6,0,0.8,0
			C457,95.6,457.2,95.7,457.2,95.9L457.2,95.9z"/>
	</g>
</svg>



	</div>
</template>

<script>
    export default {
        name: 'Poster1',
        methods:{
        },
        mounted() {

        },
        created() {
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.shadow{fill:rgba(114,1,5,0.1); filter:url(#shadow);}
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#900105;}
	.st1{fill:none;}
	.st2{fill:#FFFFFF;}
	.st3{clip-path:url(#SVGID_2_);}
	.st4{clip-path:url(#SVGID_4_);}
	.st5{clip-path:url(#SVGID_6_);}
	.st6{clip-path:url(#SVGID_8_);}
	.st7{clip-path:url(#SVGID_10_);}
	.st8{clip-path:url(#SVGID_12_);}
	.st9{clip-path:url(#SVGID_14_);}
	.st10{clip-path:url(#SVGID_16_);}
	.st11{clip-path:url(#SVGID_18_);}
	.st12{clip-path:url(#SVGID_20_);}
	.st13{clip-path:url(#SVGID_22_);}
	.st14{clip-path:url(#SVGID_24_);}
	.st15{clip-path:url(#SVGID_26_);}
	.st16{clip-path:url(#SVGID_28_);}
	.st17{clip-path:url(#SVGID_30_);}
	.st18{clip-path:url(#SVGID_32_);}
	.st19{clip-path:url(#SVGID_34_);}
	.st20{clip-path:url(#SVGID_36_);}
	.st21{fill:#900105;}
	.st22{fill:#FEFEFE;}

</style>
