<template>
    <div>
        <div class="scrollView" style="height: 100vh" >
            <div ref="contentView"  class="contentView">
                <div>
                    <svg style='enable-background:new 0 0 768 120;' version="1.1" viewBox='0 0 768 120'
                         x= "0px" xml:space="preserve"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                        <image xlink:href="../assets/cctv9.png" x= "43" y="57" width="127" height="29"></image>
                        <image xlink:href="../assets/logo_lq.png" x= "198" y="39" width="75" height="65"></image>
                        <image xlink:href="../assets/logo_wp.png" x= "650" y="38" width="75" height="68"></image>
                    </svg>
                </div>
                <First class="first" />

                <p style="font-weight: bold;padding-bottom: 0;margin-block-end: 0">执良庆之手，迎新年之姿。</p>
                <p style="padding-top: 0"> 于2021年元旦之际，由12个声音故事串联而成的《听见幸福良庆》声音纪录片，在CCTV-9央视纪录频道首播。全国12亿观众朋友们聚焦幸福良庆，一起听见幸福良庆。 </p>
                <div class="video" style="position: relative">
                    <video controls="controls" poster="../assets/vbg.png" style="width: 100%;">
                        <source src="../assets/lq.mp4" type="video/mp4"/>
                        你的浏览器不支持H5播放器
                    </video>
                </div>
                <p>
                    在广西壮族自治区南宁市良庆区，侧耳就能听到广西自贸区发展的潮涌声、学校里朗朗的读书声、山水间悠扬的嘹啰山歌声……这些幸福的声音，都离不开良庆在全面决胜小康、决战脱贫攻坚道路上艰苦卓绝的努力。这些声音，是良庆建区十五年高歌逐梦，人民奔向美好生活的幸福回响。</p>

                <el-carousel style="margin: 5vw" :height='cardHeight + "px"' @change="carouselChange" indicator-position="none">
                    <el-carousel-item v-for="(url,index) in images" :key="index*400">
                        <img :src="url" width="100%" alt="" />
                    </el-carousel-item>
                </el-carousel>
                <div class="tips">
                    <div style="width: 100%;height: 10px; display: flex;justify-content: center;">
                        <div v-for="(image,i) in images" :class="carouselIndex === i ? 'indicator --dactive':'indicator'"></div>
                    </div>
                    <span style="margin-right: 0">*视频截图</span>
                </div>
                <p style="padding-bottom: 0px">
                    这样的幸福声音还有很多，稍不注意就会在耳边消逝。当地摄制组历时35天拍摄制作，来到熙攘奋进的高楼里、秀美壮丽的山水间、载歌载舞的人群中，收集12种来自良庆的幸福声音。通过这些声音，将良庆浑然天成的美好生活方式和人民收获的幸福生活传递出去，在新年的第一天与全国观众朋友们一同分享。</p>

                <svg  version="1.1" style="margin-left: 10vw;margin-right: 10vw;margin-top: 5vw;margin-bottom: 0"  viewBox='0 0 454 140'
                      x= "0px" xml:space="preserve"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                    <g>
                        <g v-for="i in 5" :key="i">
                            <polygon :points='(37-i*7)+","+70+" "+37+","+(70-i*7)+" "+(37+i*7)+","+70+" "+37+","+(70+i*7)+" "' stroke-width="3" stroke="rgba(144,1,5,1)" fill="rgba(0,0,0,0)">
                            </polygon>
                            <polygon :points='(418-i*7)+","+70+" "+418+","+(70-i*7)+" "+(418+i*7)+","+70+" "+418+","+(70+i*7)+" "' stroke-width="3" stroke="rgba(144,1,5,1)" fill="rgba(0,0,0,0)">
                            </polygon>
                        </g>
                        <text x="227" y="80" style="letter-spacing: 8px;" text-anchor="middle"  font-size="35px"  fill="rgba(144,1,5,1)">
                            精彩倒计时海报
                        </text>
                    </g>
                </svg>
                <div class="poster" v-for="(url,i) in poster" :key="i+'poster'">
                    <Poster v-if="i === 0" :posterIndex="i" style="width: 76vw;height: 122vw" />
                    <img v-else :src="url"  style="width: 76vw;height: 122vw" />
                </div>
                <svg  version="1.1" style="margin-left: 10vw;margin-right: 10vw;margin-top: 5vw;overflow: inherit;"  viewBox='0 0 768 192'
                      x= "0px" xml:space="preserve"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                            <g>
                                <line x1="0" y1="96" x2="75" y2="96" stroke-width="4" stroke="rgba(144,1,5,1)"></line>
                                <line x1="693" y1="96" x2="768" y2="96" stroke-width="4" stroke="rgba(144,1,5,1)"></line>
                                <g v-for="j in (768/96 - 2)" :key="j*1000">
                                   <g v-for="i in 48/12" :key="i">
                                    <circle :r="i*12" cy="96" :cx="(j)*96+48" stroke-width="4" stroke-opacity="1" stroke="rgba(144,1,5,1)" fill="rgba(0,0,0,0)">
                                           </circle>
                                   </g>
                                </g>
                            </g>
                    </svg>
                <p style="padding-top: 0px">你认为幸福的声音是什么样的?不妨在2021年1月3日-1月10日，亲临南宁市良庆区大沙田滨江公园的良庆幸福声音博物馆，一听究竟。现场还可以参与线下互动，更多创新玩法等你探索。</p>
                <img class="video" src="../assets/bwg.jpg" alt="" />
                <img style="width: 90%;margin-top: 20px" src="../assets/lqbottom.jpg" />
                <p>12种幸福声音，12个幸福声音独立展位，让你可以用耳朵感受良庆的幸福回响；同时，在留声亭用自己的声音、照片定成一张独一无二的明信片，把幸福的声音传递给家人朋友；手写幸福展区将提供情怀满分的黑胶唱片，等着你手写幸福，还有更多隐藏打卡点等待着你。这座好听的、好玩的、好看的博物馆，将带你用另一种角度拥抱良庆，将良庆动听的故事延续下去。</p>
                <img src="../assets/poster4.jpg" style="width: 90vw;margin: 5vw;" alt="" />
            </div>
        </div>
    </div>

</template>

<script>
    import Poster from "@/components/Poster1"
    import First from "@/components/First"
    import division from "@/components/division"
    export default {
        name: 'Poster1',
        components:{
            Poster,
            First,
            division
        },
        data() {
            return {
                cShow:false,
                bh:1280,
                height:0,
                bw:768,
                cardHeight:320,
                carouselIndex:0,
                cur:0,
                images:[require("../assets/clip/1.png"),require("../assets/clip/2.png"),require("../assets/clip/3.png"),require("../assets/clip/4.png"),require("../assets/clip/5.png"),require("../assets/clip/6.png"),require("../assets/clip/7.png")],
                poster:[require("../assets/poster2.jpg"),require("../assets/poster2.jpg"),require("../assets/poster1.jpg")],
            }
        },
        methods:{
            reSetHeight(){
                 this.cardHeight = ( document.documentElement.clientWidth *428/768);
            },
            carouselChange(index){
                this.carouselIndex = index;
            }
        },
        mounted() {

        },
        created() {
            this.reSetHeight()
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .first{
        padding-top: 35px;
        @media screen and (max-width: 320px) {
            padding-top: 35px;
        }
        @media screen and (min-width: 450px) {
            padding-top: 35px;
        }
        @media screen and (min-width: 550px) {
            padding-top: 50px;
        }
        @media screen and (min-width: 768px) {
            padding-top: 73px;
        }


    }
    .contentView {
        position: relative;
        width: 100%;
        background: url("../assets/newbg.png");
        p{
            text-indent: 2em;
            padding: 50px;
            font-size: 16px;
            line-height: 2;
            text-align: justify;
            margin-block-end: 0;
            @media screen and (max-width: 320px) {
                font-size: 14px;
                padding: 25px;
            }
            @media screen and (min-width: 450px) {
                font-size: 20px;
            }
            @media screen and (min-width: 550px) {
                font-size: 25px;
            }
            @media screen and (min-width: 768px) {
                font-size: 35px;
            }
        }
    }
    .view{
        width: 100vw;
        position: relative
    }
    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        transform: translate(-50%,-50%);
    }
    .video {
        width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;
    }
    .poster{
        padding-top: 30px;
        position: relative;
        font-size: 0;
        svg {
            transform: translateY(0)!important;
        }
    }
    .tips{

        div{
            padding-top: 0
        }
        margin-right: 5vw;
        margin-left: 5vw;
        text-align: right;
        font-size: 7px;
        margin-top: -20px;
        @media screen and (min-width: 450px) {
            font-size: 15px;
        }
        @media screen and (min-width: 550px) {
            font-size: 16px;
        }
        @media screen and (min-width: 768px) {
            margin-top: -30px;
            font-size: 18px;
        }
    }
    .tipsImg{
        width: 16px;
        @media screen and (max-width: 320px) {
            width: 14px;
        }
        @media screen and (min-width: 450px) {
            width: 20px;
        }
        @media screen and (min-width: 550px) {
            width: 25px;
        }
        @media screen and (min-width: 768px) {
            width: 30px;
        }
    }
    .title{
        color: #4BA7E0;
        margin-bottom: 5px;
        font-size: 9px;

        @media screen and (min-width: 768px) {
            font-size: 22px;
        }
    }

    .scrollView {
        width:100vw;
        overflow: auto;
        overflow-x: hidden;
    }
    .scrollView::-webkit-scrollbar{
         display: none;
     }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .indicator{
        z-index: 100;

        margin: 5px;
        @media screen and (max-width: 320px) {
            margin: 6px 3px 0 3px;
        }
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: gray;
        opacity: 0.5;
    }
    .--dactive{
        background: #900105;
        opacity: 1;

    }
</style>
