<template>
  <div class="home">
    <Poster />
    <div v-if="loading" class="loading">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48px" height="60px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50" xml:space="preserve">
        <rect x="0" y="7.6416" width="4" height="14.7168" fill="#FF6700" opacity="0.2">
            <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
        </rect>
            <rect x="8" y="5.1416" width="4" height="19.7168" fill="#FF6700" opacity="0.2">
            <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
        </rect>
            <rect x="16" y="7.3584" width="4" height="15.2832" fill="#FF6700" opacity="0.2">
            <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
        </rect>
    </svg>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Poster from '@/components/Poster1.vue'
import Poster from '@/components/Detail2.vue'

export default {
  name: 'Home',
  components: {
    Poster
  },
  data() {
        return {
          loading: false,
          show: true,
          count:0,
          op:1,
        }
  },
  methods:{
    waitLoad(func){
        if (this.count === 0){
            let self = this
            self.loading = false;
            self.op = 1;
            setTimeout(()=>{
                func();
            },400);
        }
        this.count++;
        console.log(this.count);
    },

// 监听微信分享
      wxShare(config){
          let self=this;
          //that.$wx 必须执行过步骤一的操作
          let wx=self.$wx;
          // 获取当前域名地址
          let href=window.location.href;
          // 配置签名wx.config属性
          wx.config(config);
          // 发生错误触发
          wx.error(function(res){
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
          // 接口校验成功触发
          wx.ready(function(){
              // 判断当前客户端版本是否支持指定JS接口
              wx.checkJsApi({
                  // 需要检测的JS接口列表
                  jsApiList: [
                      "updateAppMessageShareData",
                      "updateTimelineShareData",
                      "onMenuShareTimeline",
                      "onMenuShareAppMessage"
                  ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                  success: function(res) {
                      console.log("检测接口是否可用=================",res);
                  },
                  fail(err){
                      console.log("检测接口是否错误=================",err);
                  }
              });
              // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
              //需在用户可能点击分享按钮前就先调用(自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）)
              wx.updateTimelineShareData({
                  title:"2021年1月1日央视关注广西良庆" , // 分享标题
                  link: href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: 'https://cctvgx.cpchar.com/logo_lq.png', // 分享图标,必须是https网络路径,不能大于20kb
                  success: function () {
                      console.log('ok')
                  }
              })
              // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
              wx.updateAppMessageShareData({
                  title: "2021年1月1日央视关注广西良庆", // 分享标题
                  desc: "执良庆之手，迎新年之姿。\n" +
                      "于2021年元旦之际，由12个声音故事串联而成的《听见幸福良庆》声音纪录片，在CCTV-9央视纪录频道首播。全国12亿观众朋友们聚焦幸福良庆，一起听见幸福良庆" , // 分享描述
                  link:href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: 'https://cctvgx.cpchar.com/logo_lq.png' , // 分享图标，必须是https网络路径,不能大于20kb
                  success: function () {
                      // 设置成功
                      console.log('ok2')
                  }
              })
          });
      }
  },created(){
        let self =this;
        this.$network.callapi('/getWxConfig',{url:window.location.href},(isOK,data)=>{
            if (isOK) {
                self.wxShare(data.data.config)
            }
        })

    },
    mounted() {
      // setTimeout(()=>{
          this.show = true
      // },500);
    }
}
</script>
<style scoped lang="scss">
.loading {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  background: white;
}
</style>
