<template>
    <div class="area">
        <div :style='"width: "+bw+"px;height:"+bh+"px"'>
            <svg :style='"enable-background:new 0 0 "+bw+" "+bh+";"' version="1.1" :viewBox='"0 0 "+bw+" "+bh'
                 x= "0px" xml:space="preserve"
                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                <defs>
                    <filter id="blur">
                        <feGaussianBlur stdDeviation="8"></feGaussianBlur>
                    </filter>
                    <linearGradient id="shadowbg"  x1="0%" y1="0%" x2="0%" y2="100%">
                       <stop offset="0" stop-color="rgba(0,0,0,0)"></stop>
                        <stop offset="30%" stop-color="rgba(0,0,0,0)"></stop>
                        <stop offset="100%" stop-color="rgba(0,0,0,0.6)"></stop>
                    </linearGradient>
                </defs>
                <g  filter="url(#blur)">
                    <image :href="bgs[posterIndex].bgUrl" :x= '-bx' :y="-by" :height="bh" :width="bw"></image>
                    <rect  :x= '-bx' :y="bh-(bh-bw*0.2)" :height="(bh-bw*0.2)" :width="bw" fill="url(#shadowbg)"></rect>
                </g>
            </svg>
            <div class="bg" :style='"top: "+(0.08*this.w)+"px;left: "+(0.08*this.w)+"px;bottom: "+(0.08*this.w)+"px;right: "+(0.08*this.w)+"px"'>
                <img class="bgimg" :style='"left: -"+(0.08*w+bx)+"px;top: -"+(0.08*w+by)+"px"' :width='bw+"px"' :height='bh+"px"'  :src="bgs[posterIndex].bgUrl"  alt=""/>
            </div>
            <div class="bottomarea">
                <svg style='height: 100%;width: 100%;font-size: 0' version="1.1" viewBox='0 0 768 640'
                     x= "0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                            <defs>
                                <linearGradient id="shadow"  x1="0%" y1="0%" x2="0%" y2="100%">
                                   <stop offset="0" stop-color="rgba(0,0,0,0)"></stop>
                                    <stop offset="100%" stop-color="rgba(0,0,0,0.75)"></stop>
                                </linearGradient>
                            </defs>
                    <g>
                                <rect x="0%" y="0" width="100%" height="100%" fill="url(#shadow)"></rect>
                        <!--                    <rect x="500" y="241" rx="10" ry="10" width="170" height="14" fill="rgba(0,0,0,0.6)"></rect>-->
                        <image :href="bgs[posterIndex].photoUrl" :x= "bgs[posterIndex].rect.x" :y="bgs[posterIndex].rect.y" :width="bgs[posterIndex].rect.width" :height="bgs[posterIndex].rect.height"></image>
                            </g>
                </svg>
            </div>
        </div>
        <div style="position: absolute;width: 100%;height: 100%;left: 0;top:0; z-index: 10">
                <div class="tipsarea" >
                    <svg style='enable-background:new 0 0 768 400;' version="1.1" viewBox='0 0 768 400'
                         x= "0px" xml:space="preserve"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                        <defs>
                            <filter id="color">
                                <feColorMatrix type="matrix" values="1 1 1 1 1 1 1 1 1 1 1 1 1 1 1 0 0 0 1 0"></feColorMatrix>
                            </filter>
                        </defs>

                        <g :filter='posterIndex === 1 ? "url(#color)":""'>
                                <image xlink:href="../assets/bigtitle.png" x= "177" y="174" width="415" height="78"></image>
                            <image xlink:href="../assets/subtitle.png" x= "251" y="274" width="269" height="25"></image>
                            <image :href='bgs[posterIndex].tipsUrl' x= "329" y="311" width="99" height="35"></image>
                            </g>
                        <image xlink:href="../assets/cctv9.png" x= "43" y="57" width="127" height="29"></image>
                        <image xlink:href="../assets/logo_lq.png" x= "198" y="39" width="75" height="65"></image>
                        <image xlink:href="../assets/logo_wp.png" x= "650" y="38" width="75" height="68"></image>
                    </svg>
                </div>
                <div class="bottomarea">
                    <svg style='enable-background:new 0 0 768 611;' version="1.1" viewBox='0 0 768 611'
                         x= "0px" xml:space="preserve"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                        <g>
                            <image xlink:href="../assets/b_time.png" x= "95" y="445" width="264" height="71"></image>
                            <image xlink:href="../assets/b_tips.png" x= "371" y="461" width="302" height="40"></image>
                            <image xlink:href="../assets/battoom.png" x= "218" y="536" width="331" height="20"></image>
                        </g>
                    </svg>
                </div>
                <div class="svgarea">
                <svg id="svg" style="enable-background:new 0 0 844.44 297.11;" version="1.1" viewBox="0 0 844.44 297.11"
                     x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
                    <g>
                        <g>
                            <path class="st0"
                                  d="M35.63,34.56c-2.86,0.38-5.95,1.04-9.25,1.96v40.74c2.79-0.54,5.87-1.19,9.25-1.96V34.56z"/>
                            <path class="st0" d="M88.03,212.26c-3.03-0.25-5.28-0.41-6.73-0.47c-0.96,0.02-1.84-0.16-2.67-0.47c0.13,0.26,0.25,0.52,0.38,0.77
                                c-0.45-0.16-0.59-0.45-0.51-0.82c-1.19-0.47-2.26-1.23-3.17-2.39c-1.58-2-2.37-4.21-2.37-6.64c0-0.62,0.03-1.09,0.09-1.4l0.57-5.8
                                c0.45-4.99,0.78-9.14,1.01-12.49c-0.25,0.47-0.82,0.81-0.71,1.37c-0.17-0.84-0.11-0.99,0.73-1.68c0.15-2.2,0.25-4.05,0.3-5.52
                                c0-1.12-0.03-1.96-0.09-2.52c-2.72,10.53-7.34,19.58-13.85,27.15c-3.27,3.8-6.94,6.85-11.02,9.15l2.25,5.44
                                c0.02,0.1,0.05,0.21,0.06,0.31c1.88,7.83,4.99,9.38,9.98,9.64c2.1,0.09,4.48-0.09,7.23-0.09c1.45,1.2,3.91,2.76,6.36,4.3
                                c3.4,2.15,6.73,4.39,7.23,5.59c0.87,0,1.66,0,2.46,0c4.12,0,8.1,0,12.08,0c0.5-1.29,0.5-1.29,5.93-5.33
                                c0.11-0.26,0.22-0.57,0.33-0.88c-0.13-0.13-0.25-0.27-0.32-0.42c-0.08-0.16,0.19-0.18,0.36-0.18c0.06,0,0.11,0.01,0.16,0.02
                                c0.86-2.62,1.79-6.6,2.69-10.85l1.37-7.04c-3.1,1.19-6.74,1.79-10.92,1.79C95.14,212.82,92.08,212.64,88.03,212.26z M59.31,212.45
                                c0.39-0.88,0.59-1.71,1.18-2.62C60.86,211.27,60.74,211.54,59.31,212.45z M63.13,203.94c-0.03,0-0.06,0-0.09,0
                                c0.07-0.51,0.15-1.01,0.22-1.52c0.03,0,0.06,0,0.09,0C63.28,202.93,63.21,203.43,63.13,203.94z M65.7,205.44
                                c-0.7,0.16-1.43,0.34-2.29,0.19c0.13-0.26,0.27-0.44,0.31-0.63c0.11-0.49,0.07-1.33,0.52-1.34c0.68-0.02,1.38,0.41,2.01,0.74
                                C66.66,204.62,66.35,205.28,65.7,205.44z"/>
                            <path class="st0" d="M102.82,12.9c-5.14,2.46-10.76,4.36-16.85,5.7c-6.1,1.34-12.23,2.09-18.39,2.24
                                c0.05,0.89,0.09,1.75,0.14,2.62c0.06-0.09,0.13-0.17,0.21-0.26c0.08,0.5,0.2,0.93-0.15,1.31c0.14,2.85,0.25,5.6,0.33,8.25
                                c0.2-0.28,0.43-0.54,0.57-0.83c0.13,0.68-0.08,1.27-0.55,1.83c0.07,2.7,0.12,5.32,0.12,7.8c13.36-1,26.1-2.53,38.22-4.6l0.55-0.12
                                v0.12l3.44,4.27c-0.44-11.77-0.68-24.87-3.77-29.34c-2.16-1.33-4.44-2.57-6.8-3.7l3.17,4.48L102.82,12.9z M72.67,35.96
                                c-0.19,0.39-0.43,0.77-0.64,1.15c-0.03-0.02-0.07-0.03-0.1-0.05c0.06-0.41,0.1-0.82,0.19-1.23c0.05-0.21,0.24-0.42,0.49-0.34
                                C72.83,35.56,72.74,35.81,72.67,35.96z M74.01,25.26c-0.44,0.17-0.86,0.16-1.34-0.08c0.41-0.19,0.76-0.35,1.12-0.5
                                c0.19-0.08,0.43-0.12,0.51,0.08C74.37,24.95,74.26,25.16,74.01,25.26z M80.07,31.13c-0.22,0.31-0.33,0.73-0.83,0.89
                                c-0.2-0.69,0.35-1.18,0.64-1.72c0.13-0.24,0.25-0.46,0.01-0.63c-0.23-0.17-0.47-0.03-0.73,0.09c-0.44,0.21-0.85,0.88-1.33,0.52
                                c-0.44-0.33-0.13-0.92,0.14-1.4c0.04-0.08,0.06-0.17,0.06-0.26c-0.01-0.31,0.23-0.78,0.37-0.77c0.38,0.01,0.46,0.35,0.5,0.7
                                c0.04,0.33,0.43,0.47,0.73,0.64C80.63,29.73,80.75,30.18,80.07,31.13z M85.43,34.59c-0.26-0.4,0.25-0.48,0.38-0.89
                                c0.31,0.53-0.04,0.73-0.17,0.98C85.57,34.65,85.46,34.64,85.43,34.59z M86.74,35.96c-0.01,0.11-0.03,0.33-0.43,0.29
                                c-0.25-0.03-0.42-0.04-0.39-0.34c0.04-0.35,0.28-0.6,0.59-0.62C86.76,35.26,86.77,35.59,86.74,35.96z"/>
                            <path class="st0" d="M59.72,189.74c5.41-10.41,8.11-21.93,8.11-34.58c0-1.25-0.06-3.18-0.19-5.8c-0.06-0.56-0.24-1.11-0.52-1.64
                                c-0.28-0.53-0.62-1.04-1-1.54l1.04-1.31c3.54,0.5,6.79,1.15,9.77,1.96l0.19,0.09c0.25,3.12,0.38,5.51,0.38,7.2
                                c0,4.49-0.44,9.25-1.33,14.3l8.25,2.52l-2.47,25.89c-0.06,0.25-0.09,0.62-0.09,1.12c0,1.74,0.49,3.29,1.47,4.63
                                c0.98,1.34,2.29,1.95,3.94,1.82L94,204.5c2.34,0,4.42-0.17,6.26-0.51c1.83-0.34,3.51-1.01,5.03-2.01c2.02-1.87,3.22-4.21,3.6-7.01
                                l1.85,0.08c1.11-35.92,0.79-70.45,1.36-105.63c1.45-7.74,1.45-7.74,1.95-8.18c0-6.45,0-12.74,0-18.93
                                c-1.02-0.61-1.73-2.33-2.24-4.77l-0.88-6.82c-0.12-1.57-0.22-3.25-0.3-4.98c-5.01,0.13-10.09,0.38-15.26,0.75
                                c-0.1,0.15-0.29,0.32-0.63,0.52c-0.23,0.13-0.3,0.48-0.45,0.73c-0.05-0.02-0.09-0.04-0.14-0.06c0.05-0.37,0.11-0.74,0.16-1.12
                                c-0.74,0.06-1.48,0.13-2.22,0.19c0.11,0.64,0.6,1.19,0.7,1.83l1.64,0.56v73.82l-10.68,3.45V112.1l0.07-23.87
                                c-0.09,0.12-0.23,0.22-0.47,0.29c-0.03-0.38,0.12-0.59,0.48-0.79l0.04-14.76c0-9.9-0.04-15.46-0.11-16.69
                                c-0.07-2.84-0.7-5.64-1.87-8.4c-3.3,0.39-7.89,1.11-13.77,2.19c-0.51,11.51-1.91,21.29-4.19,29.35
                                c-2.28,8.13-5.16,15.06-8.65,20.77c-3.49,5.71-8.1,9.99-13.82,12.83l-2.09-1.61c6.9-7.21,11.66-16.32,14.26-27.33
                                c2.61-11.01,3.91-22.96,3.91-35.85c0-5.68-0.21-11.97-0.62-18.86c-0.16-0.06-0.31-0.13-0.19-0.31c0.04-0.07,0.1-0.14,0.16-0.21
                                c-0.01-0.1-0.01-0.19-0.02-0.3l-0.22-5.75l-0.11-0.81c0-1.84-0.13-3.4-0.38-4.66c-0.26-1.27-0.79-2.32-1.6-3.16l-0.22-0.23
                                l1.54-2.42l0.22,0.23l8.48,3.48c0.01-0.01,0.02-0.02,0.03-0.03c0.02,0.02,0.06,0.05,0.09,0.07l2.64,1.08
                                c1.27-0.27,2.49-0.57,3.7-0.88c0.02-0.08,0.02-0.15,0.06-0.23c0.04,0.07,0.06,0.13,0.09,0.2c4.54-1.15,8.69-2.53,12.4-4.15
                                c3.54-1.55,6.68-3.52,9.41-5.93c-2.43-0.85-4.92-1.56-7.46-2.07c-1.1-0.18-2.27-0.35-3.44-0.5c0.16-0.11,0.29-0.27,0.39-0.52
                                c0.17-0.45,0.03-0.94,0.31-1.36c0.09-0.13,0-0.27-0.22-0.36c-0.26-0.11-0.37-0.04-0.48,0.15c-0.24,0.42-0.55,0.73-1.15,0.69
                                c-0.38-0.02-0.6,0.27-0.72,0.51c-0.13,0.27,0.29,0.24,0.43,0.38c0.13,0.12,0.25,0.22,0.31,0.37c-0.15-0.02-0.3-0.04-0.44-0.05
                                c-1.23-0.86-2.53-1.46-3.76-2.15c-0.29,0-0.65,0-0.94,0c-0.87,0-1.81,0-2.68,0c-0.14,0.43-5.72,2.23-10.92,4.13
                                c-2.61,1.03-5.13,2.06-6.87,3.01c-1.01,0.86-2.1,1.72-3.11,2.5c-5.71,4.39-11.43,7.4-19.89,9.98c-3.48,2.62-8.14,4.45-12.94,6.46
                                l4.5,1.47c1.76-0.31,4.41-0.84,7.93-1.61l8.7-2.99l7.6,5.75l-1.87,4.6V82.2l-10.68,3.57v-1.96c-3.89,0.69-6.98,1.38-9.25,2.07
                                v10.01l-10.79,3.22l0.05-18.59c-0.02,0.11-0.03,0.24-0.05,0.35c-0.04,0.26-0.06,0.66-0.43,0.6c-0.36-0.06-0.44-0.48-0.32-0.79
                                c0.11-0.28,0.32-0.56,0.8-0.7l0.01-3.9c-0.35-0.32-0.28-0.67-0.24-1c0.14,0.22,0.23,0.47,0.24,0.76l0.05-21.26
                                c0-0.46,0-0.89,0-1.33c-0.07-0.06-0.14-0.13-0.2-0.18c0.07-0.02,0.14-0.06,0.2-0.09c0-10.19-0.04-15.84-0.11-16.93
                                c-0.17-2.61-0.65-4.99-1.44-7.17l-3.64,1.86C6.22,33.2,2.37,36.33-0.22,40.98c0,2.33,0,4.73,0,7.32
                                c1.93,9.83,2.57,19.15,2.71,29.01c-0.07,0.27-0.03,0.67-0.05,0.97c-0.02,0.18-0.11,0.35-0.19,0.58c0.09,0.01,0.17,0.01,0.26,0.01
                                c0.07,7.36-0.1,15.04-0.19,23.47c2.6,9.55,0.94,16.01,1.08,27.02c3.28,9.75,2.49,11.46,1.67,21.75c0.05-0.22,0.1-0.45,0.15-0.67
                                c0.08-0.36,0.37-0.56,0.7-0.74c0.7-0.39,0.95-0.96,1.09-1.65c0.3-1.44,0.11-2.88,0.4-4.32c0.14-0.69,0.49-1.25,0.99-1.86
                                c0.2,0.63-0.35,1.24,0.12,1.76c0.33-0.38,0.49-0.79,0.63-1.21c-0.12,0.83,0.07,1.68-0.55,2.44c-0.16,0.19-0.18,0.38-0.21,0.59
                                c-0.17,1.09-0.17,2.17-0.14,3.25c0,0.08,0.03,0.2-0.02,0.24c-1.04,0.75-0.73,1.86-1.13,2.78c-0.17,0.38-0.39,0.4-0.7,0.49
                                c-1.06,0.32-1.08,0.57-0.98,1.53c0.01,0.07-0.01,0.14-0.02,0.21c2.02,5.59-1.68,26.26,3.13,36.18c5.13,4.56,11.57,4.48,17.83,4.51
                                c0.21,0.5,0.44,0.99,0.81,1.44c0.01-0.24-0.04-0.45,0.04-0.6c0.15-0.29,0.23-0.56,0.26-0.84c4.83,0.05,9.49,0.35,13.32,3.07
                                c0.04,0.21,0.1,0.42,0.27,0.61c0.06-0.11,0.08-0.21,0.12-0.31c2.21,1.7,4.12,4.26,5.6,8.13C52.1,201.55,56.42,196.09,59.72,189.74
                                z M72.96,5.12c0.28-0.21,0.43,0.14,0.69,0.15c0.36,0.02,0.36,0.31,0.35,0.6c-0.02,0.51-0.62,0.77-0.62,1.45
                                c-0.3-0.63-0.44-1.07-0.41-1.56C72.99,5.54,72.69,5.33,72.96,5.12z M70.33,6.5c0.2-0.3,0.35-0.63,0.61-1.1
                                c0.19,1.29,0.98,2.34,0.52,3.61c-0.08,0.01-0.11,0.02-0.14,0.01C69.12,8.34,69.13,8.34,70.33,6.5z M51.59,29.39
                                c0.15-0.04,0.28-0.17,0.46-0.29c0.11,0.51-0.32,0.84-0.56,0.79C50.93,29.77,51.01,29.53,51.59,29.39z M13.56,100.2
                                c-0.1,0.82-0.03,1.71-1.21,2.12c-0.27,0.09-0.36,0.34-0.49,0.67c-0.66-0.85-0.58-1.82-0.91-2.66c-0.1-0.26,0.17-0.26,0.34-0.32
                                c0.34-0.13,0.7-0.23,1-0.41c0.32-0.19,0.63-0.49,0.98-0.3C13.59,99.48,13.61,99.87,13.56,100.2z M21.7,134.75
                                c-0.39-0.1-0.74,0.58-1.02,0.15c-0.23-0.35,0.1-0.77,0.32-1.15c0.4-0.7,0.67-1.44,0.76-2.23c0.1,0,0.2,0,0.3,0
                                c0.15,0.9,0.36,1.79,0.44,2.69C22.53,134.57,22.19,134.87,21.7,134.75z M22.21,123.18c0.15,0.33,0.26,0.58,0.41,0.9
                                C22.02,123.97,22,123.68,22.21,123.18z M27,194.18c-0.06,0.08-0.05,0.21-0.08,0.34c-0.4-0.62-0.4-1.31-0.65-1.93
                                c-0.03-0.08-0.01-0.22,0.05-0.27c0.48-0.38,0.19-0.72,0.02-1.07c-0.09-0.17-0.16-0.35-0.22-0.54c-0.06-0.2-0.04-0.44-0.36-0.47
                                c-0.09-0.01-0.08-0.08-0.09-0.14c-0.16-0.95-0.82-1.81-0.44-2.85c0.05-0.13,0-0.26-0.07-0.35c-0.65-0.84-0.82-1.83-1-2.82
                                c-0.04-0.22,0.02-0.42,0.04-0.64c0.4,0.35,0.65,0.78,0.94,1.17c0.76,1.02,0.86,2.2,1.19,3.34c0.18,0.63,0.59,1.2,0.75,1.84
                                c0.17,0.67,0.31,1.36-0.09,2.04c-0.18,0.3-0.12,0.59,0.02,0.81C27.34,193.16,27.38,193.65,27,194.18z M28.32,127.42
                                c0.1,0.36,0.06,0.57-0.09,0.85C28.09,127.99,28.17,127.77,28.32,127.42z M28.29,164.8c0.04-0.35,0.24-0.48,0.58-0.43
                                c0.07,0.01,0.19,0.11,0.18,0.14C28.92,164.91,28.5,164.56,28.29,164.8z M29.72,125.01c-0.72,0.11-0.82,0.63-0.89,1.14
                                c-0.04,0.32-0.09,0.65-0.31,0.98c-0.05-1.11-0.17-2.21-0.14-3.32c0.02-0.83,0.99-1.58,1.92-1.64c0.45-0.03,0.77,0.15,0.64,0.56
                                c-0.34,1.04-0.03,2.07-0.27,3.27C30.38,125.49,30.43,124.9,29.72,125.01z M30.98,158.35c-0.56,0.51-0.22,1.25-0.6,2.01
                                c-0.44-1.01,0.1-1.85,0.35-2.73C30.98,157.84,31.55,157.83,30.98,158.35z M34.63,158.28c-0.71,0.5-0.72,1.22-1.04,1.88
                                c-0.28-0.77,0.02-1.5,0.28-2.23c0.1-0.28,0.31-0.31,0.54-0.22C34.68,157.82,34.84,158.13,34.63,158.28z M35.05,123.34
                                c-0.13-0.01-0.28-0.04-0.27-0.2c0.01-0.2,0.21-0.21,0.36-0.27c0.16,0.01,0.24,0.07,0.24,0.22
                                C35.37,123.28,35.24,123.36,35.05,123.34z M41.09,175.7c-0.84-0.84-0.33-1.5,0.57-2.23C41.48,174.3,40.63,174.91,41.09,175.7z
                                 M84.84,190.01c-0.32-0.01-0.27-0.44-0.29-0.71c-0.03-0.37,0.28-0.32,0.55-0.31c0.32,0.01,0.58,0.07,0.65,0.4
                                C85.51,189.68,85.21,190.03,84.84,190.01z M95.23,201.89c-0.04,0.26-0.06,0.66-0.43,0.6c-0.36-0.06-0.44-0.48-0.32-0.79
                                c0.12-0.3,0.33-0.6,0.89-0.73C95.32,201.31,95.28,201.6,95.23,201.89z M95.05,196.09c0.19,0.29,0.33,0.6,0.26,1.02
                                C94.94,196.79,95.01,196.44,95.05,196.09z M107.87,62.62c0.23-0.1,0.28,0.09,0.32,0.19c0.17,0.53,0.46,1.05,0.14,1.67
                                c-0.56-0.39-0.33-1.07-0.69-1.52C107.54,62.84,107.71,62.69,107.87,62.62z M108.35,67.97c-0.15,0.05-0.24,0.1-0.31,0.09
                                c-0.17-0.03-0.15-0.16-0.12-0.27c0.03-0.11,0.05-0.25,0.22-0.12C108.22,67.73,108.26,67.84,108.35,67.97z M107.41,112.97
                                c0.08-0.36,0.37-0.56,0.7-0.74c0.7-0.39,0.95-0.96,1.09-1.65c0.3-1.44,0.11-2.88,0.4-4.32c0.14-0.69,0.49-1.25,0.99-1.86
                                c0.2,0.63-0.35,1.24,0.12,1.76c0.33-0.38,0.49-0.79,0.63-1.21c-0.12,0.83,0.07,1.68-0.55,2.44c-0.16,0.19-0.18,0.38-0.21,0.59
                                c-0.17,1.08-0.17,2.17-0.14,3.25c0,0.08,0.03,0.2-0.02,0.24c-1.04,0.75-0.73,1.86-1.13,2.78c-0.17,0.38-0.39,0.4-0.7,0.49
                                c-1.06,0.32-1.08,0.57-0.98,1.53c0.06,0.59-0.36,1.26-0.66,1.87c-0.17,0.35-0.13,0.86-0.9,0.99
                                C106.52,117.03,106.97,115,107.41,112.97z M105.08,186.69c0.16,0.32,0.32,0.65,0.48,0.97
                                C105.05,187.47,104.93,187.13,105.08,186.69z M105.33,120.99c0.03,0.28,0.16,0.57-0.18,0.78c-0.03,0.02-0.14-0.02-0.16-0.05
                                C104.79,121.37,105.2,121.23,105.33,120.99z M103.02,126.78c-0.07,0.33-0.13,0.65-0.21,0.98c-0.06,0.27,0.08,0.45,0.36,0.34
                                c0.45-0.17,0.44,0.03,0.37,0.29c-0.26,0.98,0.48,2.1-0.77,2.93c-0.27,0.18-0.37,0.42-0.38,0.7c0,0.4-0.33,0.65-0.67,0.87
                                c-0.35,0.22-0.78,0.32-1.14,0.17c-0.44-0.18-0.14-0.53-0.07-0.83c0.09-0.41,0.1-0.81,0.58-1.1c1.38-0.84,1.38-2.12,1.42-3.32
                                C102.52,127.39,102.55,127.07,103.02,126.78z M101.6,81.12c0.16,0.6-0.31,0.98-0.39,1.66C101.04,82.07,100.99,81.6,101.6,81.12z
                                 M101.72,56.18c0.21-0.15,0.44-0.07,0.71-0.01c-0.2,0.3-0.44,0.35-0.7,0.22C101.69,56.37,101.69,56.21,101.72,56.18z M98.98,81.33
                                c0.51-0.49,0.83-0.96,1.01-1.63c0.12-0.45,0.94-0.78,1.18-1.39c0.09-0.22,0.13-0.45,0.4-0.91c0,0.9,0.86,1.29,0.58,2.08
                                c-0.24,0.67-0.39,1.36-1.47,1.33c-0.32-0.01-0.15,0.32-0.31,0.46c-0.3,0.28-0.48,0.69-1.02,0.63
                                C98.96,81.86,98.71,81.59,98.98,81.33z M81.18,50.54c0.22-0.28,0.51,0.06,0.49,0.26c-0.05,0.67,0.01,1.39-0.73,2.13
                                c-0.1-0.59-0.17-1.01-0.24-1.47c-0.56,0.14-0.96,0.77-1.72,0.29C79.83,51.43,80.65,51.21,81.18,50.54z M70.19,116.4
                                c0.24,0.67-0.39,0.88-0.74,1.21c0.27,0.27,0.03,0.45-0.14,0.72c-0.16-0.31,0-0.52,0.04-0.73C69,116.88,69.84,116.81,70.19,116.4z
                                 M44.84,158.41c0.07-0.46,0.13-0.89,0.21-1.44C45.46,157.68,45.45,157.77,44.84,158.41z M48.74,185.03
                                c0.15,0.35,0.33,0.66,0.51,0.95l0.09-32.88c0-8.47-0.03-13.21-0.1-14.21l-0.57-7.38v-0.19l-0.19-1.31h0.09l9.58,2.9
                                c12.2,0,22.57-0.65,31.1-1.96l5.22-2.34l6.54,4.86l-1.61,3.65v44.49l-9.2,2.43v-46.64c-8.35,0.31-18.94,0.97-31.77,1.96v44.68
                                l-8.84,2.52c0.24,0.38,0.45,0.76,0.56,1.18c0.05,0.19,0.19,0.41-0.14,0.53c-0.22,0.08-0.34-0.11-0.51-0.18
                                C48.05,187.47,48.36,186.37,48.74,185.03z"/>
                            <path class="st0" d="M91.77,46.79c-1.13,0.09-2.25,0.18-3.38,0.29l3.02,1.03c0.03-0.11,0.05-0.22,0.07-0.32
                                C91.54,47.45,91.61,47.11,91.77,46.79z"/>
                        </g>
                        <g>
                            <path class="st0" d="M282.42,136.88c-22.3,0.6-44.54,1.32-66.73,2.16v44.6l-17.63,4.86l0.18-48.92l-55.93,2.16l-9.89-13.13
                                c10.91,0,19.12,0.06,24.64,0.18l24.64,0.18l16.55-0.18c-0.12-1.32-0.18-2.88-0.18-4.68c-0.36-4.32-1.26-8.33-2.7-12.05
                                c-17.27,0.72-30.28,1.32-39.03,1.8l-5.76,0.18l-9.89-12.77l33.63,0.18c-3.12-5.63-6.42-10.61-9.89-14.93l4.68-4.86
                                c4.44,1.56,8.69,3.81,12.77,6.74c4.08,2.94,7.49,6.27,10.25,9.98l-3.6,2.88c12.47-0.24,21.76-0.54,27.88-0.9
                                c2.04-2.4,4.2-5.63,6.47-9.71c0.84-1.68,1.56-3,2.16-3.96c1.92-3.6,2.7-6.83,2.34-9.71l-67.26,3.24l-26.62,1.44l-9.89-12.77
                                l31.84-0.54c18.94-0.24,33.15-0.48,42.62-0.72c0.12-3.72,0.18-10.01,0.18-18.88l-41.91,2.16l-9.89-12.95l29.5,0.18
                                c4.79,0,12.23-0.12,22.3-0.36l-0.18-5.58c-0.36-5.27-1.56-10.01-3.6-14.21l-0.36-0.18l2.52-2.52h0.18l18.88,5.76v16.01
                                c13.67-0.84,26.2-2.22,37.59-4.14l9.53,13.13c-21.1,0.72-36.81,1.32-47.12,1.8V67.1c25.06-1.2,46.52-3.24,64.39-6.12l9.89,13.13
                                l-55.76,2.52l10.97,7.55l-0.18,0.54c-2.4,5.4-6.47,10.19-12.23,14.39c8.63-0.72,17.27-1.8,25.9-3.24l9.53,13.31
                                c-9.95,0.24-23.62,0.72-41.01,1.44c-3.72,0.24-9.41,0.48-17.09,0.72l5.57,1.8v15.29c21.7-0.6,40.77-2.16,57.19-4.68L282.42,136.88
                                z"/>
                        </g>
                        <g>
                <path class="st0" d="M336.59,176.01c-2.31-6.2-4.74-11.49-7.29-15.86v85.69l-17.68,5.47v-0.36l0.18-55.97
                c0-15.32-0.06-24.55-0.18-27.71c-11.67,11.67-23.83,21.27-36.47,28.81h-0.18l-2.55-2.01l0.37-0.18
                c12.03-14.22,22.73-27.62,32.09-40.2c9.36-12.58,17.62-25.43,24.8-38.56c-7.29,0.36-13.92,1.07-19.87,2.1
                c-5.96,1.03-11.61,2.64-16.96,4.83l-10.21-13.49c12.64-0.48,22.91-1.28,30.81-2.37c-3.16-9.85-6.81-17.38-10.94-22.61l6.2-4.38
                c7.17,5.11,13.67,11.37,19.51,18.78l-12.22,7.84c7.78-1.21,14.83-2.98,21.15-5.29l0.18,0.18l2-2.01l15.32,8.2v0.18
                c-6.81,13.86-14.89,26.8-24.25,38.83c8.39,6.2,15.38,13.07,20.97,20.6L336.59,176.01z M359.93,103.26l-9.85-13.49
                c17.38-0.12,32.91-0.61,46.58-1.46c13.67-0.85,26.47-2.49,38.38-4.92l9.48,14.04C419.01,97.43,390.8,99.38,359.93,103.26z
                 M447.81,172l-3.1,6.93v63.63l-17.5,5.47V238c-17.5,0.85-36.28,2.25-56.34,4.19v4.19l-17.69,5.1l0.18-47.4
                c0-12.4-0.06-19.57-0.18-21.51c-0.25-5.1-1.09-9.54-2.55-13.31l2.55-2.73l17.14,4.38c22-0.24,41.08-1.82,57.25-4.74l7.84-3.65
                L447.81,172z M414.45,157.41v-5.65c-6.44,0.25-12.07,0.58-16.86,1c-4.8,0.43-9.39,1.19-13.77,2.28v2.55l-17.69,4.92l0.18-22.43
                c0-6.56-0.06-10.82-0.18-12.76c-0.37-5.1-1.64-9.85-3.83-14.22l-0.18-0.18l2.37-2.92l0.18,0.18l16.96,5.29
                c9.72-0.48,20.66-1.94,32.82-4.38l8.2-3.83l12.76,9.48l-3.1,6.93v28.26L414.45,157.41z M370.87,198.99l6.02-0.18
                c2.55,0,6.5-0.12,11.85-0.36l-0.18-9.48c0-2.07-0.31-4.31-0.91-6.75c-3.4,0.24-9,0.73-16.77,1.46V198.99z M370.87,229.43
                c7.9,0,13.79-0.06,17.69-0.18l0.18-19.33l-17.87,1.64V229.43z M414.45,138.64v-14.22c-12.03,0.61-22.24,1.76-30.63,3.46v14.95
                C392.32,142.34,402.53,140.95,414.45,138.64z M406.06,197.16c8.14-0.85,15.19-1.82,21.15-2.92v-14.59
                c-6.32,0.24-10.94,0.49-13.86,0.73l-7.29,0.36V197.16z M427.21,225.97v-18.42l-21.15,1.09v19.69
                C411.04,227.97,418.09,227.18,427.21,225.97z"/>
                </g>
                        <g>
                <path class="st0" d="M616.87,185.38c11.09,7.78,22.39,13.9,33.92,18.36c-5.62,1.01-11.06,2.95-16.31,5.83
                c-5.26,2.88-9.76,6.34-13.5,10.37c-18.15-9.51-33.24-23.19-45.26-41.05c-12.03-17.86-20.56-37.81-25.6-59.84
                c-21.46,1.44-37.02,2.59-46.67,3.46v63.52c17.28-6.19,33.49-13.03,48.61-20.52l2.16,2.81c-25.49,21.6-52.64,38.96-81.45,52.07
                l-10.8-20.52c9.21-2.88,16.06-5.11,20.52-6.7c0.14-12.24,0.22-42.63,0.22-91.17c0-28.95-0.07-44.65-0.22-47.1
                c-0.29-6.05-1.8-11.67-4.54-16.85l-0.43-0.22l3.02-3.46l0.22,0.22l21.61,6.7c15.99,0,27.94-0.14,35.86-0.43
                c-3.75-6.91-7.85-13.18-12.31-18.8l5.19-6.27c5.47,1.87,10.66,4.61,15.56,8.21c4.89,3.6,9,7.71,12.31,12.32l-4.54,3.67
                c11.95-0.86,23.91-2.38,35.86-4.54l5.4-2.81l18.15,11.23l-3.46,8.21v62.44l-20.96,9.72v-7.13c-14.55,0.58-25.28,1.08-32.19,1.51
                c6.62,12.1,14.04,22.98,22.25,32.62c4.32-2.45,7.92-4.82,10.8-7.13c2.88-2.3,7.2-6.05,12.96-11.23c4.61-4.61,6.98-9.07,7.13-13.4
                v-0.22l3.67-1.08v0.22l15.12,19.66l-0.43,0.22c-5.33,5.19-11.7,9.47-19.12,12.85c-7.42,3.39-15.23,5.87-23.44,7.45
                C595.55,168.67,605.77,177.6,616.87,185.38z M503.44,56.62v17.93c20.31-0.14,36.73-0.61,49.26-1.4
                c12.53-0.79,24.77-2.7,36.73-5.72V51.65C571.28,52.37,542.62,54.03,503.44,56.62z M503.44,107.39c17.86-0.14,33.49-0.57,46.88-1.3
                c13.39-0.72,26.43-2.16,39.1-4.32v-18.8c-26.22,1.01-54.88,3.39-85.99,7.13V107.39z"/>
                </g>
                        <g>
                <path class="st0" d="M702.89,98.67c0,40.58-3.69,72.16-11.07,94.72c-4.54,13.48-10.96,25.22-19.26,35.23
                c-8.3,10-18.41,17.63-30.33,22.88l-3.4-2.77c28.38-26.82,42.57-71.8,42.57-134.95c0-7.8-0.14-16.25-0.43-25.33
                c-0.14-1.13-0.21-2.62-0.21-4.47c0.28-5.39-0.93-8.94-3.62-10.64l-0.21-0.43l3.4-2.77h0.21c6.95,1.7,12.98,3.55,18.09,5.53
                l9.58-0.21c21-0.85,35.19-1.49,42.57-1.92c-3.55-6.67-7.52-12.7-11.92-18.09l5.32-6.39c5.25,1.85,10.29,4.54,15.11,8.09
                c4.82,3.55,8.87,7.59,12.13,12.13l-4.05,3.19c22.99-1.84,40.51-3.76,52.57-5.75l0.85-0.21l0.43,0.21l13.2,16.18l-3.19,0.21
                c-32.07,0.14-74.85,2.77-128.35,7.88V98.67z M800.58,196.26c12.2,15.68,26.75,28.91,43.63,39.7c-8.23,0.71-16.89,4.68-25.97,11.92
                c-14.33-11.35-26.11-25.93-35.33-43.74c-9.22-17.81-14.62-36.36-16.18-55.66c-3.27,21.43-9.44,39.52-18.52,54.28
                c-7.52,11.78-17.24,21.74-29.16,29.91c-11.92,8.16-24.76,13.44-38.52,15.86c-0.71-0.71-1.42-1.63-2.13-2.77
                c18.16-8.37,32.95-21.5,44.38-39.38c11.42-17.88,18.98-37.89,22.67-60.02l-29.59,2.55l-11.49-15.96l42.78-0.43
                c0.43-5.53,0.64-11,0.64-16.39c0-3.97-0.07-6.95-0.21-8.94c0-4.54-1.21-8.44-3.62-11.71l3.4-2.77l21.71,6.17
                c0.14,3.27,0.21,7.95,0.21,14.05c0,6.53-0.21,12.77-0.64,18.73c21.42-1.28,39.73-3.33,54.91-6.17l11.28,16.6
                c-19.44,0.14-39.8,0.92-61.09,2.34C779.44,163.31,788.38,180.58,800.58,196.26z"/>
                </g>
                        <g>
                                <g>
                                    <path class="st1" d="M393.42,230.26c0.41-0.79,0.83-1.06,1.26-0.8c-0.17,0.3-0.71,0.87-1.61,1.71c-2.45,2.32-4.06,4.21-4.83,5.67
                                        c0,0.13-0.04,0.16-0.13,0.1c-0.11-0.13-0.21-0.16-0.32-0.1c-3.26,4.14-5.47,7.21-6.63,9.21c0.23,0.3,0.22,0.67-0.03,1.09
                                        c-0.34,0.54-0.52,0.96-0.52,1.26c1.74-3.26,4.39-6.77,7.95-10.53c1.14-1.22,2.67-2.77,4.6-4.64c2.02-2,3.54-3.55,4.57-4.67
                                        c-0.34-0.34-0.53-0.71-0.55-1.1c-0.04-0.45,0.14-0.79,0.55-1.03c-0.02,0.22,0.28,0.54,0.9,0.97c1.72-1.37,2.64-2.48,2.77-3.32
                                        c0.6,0.02,0.85,0.05,0.74,0.1c0.3-0.3,0.54-0.61,0.71-0.93c0.28-0.47,0.52-0.78,0.71-0.93c0.09-0.09,0.21-0.26,0.39-0.52
                                        c0.24-0.3,0.41-0.49,0.52-0.58c0.19-0.15,0.62-0.44,1.29-0.87c0.79-0.45,1.26-0.73,1.38-0.84c0.15-0.09,0.67-0.54,1.55-1.35
                                        c1.09-0.99,1.68-1.64,1.77-1.96c0.02,0,0.08-0.02,0.18-0.06c0.1-0.04,0.18-0.08,0.24-0.1l0.39-0.19c0.21-0.22,0.97-0.87,2.25-1.96
                                        c0.99-0.81,1.64-1.57,1.96-2.25c0.32,0.04,0.51,0.04,0.55,0c0.9-1.12,1.41-1.83,1.51-2.16c0.09,0.02,0.22,0.01,0.42-0.03
                                        c0.11-0.06,0.23-0.07,0.39-0.03c3.09-2.79,5.37-4.91,6.83-6.37c1.91-1.91,3.19-3.09,3.83-3.54c1.76-1.24,3.59-1.77,5.5-1.58
                                        c1.72,0.19,3.11,0.83,4.19,1.9c1.18,1.22,1.39,2.6,0.64,4.12c-0.73,1.48-2.22,3.51-4.48,6.09c-3.26,3.67-7.08,7.11-11.46,10.33
                                        c-4.94,3.56-8.76,6.1-11.46,7.6c-0.15,0.06-1.52,0.87-4.12,2.42c-1.95,1.12-3.36,1.89-4.22,2.32
                                        c-11.23,5.26-17.53,10.6-18.9,16.03c-0.39,1.48,0.07,3.13,1.38,4.96c1.22,1.72,2.54,2.82,3.96,3.32
                                        c1.87-0.26,5.57-2.02,11.11-5.28c2.51-1.5,4.37-1.72,5.57-0.64c0.52,0.49,0.79,1.12,0.84,1.87c0.06,0.82-0.16,1.53-0.68,2.16
                                        c-0.75,0.97-2.14,1.87-4.15,2.7c-1.25,0.49-2.12,0.83-2.64,1c-1.2,0.43-2.07,0.75-2.61,0.97c-6.57,2.68-9.92,4.02-10.05,4.02
                                        c-0.49,0.04-1.72-0.16-3.67-0.61c-0.11-0.02-0.32-0.03-0.64-0.03c-0.24,0.02-0.44,0-0.61-0.06c-1.25-0.41-2.32-1.25-3.22-2.51
                                        c-0.32-0.47-0.62-1.28-0.9-2.41c-0.54-2.1-0.65-4.35-0.35-6.73c0.07-0.62,0.6-2,1.61-4.12c1.93-4.1,4.31-7.35,7.15-9.76
                                        c-0.02-0.69,0.7-1.44,2.16-2.25c0.34-0.86,1.28-2.02,2.8-3.48c-0.02,0.09-0.02,0.14,0.02,0.16c0.03,0.02,0.09,0.02,0.18,0
                                        c0.13-0.02,0.22-0.07,0.26-0.16c0.02-0.09,0-0.18-0.07-0.29c-0.04-0.11-0.05-0.19-0.03-0.26
                                        C392.53,231.43,392.97,231.1,393.42,230.26z M379.64,250.45c-0.04,0.04-0.26,0.73-0.64,2.06c-0.02,0.19,0,0.29,0.06,0.29
                                        s0.07-0.1,0.03-0.29c0.02-0.06,0.09-0.26,0.19-0.6c0.11-0.33,0.2-0.63,0.29-0.89c0.26-0.69,0.4-1.13,0.42-1.32
                                        c-0.13,0.3-0.25,0.3-0.35,0V250.45z M395.29,228.78c0.04,0.04,0.09,0.06,0.13,0.06c0.02,0.13-0.01,0.23-0.1,0.29
                                        c-0.13,0.06-0.19,0.14-0.19,0.23c-0.21-0.04-0.21-0.25,0-0.61C395.21,228.75,395.27,228.76,395.29,228.78z M395.77,228.75
                                        c-0.32-0.67-0.35-1.17-0.1-1.51C396,227.36,396.03,227.87,395.77,228.75z M431.22,209.01c1.12-1.18,1.84-2.11,2.19-2.8
                                        c0.64-1.29,0.52-2.33-0.39-3.12c-0.56,0.73-0.89,1.07-1,1.03c0.28-0.77,0.42-1.23,0.42-1.38c-0.11,0-0.34-0.03-0.71-0.1
                                        c-0.24-0.06-0.48-0.07-0.74-0.03c-0.06,0.06-0.11,0.16-0.13,0.29c-0.02,0.11-0.09,0.18-0.19,0.23c-0.19-0.15-0.32-0.25-0.39-0.31
                                        c-0.06-0.05-0.09-0.09-0.06-0.11c-0.19,0.47-0.58,0.76-1.16,0.87c-0.13,0.02-0.41,0.05-0.84,0.1c-0.41,0.04-0.7,0.09-0.87,0.13
                                        c-0.17,0.04-0.87,0.46-2.09,1.26c-0.69,0.43-2.64,1.89-5.86,4.38c-0.24,0.56-0.71,1.08-1.42,1.58c-0.6,1.35-2.46,3.03-5.57,5.02
                                        c-0.41,0.39-0.75,0.72-1.03,1.01c-0.28,0.29-0.55,0.56-0.82,0.82c-0.27,0.26-0.48,0.47-0.64,0.63c-0.16,0.16-0.3,0.31-0.42,0.43
                                        c-0.12,0.13-0.19,0.22-0.23,0.26c-0.03,0.04-0.04,0.07-0.02,0.07c-0.54,0.43-1.05,1.08-1.55,1.96c-0.67,1.18-1.08,1.89-1.26,2.13
                                        c-0.47,0.6-1.47,1.59-2.99,2.96c-1.35,1.2-2.33,2.23-2.93,3.09h-0.06h-0.26c-0.28,0.45-0.65,0.98-1.13,1.58
                                        c-0.47,0.6-0.82,1.08-1.03,1.45c0.39-0.43,1.26-1.05,2.61-1.87c1.24-0.77,2.07-1.44,2.48-2c0.3-0.13,0.73-0.38,1.29-0.74
                                        c0.6-0.39,1.04-0.61,1.32-0.68c1.07-1.48,3.64-3.34,7.7-5.57c0.51-0.26,1.89-1.15,4.12-2.67c1.65-1.14,3.05-1.93,4.19-2.38
                                        c0-0.04,0.02-0.15,0.06-0.32c0.04-0.17,0.05-0.3,0.03-0.39c0.32-0.11,0.61-0.33,0.87-0.68c0.39-0.47,0.59-0.71,0.61-0.71h0.03
                                        c0.02,0,0.11-0.05,0.27-0.14c0.16-0.1,0.33-0.19,0.5-0.29c0.17-0.1,0.42-0.23,0.74-0.4c0.58-0.32,0.9-0.75,0.97-1.29
                                        c0.69-0.54,1.48-1.32,2.38-2.35c0.84-0.94,1.62-1.74,2.35-2.38c-0.11-0.28-0.14-0.5-0.1-0.68c0.17,0.02,0.33-0.05,0.48-0.23
                                        c0.13-0.15,0.28-0.23,0.45-0.23c-0.02-0.41,0.07-0.85,0.29-1.32c0.24-0.49,0.52-0.74,0.87-0.74c-0.75,2.02-1.13,3.05-1.13,3.09
                                        c-0.11,0-0.2-0.01-0.27-0.03c-0.08-0.02-0.13-0.04-0.16-0.06c-0.03-0.02-0.04-0.04-0.02-0.06c-0.52,1.24-1.45,2.52-2.8,3.83
                                        c0,0.09,0.03,0.18,0.1,0.29c0.09,0.09,0.12,0.19,0.1,0.32c-0.19-0.04-0.33,0.03-0.42,0.22c-0.09,0.17-0.12,0.3-0.1,0.39
                                        C428.58,211.74,429.69,210.62,431.22,209.01z M404.11,252.06c-1.5,0.19-2.37,0.67-2.61,1.42
                                        C402.21,252.96,403.08,252.49,404.11,252.06z M412.09,225.4c0.26-0.15,0.43-0.29,0.52-0.42c-0.9,0.11-1.26,0.38-1.06,0.8
                                        C411.65,225.63,411.84,225.5,412.09,225.4z M428.74,213.58c-0.04,0-0.07-0.01-0.1-0.03c-0.02-0.02-0.04-0.03-0.06-0.03l-0.71,0.9
                                        c-0.04,0-0.09,0.02-0.13,0.06c-0.04,0.04-0.03,0.08,0.03,0.1c0.11-0.13,0.32-0.32,0.64-0.58c0.32-0.24,0.56-0.43,0.71-0.58
                                        c0.06-0.02-0.06-0.09-0.39-0.19V213.58z"/>
                                    <path class="st1" d="M413.19,254.7c0.32,0.37,1.42,0.16,3.28-0.61c2.94-1.18,5.58-2.65,7.92-4.41c0.26-0.17,1.09-0.93,2.51-2.29
                                        c1.05-0.99,1.94-1.64,2.67-1.96c1.25-0.54,2.04-0.53,2.38,0.03c0.26,0.47,0.12,1.2-0.42,2.19c-0.69,1.29-3.13,3.58-7.34,6.89
                                        c-3.2,2.55-5.25,4.09-6.15,4.6c-0.47,0.26-1.19,0.74-2.16,1.45c-1.31,0.94-2.05,1.47-2.22,1.58c-1.85,1.14-3.5,1.58-4.96,1.32
                                        c-0.81-0.15-1.7-0.54-2.64-1.16c-1.16-0.81-1.94-1.33-2.35-1.54c-0.43-0.43-0.64-1.14-0.64-2.13l0.03-2.48
                                        c-0.04-0.15-0.12-0.35-0.23-0.61c-0.11-0.28-0.16-0.47-0.16-0.58c-0.04-0.75,0.48-2.25,1.58-4.51c0.73-1.52,1.53-2.73,2.41-3.61
                                        c0-0.13-0.04-0.25-0.13-0.35c-0.06-0.11-0.09-0.23-0.06-0.35l0.03-0.03h0.03l0.03-0.03l0.03-0.03h0.03l0.06-0.03l0.97-0.68
                                        c-0.13-0.41-0.09-0.82,0.13-1.22l0.58-1.09c0.34,0.09,0.5,0.16,0.48,0.23c-0.09,0.02-0.15-0.01-0.18-0.08
                                        c-0.03-0.07-0.04-0.19-0.02-0.34c0.02-0.15,0.1-0.16,0.23-0.03c0.15,0.15,0.28,0.16,0.39,0.03c0.09-0.09,0.12-0.3,0.1-0.64
                                        c-0.02-0.34,0.01-0.56,0.1-0.64c0.15,0,0.28,0.04,0.39,0.13c0.17,0.11,0.31,0.16,0.42,0.16c-0.13,0.22-0.39,0.55-0.77,1l-0.8,0.93
                                        c-0.58,0.82-0.86,1.71-0.84,2.67c0.02,0.09,0.02,0.14-0.02,0.16c-0.03,0.02-0.07,0.01-0.11-0.03c-0.19-0.11-0.31-0.15-0.35-0.13
                                        c-0.79,1.95-1.66,3.82-2.61,5.6c0.04,0.06,0.15,0.14,0.32,0.23c0.13,0.09,0.23,0.18,0.29,0.29c-0.34,0.06-0.48,0.43-0.42,1.09
                                        c0.9-1.7,2.16-3.71,3.77-6.05c2.02-2.94,3.34-4.89,3.96-5.86c-0.02-0.07-0.32-0.31-0.9-0.74c-0.39-0.28-0.53-0.6-0.42-0.97
                                        c0.17,0.04,0.42,0.18,0.74,0.42c0.34,0.26,0.63,0.42,0.87,0.48c0.26-0.11,0.47-0.32,0.64-0.64c0.21-0.41,0.4-0.66,0.55-0.77
                                        c-0.02-0.09-0.1-0.2-0.23-0.35c-0.11-0.17-0.16-0.31-0.16-0.42c0.13-0.04,0.26-0.01,0.39,0.1c0.13,0.09,0.27,0.12,0.42,0.1
                                        c0.02-0.84,0.09-1.44,0.19-1.8c0.54-0.52,1.17-0.96,1.9-1.32c0,0.02,0.06-0.08,0.19-0.31c0.13-0.23,0.4-0.69,0.81-1.38
                                        c0.41-0.7,0.81-1.38,1.22-2.04c0.9-1.55,2.06-2.23,3.48-2.06c0.81,0.11,1.78,0.54,2.9,1.29c1.16,0.79,1.9,1.53,2.22,2.22
                                        c0.6,1.31,0.38,2.62-0.68,3.93c-0.94,1.18-2.12,1.87-3.54,2.06c-0.64,0.34-1.36,1.06-2.16,2.16c-0.79,1.12-1.5,1.84-2.12,2.16
                                        c-0.73,0.6-1.67,2.31-2.83,5.12c-0.02,0.11-0.49,0.84-1.42,2.19c-0.84,1.27-1.37,2.19-1.61,2.77
                                        C413.06,254.31,413.06,254.57,413.19,254.7z M404.37,256.21c0.04,0.04,0.06,0.02,0.06-0.06c0-0.06-0.01-0.16-0.03-0.29
                                        c0-0.04,0.01-0.12,0.03-0.22l0.29-1.35c-0.02,0.13-0.16,0.13-0.42,0c0.11,0.13,0.13,0.32,0.06,0.58c-0.09,0.3-0.11,0.6-0.06,0.9
                                        C404.26,256.02,404.28,256.17,404.37,256.21z M410.45,240.72c0.17,0.3,0.33,0.51,0.48,0.61c-0.36,0.04-0.71-0.35-1.03-1.19
                                        C410.12,240.25,410.3,240.44,410.45,240.72z M410.52,241.33c0.17,0.17,0.17,0.34,0,0.51c-0.19-0.26-0.29-0.43-0.29-0.51H410.52z
                                         M421.43,256.82c0.36-0.24,0.43-0.45,0.19-0.64c-0.11,0.13-0.32,0.28-0.64,0.45c-0.32,0.17-0.54,0.32-0.64,0.45
                                        C420.7,257.14,421.07,257.06,421.43,256.82z M435.95,210.81c0.64,0.77,1.45,1.07,2.42,0.9c0.04,0.22,0.17,0.38,0.39,0.48
                                        c0.04,0.02,0.14,0.05,0.29,0.1c0.06,0.02,0.17,0.06,0.32,0.13c0.24,0.43,0.2,0.62-0.1,0.58c0.45,0.43,0.61,0.73,0.48,0.9
                                        c0,0.09,0.07,0.21,0.22,0.39c0.17,0.24,0.27,0.41,0.29,0.52c-0.24,0.84-0.27,1.34-0.1,1.51c-0.26,0-0.49,0.1-0.71,0.29
                                        c0.13,0.17,0.16,0.44,0.1,0.8v0.42c-0.45,0.13-0.91,0.09-1.38-0.13c-0.73,2.04-2.26,3.27-4.6,3.7c-0.49-0.3-0.97-0.43-1.42-0.39
                                        c-0.09,0.24-0.4-0.06-0.93-0.9c-0.11-0.19-0.22-0.4-0.35-0.61c-0.04,0.04-0.08-0.07-0.1-0.35c-0.02-0.21-0.03-0.59-0.03-1.13
                                        c0.06-0.21,0.12-0.38,0.16-0.48c0.04-0.13,0.1-0.3,0.16-0.51v-0.03c-0.02-0.11-0.04-0.19-0.05-0.26
                                        c-0.01-0.06-0.03-0.15-0.05-0.26c-0.02-0.11-0.04-0.22-0.06-0.34c-0.02-0.12-0.05-0.26-0.08-0.42c-0.03-0.16-0.06-0.33-0.08-0.5
                                        c0.24-0.21,0.47-0.69,0.71-1.42c0.34-0.13,0.84-0.55,1.48-1.26c0.64-0.69,1.22-1.1,1.74-1.26c0.11-0.06,0.29,0,0.55,0.19
                                        c0.26,0.22,0.44,0.29,0.55,0.23c-0.02-0.11-0.01-0.27,0.03-0.48c0-0.06,0-0.13,0.02-0.19c0.01-0.06,0.02-0.11,0.03-0.15
                                        c0.01-0.03,0.02-0.06,0.02-0.08H435.95z M438.85,214.74c-0.17-0.62-0.4-0.93-0.68-0.93c0.19,0.17,0.31,0.54,0.35,1.09
                                        c0.02,0.64,0.06,1.08,0.13,1.32C438.96,215.86,439.02,215.36,438.85,214.74z"/>
                                    <path class="st1" d="M453.05,231.22c1.95,0.34,3.55,0.94,4.8,1.8c1.44,1.01,2.13,2.29,2.09,3.83c-0.06,1.39-0.96,3.27-2.67,5.63
                                        c-2.04,2.81-3.54,4.67-4.51,5.57c-0.04,0.11-0.03,0.22,0.03,0.35c0.06,0.15,0.09,0.27,0.06,0.35c-0.58,1.18-1.37,2.36-2.38,3.54
                                        c-0.11,0.13-0.29,0.31-0.55,0.55c-0.26,0.24-0.44,0.42-0.55,0.55c-0.45,0.47-0.69,0.9-0.71,1.29c-0.13,1.35,0.35,1.67,1.45,0.97
                                        c0.73-0.45,1.45-1.1,2.16-1.96c2.12-2.53,4.01-3.95,5.67-4.25c-0.58,0.73-1.45,1.57-2.61,2.51c-1.33,1.09-2.25,1.88-2.77,2.35
                                        c-2.19,2.17-3.83,3.36-4.93,3.57c-0.09-0.43-0.26-0.88-0.52-1.35c-0.15,0.73-0.13,1.26,0.05,1.58s0.53,0.44,1.05,0.35
                                        c0.79-0.13,1.66-0.57,2.61-1.32c0.6-0.49,1.35-1.23,2.25-2.22c1.2-1.33,1.92-2.11,2.16-2.35c1.12-1.12,2.3-1.89,3.54-2.32
                                        c0.84-0.24,1.47-0.31,1.9-0.23c0.73,0.13,0.98,0.63,0.74,1.51c0.02-0.24-1.01,0.99-3.09,3.67c-0.8,1.05-2.05,2.11-3.77,3.19
                                        c-2.88,1.82-4.5,2.81-4.86,2.96c-0.13,0.06-0.73,0.39-1.8,0.97c-0.92,0.47-1.58,0.77-1.96,0.9c-1.54,0.52-2.73,0.17-3.54-1.03
                                        c-0.51-0.73-0.72-1.65-0.61-2.77c0-0.04,0.12-0.5,0.35-1.38c0.15-0.56,0.16-1.05,0.03-1.48c-0.43-0.36-0.86-0.48-1.29-0.35
                                        c-0.32,0.09-0.7,0.38-1.13,0.87c-1.31,1.48-2.07,2.26-2.29,2.35c-0.15,0.09-0.36,0.13-0.64,0.13c-0.28,0.04-0.47,0.08-0.58,0.1
                                        c-0.67,0.24-2.25,1.18-4.77,2.83c-1.89,1.29-3.58,1.85-5.09,1.67c-1.85-0.22-2.73-1.52-2.64-3.93c0.06-1.22,0.49-3.17,1.29-5.83
                                        c0.04-0.04,0.12-0.06,0.23-0.06c0.13-0.04,0.19-0.09,0.19-0.13c0.67-1.82,1.74-3.66,3.22-5.51c0.67-0.84,2.05-2.36,4.15-4.57
                                        c0.43-1.09,1.16-2.19,2.19-3.28c0.75-0.75,1.22-1.21,1.42-1.39l1.45-1.38c0.21-0.21,0.5-0.55,0.87-1
                                        c0.39-0.45,0.68-0.76,0.87-0.93c0.39-0.3,1-0.61,1.84-0.93c1.01-0.36,1.66-0.63,1.96-0.81c0.26-0.04,0.76-0.31,1.51-0.8
                                        c0.84-0.54,1.46-0.92,1.87-1.16c0.9-0.45,2.17-0.86,3.8-1.22H453.05z M445.64,246.68c-0.39,0.32-0.51,0.78-0.39,1.38l-0.19-0.26
                                        c-0.06-0.11-0.14-0.16-0.23-0.16c-0.06,0.43-0.27,0.85-0.61,1.26c-0.32,0.41-0.59,0.77-0.81,1.09c0.15,0.13,0.19,0.28,0.13,0.45
                                        c0,0.02-0.02,0.05-0.05,0.1c-0.03,0.04-0.06,0.09-0.08,0.13l-0.13,0.26c0.6-0.32,2.29-1.52,5.05-3.61
                                        c-0.06-0.21-0.29-0.51-0.68-0.9c-0.34-0.3-0.49-0.58-0.45-0.84c0.09,0.06,0.32,0.3,0.71,0.71c0.26,0.28,0.52,0.48,0.8,0.61
                                        c0.09-0.15,0.23-0.28,0.42-0.39c0.04-0.13-0.02-0.28-0.19-0.45c-0.17-0.13-0.2-0.22-0.1-0.29l0.61,0.32
                                        c0.04-0.09-0.02-0.25-0.19-0.48c-0.15-0.19-0.19-0.34-0.13-0.45c0.09-0.13,0.27-0.2,0.55-0.22c0.36-0.04,0.59-0.1,0.68-0.16
                                        c0.32-0.26,0.52-0.61,0.58-1.06c0.02-0.21,0-0.69-0.06-1.42c-1.18-0.02-2.23,0.01-3.16,0.1c-0.36,0.69-0.88,1.16-1.54,1.42
                                        c-0.77,0.32-1.49,0.23-2.16-0.29c-0.37,0.04-0.7,0.23-1,0.55c-0.43,0.49-0.68,0.76-0.74,0.81c-0.11,0.04-0.22,0.02-0.32-0.07
                                        c-0.09-0.06-0.19-0.07-0.32-0.03c-0.52,0.13-0.91,0.52-1.19,1.16c-0.39,0.82-0.67,1.31-0.84,1.48c-0.32,0.32-0.92,0.65-1.8,1
                                        c-0.77,0.34-1.34,0.73-1.71,1.16c-0.15-0.04-0.25-0.04-0.29,0c-0.17,0.51-0.62,1.11-1.35,1.77c-0.79,0.73-1.27,1.31-1.42,1.74
                                        c-0.02,0.06-0.09,0.4-0.19,1c-0.06,0.41-0.2,0.72-0.42,0.93c0.99-0.09,2.18-0.55,3.57-1.39c1.35-0.86,2.52-1.35,3.51-1.48
                                        c0.15-1.25,0.83-1.79,2.03-1.64c0.06-0.02,0.1-0.29,0.1-0.8c0.36-0.17,0.83-0.55,1.38-1.13c0.52-0.56,0.99-0.9,1.42-1.03
                                        c0-0.13-0.04-0.25-0.13-0.35c-0.09-0.09-0.12-0.21-0.1-0.39c0.11,0.13,0.34,0.16,0.71,0.1c0.02-0.39,0.15-0.66,0.39-0.84
                                        c0.21-0.15,0.43-0.07,0.64,0.23c0.04-0.13,0.03-0.27-0.03-0.42c-0.04-0.13-0.03-0.23,0.03-0.29c0.06,0,0.18,0.1,0.35,0.29
                                        c0.15,0.17,0.24,0.22,0.26,0.13c0.06-0.11-0.02-0.31-0.26-0.61c-0.19-0.24-0.2-0.41-0.03-0.51c-0.02,0.13,0.07,0.35,0.29,0.68
                                        c0.21,0.28,0.29,0.53,0.23,0.74C446.35,246.25,445.97,246.38,445.64,246.68z M434.38,256.76c0.19-0.04,0.3-0.11,0.32-0.19
                                        l-0.74-0.1c0.02,0.02,0.02,0.13,0,0.32c0,0.13,0.04,0.19,0.13,0.19C434.11,256.9,434.2,256.82,434.38,256.76z M442.81,251.28
                                        c0.17-0.11,0.27-0.19,0.29-0.26c-0.04,0-0.1-0.03-0.16-0.1c-0.02-0.06-0.06-0.1-0.13-0.1c-0.13,0.45-0.24,0.69-0.32,0.71
                                        C442.51,251.48,442.62,251.39,442.81,251.28z M447.25,254.6c-0.17,0.47-0.22,0.86-0.16,1.16c0.13-0.3,0.3-0.34,0.51-0.13
                                        c-0.22-0.15-0.24-0.45-0.06-0.9c0.19-0.58,0.26-1,0.19-1.26l-0.29,0.68C447.36,254.34,447.3,254.49,447.25,254.6z M453.24,233.61
                                        c0.41,0.06,0.69,0.05,0.84-0.03c-0.3-0.21-0.62-0.33-0.97-0.35c-0.43-0.04-0.72,0.08-0.87,0.35
                                        C452.5,233.53,452.83,233.54,453.24,233.61z M459.39,253.57c0.04,0.13,0.02,0.24-0.07,0.32c-0.17,0-0.25-0.17-0.22-0.52
                                        C459.25,253.33,459.35,253.4,459.39,253.57z M459.62,252.06c-0.02,0.15-0.01,0.41,0.03,0.77c0.06,0.39,0.09,0.64,0.06,0.77
                                        c-0.09-0.15-0.22-0.67-0.39-1.55H459.62z"/>
                                    <path class="st1" d="M455.27,247.13c0.11-0.51,0.36-1.07,0.77-1.67c0.47-0.73,0.86-0.89,1.16-0.48c0.09,0.11-0.1,0.41-0.55,0.9
                                        c-0.52,0.52-0.83,0.93-0.93,1.26c-0.28,0.9-0.2,1.78,0.23,2.64c0.34,0.71,0.88,1.27,1.61,1.67c1.33,0.77,2.77,0.84,4.31,0.19
                                        c1.52-0.62,2.51-1.63,2.96-3.03c0.09-0.09,0.17-0.09,0.26,0c0.13,0.09,0.27,0.12,0.42,0.1c2.64-3.05,4.27-4.74,4.89-5.09
                                        c0.62-0.39,1.22-0.55,1.8-0.48c0.19,0.02,0.41,0.14,0.64,0.35c0.19,0.19,0.4,0.31,0.61,0.35c-0.11,0.82-0.33,1.46-0.68,1.93
                                        c0.52-0.09,0.81-0.64,0.87-1.67c0.06-1.03-0.23-1.65-0.87-1.87c-1.91-0.13-3.94,0.85-6.08,2.93c-2.88,2.77-4.49,4.26-4.83,4.48
                                        c-0.67,0.34-1.5,0.44-2.51,0.29c-1.12-0.15-1.76-0.59-1.93-1.32c-0.19-0.88,0.22-1.67,1.25-2.38c1.5-1.03,2.26-1.57,2.29-1.61
                                        c-0.34-0.54-0.54-0.98-0.58-1.32c-0.11-0.51,0.09-0.9,0.58-1.16c0.04,0.3,0.18,0.56,0.42,0.77l0.58,0.58l2-1.29
                                        c0.71-0.56,1.15-1.22,1.32-2c0.15-0.02,0.28,0.01,0.39,0.1c0.11,0.11,0.24,0.15,0.39,0.13c0.3-0.13,0.57-0.38,0.81-0.74l0.29-0.48
                                        c0.11-0.21,0.2-0.38,0.29-0.48c0.49-0.15,0.76-0.34,0.81-0.58c0.41-0.34,1.07-0.7,2-1.06c0.9-0.39,1.6-0.72,2.09-1
                                        c0.3-0.17,0.8-0.57,1.51-1.19l1.48-1.35c1.24-0.99,2.48-1.35,3.7-1.1c0.81,0.17,1.36,0.66,1.64,1.45c0.21,0.56,0.3,1.43,0.26,2.61
                                        c-0.13,3.33-0.12,5.21,0.03,5.63c0.77-0.15,1.51-0.5,2.22-1.06c0.09-0.06,0.39-0.31,0.9-0.74c0.3-0.28,0.59-0.52,0.87-0.71
                                        c0.43-0.32,1.05-0.74,1.87-1.26c0.94-0.62,1.61-1.07,2-1.35c2.15-1.63,4.06-2.74,5.73-3.32c2.68-0.94,4.74-0.68,6.18,0.81
                                        c0.82,0.82,1.36,2.15,1.64,3.99c0.09,0.47,0.2,1.32,0.35,2.54c0.15,1.2,0.29,2.09,0.42,2.67c0.15,0.6,0.3,1.13,0.45,1.58
                                        c0.06,0.19,0.13,0.41,0.19,0.64c0.06,0.24,0.12,0.41,0.16,0.53c0.04,0.12,0.07,0.22,0.1,0.31c0.11,0.58,0.19,1.17,0.26,1.77
                                        c0.11,0.82,0.17,1.33,0.19,1.54c0.32,1.74,0.93,3.16,1.83,4.25c1.12,1.37,2.51,1.96,4.19,1.77c1.09-0.13,3.64-1.22,7.63-3.28
                                        c0.58-0.32,1.17-0.67,1.77-1.03c0.64-0.36,1.27-0.69,1.87-0.97c1.48-0.69,2.84-0.93,4.09-0.74c0.32,1.35-0.19,2.45-1.55,3.28
                                        c-2.38,1.52-3.5,2.21-3.35,2.06c-0.15-0.17-0.22-0.24-0.19-0.19c-0.11-0.04-0.19-0.02-0.26,0.06c-0.3,0.17-3.07,2.18-8.31,6.02
                                        c-2.17,1.61-4.34,2.76-6.5,3.44c-3.15,1.03-5.46,0.69-6.92-1.03c-0.92-1.16-1.75-3.21-2.48-6.15c-1.46-5.9-2.25-10.53-2.38-13.88
                                        c-0.02-0.11-0.02-0.19,0-0.24c0.02-0.05,0.07-0.07,0.16-0.05c0.09,0.02,0.13-0.01,0.13-0.1c-0.02-0.06-0.04-0.13-0.06-0.19
                                        c-0.3-0.06-0.63,0.02-1,0.26c-0.02,0.02-0.05,0.05-0.1,0.08c-0.04,0.03-0.1,0.08-0.16,0.13c-0.06,0.05-0.12,0.1-0.16,0.15
                                        c-0.09,0.09-0.23,0.19-0.42,0.32c-0.26,0.15-4.57,3.22-12.94,9.21c-3.11,2.19-5.46,4.08-7.05,5.67c-1.85,1.83-3.13,2.98-3.86,3.48
                                        c-1.65,1.07-3.48,1.51-5.47,1.32c-1.37-0.15-2.29-0.57-2.74-1.26c-0.49-0.81-0.47-1.67,0.06-2.58c0.69-1.16,1.05-1.97,1.09-2.45
                                        c0.24-0.17,0.96-1.12,2.16-2.83c0.34-0.47,1.39-1.57,3.16-3.28c1.33-1.31,2.17-2.6,2.51-3.86c0.56-0.58,1.4-1.64,2.51-3.19
                                        c-0.75,0.24-1.62,0.77-2.61,1.61c-1.31,1.12-2.15,1.79-2.51,2.03l-0.06,0.51c-0.04,0.3-0.1,0.51-0.16,0.61
                                        c-0.28,0.06-0.65,0.31-1.13,0.74c-0.39,0.41-0.8,0.61-1.25,0.61c-0.49,1.09-1.41,1.78-2.74,2.06c-1.35,0.32-2.6,0.17-3.73-0.45
                                        c-0.24,0-0.33-0.12-0.29-0.35c0.02-0.28-0.04-0.46-0.19-0.55c0.02,0-0.01,0-0.1,0c-0.09,0-0.18-0.01-0.27-0.02
                                        c-0.1-0.01-0.21-0.02-0.34-0.02c-0.34-0.04-0.56-0.15-0.64-0.32c0.22-0.15,0.23-0.46,0.03-0.93c-0.3-0.69-0.46-1.09-0.48-1.22
                                        V247.13z M458.23,244.55c-0.13,0.17-0.22,0.29-0.27,0.35c-0.05,0.06-0.08,0.09-0.08,0.06c-0.09,0-0.13-0.07-0.13-0.23
                                        c0.02-0.15,0.03-0.27,0.03-0.35c0.04-0.02,0.11-0.05,0.19-0.1c0.09-0.02,0.13-0.06,0.13-0.13
                                        C458.34,244.28,458.38,244.4,458.23,244.55z M458.91,242.59c0.02,0.19,0,0.47-0.06,0.84c-0.06,0.39-0.09,0.68-0.06,0.87
                                        c-0.02-0.02-0.1-0.59-0.23-1.71H458.91z M470.47,248.58c-0.34,0.52-0.6,0.9-0.77,1.16c1.39-1.65,2.16-2.64,2.29-2.96
                                        c-0.09,0.15-0.17,0.2-0.26,0.16l-0.32-0.16v0.71c-0.02,0-0.05-0.02-0.1-0.06c-0.04-0.04-0.09-0.06-0.13-0.06
                                        C471.03,247.74,470.79,248.15,470.47,248.58z M484.63,247.42c0.51-0.39,0.77-0.78,0.77-1.19c-0.13,0.19-0.53,0.55-1.19,1.06
                                        c-0.54,0.41-0.86,0.73-0.97,0.97L484.63,247.42z"/>
                                    <path class="st1" d="M542.2,259.98c3.03-0.79,4.68-0.86,4.96-0.19c0.19,0.41-0.05,0.93-0.74,1.58c-0.45,0.43-0.97,0.8-1.54,1.09
                                        c-0.43,0.22-1.07,0.48-1.93,0.81c-0.34,0.13-0.71,0.27-1.09,0.42c-0.39,0.15-0.61,0.24-0.68,0.26c-2.3,1.05-4.54,2.25-6.73,3.61
                                        c-1.18,0.13-2.93,1.42-5.25,3.86c-1.16,1.22-2.42,2.73-3.8,4.51c-3.99,5.19-9.65,10.67-16.97,16.42
                                        c-4.08,3.2-7.92,4.93-11.53,5.18c-3.97,0.26-7.29-0.85-9.95-3.32c-1.67-1.54-2.58-3.36-2.7-5.44c-0.11-2.17,0.45-4.36,1.67-6.57
                                        c1.1-2.02,2.3-3.55,3.61-4.6c0.09-0.06,0.41-0.28,0.97-0.64c0.56-0.36,0.88-0.58,0.97-0.64c4.36-3.35,9.7-5.97,16.03-7.86
                                        c1.67-0.49,4.38-1.18,8.11-2.06c3.56-0.82,6.31-1.51,8.24-2.09c2.73-0.79,4.41-1.34,5.06-1.64c0.15-0.06,0.32-0.17,0.51-0.32
                                        l0.52-0.32c0.41,0.52,1.14,0.6,2.19,0.26c0.43-0.13,0.93-0.58,1.51-1.35c0.62-0.86,1.04-1.35,1.26-1.48
                                        c-0.04-0.15-0.14-0.36-0.29-0.64c-0.15-0.3-0.24-0.52-0.26-0.68c0.62-1.14,1.05-1.65,1.29-1.55c-0.24,0.24-0.22,0.41,0.06,0.52
                                        c0.39,0.15,0.58,0.26,0.58,0.32c0.39-0.15,1.16-0.95,2.32-2.41l2.03-2.58c-0.02-0.47,0.04-0.93,0.19-1.38
                                        c0.09-0.06,0.27-0.14,0.55-0.23c0.26-0.11,0.44-0.21,0.55-0.32c0.26-0.26,0.5-0.78,0.74-1.58c0.26-0.92,0.44-1.51,0.55-1.77
                                        c-0.69,0.28-2.06,0.79-4.12,1.55l-4.09,1.51c-3.58,1.35-6.65,2.03-9.21,2.03c-2.21,0-3.82-0.39-4.83-1.16
                                        c-1.01-0.77-1.43-1.92-1.26-3.45c0.19-1.93,1.51-4.21,3.96-6.83c1.76-1.93,3.79-3.47,6.09-4.6c4.12-2.08,9.84-3.56,17.16-4.44
                                        c1.72-0.24,3.6-0.2,5.63,0.1c0.79,0.09,2.03,0.46,3.7,1.13c1.46,0.56,2.65,0.86,3.57,0.9c0.84,0.02,2.12-0.29,3.86-0.93
                                        c2.12-0.79,3.33-1.19,3.61-1.19c0.73,0,1.53,0.39,2.41,1.16c0.86,0.77,1.29,1.46,1.29,2.06c-0.04,0.77-0.6,1.36-1.67,1.77
                                        c-0.52,0.17-0.99,0.31-1.42,0.42c-0.47,0.13-0.89,0.27-1.26,0.42c-0.94,0.39-3.4,1.76-7.37,4.12c-0.79,0.49-2.7,2.07-5.73,4.73
                                        c-10.45,9.25-15.96,14.07-16.52,14.46C538.26,260.86,539.99,260.56,542.2,259.98z M505.24,274.5c-1.05,0.34-1.9,0.67-2.54,0.97
                                        c-0.13,0.09-0.38,0.29-0.74,0.61c-0.36,0.36-0.63,0.59-0.81,0.68c-0.58,0.32-1.04,0.57-1.38,0.74c-0.62,0.3-1.08,0.55-1.38,0.74
                                        c-0.24,0.15-0.62,0.46-1.16,0.93c-0.67,0.6-1.05,0.93-1.16,1c-0.06,0.06-0.27,0.19-0.61,0.39c-0.21,0.11-0.41,0.23-0.58,0.35
                                        c-2.6,2.32-3.8,4.41-3.61,6.28c0.17,1.59,1.74,2.54,4.7,2.87c2.47,0.26,4.81-0.27,7.02-1.58c0.84-0.52,2.57-1.87,5.18-4.06
                                        l8.27-7.11c0.15-0.34,1.42-1.81,3.8-4.41c0.34-0.71,0.94-1.57,1.8-2.58c1.16-1.39,1.78-2.17,1.87-2.32
                                        c-3.28,0.97-8.6,2.84-15.94,5.63C507.74,273.71,506.83,274,505.24,274.5z M497,291.05c-0.64,0.11-1.04,0.2-1.19,0.29
                                        c0.04-0.11,0.01-0.17-0.1-0.19c-2.81-0.79-4.29-1.37-4.44-1.74c1.29,1.52,3.28,2.2,5.99,2.03
                                        C496.72,291.43,496.63,291.3,497,291.05z M518.67,275.91c-0.11-0.17-0.29-0.23-0.55-0.16c-4.44,5.28-9.42,9.8-14.94,13.56
                                        l0.03,0.06c0,0.06,0,0.13,0,0.19c0.02,0.15-0.02,0.28-0.13,0.39c-0.43,0.11-1.06,0.33-1.9,0.68c-0.84,0.34-1.47,0.58-1.9,0.71
                                        c2.58-0.43,5.47-1.96,8.69-4.6l7.18-6.08c1.91-1.67,4.09-3.95,6.53-6.83c3.2-3.69,5.29-6.03,6.28-7.02c0.04,0,0.06-0.01,0.06-0.03
                                        c0.04-0.04,0.02-0.06-0.06-0.06c-0.64,0.28-1.62,0.59-2.93,0.93C521.97,271.03,519.85,273.79,518.67,275.91z M517.19,267.64
                                        c-2.23,0.21-3.92,0.64-5.05,1.29c-0.06,0.02,0.07-0.02,0.4-0.11c0.33-0.1,0.92-0.25,1.75-0.47
                                        C515.13,268.13,516.09,267.9,517.19,267.64z M547.26,235.54c-0.13,0.45-0.4,0.63-0.8,0.55c-0.02,0-0.05-0.01-0.1-0.03
                                        c-0.04-0.02-0.11-0.05-0.21-0.08c-0.1-0.03-0.19-0.07-0.27-0.11c-0.13-0.04-0.34-0.1-0.64-0.16c-0.22-0.02-1.38,0.22-3.51,0.74
                                        c-0.69,0.17-1.79,0.48-3.32,0.93c-1.27,1.61-2.68,2.25-4.25,1.93c-0.34,0.09-0.74,0.42-1.19,1c-0.41,0.52-0.87,0.8-1.38,0.84
                                        c-0.82,0.73-1.74,2.82-2.77,6.28c0.67,0.64,1.71,0.8,3.12,0.48c0.21-0.02,0.8-0.18,1.77-0.48c0.62-0.19,1.18-0.31,1.67-0.35
                                        c0.24-0.41,0.94-0.78,2.13-1.13c0.28-0.11,0.63-0.27,1.06-0.48c0.51-0.24,0.89-0.41,1.13-0.51c0.13-0.04,0.44-0.09,0.93-0.13
                                        c0.49-0.04,0.8-0.1,0.93-0.16c0.13-0.06,0.28-0.18,0.45-0.35c0.13-0.15,0.25-0.28,0.35-0.39c0.9-0.58,2.17-0.94,3.8-1.09
                                        c1.03-0.13,2.24-0.45,3.64-0.97c1.55-0.58,2.64-0.93,3.28-1.06c-0.02,0.19,0.62-0.51,1.93-2.12c0.06,0.06-0.01,0.05-0.23-0.03
                                        c-0.3-0.13-0.97-0.46-2-1c-0.37,0.22-0.52,0.34-0.45,0.39c0-0.15-0.06-0.26-0.19-0.32c-0.04-0.02-0.13-0.1-0.26-0.23
                                        c-0.02,0.13-0.18,0.22-0.48,0.26c-0.28,0.02-0.49-0.01-0.64-0.1c0.04-0.17,0.04-0.26,0-0.26c-0.04,0.15-0.11,0.24-0.19,0.26v-0.35
                                        c-0.15-0.04-0.27,0-0.35,0.13c-0.11,0.15-0.23,0.2-0.35,0.16c0.04-0.24,0.07-0.39,0.08-0.45s0.01-0.11-0.02-0.13
                                        c-0.19-0.13-0.29-0.14-0.29-0.03c0,0.02,0.01,0.06,0.03,0.13v-0.26c1.42,0.52,2.39,0.54,2.93,0.07l-0.06,0.39
                                        c0.39-0.15,0.99-0.11,1.8,0.13c0.67,0.22,1.14,0.24,1.42,0.06c-0.04,0.15,0.07,0.3,0.35,0.45c-0.45-0.88-1.42-1.51-2.9-1.9
                                        c-0.69-0.17-2.03-0.36-4.02-0.58v0.39c-0.02,0.22-0.03,0.39-0.03,0.51c-0.04,0.37-0.18,0.55-0.42,0.55c0.09-0.32,0.09-0.81,0-1.45
                                        c-0.6-0.13-1,0.05-1.19,0.55c-0.02-0.17-0.05-0.3-0.1-0.37C547.41,235.54,547.34,235.52,547.26,235.54z M531.26,261.42
                                        c-0.17,0.15-0.28,0.31-0.32,0.48c-0.15-0.21-0.13-0.46,0.06-0.74c0.19-0.26,0.39-0.32,0.58-0.19
                                        C531.56,261.12,531.45,261.28,531.26,261.42z M532.12,260.62c-0.06-0.17-0.07-0.53-0.03-1.06c0.06-0.62,0.2-0.98,0.42-1.06
                                        C532.47,258.47,532.34,259.18,532.12,260.62z M546.07,244.39c-0.47-0.09-0.72,0-0.74,0.26
                                        C545.71,244.65,545.96,244.57,546.07,244.39z M549.22,237.73c-0.02-0.06-0.05-0.1-0.1-0.1c0-0.04,0.01-0.05,0.03-0.03
                                        c0.02,0,0.03,0.01,0.03,0.03l0.22-0.42l0.03,0.03c0.02,0.09-0.03,0.28-0.16,0.58C549.27,237.82,549.24,237.79,549.22,237.73z
                                         M556.11,238.21c-0.26,0.56-0.31,0.92-0.16,1.09c0.02-0.11,0.04-0.25,0.06-0.42L556.11,238.21z"/>
                                </g>
                            </g>
                        <g>
                                <g>
                                    <path class="st1" d="M593.88,264.14c-0.35,0.04-0.68,0.21-0.99,0.52c-0.37,0.35-0.68,0.54-0.93,0.58c-0.06,0.6-0.32,1.2-0.79,1.78
                                        c-0.62,0.8-0.97,1.29-1.05,1.49c0.04,0.04-0.05-0.05-0.26-0.26c-0.12-0.1-0.23-0.1-0.35,0c-0.1,0.1-0.08,0.21,0.06,0.35
                                        c0.14,0.14,0.17,0.27,0.12,0.41c-0.12,0.04-0.2,0.01-0.23-0.09c-0.06-0.1-0.12-0.15-0.17-0.15c-0.41,0.12-0.63,0.61-0.67,1.49
                                        c-0.12-0.06-0.23-0.11-0.35-0.15c-0.19-0.04-0.3-0.1-0.32-0.18c0.14,0.04,0.23,0,0.28-0.12c0.05-0.12,0.06-0.3,0.04-0.55
                                        c-0.19-0.58-0.67-1.03-1.43-1.34c-0.45,0.27-0.78,0.78-0.99,1.52c-0.02,0.14,0.05,0.21,0.2,0.23c0.19,0.02,0.29,0.08,0.29,0.17
                                        l-0.23,0.26c-0.17,0.25-0.41,0.61-0.7,1.08c0.54,0.78,0.84,1.19,0.88,1.23c0.27,0.54,0.13,0.95-0.44,1.23
                                        c-0.02-0.37-0.2-0.67-0.53-0.91l-0.41-0.26c-0.2-0.1-0.33-0.19-0.41-0.26c-0.27,0.17-0.66,0.52-1.17,1.02
                                        c-0.39,0.43-0.76,0.79-1.11,1.08c0.08,0.66,0.11,1.02,0.09,1.08c-0.14,0-0.24-0.04-0.32-0.12c-0.14-0.1-0.25-0.15-0.35-0.15
                                        c-0.31,0.06-0.51,0.27-0.58,0.64l-0.09,0.52l-0.09,0.53l-0.2,0.2c-0.1,0.1-0.2,0.14-0.29,0.12c-0.1,0.53-0.5,1.03-1.2,1.52
                                        c-0.08,0.06-0.4,0.24-0.96,0.55c-0.27,0.14-0.57,0.32-0.88,0.55c-0.25,0.17-0.92,0.87-2.01,2.07c-1.62,1.75-3.09,2.99-4.44,3.71
                                        c-0.33,0.18-0.94,0.42-1.84,0.73c-0.97,0.31-1.63,0.54-1.96,0.7c-0.2-0.16-0.61-0.25-1.26-0.29c-0.7,0-1.15-0.02-1.34-0.06
                                        c0.02-1.69,0.13-2.55,0.32-2.57c0.02,0,0.05,0.02,0.09,0.06c0.39-0.33,0.76-0.53,1.11-0.58c0.89-0.82,2.02-2.24,3.39-4.26
                                        c1.44-2.1,2.55-3.56,3.33-4.38c1.4-1.44,2.8-2.59,4.2-3.45c0.04-0.02,0.32-0.16,0.85-0.41c0.37-0.14,0.64-0.28,0.82-0.44
                                        c0.21-0.16,0.45-0.51,0.7-1.05c0.19-0.35,0.41-0.73,0.64-1.14c0.06-0.08,0.36-0.41,0.9-0.99c0.51-0.54,0.77-0.83,0.79-0.85
                                        c0.1-0.1,0.37-0.53,0.82-1.28c0.29-0.51,0.6-0.92,0.93-1.26c0.19-0.2,0.51-0.4,0.93-0.61c0.1-0.06,0.41-0.24,0.93-0.55
                                        c1.5-1.09,2.9-2.94,4.2-5.55c0.54-1.05,1.41-2.26,2.6-3.62c1.69-1.91,2.67-3.05,2.92-3.42c-0.57,0.16-1.43,0.68-2.6,1.58
                                        c-1.11,0.88-2.11,1.35-3.01,1.43v-0.58c-0.04,0.06-0.46,0.16-1.26,0.29c-0.53,0.08-0.81,0.46-0.85,1.14
                                        c-0.14-0.04-0.25-0.11-0.35-0.2c-0.14-0.12-0.24-0.18-0.32-0.2c-0.51,0.1-1.49,0.57-2.95,1.43c-1.27,0.78-2.38,1.11-3.36,0.99
                                        c-2.04-0.27-3.31-1.39-3.8-3.36c0.08-0.37,0.67-1.71,1.78-4.03c1.21-2.51,1.85-3.8,1.93-3.88v-0.5c0.06-0.04,0.25-0.23,0.58-0.58
                                        c0.02-0.06,0.01-0.19-0.03-0.41c-0.02-0.14-0.01-0.22,0.03-0.26l4.23-3.77c0.76-0.68,1.59-1.41,2.48-2.19
                                        c0.8-0.43,1.65-0.85,2.57-1.26c1.61-0.74,2.53-1.17,2.74-1.28c0.06-0.06,0.14,0,0.23,0.17c3.85-2.61,8-3.61,12.44-3.01
                                        c-0.6-2.24,0.16-3.69,2.28-4.35c2-0.64,3.82-0.32,5.46,0.96c1.58,1.26,2.22,3.03,1.93,5.31c-0.31,2.53-2.07,5.53-5.28,8.99v0.91
                                        c-1.07,1.58-1.63,2.53-1.69,2.86c-0.19,0.08-0.4,0.27-0.61,0.58c-0.25,0.37-0.44,0.59-0.55,0.67c-0.27,0.31-0.64,0.64-1.11,0.99
                                        c-0.62,0.49-1,0.81-1.14,0.96c-0.88,0.8-1.39,1.69-1.55,2.69c-0.91,0.99-2.42,2.79-4.52,5.4c-1.83,2.22-3.43,3.94-4.79,5.17
                                        C593.92,263.07,593.92,263.46,593.88,264.14z M594.82,239.97c0-0.12-0.03-0.24-0.09-0.38c-0.06-0.14-0.09-0.26-0.09-0.38
                                        c0.17,0.16,0.34,0.04,0.5-0.35c-2.59,0.76-4.71,1.68-6.36,2.77c-0.02,0,0.01,0.07,0.1,0.2c0.09,0.14,0.23,0.37,0.42,0.7
                                        c0.21,0.37,0.28,0.71,0.2,1.02l-0.53-0.88c-0.14-0.27-0.32-0.56-0.55-0.88c-0.12,0-0.24,0.06-0.38,0.17
                                        c-0.16,0.1-0.28,0.16-0.38,0.18c-0.02,0.14,0.01,0.27,0.09,0.41c0.08,0.14,0.11,0.27,0.09,0.41c-0.1-0.04-0.19-0.15-0.26-0.32
                                        c-0.1-0.17-0.2-0.26-0.32-0.26c0.04,0.21,0.03,0.43-0.03,0.64c-0.06,0.25-0.08,0.41-0.06,0.47c0.08-0.02-0.4-0.05-1.43-0.09
                                        c-2.28,1.58-3.43,3.25-3.44,5.02c2,2.02,2.96,2.87,2.86,2.54c0.49,0.14,1.07,0.03,1.75-0.32c0.56-0.27,1.01-0.25,1.34,0.06
                                        c0.62,0,1.19-0.25,1.69-0.76c0.58-0.64,1.03-1.03,1.34-1.17c0.64-0.31,1.54-0.51,2.69-0.58c1.05-1.01,2.06-1.41,3.03-1.2
                                        c0.91-0.68,1.95-1.29,3.09-1.84c0.06-0.53,0.47-1.07,1.23-1.63c0.72-0.53,1.1-1.1,1.14-1.72c0.58-0.49,0.97-0.85,1.17-1.08
                                        c-0.14-0.78-0.14-1.45,0-2.01c-2.47,0.14-3.87,0.14-4.2,0c0.02,0.06,0.01,0.17-0.03,0.32c-0.06,0.14-0.08,0.25-0.06,0.35
                                        c-0.29-0.18-0.57,0.02-0.85,0.58c-0.02-0.02-0.24-0.05-0.67-0.09c-0.43-0.06-0.73-0.12-0.9-0.17c-0.08,0.06-0.22,0.34-0.44,0.85
                                        c-0.43,0.1-0.99,0.37-1.69,0.82c-0.66,0.41-1.28,0.64-1.84,0.7c0.1,0.23,0.13,0.49,0.09,0.76c-0.02-0.08-0.15-0.14-0.38-0.17
                                        c-0.19-0.02-0.32-0.08-0.38-0.17c0.04,0.51-0.2,0.82-0.73,0.93c-0.02-0.23-0.11-0.38-0.26-0.44c-0.02,0.04,0.02,0.14,0.12,0.29
                                        c0.06,0.1,0.05,0.17-0.03,0.23c-0.25-0.25-0.37-0.4-0.35-0.44c0,0.33-0.06,0.55-0.17,0.67c-0.35-0.39-0.49-0.67-0.41-0.85
                                        c0.56,0.06,1.02-0.07,1.37-0.38c0.31-0.27,0.45-0.64,0.41-1.11c0.08,0,0.15,0.08,0.2,0.23c0.06,0.16,0.13,0.24,0.2,0.26
                                        C593.02,241.16,593.75,240.52,594.82,239.97z M588.6,269.02c2.14-2.35,3.37-4.25,3.68-5.69l0.67,0.32
                                        c1.19-2.02,3.18-4.74,5.98-8.14c-0.06-0.12-0.14-0.22-0.23-0.32c-0.12-0.12-0.16-0.26-0.12-0.44c0.57-0.16,0.94-0.66,1.11-1.52
                                        c-8.64,9.75-12.87,14.51-12.7,14.27C587.42,267.61,587.96,268.12,588.6,269.02z M587.75,273.72c-0.1-0.06-0.18-0.09-0.26-0.09
                                        c-0.02-0.06-0.01-0.13,0.03-0.2c0.06-0.1,0.08-0.17,0.06-0.2C587.7,273.32,587.75,273.48,587.75,273.72z M588.02,271.12
                                        c-0.14,0.21-0.1,0.57,0.12,1.08c0.2,0.43,0.2,0.74,0.03,0.93c-0.1-0.1-0.23-0.77-0.41-2.01H588.02z M590.24,243.24
                                        c-0.08-0.14-0.12-0.14-0.12,0c0.04,0.02,0.06,0,0.07-0.06c0.01-0.06,0-0.15-0.01-0.29c0.08,0.02,0.13,0.09,0.15,0.2
                                        c0.02,0.14,0.06,0.21,0.12,0.23C590.38,243.46,590.31,243.43,590.24,243.24z M590.62,244.5c-0.1-0.1-0.16-0.1-0.17,0
                                        c-0.1-0.1-0.16-0.27-0.17-0.53c-0.04-0.31-0.09-0.53-0.15-0.64C590.24,243.46,590.4,243.85,590.62,244.5z M596.4,238.45
                                        c-0.02,0-0.05,0.01-0.09,0.03c-0.04,0.02-0.1,0.04-0.19,0.06c-0.09,0.02-0.17,0.04-0.25,0.06c-0.23,0.06-0.39,0.12-0.47,0.17
                                        C595.68,239.12,596.01,239.02,596.4,238.45z M600.72,252.46c0.12,0,0.19-0.02,0.23-0.06c-0.2,0.2-0.17,0.3,0.09,0.32
                                        c0.06-0.06,0.09-0.17,0.09-0.32c0-0.17-0.03-0.29-0.09-0.35l0.12,0.06c0.02,0.02,0.06,0.03,0.12,0.03
                                        c0.08-0.17,0.33-0.55,0.76-1.14c0.33-0.45,0.52-0.8,0.58-1.05c-0.16,0.23-0.54,0.67-1.14,1.31c-0.51,0.58-0.83,1.01-0.96,1.28
                                        C600.55,252.49,600.62,252.46,600.72,252.46z M607.46,234.66c0.1-0.02,0.16-0.03,0.18-0.03l-0.26-0.03h-0.06
                                        c-1.11-0.58-1.92-0.73-2.42-0.44c0.02,0,0.07,0.01,0.15,0.04c0.08,0.03,0.18,0.06,0.31,0.1c0.13,0.04,0.26,0.08,0.39,0.12
                                        c0.37,0.12,0.7,0.19,0.99,0.23C606.94,234.7,607.19,234.7,607.46,234.66z M605.97,238.69c0.1,0.06,0.18,0.09,0.26,0.09
                                        c-0.06-0.45-0.15-0.69-0.26-0.73c0,0.08-0.04,0.16-0.12,0.23c-0.08,0.08-0.12,0.17-0.12,0.26
                                        C605.8,238.54,605.87,238.59,605.97,238.69z"/>
                                    <path class="st1" d="M620.01,248.17c0.29,0.33,1.28,0.15,2.98-0.55c2.67-1.07,5.06-2.4,7.18-4c0.23-0.15,0.99-0.85,2.28-2.07
                                        c0.95-0.89,1.76-1.49,2.42-1.78c1.13-0.49,1.85-0.48,2.16,0.03c0.23,0.43,0.11,1.09-0.38,1.99c-0.62,1.17-2.84,3.25-6.66,6.25
                                        c-2.9,2.32-4.76,3.71-5.58,4.17c-0.43,0.23-1.08,0.67-1.96,1.31c-1.19,0.86-1.86,1.33-2.01,1.43c-1.67,1.03-3.17,1.43-4.5,1.2
                                        c-0.74-0.14-1.54-0.49-2.39-1.05c-1.05-0.74-1.76-1.21-2.13-1.4c-0.39-0.39-0.58-1.03-0.58-1.93l0.03-2.25
                                        c-0.04-0.14-0.11-0.32-0.2-0.55c-0.1-0.25-0.15-0.43-0.15-0.53c-0.04-0.68,0.44-2.04,1.43-4.09c0.66-1.38,1.39-2.47,2.19-3.27
                                        c0-0.12-0.04-0.22-0.12-0.32c-0.06-0.1-0.08-0.2-0.06-0.32l0.03-0.03h0.03l0.03-0.03l0.03-0.03h0.03l0.06-0.03l0.88-0.61
                                        c-0.12-0.37-0.08-0.74,0.12-1.11l0.53-0.99c0.31,0.08,0.46,0.15,0.44,0.2c-0.08,0.02-0.13,0-0.16-0.07
                                        c-0.03-0.07-0.03-0.17-0.02-0.31c0.02-0.14,0.09-0.15,0.2-0.03c0.14,0.14,0.25,0.15,0.35,0.03c0.08-0.08,0.11-0.27,0.09-0.58
                                        c-0.02-0.31,0.01-0.51,0.09-0.58c0.14,0,0.25,0.04,0.35,0.12c0.16,0.1,0.28,0.15,0.38,0.15c-0.12,0.2-0.35,0.5-0.7,0.91
                                        l-0.73,0.85c-0.53,0.74-0.78,1.55-0.76,2.42c0.02,0.08,0.01,0.13-0.01,0.15c-0.03,0.02-0.06,0.01-0.1-0.03
                                        c-0.17-0.1-0.28-0.14-0.32-0.12c-0.72,1.77-1.51,3.46-2.36,5.08c0.04,0.06,0.14,0.13,0.29,0.2c0.12,0.08,0.2,0.17,0.26,0.26
                                        c-0.31,0.06-0.44,0.39-0.38,0.99c0.82-1.54,1.96-3.37,3.42-5.49c1.83-2.67,3.03-4.44,3.59-5.31c-0.02-0.06-0.29-0.28-0.82-0.67
                                        c-0.35-0.25-0.48-0.54-0.38-0.88c0.15,0.04,0.38,0.17,0.67,0.38c0.31,0.23,0.57,0.38,0.79,0.44c0.23-0.1,0.43-0.29,0.58-0.58
                                        c0.19-0.37,0.36-0.6,0.5-0.7c-0.02-0.08-0.09-0.19-0.2-0.32c-0.1-0.16-0.15-0.28-0.15-0.38c0.12-0.04,0.23-0.01,0.35,0.09
                                        c0.12,0.08,0.24,0.11,0.38,0.09c0.02-0.76,0.08-1.3,0.17-1.63c0.49-0.47,1.06-0.87,1.72-1.2c0,0.02,0.06-0.07,0.17-0.28
                                        c0.12-0.2,0.36-0.62,0.73-1.26c0.37-0.63,0.74-1.25,1.11-1.85c0.82-1.4,1.87-2.02,3.15-1.87c0.74,0.1,1.62,0.49,2.63,1.17
                                        c1.05,0.72,1.72,1.39,2.01,2.01c0.54,1.19,0.34,2.37-0.61,3.56c-0.86,1.07-1.93,1.69-3.21,1.87c-0.58,0.31-1.24,0.96-1.96,1.95
                                        c-0.72,1.01-1.36,1.66-1.93,1.96c-0.66,0.55-1.52,2.09-2.57,4.64c-0.02,0.1-0.45,0.76-1.28,1.98c-0.76,1.15-1.25,1.99-1.46,2.51
                                        C619.89,247.82,619.89,248.06,620.01,248.17z M612.01,249.54c0.04,0.04,0.06,0.02,0.06-0.06c0-0.06-0.01-0.15-0.03-0.26
                                        c0-0.04,0.01-0.11,0.03-0.2l0.26-1.23c-0.02,0.12-0.15,0.12-0.38,0c0.1,0.12,0.12,0.29,0.06,0.53c-0.08,0.27-0.1,0.54-0.06,0.82
                                        C611.91,249.37,611.94,249.51,612.01,249.54z M617.53,235.5c0.16,0.27,0.3,0.46,0.44,0.56c-0.33,0.04-0.64-0.32-0.93-1.08
                                        C617.23,235.08,617.39,235.25,617.53,235.5z M617.59,236.06c0.15,0.16,0.15,0.31,0,0.47c-0.18-0.23-0.26-0.39-0.26-0.47H617.59z
                                         M627.48,250.1c0.33-0.21,0.39-0.41,0.18-0.58c-0.1,0.12-0.29,0.25-0.58,0.41c-0.29,0.16-0.49,0.29-0.58,0.41
                                        C626.82,250.39,627.15,250.31,627.48,250.1z M640.65,208.39c0.58,0.7,1.31,0.97,2.19,0.82c0.04,0.2,0.15,0.34,0.35,0.44
                                        c0.04,0.02,0.13,0.05,0.26,0.09c0.06,0.02,0.16,0.06,0.29,0.12c0.21,0.39,0.18,0.56-0.09,0.52c0.41,0.39,0.55,0.66,0.44,0.82
                                        c0,0.08,0.07,0.19,0.2,0.35c0.16,0.21,0.24,0.37,0.26,0.47c-0.21,0.76-0.24,1.22-0.09,1.37c-0.23,0-0.45,0.09-0.64,0.26
                                        c0.12,0.16,0.15,0.4,0.09,0.73v0.38c-0.41,0.12-0.83,0.08-1.26-0.12c-0.66,1.85-2.05,2.97-4.17,3.36
                                        c-0.45-0.27-0.88-0.39-1.28-0.35c-0.08,0.21-0.36-0.06-0.85-0.82c-0.1-0.17-0.2-0.36-0.32-0.55c-0.04,0.04-0.07-0.07-0.09-0.32
                                        c-0.02-0.19-0.03-0.54-0.03-1.02c0.06-0.19,0.11-0.34,0.15-0.44c0.04-0.12,0.09-0.27,0.15-0.47v-0.03
                                        c-0.02-0.1-0.03-0.17-0.04-0.23c-0.01-0.06-0.03-0.14-0.04-0.23c-0.02-0.1-0.04-0.2-0.06-0.31c-0.02-0.11-0.04-0.23-0.07-0.38
                                        c-0.03-0.15-0.05-0.3-0.07-0.45c0.21-0.19,0.43-0.62,0.64-1.28c0.31-0.12,0.76-0.5,1.34-1.14c0.58-0.62,1.11-1,1.58-1.14
                                        c0.1-0.06,0.26,0,0.5,0.17c0.23,0.2,0.4,0.26,0.5,0.2c-0.02-0.1-0.01-0.24,0.03-0.44c0-0.06,0-0.12,0.01-0.17
                                        c0.01-0.06,0.02-0.1,0.03-0.13c0.01-0.03,0.02-0.05,0.02-0.07H640.65z M643.28,211.95c-0.16-0.56-0.36-0.85-0.61-0.85
                                        c0.18,0.16,0.28,0.49,0.32,0.99c0.02,0.58,0.06,0.98,0.12,1.2C643.37,212.96,643.43,212.51,643.28,211.95z"/>
                                    <path class="st1" d="M629.97,241.31c0.1-0.47,0.33-0.97,0.7-1.52c0.43-0.66,0.78-0.81,1.05-0.44c0.08,0.1-0.09,0.37-0.5,0.82
                                        c-0.47,0.47-0.75,0.85-0.85,1.14c-0.25,0.82-0.18,1.62,0.2,2.39c0.31,0.64,0.8,1.15,1.46,1.52c1.21,0.7,2.51,0.76,3.91,0.17
                                        c1.38-0.56,2.28-1.48,2.69-2.74c0.08-0.08,0.16-0.08,0.23,0c0.12,0.08,0.24,0.11,0.38,0.09c2.39-2.76,3.87-4.3,4.44-4.61
                                        c0.56-0.35,1.11-0.5,1.63-0.44c0.17,0.02,0.37,0.13,0.58,0.32c0.17,0.17,0.36,0.28,0.55,0.32c-0.1,0.74-0.3,1.32-0.61,1.75
                                        c0.47-0.08,0.73-0.58,0.79-1.52c0.06-0.93-0.2-1.5-0.79-1.69c-1.73-0.12-3.57,0.77-5.52,2.66c-2.61,2.51-4.07,3.86-4.38,4.06
                                        c-0.6,0.31-1.36,0.4-2.28,0.26c-1.01-0.14-1.6-0.54-1.75-1.2c-0.18-0.8,0.2-1.52,1.14-2.16c1.36-0.93,2.05-1.42,2.07-1.46
                                        c-0.31-0.49-0.49-0.89-0.53-1.2c-0.1-0.47,0.08-0.82,0.53-1.05c0.04,0.27,0.16,0.51,0.38,0.7l0.52,0.53l1.81-1.17
                                        c0.64-0.51,1.04-1.11,1.2-1.81c0.14-0.02,0.25,0.01,0.35,0.09c0.1,0.1,0.21,0.14,0.35,0.12c0.27-0.12,0.52-0.34,0.73-0.67
                                        l0.26-0.44c0.1-0.19,0.18-0.34,0.26-0.44c0.45-0.14,0.69-0.31,0.73-0.53c0.37-0.31,0.97-0.63,1.81-0.96
                                        c0.82-0.35,1.45-0.65,1.9-0.91c0.27-0.16,0.73-0.52,1.37-1.08l1.34-1.23c1.13-0.89,2.25-1.23,3.36-0.99
                                        c0.74,0.16,1.23,0.59,1.49,1.31c0.19,0.51,0.27,1.29,0.23,2.36c-0.12,3.02-0.11,4.72,0.03,5.11c0.7-0.14,1.37-0.46,2.01-0.96
                                        c0.08-0.06,0.35-0.28,0.82-0.67c0.27-0.25,0.53-0.47,0.79-0.64c0.39-0.29,0.95-0.67,1.69-1.14c0.86-0.56,1.46-0.97,1.81-1.23
                                        c1.95-1.48,3.68-2.48,5.2-3.01c2.43-0.86,4.3-0.61,5.6,0.73c0.74,0.74,1.24,1.95,1.49,3.62c0.08,0.43,0.19,1.2,0.32,2.31
                                        c0.14,1.09,0.26,1.9,0.38,2.42c0.14,0.54,0.27,1.02,0.41,1.43c0.06,0.17,0.12,0.37,0.17,0.58c0.06,0.21,0.11,0.37,0.15,0.48
                                        c0.04,0.11,0.07,0.2,0.09,0.28c0.1,0.53,0.18,1.06,0.23,1.61c0.1,0.74,0.15,1.21,0.17,1.4c0.29,1.58,0.85,2.86,1.66,3.85
                                        c1.01,1.25,2.28,1.78,3.8,1.61c0.99-0.12,3.3-1.11,6.92-2.98c0.53-0.29,1.06-0.6,1.61-0.93c0.58-0.33,1.15-0.62,1.69-0.88
                                        c1.34-0.62,2.58-0.85,3.71-0.67c0.29,1.23-0.17,2.22-1.4,2.98c-2.16,1.38-3.17,2-3.03,1.87c-0.14-0.16-0.2-0.21-0.18-0.18
                                        c-0.1-0.04-0.17-0.02-0.23,0.06c-0.27,0.16-2.78,1.98-7.53,5.46c-1.97,1.46-3.93,2.5-5.9,3.12c-2.86,0.93-4.95,0.62-6.28-0.93
                                        c-0.84-1.05-1.59-2.91-2.25-5.58c-1.32-5.35-2.04-9.55-2.16-12.58c-0.02-0.1-0.02-0.17,0-0.22c0.02-0.05,0.07-0.06,0.15-0.04
                                        c0.08,0.02,0.12-0.01,0.12-0.09c-0.02-0.06-0.04-0.12-0.06-0.17c-0.27-0.06-0.57,0.02-0.91,0.23c-0.02,0.02-0.05,0.04-0.09,0.07
                                        c-0.04,0.03-0.09,0.07-0.15,0.12c-0.06,0.05-0.11,0.09-0.15,0.13c-0.08,0.08-0.2,0.17-0.38,0.29c-0.23,0.14-4.14,2.92-11.74,8.35
                                        c-2.82,1.98-4.95,3.7-6.39,5.14c-1.67,1.65-2.84,2.71-3.5,3.15c-1.5,0.97-3.15,1.37-4.96,1.2c-1.25-0.14-2.07-0.52-2.48-1.14
                                        c-0.45-0.74-0.43-1.52,0.06-2.34c0.62-1.05,0.95-1.79,0.99-2.22c0.21-0.15,0.87-1.01,1.96-2.57c0.31-0.43,1.26-1.42,2.86-2.98
                                        c1.21-1.19,1.97-2.35,2.28-3.5c0.51-0.53,1.27-1.49,2.28-2.89c-0.68,0.21-1.47,0.7-2.36,1.46c-1.19,1.01-1.95,1.63-2.28,1.84
                                        l-0.06,0.47c-0.04,0.27-0.09,0.46-0.15,0.55c-0.25,0.06-0.59,0.28-1.02,0.67c-0.35,0.37-0.73,0.55-1.14,0.55
                                        c-0.45,0.99-1.28,1.62-2.48,1.87c-1.23,0.29-2.35,0.16-3.39-0.41c-0.21,0-0.3-0.11-0.26-0.32c0.02-0.25-0.04-0.42-0.17-0.5
                                        c0.02,0-0.01,0-0.09,0c-0.08,0-0.16,0-0.25-0.02c-0.09-0.01-0.19-0.01-0.31-0.01c-0.31-0.04-0.51-0.14-0.58-0.29
                                        c0.2-0.14,0.2-0.42,0.03-0.85c-0.27-0.62-0.42-0.99-0.44-1.11V241.31z M632.65,238.98c-0.12,0.16-0.2,0.26-0.25,0.32
                                        c-0.05,0.06-0.07,0.08-0.07,0.06c-0.08,0-0.12-0.07-0.12-0.2c0.02-0.14,0.03-0.24,0.03-0.32c0.04-0.02,0.1-0.05,0.18-0.09
                                        c0.08-0.02,0.12-0.06,0.12-0.12C632.75,238.73,632.79,238.84,632.65,238.98z M633.26,237.2c0.02,0.18,0,0.43-0.06,0.76
                                        c-0.06,0.35-0.08,0.61-0.06,0.79c-0.02-0.02-0.09-0.54-0.2-1.55H633.26z M643.74,242.63c-0.31,0.47-0.54,0.82-0.7,1.05
                                        c1.26-1.5,1.96-2.39,2.07-2.69c-0.08,0.14-0.16,0.19-0.23,0.15l-0.29-0.15v0.64c-0.02,0-0.05-0.02-0.09-0.06
                                        c-0.04-0.04-0.08-0.06-0.12-0.06C644.25,241.87,644.03,242.24,643.74,242.63z M656.59,241.58c0.47-0.35,0.7-0.71,0.7-1.08
                                        c-0.12,0.17-0.48,0.5-1.08,0.96c-0.49,0.37-0.78,0.66-0.88,0.88L656.59,241.58z"/>
                                    <path class="st1" d="M708.78,252.96c2.74-0.72,4.24-0.78,4.5-0.17c0.17,0.37-0.05,0.85-0.67,1.43c-0.41,0.39-0.88,0.72-1.4,0.99
                                        c-0.39,0.2-0.97,0.44-1.75,0.73c-0.31,0.12-0.64,0.24-0.99,0.38c-0.35,0.14-0.55,0.21-0.61,0.23c-2.08,0.95-4.12,2.04-6.1,3.27
                                        c-1.07,0.12-2.66,1.28-4.76,3.5c-1.05,1.11-2.2,2.47-3.44,4.09c-3.62,4.71-8.75,9.67-15.38,14.89c-3.7,2.9-7.18,4.47-10.45,4.7
                                        c-3.6,0.23-6.61-0.77-9.02-3.01c-1.52-1.4-2.34-3.05-2.45-4.93c-0.1-1.97,0.41-3.95,1.52-5.96c0.99-1.83,2.08-3.22,3.27-4.17
                                        c0.08-0.06,0.37-0.25,0.88-0.58c0.51-0.33,0.8-0.53,0.88-0.58c3.95-3.04,8.8-5.41,14.54-7.12c1.52-0.45,3.97-1.07,7.36-1.87
                                        c3.23-0.74,5.72-1.37,7.47-1.9c2.47-0.72,4-1.22,4.58-1.49c0.14-0.06,0.29-0.16,0.47-0.29l0.47-0.29
                                        c0.37,0.47,1.03,0.54,1.98,0.23c0.39-0.12,0.85-0.52,1.37-1.23c0.57-0.78,0.94-1.23,1.14-1.34c-0.04-0.14-0.13-0.33-0.26-0.58
                                        c-0.14-0.27-0.22-0.48-0.23-0.61c0.57-1.03,0.95-1.5,1.17-1.4c-0.21,0.21-0.2,0.37,0.06,0.47c0.35,0.14,0.52,0.23,0.52,0.29
                                        c0.35-0.14,1.05-0.86,2.1-2.19l1.84-2.34c-0.02-0.43,0.04-0.85,0.17-1.26c0.08-0.06,0.24-0.13,0.5-0.2c0.23-0.1,0.4-0.19,0.5-0.29
                                        c0.23-0.23,0.46-0.71,0.67-1.43c0.23-0.84,0.4-1.37,0.5-1.61c-0.62,0.25-1.87,0.72-3.74,1.4l-3.71,1.37
                                        c-3.25,1.23-6.03,1.84-8.35,1.84c-2,0-3.46-0.35-4.38-1.05c-0.91-0.7-1.29-1.74-1.14-3.12c0.17-1.75,1.37-3.81,3.59-6.19
                                        c1.6-1.75,3.43-3.14,5.52-4.17c3.74-1.89,8.92-3.23,15.56-4.03c1.56-0.21,3.26-0.19,5.11,0.09c0.72,0.08,1.84,0.42,3.36,1.02
                                        c1.32,0.51,2.4,0.78,3.24,0.82c0.76,0.02,1.93-0.26,3.5-0.85c1.93-0.72,3.02-1.08,3.27-1.08c0.66,0,1.39,0.35,2.19,1.05
                                        c0.78,0.7,1.17,1.32,1.17,1.87c-0.04,0.7-0.54,1.24-1.52,1.61c-0.47,0.16-0.9,0.28-1.28,0.38c-0.43,0.12-0.81,0.24-1.14,0.38
                                        c-0.86,0.35-3.08,1.6-6.68,3.74c-0.72,0.45-2.45,1.88-5.2,4.29c-9.48,8.39-14.47,12.76-14.97,13.11
                                        C705.21,253.76,706.78,253.49,708.78,252.96z M675.27,266.13c-0.95,0.31-1.72,0.6-2.31,0.88c-0.12,0.08-0.34,0.26-0.67,0.55
                                        c-0.33,0.33-0.57,0.54-0.73,0.61c-0.52,0.29-0.94,0.52-1.26,0.67c-0.56,0.27-0.98,0.5-1.26,0.67c-0.21,0.14-0.56,0.42-1.05,0.85
                                        c-0.6,0.54-0.95,0.85-1.05,0.91c-0.06,0.06-0.24,0.17-0.55,0.35c-0.19,0.1-0.37,0.2-0.52,0.32c-2.35,2.1-3.45,4-3.27,5.69
                                        c0.16,1.44,1.58,2.31,4.26,2.6c2.24,0.23,4.36-0.24,6.36-1.43c0.76-0.47,2.33-1.69,4.7-3.68l7.5-6.45c0.14-0.31,1.28-1.65,3.44-4
                                        c0.31-0.64,0.86-1.42,1.63-2.34c1.05-1.26,1.62-1.97,1.69-2.1c-2.98,0.88-7.79,2.58-14.45,5.11
                                        C677.54,265.42,676.71,265.68,675.27,266.13z M667.8,281.13c-0.58,0.1-0.94,0.18-1.08,0.26c0.04-0.1,0.01-0.16-0.09-0.18
                                        c-2.55-0.72-3.89-1.25-4.03-1.58c1.17,1.38,2.98,2,5.43,1.84C667.54,281.48,667.47,281.36,667.8,281.13z M687.44,267.41
                                        c-0.1-0.16-0.26-0.2-0.5-0.15c-4.03,4.79-8.54,8.88-13.54,12.29l0.03,0.06c0,0.06,0,0.12,0,0.17c0.02,0.14-0.02,0.25-0.12,0.35
                                        c-0.39,0.1-0.96,0.3-1.72,0.61c-0.76,0.31-1.33,0.53-1.72,0.64c2.34-0.39,4.96-1.78,7.88-4.17l6.51-5.52
                                        c1.73-1.52,3.71-3.58,5.92-6.19c2.9-3.35,4.8-5.47,5.69-6.36c0.04,0,0.06-0.01,0.06-0.03c0.04-0.04,0.02-0.06-0.06-0.06
                                        c-0.58,0.25-1.47,0.54-2.66,0.85C690.44,262.98,688.51,265.48,687.44,267.41z M686.1,259.91c-2.02,0.19-3.55,0.58-4.58,1.17
                                        c-0.06,0.02,0.06-0.01,0.37-0.1c0.3-0.09,0.83-0.23,1.59-0.42C684.23,260.36,685.11,260.14,686.1,259.91z M713.36,230.8
                                        c-0.12,0.41-0.36,0.57-0.73,0.5c-0.02,0-0.05-0.01-0.09-0.03c-0.04-0.02-0.1-0.04-0.19-0.07c-0.09-0.03-0.17-0.06-0.25-0.1
                                        c-0.12-0.04-0.31-0.09-0.58-0.15c-0.2-0.02-1.26,0.2-3.18,0.67c-0.62,0.16-1.63,0.44-3.01,0.85c-1.15,1.46-2.43,2.04-3.85,1.75
                                        c-0.31,0.08-0.67,0.38-1.08,0.9c-0.37,0.47-0.79,0.72-1.26,0.76c-0.74,0.66-1.58,2.56-2.51,5.69c0.6,0.58,1.55,0.73,2.83,0.44
                                        c0.19-0.02,0.73-0.17,1.61-0.44c0.56-0.17,1.07-0.28,1.52-0.32c0.21-0.37,0.86-0.71,1.93-1.02c0.25-0.1,0.57-0.24,0.96-0.44
                                        c0.47-0.21,0.81-0.37,1.02-0.47c0.12-0.04,0.4-0.08,0.85-0.12c0.45-0.04,0.73-0.09,0.85-0.15c0.12-0.06,0.25-0.17,0.41-0.32
                                        c0.12-0.14,0.22-0.25,0.32-0.35c0.82-0.52,1.97-0.86,3.44-0.99c0.93-0.12,2.03-0.41,3.3-0.88c1.4-0.53,2.39-0.85,2.98-0.96
                                        c-0.02,0.18,0.57-0.47,1.75-1.93c0.06,0.06-0.01,0.05-0.2-0.03c-0.27-0.12-0.88-0.42-1.81-0.9c-0.33,0.2-0.47,0.31-0.41,0.35
                                        c0-0.14-0.06-0.23-0.17-0.29c-0.04-0.02-0.12-0.09-0.23-0.2c-0.02,0.12-0.17,0.2-0.44,0.23c-0.25,0.02-0.45-0.01-0.58-0.09
                                        c0.04-0.16,0.04-0.23,0-0.23c-0.04,0.14-0.1,0.21-0.17,0.23v-0.32c-0.14-0.04-0.24,0-0.32,0.12c-0.1,0.14-0.2,0.19-0.32,0.15
                                        c0.04-0.21,0.06-0.35,0.07-0.41c0.01-0.06,0-0.1-0.01-0.12c-0.17-0.12-0.26-0.13-0.26-0.03c0,0.02,0.01,0.06,0.03,0.12v-0.23
                                        c1.28,0.47,2.17,0.49,2.66,0.06l-0.06,0.35c0.35-0.14,0.9-0.1,1.63,0.12c0.6,0.2,1.03,0.21,1.28,0.06
                                        c-0.04,0.14,0.07,0.27,0.32,0.41c-0.41-0.8-1.28-1.37-2.63-1.72c-0.62-0.16-1.84-0.33-3.65-0.53v0.35
                                        c-0.02,0.2-0.03,0.35-0.03,0.47c-0.04,0.33-0.17,0.5-0.38,0.5c0.08-0.29,0.08-0.73,0-1.31c-0.54-0.12-0.91,0.05-1.08,0.5
                                        c-0.02-0.16-0.05-0.27-0.09-0.34C713.5,230.81,713.44,230.79,713.36,230.8z M698.86,254.27c-0.16,0.14-0.25,0.28-0.29,0.44
                                        c-0.14-0.19-0.12-0.42,0.06-0.67c0.18-0.23,0.35-0.29,0.53-0.17C699.13,254,699.03,254.14,698.86,254.27z M699.64,253.54
                                        c-0.06-0.16-0.07-0.48-0.03-0.96c0.06-0.56,0.18-0.89,0.38-0.96C699.96,251.6,699.84,252.24,699.64,253.54z M712.28,238.83
                                        c-0.43-0.08-0.65,0-0.67,0.23C711.96,239.07,712.19,238.99,712.28,238.83z M715.14,232.79c-0.02-0.06-0.05-0.09-0.09-0.09
                                        c0-0.04,0.01-0.05,0.03-0.03c0.02,0,0.03,0.01,0.03,0.03l0.2-0.38l0.03,0.03c0.02,0.08-0.03,0.25-0.15,0.53
                                        C715.18,232.88,715.16,232.85,715.14,232.79z M721.39,233.23c-0.23,0.51-0.28,0.84-0.15,0.99c0.02-0.1,0.04-0.22,0.06-0.38
                                        L721.39,233.23z"/>
                                </g>
                            </g>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Poster1',
        props:{
            posterIndex:{
                type:Number,
                default:1,
            }
        },
        data() {
            return {
                bgs : [
                {
                    bgUrl:require('../assets/bg3.png'),
                    tipsUrl:require('../assets/3day.png'),
                    photoUrl:require('../assets/photo_tips3.png'),
                    rect:{x:495, y:220 ,width:182,height:26}
                },{
                    bgUrl:require('../assets/bg2.png'),
                    tipsUrl:require('../assets/2day.png'),
                    photoUrl:require('../assets/photo_tips2.png'),
                    rect:{x:536, y:234 ,width:120,height:15}
                },{
                    bgUrl:require('../assets/bg1.png'),
                    tipsUrl:require('../assets/1day.png'),
                    photoUrl:require('../assets/photo_tips1.png'),
                    rect:{x:520, y:234 ,width:147,height:15}
                },{}
            ],
                bx:0,
                by:0,
                w:320,
                h:640,
                bw:768,
                bh:1280,
            }
        },
        methods:{
        },
        mounted() {
           // this.start();

            this.w = this.$el.clientWidth;
            this.h = this.$el.clientHeight;
            if (this.w / this.h <765/1280) {
                this.bh = this.h;
                this.bw = this.h * (768/1280);
                this.bx = (this.bw - this.w)*0.5
            }else {
                this.bw = this.w;
                this.bh = this.bw / (768/1280);
                this.by = (this.bh - this.h)*0.5
            }

        },
        created() {
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .bg{
        overflow: hidden;
        position: absolute;
    }
    .bgimg{
        position: absolute;
    }
    .st0 {
        fill: #FFFFFF;
    }

    .st1 {
        fill: #FFFFFF;
    }

    .path1 {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        stroke: #4BA7E0;
        stroke-width: 0.5em;
    }

    .path2 {
        stroke-dasharray: 300;
        stroke-dashoffset: 300;
        stroke: #4BA7E0;
        stroke-width: 0.4em;
    }

    .path3 {
        stroke-dasharray: 800;
        stroke-dashoffset: 800;
        stroke: #4BA7E0;
        stroke-width: 1em;
    }

    .path5 {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        stroke: #4BA7E0;
        stroke-width: 0.32em;
    }

    .path4 {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        stroke: #4BA7E0;
        stroke-width: 0.4em;
    }
    .area {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
    }

    .tipsarea {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .bottomarea {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 0;
    }

    .svgarea {
        position: absolute;
        left: 13%;
        right: 13%;
        bottom: 20vw;
        font-size: 25px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    canvas{
        position: absolute;
        left: 0;
        top: 0;
        transform :translateY(-50%);
    }
</style>
